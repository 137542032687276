import HttpHandler from "@/common/services/connect/HttpHandler";
import LogService from "@/common/services/Log/LogService";
import { isUndefined } from "lodash";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type {Disclosure, DisclosureActives} from "@/common/data/Disclosure";

type DisclosureModel = Disclosure & DisclosureActives

@injectable()
class DisclosureFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService
  ) {}

  get(documentCode: string): Promise<Disclosure> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/disclosure/${documentCode}`,
      {},
      ResponseTypes.Payload
    );
  }

  hasStoreId(disclosure: Disclosure) {
    return !isUndefined(disclosure.disclosureStoreId);
  }

  hasPrintActive(disclosure: DisclosureModel) {
    return disclosure.printActive;
  }

  hasEmailActive(disclosure: DisclosureModel) {
    return disclosure.emailActive;
  }

  isAllowEmail(disclosure: Disclosure) {
    return disclosure.allowEmail;
  }

  isAllowPrint(disclosure: Disclosure) {
    return disclosure.allowPrint;
  }
}

export default DisclosureFactory;
