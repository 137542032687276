import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import ResponseTypes from '@/common/enums/responseTypesEnum'
import type {WorkflowDecision} from './WorkflowDecisionFactory'

@injectable()
class WorkflowStateFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(workflowName: string): Promise<Array<WorkflowState>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state/`,
      {},
      ResponseTypes.Payload
    )
  }
  get(workflowName: string, stateId: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state/${stateId}`,
      {},
      ResponseTypes.Payload
    )
  }
  getByQuery(workflowName: string, queryParams: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state`,
      queryParams,
      ResponseTypes.Payload
    )
  }
  save(workflowName: string, stateId: string, data: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state/${stateId}`,
      data,
      {},
      ResponseTypes.Payload
    )
  }
  delete(workflowName: string, stateId: number) {
    return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state/${stateId}`)
  }
  create(workflowName: string, data: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI_WORKFLOW}/${workflowName}/state/`,
      data,
      {},
      ResponseTypes.Payload
    )
  }
}

export default WorkflowStateFactory

export type WorkflowState = {
  back: boolean
  createdTime: string
  decisions: WorkflowDecision[]
  description: string
  descriptionKey: string
  disclosureCode: any
  locked: boolean
  nextStateId: number | null
  ruleUUID: string
  state: string
  stateId: number
  title: string
  titleKey: string
  type: WorkflowStateTypes
  updatedTime: string
  x: number
  y: number
}

export const enum WorkflowStateTypes {
  INFO = 'INFO',
  ACTION = 'ACTION',
  DECISION = 'DECISION',
  RULE_DECISION = 'RULE_DECISION',
  DEFAULT = 'EMAIL'
}

export const enum DisclosureStateTypes {
  SHOW_DISCLOSURE = 'SHOW_DISCLOSURE',
  CONGRATULATIONS = 'CONGRATULATIONS'
}
