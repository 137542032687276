import { injectable } from "inversify";
import { isEmpty } from "lodash";

@injectable()
class RemainingTypeCodesFilter {
  remainingTypeCodes(
    typeList: { typeCode: string }[],
    objectList: { [x: string]: string | number | boolean }[],
    currentObject: { [x: string]: string | number | boolean },
    typeCodeName: string
  ) {
    currentObject = currentObject || {};
    const filteredList = typeList.filter((type: { typeCode: string }) => {
      return (
        !objectList.some((obj) => {
          return obj[typeCodeName] === type.typeCode;
        }) || type.typeCode === currentObject[typeCodeName]
      );
    });
    if (!isEmpty(currentObject)) {
      const foundIdx = filteredList.findIndex((el) => el.typeCode === currentObject[typeCodeName]);
      filteredList.push(...filteredList.splice(0, foundIdx));
    }
    return filteredList;
  }
}

export default RemainingTypeCodesFilter;
