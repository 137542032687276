import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type IPerson from "@/common/models/IPerson";
import type IApplicant from "@/common/models/IApplicant";

@injectable()
class ApplicantPersonFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }
  async set(workspaceUUID: string, applicant: IApplicant): Promise<IApplicant> {
    return this.get(workspaceUUID, applicant.applicantId)
      .then((response) => {
        applicant.person = response;
        return applicant;
      });
  }

  //Get the applicant with UUIS and Applicant Id
  async get(workspaceUUID: string, applicantId: number): Promise<IPerson> {
    return this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/person`,
        {},
        ResponseTypes.Payload
    );
  }

  // Update the appplicant with UUID, Applicant id and person details
  async put(workspaceUUID: string, applicantId: number, person: any) {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/person`, person, {}, ResponseTypes.Payload);
  }

  // Post the applicant with UUID, Applicant id and person details
  async post(workspaceUUID: string, applicantId: number, person: any) {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/person`, person, {}, ResponseTypes.Payload);
  }

  // Delete the applicant with UUID, Applicant id and personId details
  async delete(workspaceUUID: string, applicantId: number, personId: string) {
    return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/person`, {}, ResponseTypes.Data);
  }

  // Save the applicant details with UUID, Applicant id and person
  async save(workspaceUUID: string, applicantId: number, person: any) {
    return person.personId
      ? this.put(workspaceUUID, applicantId, person)
      : this.post(workspaceUUID, applicantId, person);
  }
}

export default ApplicantPersonFactory;
