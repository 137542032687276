export class ApplicantConstant {
  static readonly APPLICANT: string = "APPLICANT";
  static readonly PERSON_APPLICANTS: string = "personApplicants";
  static readonly BUSINESS_APPLICANTS: string = "businessApplicants";
  static readonly APPLICANT_LOCKED: string = "This applicant is locked, changes may not be made at this time.";
  static readonly APPLICANT_EXISTING: string = "This applicant is an existing user that was saved to the core.";
  static readonly PERSON_INFO_WORKFLOW: string = "PERSON INFO WORKFLOW";
  static readonly BUSINESS_INFO_WORKFLOW: string = "BUSINESS INFO WORKFLOW";
  static readonly ENROLLMENT_WORKFLOW: string = "ENROLLMENT WORKFLOW";
}
