import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { cloneDeep } from "lodash";
import ERROR from "@/common/enums/errorEnum";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import type IHttpErrorHandler from "../services/connect/IHttpErrorHandler";
import type AxiosResponseInterceptor from "@/common/interfaces/axios/AxiosResponseInterceptor";
import type { AxiosError } from "axios";

export default class CommonResponseInterceptor implements AxiosResponseInterceptor {

  onFulfilled<T, V>(response: V): Promise<T> | T {
    return Promise.resolve(response) as unknown as T;
  }

  onRejected(axiosError: AxiosError): Promise<AxiosError> {

    const broadcastService = $inj(BroadcastService);
    const httpErrorHandler = $injByInterface<IHttpErrorHandler>("IHttpErrorHandler");

    // todo(mikol): This should be localized to each screen, this is way too magical and makes error handling very inflexible...
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const toastResponse = cloneDeep(axiosError.response) as any;
      toastResponse.data.errors = toastResponse.data.errors.filter((error: { code: string }) => {
        return !~[
          ERROR.UNAUTHORIZED,
          ERROR.EXPIRED,
          ERROR.INVALID,
          ERROR.REVOKED,
          ERROR.MISSING_LOGIN,
          ERROR.APPLICANT_UNAUTHORIZED,
          ERROR.EOD_ERROR
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ].indexOf(error.code);
      });
      broadcastService.broadcast(BroadcastConstant.DISPLAY_TOAST, toastResponse);
    } catch (e: unknown) {
      console.error("Error occurred while trying to broadcast error notifications in CommonResponseInterceptor", e)
    }

    // todo(mikol): What is the difference between this and the broadcast, they seem to do the same thing :S?
    try {
      if (httpErrorHandler) {
        httpErrorHandler.handle(axiosError.response);
      }
    } catch (e: unknown) {
      console.error("Error occured when attempting to call httpErrorHandler in CommonResponseInterceptor", e)
    }

    return Promise.reject(axiosError);

  }

}
