import { injectable } from "inversify";

  /**
   * @deprecated
   * OpenThemeProvider Service is not used
   */
@injectable()
class OpenThemeProvider {
  public _PALETTES: any;
  constructor() {
    this._PALETTES = {
      blue: "blue",
      red: "red"
    };
  }

  definePalette(name: string, app: any) {
    return;
  }

  extendPalette(name: string, app: any) {
    return;
  }

  parsePalette(app: any) {
    return;
  }

  defineTheme(themeName: string, DTO: any) {
    return;
  }

  theme(themeName: string) {
    return;
  }
  primaryPalette(name: string) {
    return;
  }

  accentPalette(name: string) {
    return;
  }
}

export default OpenThemeProvider;
