import Base64Factory from "@/common/services/utils/Base64Factory";
import HttpHandler from "@/common/services/connect/HttpHandler";

import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type { IdentifcationImage } from "@/common/models/IIdentificationImage";

@injectable()
class ApplicantIdentificationImageFactory {
  constructor(
    @inject(Base64Factory) private base64Factory: Base64Factory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  async get(
    workspaceUUID: string,
    applicantId: number,
    identificationId: number,
    imageId: number
  ): Promise<IdentifcationImage> {
    return await this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/identification/${identificationId}/image/${imageId}`,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        const encodedResponse = this.base64Factory.getFromUnencoded(response.imageDataBase64, response.imageFormat);
        return {
          imageType: response.imageType,
          imageId: response.imageId,
          encodedBase64: encodedResponse.encodedBase64,
          imageDataBase64: encodedResponse.imageDataBase64,
          imageFormat: encodedResponse.imageFormat
        };
      });
  }

  async getImageList(
    workspaceUUID: string,
    applicantId: number,
    identificationId: number,
    imageList: IdentifcationImage[]
  ): Promise<IdentifcationImage[]> {
    return Promise.all(
      imageList.map((image) => {
        return this.get(workspaceUUID, applicantId, identificationId, image.imageId);
      })
    );
  }
}
export default ApplicantIdentificationImageFactory;
