const ERROR = Object.freeze({
  GENERAL_ERROR: "GENERAL_ERROR",
  APPLICANT_UNAUTHORIZED: "OPEN_NOT_AUTHORIZED",
  UNAUTHORIZED: "",
  EXPIRED: "AUTH_APITOKEN_EXPIRED",
  INVALID: "AUTH_APITOKEN_NOTVALID",
  REVOKED: "AUTH_APITOKEN_REVOKED",
  MISSING_LOGIN: "AUTH_HEADER_MISSING_LOGIN",
  OPEN_EXPIRED: "OPEN_API_TOKEN_EXPIRED",
  OPEN_UNAUTHORIZED: "OPEN_NOT_AUTHORIZED",
  OPEN_ABORT: "ABORT_PROCESSING_APPLICATION",
  EOD_ERROR: "JXCHANGE_CORE_EOD_PROCESSING_ERROR",
  ERROR: "ERROR",
  TIMEOUT: "timeout",
  RULE_COMPONENT_MESSAGE: "RULE_COMPONENT_MESSAGE",
  RULE_VALIDATOR_MESSAGE: "RULE_VALIDATOR_MESSAGE",
  DISCLOSURE_TO_EMAIL_INVALID: "DISCLOSURE_TO_EMAIL_INVALID",
  ERROR_HEADING: "Error occoured",
  APPLICANT_DEMOGRAPHIC_RACE: "APPLICANT_DEMOGRAPHIC_RACE",
  CHALLENGE_VALIDATION_FAILED: "CHALLENGE_VALIDATION_FAILED",
  WORKSPACE_IS_CLOSED: "WORKSPACE_IS_CLOSED",
  BRANCH_MAPPING_NOT_FOUND: "BranchMappingNotFound",
  REQUEST_FAILED: "RequestFailed",
  APPLICANT_DOES_EXIST_IN_CORE: "APPLICANT_DOES_EXIST_IN_CORE",
  NCR_INVALID_PASSWORD: "NCR_INVALID_PASSWORD",
  NCR_PROPERTIES_NOT_CONFIGURED: "NCR_PROPERTIES_NOT_CONFIGURED",
  WORKSPACE_NOT_FOUND: "WORKSPACE_NOT_FOUND",
  JXCHANGE_ERROR: "JXCHANGE_ERROR",
  CREATE_APPLICANT_IN_CORE_FAILED: "CREATE_APPLICANT_IN_CORE_FAILED",
  IMAGE_FAILED_PROCESS: "IMAGE_FAILED_PROCESS",
  APPLICANT_TYPE_NOT_PERSON: "APPLICANT_TYPE_NOT_PERSON",
  REQUEST_NOT_PROCESSED: "REQUEST_NOT_PROCESSED",
  DATA_INTEGRITY_VIOLATION: "DATA_INTEGRITY_VIOLATION",
  AUTH_HEADER_NOT_FOUND: "AUTH_HEADER_NOT_FOUND",
  AUTH_HEADER_NOT_FOUND_OR_INVALID: "AUTH_HEADER_NOT_FOUND_OR_INVALID",
  AUTH_PASSWORD_EXPIRED: "AUTH_PASSWORD_EXPIRED",
  AUTH_CREDENTIALS_FAIL: "AUTH_CREDENTIALS_FAIL",
  AUTH_TFA_CODE_EXPIRED: "AUTH_TFA_CODE_EXPIRED",
  SESSION_EXPIRED: "expired",
});

export default ERROR;
