import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class ApplicantQuestionnaireQuestionFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getOne(uuid: string, applicantId: number, questionnaireId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/questionnaire/${questionnaireId}/question/current`,
      {},
      ResponseTypes.Payload
    );
  }

  async post(
    uuid: string,
    applicantId: number,
    questionnaireId: number,
    question: { componentId: number },
    answers: any
  ) {
    const message = {
      cddRequestId: questionnaireId,
      questionId: question.componentId,
      answers: answers,
    };

    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/questionnaire/${questionnaireId}/question/${question.componentId}/answer`,
      message,
      {},
      ResponseTypes.Payload
    );
  }
}

export default ApplicantQuestionnaireQuestionFactory;
