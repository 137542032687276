const bltClickOutside = {
    beforeMount(el: { clickOutsideEvent: { (evt: any): void; (this: Document, ev: MouseEvent): any; }; contains: (arg0: any) => any; }, binding: { value: (arg0: any, arg1: any) => void; }) {
        el.clickOutsideEvent = (evt) => {
            evt.stopPropagation();
            if (!(el === evt.target || el.contains(evt.target))) {
                binding.value(evt, el);
            }
        };
        window.requestAnimationFrame(() => {
            document.addEventListener("click", el.clickOutsideEvent);
        });
    },
    unmounted(el: { clickOutsideEvent: (this: Document, ev: MouseEvent) => any; }) {
        document.removeEventListener("click", el.clickOutsideEvent);
    },

}

export default bltClickOutside;