import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_CONSTANTS from "@/common/constant/servicePathConstants";
import workspaceConstants from "@/common/constant/WorkspaceConstants";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class WorkspaceSearchFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async search(
    searchType: string,
    searchBy: string,
    queryList: { typeCode: string; value: string }[],
    workspaceUUID: string,
    showClosed?: boolean
  ) {
    switch (searchType) {
      case WORKFLOW_TYPES.PERSON:
        switch (searchBy) {
          case workspaceConstants.TAX_ID:
            return this.searchPersonWithTaxId(workspaceUUID, queryList, showClosed);
          default:
            return this.searchPerson(workspaceUUID, queryList);
        }
      case WORKFLOW_TYPES.WORKSPACE:
        switch (searchBy) {
          case workspaceConstants.TAX_ID:
            return this.searchWorkspaceWithTaxId(queryList, showClosed);
          case workspaceConstants.EIN:
            return this.searchWorkspaceWithEin(queryList, showClosed);
          default:
            return this.searchWorkspace(queryList);
        }
    }
  }

  searchPerson(workspaceUUID: string, queryList: { typeCode: string; value: string }[]) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/customerSearch`,
      { params: this.getParams(queryList) },
      ResponseTypes.Payload
    );
  }

  searchPersonWithTaxId(workspaceUUID: string, query: { value: string }[], showClosed?: boolean) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/customerSearch`,
      {
        headers: { taxId: this.withoutNonNumeric(query[0].value) },
        params: { showclosed: showClosed ? true : undefined }
      },
      ResponseTypes.Payload
    );
  }

  searchWorkspace(queryList: { typeCode: string; value: string }[]) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.BOLTSBRANCH}/workspace`,
      { params: this.getParams(queryList) },
      ResponseTypes.Payload
    );
  }
  /* workspace api query parameters:
  /workspace
    ?query=(String)
    &type=(PERSON|BUSINESS).   (optional)
    &status=(OPEN|CLOSED).     (optional)
    &channel=(BRANCH|ENDUSER). (optional)
    &user=(String).            (optional)
    &limit=(Number)            (Defaults to 10)
    &page=(number)             (Defaults to 0)
  */
  searchWorkspaceV1(queryList: string) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.BOLTSBRANCH}/workspace?query=${queryList}`,
      {},
      ResponseTypes.Payload
    );
  }
  searchWorkspaceWithEin(query: { value: string }[], showClosed?: boolean) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.BOLTSBRANCH}/workspace`,
      {
        headers: { taxId: this.withoutNonNumeric(query[0].value) },
        params: { queryType: workspaceConstants.BUSINESS, closed: showClosed ? undefined : false }
      },
      ResponseTypes.Payload
    );
  }

  searchWorkspaceWithTaxId(query: { value: string }[], showClosed?: boolean) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.BOLTSBRANCH}/workspace`,
      {
        headers: { taxId: this.withoutNonNumeric(query[0].value) },
        params: { queryType: workspaceConstants.PERSON, closed: showClosed ? undefined : false }
      },
      ResponseTypes.Payload
    );
  }

  formatEmailParam(value: string): string {
    return value;
  }

  formatLastNameParam(value: string): string {
    return value;
  }

  formatFirstNameParam(value: string): string {
    return value;
  }

  formatBusinessName(value: string): string {
    return value;
  }

  withoutNonNumeric(value: string): string {
    return value.replace(/\D/g, "");
  }
  
  formatPhoneExtParam(value: string): string {
    return this.withoutNonNumeric(value);
  }

  formatPhoneParam(value: string): string {
    return value.replace(/\D/g, "");
  }

  formatTaxIdParam(value: string): string {
    return this.withoutNonNumeric(value);
  }

  formatEinParam(value: string): string {
    return this.withoutNonNumeric(value);
  }

  backendTypeCodeMap = {
    FIRST_NAME: workspaceConstants.FIRST_NAME,
    LAST_NAME: workspaceConstants.LAST_NAME,
    TAX_ID: workspaceConstants.TAXID,
    PHONE: workspaceConstants.PHONE,
    PHONE_EXT: workspaceConstants.PHONE_EXT,
    EMAIL: workspaceConstants.EMAIL,
    BUSINESS_NAME: workspaceConstants.BUSINESS_NAME
  };

  paramFormatters = {
    FIRST_NAME: this.formatFirstNameParam,
    LAST_NAME: this.formatLastNameParam,
    TAX_ID: this.formatTaxIdParam,
    PHONE: this.formatPhoneParam,
    PHONE_EXT: this.formatPhoneExtParam,
    EMAIL: this.formatEmailParam,
    EIN: this.formatEinParam,
    BUSINESS_NAME: this.formatBusinessName
  };

  getParams(queryList: { typeCode: string; value: string }[]) {
    return queryList.reduce((acc: { [x: string]: string }, cur: { typeCode: string | number; value: string }) => {
      if (this.paramFormatters[cur.typeCode as keyof typeof this.paramFormatters](cur.value)) {
        acc[this.backendTypeCodeMap[cur.typeCode as keyof typeof this.backendTypeCodeMap]] = this.paramFormatters[
          cur.typeCode as keyof typeof this.paramFormatters
        ](cur.value);
      }
      return acc;
    }, {});
  }
}
export default WorkspaceSearchFactory;
