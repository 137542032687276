import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
@injectable()
class CoreLoginService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async post() {
    return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/coreping`, {}, {}, ResponseTypes.Payload);
  }
}
export default CoreLoginService;
