<template>
  <div class="md-avatar" :style="{ 'background-color': color.hex, color: textColor }">
    <span v-if="(firstName || lastName) && !fontIcon"> {{ firstName?.charAt(0) }}{{ lastName?.charAt(0) }} </span>
    <span v-if="fontIcon"><i class="fa" :class="{ fontIcon }"></i></span>
    <span v-if="!firstName && !lastName && !fontIcon"><i class="fa fa-user"></i></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { $inj } from "@/common/decorators/depinject";
import IntToPalletFilter from "@/common/services/filters/intToPaletteFilter";
import BltColorsFilter from "@/common/services/filters/bltColorsFilter";

export default defineComponent({
  name: "bltAvatarIcon",
  props: {
    firstName: {
      type: String
    },
    lastName: {
      type: String
    },
    applicantId: {
      type: Number
    },
    fontIcon: {
      type: String
    },
    customColor: {
      type: String
    }
  },
  setup() {
    const intToPalette = $inj(IntToPalletFilter);
    const bltColors = $inj(BltColorsFilter);
    return {
      intToPalette,
      bltColors
    };
  },
  data() {
    return {
      color: {} as { contrast: number[]; hex: string },
      textColor: "" as string
    };
  },
  methods: {
    onInit() {
      if (this.applicantId) {
        this.color = this.intToPalette.intToPalette(this.applicantId);
      } else if (this.customColor) {
        this.color = { hex: this.bltColors.bltColors(this.customColor), contrast: [255, 255, 255, 0.9] };
      } else {
        this.color = { hex: "#787480", contrast: [255, 255, 255, 0.9] };
      }

      this.color.contrast[3] = this.color.contrast[3] || 1.0;

      this.textColor = `rgba(${this.color.contrast[0]},${this.color.contrast[1]},${this.color.contrast[2]},${this.color.contrast[3]})`;
    }
  },
  created() {
    this.onInit();
  }
});
</script>

<style scoped>
blt-avatar-icon .md-avatar {
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  box-sizing: content-box;
  flex: none;
  width: 40px;
  height: 40px;
}

blt-avatar-icon .md-avatar span {
  font-size: 19px;
  text-transform: uppercase;
}

blt-avatar-icon .md-avatar span .fa-university {
  -webkit-transform: translate(0.75px, -0.25px);
  transform: translate(0.75px, -0.25px);
}
</style>
