<template>
  <jha-form-text-input
    outline
    class="input"
    name="bltSingleLookup"
    :list="'list'"
    :value="bltSelectedLookup"
    :listOptions="options"
    v-blt-ui-text="placeholder"
    :required="isRequired"
    :warning="errorMessageRequired"
    @change="onChange($event)"
  >
    <jha-button v-if="showClearButton && bltSelectedLookup"
                @click="clear"
                sync
                icon
                slot="input-right">
      <jha-icon-circle-close/>
    </jha-button>
  </jha-form-text-input>
</template>
<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import { defineComponent } from "vue";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import BltLookupFactory from "@/common/components/bltLookup/bltLookupFactory";
import { find } from "lodash";

export default defineComponent({
  name: "bltSingleLookup",
  emits: ["onLookupChange", "onError", 'clear'],
  props: {
    showClearButton: {
      type: Boolean,
      required: false,
      default: false
    },
    lookupType: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: "",
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    selectedLookup: {
      type: String
    },
    isSingleLookup: {
      type: Boolean,
      default: false
    },
    typeCode: {
      type: String,
      default: "",
      required: true
    },
    require: {
      type: Boolean
    },
    errorMessageRequired: {
      type: String
    }
  },
  data: () => ({
    selectedItems: [],
    bltSelectedLookup: "",
    options: [],
    searchText: "",
    selectedValue: "",
    lookupData: []
  }),
  setup() {
    const bltLookupFactory = $inj(BltLookupFactory);
    return {
      bltLookupFactory
    };
  },
  created() {
    this.querySearch(this.searchText);
    this.bltSelectedLookup = this.selectedLookup || "";
  },
  computed: {
    isRequired() {
      return this.require;
    }
  },
  watch: {
    typeCode(newVal: string) {
      if (newVal) this.findSelected();
    }
  },
  methods: {
    clear(event: CustomEvent<string>) {
      this.bltSelectedLookup = '';
      this.$emit('clear');
    },
    onChange(event: any) {
      if (event.detail && event.detail.indexOf(" - ") > 0) {
        this.$emit("onLookupChange", event.detail.split(" - ")[0]);
      } else {
        this.$emit("onError", event.detail.split(" - ")[0]);
      }
    },
    querySearch(query: string | string[][] | Record<string, string>) {
      this.bltLookupFactory.getLookup(this.lookupType, query).then((response) => {
        this.lookupData = response;
        this.options = response.map((item: any) => {
          return { value: item.typeCode + " - " + item.typeDescription, typeCode: item.typeCode };
        });
        this.findSelected();
      });
    },
    findSelected() {
      if (this.typeCode && this.lookupData) {
        const result = find(this.lookupData, { typeCode: this.typeCode }) as any;
        if (result) {
          this.bltSelectedLookup = result?.typeCode + " - " + result?.typeDescription || "";
        }
      }
    }
  }
});
</script>
