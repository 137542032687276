import BltModalDialog from "@/common/components/bltDialog/bltModalDialog.vue";
import {injectable} from "inversify";
import {Service} from "@/common/dependency.configs";
import {createApp, h} from "vue";

@injectable()
@Service
export class ModalUtil {
    prompt(
        title: string,
        body: string,
    ): Promise<void> {
        const elem = document.createElement('div')

        return new Promise((res, rej) => {
            const app = createApp(h(
                BltModalDialog,
                {
                    title: title,
                    messageBody: body,
                    type: 'small-dialog',
                    showCancel: true,
                    showConfirm: true,
                    confirmLabel: "Yes, confirm",
                    cancelLabel: "Cancel",
                    onCancel: () => { rej(); app.unmount() },
                    onConfirm: () => { res(); app.unmount() },
                },
            ))

            const component = app.mount(elem)
            component.open()
        })
    }
}