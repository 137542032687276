
import { injectable } from "inversify";

@injectable()
class LogService {
  public error(config: any) {
    console.error(config);
  }

  public info(config: any) {
    console.info(config);
  }

  public log(config: any) {
    console.log(config);
  }

  public warn(config: any) {
    console.warn(config);
  }

  public debug(config: any) {
    console.debug(config);
  }
}
export default LogService;
