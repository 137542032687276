import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentOwnershipFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  setOwnershipType(workspaceUUID: string, enrollmentId: number) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/ownership`,
      {},
      {},
      ResponseTypes.Data
    );
  }
}
export default EnrollmentOwnershipFactory;
