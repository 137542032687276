import NotificationFactory from "@/common/services/notificationFactory";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type { Validation } from "@/common/models/IStateValidationResponse";

@injectable()
class EnrollmentValidationFactory {
  constructor(
    @inject(NotificationFactory) private notificationFactory: NotificationFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  async getValidation(workspaceUUID: string, enrollmentId: number, state: string): Promise<Validation> {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/validate?type=${state}`,
      {},
      ResponseTypes.Payload
    );
    return response[0];
  }

  async checkValidation(workspaceUUID: string, enrollmentId: number, state: string) {
    const response = await this.getValidation(workspaceUUID, enrollmentId, state);
    if (Array.isArray(response.errors) && response.errors.length > 0) {
      this.notificationFactory.showData(response);
      return Promise.reject(response.errors);
    }
    return response;
  }
}
export default EnrollmentValidationFactory;
