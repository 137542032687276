import { inject, injectable } from "inversify";
import BltDateFilter from "@/common/services/filters/bltDateFilter";
import FILTER_MSG from "@/common/messages/filter.messages";

@injectable()
class EnrollmentSummaryFilter {
    constructor(@inject(BltDateFilter) private bltDateFilter: BltDateFilter) {}
    enrollmentSummary(enrollment: { enrollmentType?: string; enrollmentState?: string; createdDate?: any; }){
        if (!enrollment) {
            return FILTER_MSG.ENROLLMENT_SUMMARY.NEW_ENROLLMENT;
        }
        enrollment.enrollmentType = enrollment.enrollmentType || FILTER_MSG.ENROLLMENT_SUMMARY.ENROLLMENT;;
        enrollment.enrollmentState = enrollment.enrollmentState || FILTER_MSG.ENROLLMENT_SUMMARY.NEW_WORKSPACE;;
        enrollment.createdDate = enrollment.createdDate || FILTER_MSG.ENROLLMENT_SUMMARY.NA;;
        return `${enrollment.enrollmentType} ${FILTER_MSG.ENROLLMENT_SUMMARY.ACCOUNTS} ${enrollment.enrollmentState} ${FILTER_MSG.ENROLLMENT_SUMMARY.CREATED} ${this.bltDateFilter.bltDate(enrollment.createdDate)}`
    }

}

export default EnrollmentSummaryFilter;