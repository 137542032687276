export class LocalStorageKeyConstant {
  static readonly APITOKEN: string = "apitoken"
  static readonly LOGIN: string = "login"
  static readonly GUID: string = "guid"
  static readonly CACHE_STORE: string = "cache_store"
  static readonly USER: string = "user"
  static readonly CREATED_BY_ID: string = "created_by_id"
  static readonly CREATED_BY_LABEL: string = "created_by_label"
  static readonly SSO_UUID: string = "sso_uuid"
  static readonly IS_SSO_REDIRECT: string = "is_sso_redirect"
  static readonly SSO_REDIRECT_WORKSPACE_UUID = "sso_redirect_workspace_uuid"
  static readonly SSO_REDIRECT_TRACKER_UUID = "sso_redirect_tracker_uuid"
  static readonly SSO_REDIRECT_PROVIDER_UUID = "sso_redirect_provider_uuid"
  static readonly SSO_REDIRECT_APPLICANT_ID = "sso_redirect_applicant_id"
}