const CIP = Object.freeze({
    API_STATUS: {
        FAILED: 'FAILED',
        INPROGRESS: 'INPROGRESS',
        INCOMPLETE: 'INCOMPLETE',
    },
    API_DECISION: {
        ACCEPT: 'ACCEPT',
        REJECT: 'REJECT',
    },
    UI_STATUS: {
        PENDING_REVIEW: 'PENDING_REVIEW',
        API_ACCEPTED: 'API_ACCEPTED',
        API_REJECTED: 'API_REJECTED',
        USER_ACCEPTED: 'USER_ACCEPTED',
        USER_REJECTED: 'USER_REJECTED',
        FAILED: 'FAILED',
        INPROGRESS: 'INPROGRESS',
        PRISTINE: 'PRISTINE',
    },
    UI_TITLE_KEY: {
        PENDING_REVIEW: 'branch_cip_title_review_service',
        API_ACCEPTED: 'branch_cip_title_accepted',
        API_REJECTED: 'branch_cip_title_rejected',
        USER_ACCEPTED: 'branch_cip_title_validated',
        USER_REJECTED: 'branch_cip_title_denied',
        FAILED: 'branch_cip_title_failed',
        INPROGRESS: 'branch_cip_title_pending',
        PRISTINE: 'branch_cip_title_pristine',
    },
    UI_SECONDARY_KEY: {
        PENDING_REVIEW: 'branch_cip_secondary_review_service',
        API_ACCEPTED: 'branch_cip_secondary_accepted',
        API_REJECTED: 'branch_cip_secondary_rejected',
        USER_ACCEPTED: 'branch_cip_secondary_validated',
        USER_REJECTED: 'branch_cip_secondary_denied',
        FAILED: 'branch_cip_secondary_failed',
        INPROGRESS: 'branch_cip_secondary_pending',
        PRISTINE: 'branch_cip_secondary_pristine',
    },
    UI_ICON_COLOR: {
        PENDING_REVIEW: 'DEFAULT',
        API_ACCEPTED: 'SUCCESS',
        API_REJECTED: 'ERROR',
        USER_ACCEPTED: 'SUCCESS',
        USER_REJECTED: 'ERROR',
        FAILED: 'WARNING',
        INPROGRESS: 'DEFAULT',
        PRISTINE: 'DEFAULT',
    },
    UI_ICON_CLASS: {
        PENDING_REVIEW: 'fa-ellipsis-h',
        API_ACCEPTED: 'fa-check',
        API_REJECTED: 'fa-times',
        USER_ACCEPTED: 'fa-check',
        USER_REJECTED: 'fa-times',
        FAILED: 'fa-exclamation-triangle',
        INPROGRESS: 'fa-ellipsis-h',
        PRISTINE: 'fa-shield',
    },
});

export default CIP;