import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class CompleteQuestionnaireFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  async get(workspaceUUID: string, resource: string, resourceId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/${resource}/${resourceId}/questionnaire`
      , {}, ResponseTypes.None
    );
  }

  save(workspaceUUID: string, resource: string, resourceId: number, questionnaireId: number, questionnaire: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}/complete`
      , questionnaire, {}, ResponseTypes.None
    );
  }
}
export default CompleteQuestionnaireFactory;
