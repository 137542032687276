import { defineStore } from "pinia";
import { WorkflowConstant } from "@/common/constant/WorkflowConstant";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type IWorkspace from "@/common/models/IWorkspace";

export const WorkspaceStore = defineStore({
  id: "WorkspaceStore",
  state: (): IWorkspaceStore => ({
    workspace: {} as IWorkspace,
    workspaceType: "",
    workspaceUUID: "",
    trackerUUID: "",
    activeApplicantId: 0,
    applicant: null,
    applicantsCount: 0,
    workflow: {
      workflowUUID: "",
      currentState: null,
      previousState: null,
      direction: WorkflowConstant.NEXT,
      transitioning: false
    },
    transitionInfo: {
      currentNumber: 0,
      remaining: 0,
      percentage: 0,
      preloading: false
    },
    loading: false,
    enrollment: {
      enrollmentId: 0,
      workspaceUUID: ""
    },
    account: {
      accountId: 0
    }
  })
});
