import LookupFactory from "@/common/services/Lookup/LookupFactory";
import { inject, injectable } from 'inversify';
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from '@/common/enums/responseTypesEnum';
import { EnrollmentConstant } from "@/common/constant/EnrollmentConstant";
import type {IFundingCompleted} from "@/common/models/IFunding";
import type RestResponse from "@/common/data/RestResponse";
import type {FundingTypeList} from "@/common/data/FundingTypeList";

@injectable()
class EnrollmentFundingFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler,
        @inject(LookupFactory) private lookupFactory: LookupFactory
    ) { }

    async getFundingType(productId: number, fundingCode: string | number) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/product/${productId}/funding/${fundingCode}`, {}, ResponseTypes.Payload);
    }

    async getFundingTypeList(productId: number): Promise<FundingTypeList> {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/product/${productId}?include=funding`, {}, ResponseTypes.Payload);
    }

    setAccountFundingTypeList(account: { accountTypeId: number, fundingTypes: any[] }) {
        return this.getFundingTypeList(account.accountTypeId)
            .then((response) => {
                account.fundingTypes = response.funding;
                return account;
            });
    }

    async setDefaultFundingTypes(accountList: { fundingTypes: { typeCode: string }[], fundingDTO: { fundingType: string } }[]) {
        return accountList.map((account) => {
            if (account.fundingTypes.length === 1) {
                account.fundingDTO = {
                    fundingType: account.fundingTypes[0].typeCode,
                }
            }
            return account;
        });
    }

    setAccountListFundingTypeList(accountList: any[]) {
        return Promise.all(accountList.map((account) => {
            return this.setAccountFundingTypeList(account);
        }));
    }

    async getFunding(workspaceUUID: string, enrollmentId: number, accountId: number) {
        return Promise.all([
            this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/funding`, {}, ResponseTypes.Payload),
            this.lookupFactory.Funding?.getAllFlat(EnrollmentConstant.TYPE_CODE, { enabled: true })
        ]).then((response) => {
            return response[0].map((confirmation: { fundingTypeDescription: string; fundingType: string }) => {
                confirmation.fundingTypeDescription = response[1][confirmation.fundingType].typeDescription;
                return confirmation;
            });
        });
    }

    getAccountFunding(workspaceUUID: string, enrollmentId: number, accountId: number): Promise<RestResponse<Array<IFundingCompleted>>> {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/funding`)
    }

    async setAccountFunding(workspaceUUID: string, enrollmentId: number, account: { accountId: number; completedFunding: any }) {
        return this.getFunding(workspaceUUID, enrollmentId, account.accountId)
            .then((response) => {
                account.completedFunding = response;
                return account;
            });
    }

    async setAccountListFunding(workspaceUUID: string, enrollmentId: number, accountList: any[]) {
        return Promise.all(accountList.map((account) => {
            return this.setAccountFunding(workspaceUUID, enrollmentId, account);
        }));
    }

    async postFunding(workspaceUUID: string, enrollmentId: number, accountId: number, message: { accountId: number }): Promise<IFundingCompleted> {
        message.accountId = accountId;
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/funding`, message, {}, ResponseTypes.Payload);
    }

    async postAllFunding(workspaceUUID: string, enrollmentId: number, message: any) {
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}`, message, {}, ResponseTypes.Payload);
    }

}
export default EnrollmentFundingFactory;