<script setup lang="ts">
import WorkspaceHistoryService, {
  type ApplicantInfo,
  type WorkspaceHistory
} from '@/common/services/WorkspaceHistory/workspaceHistoryService'
import {computed, onMounted, ref} from 'vue'
import {$inj} from '@/common/decorators/depinject'
import type IPerson from '@/common/models/IPerson'
import '@banno/jha-wc/src/jha-avatar/jha-avatar'
import {FullApplicantNameFilter} from '@/common/filters/filters.module'
import dayjs from 'dayjs'
import WorkspaceHistoryStateService, {
  type WorkspaceHistoryState, type WorkspaceHistoryStateDecision
} from '@/common/services/WorkspaceHistory/workspaceHistoryStateService'

const props = defineProps<{
  historyItem: WorkspaceHistory
  nextHistory?: WorkspaceHistory
  applicantInfo: Map<number, ApplicantInfo>
}>()

const fullApplicantNameFilter = $inj(FullApplicantNameFilter)
const workspaceHistoryStateService = $inj(WorkspaceHistoryStateService)

const state = ref<WorkspaceHistoryState>()

onMounted(() => {
  if (props.historyItem.state) {
    workspaceHistoryStateService
      .getState(props.historyItem.state.workflow, props.historyItem.state.state)
      .then((response) => (state.value = response.data.payload))
  }
})

const currentApplicant = computed(() => {
  return props.historyItem.state?.activeApplicantId
    ? props.applicantInfo.get(props.historyItem.state?.activeApplicantId)
    : undefined
})

const fullName = computed(() => {
  return fullApplicantNameFilter.fullApplicantName({
    ...currentApplicant.value?.applicant,
    ...currentApplicant.value?.person,
    ...currentApplicant.value?.business
  })
})

const initials = computed(() => {
  return currentApplicant.value?.applicant.applicantType == 'PERSON'
    ? `${currentApplicant.value?.person?.firstName?.substring(
        0,
        1
      )}${currentApplicant.value?.person?.lastName?.substring(0, 1)}`
    : `${currentApplicant.value?.business?.businessName?.substring(0, 1)}`
})

const iconComponent = computed(() => {
  return currentApplicant.value?.applicant.applicantType == 'BUSINESS' ? 'jha-icon-business' : 'jha-icon-user'
})

const decisionSelected = (decision: WorkspaceHistoryStateDecision) => {
  return props.nextHistory?.state?.state === decision.nextStateId.toString()
}

const duration = computed(() => {
  if (props.nextHistory) {
    const nextItemDayjs = dayjs(props.nextHistory.createdTimestamp)
    const currentItemDayjs = dayjs(props.historyItem.createdTimestamp)

    let diff = nextItemDayjs.diff(currentItemDayjs, 'hours')

    if (diff > 0) {
      return `${diff} ${diff == 1 ? 'hour' : 'hours'}`
    }
    diff = nextItemDayjs.diff(currentItemDayjs, 'minutes')

    if (diff > 0) {
      return `${diff} ${diff == 1 ? 'minute' : 'minutes'}`
    }
    diff = nextItemDayjs.diff(currentItemDayjs, 'seconds')

    if (diff > 0) {
      return `${diff} ${diff == 1 ? 'second' : 'seconds'}`
    }

    diff = nextItemDayjs.diff(currentItemDayjs, 'milliseconds')

    if (diff > 0) {
      return `${diff} ${diff == 1 ? 'millisecond' : 'milliseconds'}`
    }
  }
})
</script>

<template>
  <jha-flex-wrapper
    space-between
    style="flex-wrap: nowrap">
    <jha-flex-wrapper style="flex: 1 0 auto; margin: var(--jh-space-300) var(--jh-space-200) var(--jh-space-300) 0">
      <jha-avatar
        style="margin-right: var(--jh-space-400)"
        v-if="currentApplicant"
        :initials="initials">
        <jha-icon-business slot="icon" />
        <jha-icon-user slot="icon" />
      </jha-avatar>

      <component
        class="history-avatar"
        :is="iconComponent"
        v-if="!currentApplicant">
      </component>

      <jha-flex-wrapper style="flex-direction: column; align-items: flex-start">
        <div class="jh-font-body-medium-1 jha-text-dark">{{ fullName }}</div>
        <div class="jh-font-helper-regular">{{ duration }}</div>
      </jha-flex-wrapper>
    </jha-flex-wrapper>

    <jha-flex-wrapper
      style="
        flex-direction: column;
        align-items: flex-end;
        flex: 1 1 100%;
        margin: var(--jh-space-300) var(--jh-space-200) var(--jh-space-300) 0;
      ">
      <div class="jh-font-body-medium-1 jha-text-dark">{{ state?.title }}</div>
      <div class="jh-font-helper-regular">{{ state?.description }}</div>
    </jha-flex-wrapper>
  </jha-flex-wrapper>

  <div
    class="decisions-section"
    v-if="state?.decisions.length">
    <ul>
      <li
        class="jh-font-body-regular-1"
        v-for="decision in state.decisions"
        :key="decision.stateDecisionId">
        <jha-flex-wrapper>
          <div v-if="decisionSelected(decision)">
            <jha-icon-checkmark/>
          </div>
          <div style="width: 24px" v-else/>

          &nbsp;
          {{ decision.display }}
        </jha-flex-wrapper>
      </li>
    </ul>
  </div>
</template>

<style scoped>
@import '../../../assets/typography.css';
@import '../../../assets/typography-colors.css';

.decisions-section {
  background-color: #f5f5f5;
  padding: var(--jh-space-400) var(--jh-space-600) var(--jh-space-400) var(--jh-space-300);
}

.history-avatar {
  margin-right: var(--jh-space-400);
  background: #eee;
  fill: #5f6468;
  padding: 12px;
  height: 24px;
  border-radius: 100%;
  width: 24px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

jha-avatar {
  background: #eeeeee;
  --jha-avatar-initials-text-color: #5f6468;
  --jha-avatar-initials-background-color: #eeeeee;
  --jha-avatar-font-size: 18px;
}
</style>
