import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import type RestResponse from '@/common/data/RestResponse'
import MEDIA_TYPE from '@/common/enums/mediaTypeEnum'

@injectable()
export default class QuestionnaireFactoryAdmin {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(): Promise<RestResponse<Array<Questionnaire>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire`)
  }

  get(questionnaireId: number): Promise<RestResponse<Questionnaire>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}`)
  }

  getAllDrafts(questionnaireId: number): Promise<RestResponse<Array<QuestionnaireDraft>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft`)
  }

  getDraft(questionnaireId: number, draftId: number): Promise<RestResponse<QuestionnaireDraft>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}`
    )
  }

  getComponents(questionnaireId: number, cddDraftId: number): Promise<RestResponse<Array<QuestionnaireComponent>>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${cddDraftId}/component`
    )
  }

  create(dto: Questionnaire): Promise<RestResponse<Questionnaire>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/`, dto)
  }

  createDraft(questionnaireId: number): Promise<RestResponse<QuestionnaireDraft>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft`)
  }

  createDraftComponent(
    questionnaireId: number,
    draftId: number,
    dto: QuestionComponent
  ): Promise<RestResponse<QuestionnaireComponent>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/component`,
      dto
    )
  }

  createScoreRange(
    questionnaireId: number,
    draftId: number,
    dto: QuestionnaireComponentScoreRange
  ): Promise<RestResponse<QuestionnaireComponentScoreRange>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/scorerange`,
      dto
    )
  }

  createDraftComponentChoice(
    questionnaireId: number,
    draftId: number,
    questionId: number,
    dto: QuestionComponentChoice
  ): Promise<RestResponse<QuestionnaireComponentChoice>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/question/${questionId}/choice`,
      dto
    )
  }

  update(dto: QuestionnaireCreate): Promise<RestResponse<Questionnaire>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${dto.questionnaireId}`, dto)
  }

  updateDraft(questionnaireId: number, dto: QuestionnaireDraft): Promise<RestResponse<QuestionnaireDraft>> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${dto.draftId}`,
      dto
    )
  }

  updateDraftComponent(
    questionnaireId: number,
    draftId: number,
    dto: QuestionnaireComponent
  ): Promise<RestResponse<QuestionnaireComponent>> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/component/${dto.componentId}`,
      dto
    )
  }

  updateDraftComponentChoice(
    questionnaireId: number,
    draftId: number,
    questionId: number,
    dto: QuestionnaireComponentChoice
  ): Promise<RestResponse<QuestionnaireComponentChoice>> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/question/${questionId}/choice/${dto.choiceId}`,
      dto
    )
  }

  updateScoreRange(
    questionnaireId: number,
    draftId: number,
    dto: QuestionnaireComponentScoreRange
  ): Promise<RestResponse<QuestionnaireComponentScoreRange>> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/scorerange/${dto.rangeId}`,
      dto
    )
  }

  delete(questionnaireId: number): Promise<RestResponse<Questionnaire>> {
    return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}`)
  }

  deleteDraft(questionnaireId: number, draftId: number): Promise<RestResponse<QuestionnaireDraft>> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}`
    )
  }

  deleteDraftComponent(questionnaireId: number, draftId: number, componentId: number): Promise<null> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/component/${componentId}`
    )
  }

  deleteDraftComponentChoice(
    questionnaireId: number,
    draftId: number,
    questionId: number,
    choiceId: number
  ): Promise<null> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/question/${questionId}/choice/${choiceId}`
    )
  }

  deleteScoreRange(
    questionnaireId: number,
    draftId: number,
    scoreRangeId: number
  ): Promise<RestResponse<QuestionnaireDraft>> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/scorerange/${scoreRangeId}`
    )
  }

  download(questionnaireId: number): Promise<RestResponse<QuestionnaireExport>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/export`)
  }

  import(questionnaireId: number, dto: FormData): Promise<RestResponse<QuestionnaireShortDraft>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/import`, dto, {
      headers: {'Content-Type': MEDIA_TYPE.MULTIPART_FORM_DATA}
    })
  }

  promoteDraft(questionnaireId: number, draftId: number): Promise<RestResponse<null>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/questionnaire/${questionnaireId}/draft/${draftId}/promote`
    )
  }
}

export type QuestionnaireCreate = Omit<Questionnaire, 'components' | 'drafts' | 'scoreModel'>

export type Questionnaire = {
  questionnaireId?: number
  questionnaireUUID?: string
  questionnaireName: string
  description?: string
  version?: number
  enabled: boolean
  createdDate?: string
  updatedDate?: string
  components?: Array<QuestionnaireComponent>
  drafts?: Array<QuestionnaireShortDraft>
  scoreModel: QuestionnaireComponentScore
}

export type QuestionnaireShortDraft = {
  components: Array<QuestionnaireComponent>
  createDate: string
  draftId: number
  locked: boolean
  updatedDate: string
  version: number
}

export type QuestionnaireDraft = {
  scoreModel: QuestionnaireComponentScore
} & QuestionnaireShortDraft

export type QuestionnaireComponent = {
  componentId: number
  componentType: (typeof QuestionnaireComponentTypes)[keyof typeof QuestionnaireComponentTypes]
  position: {
    x: number
    y: number
  }
  next?: number // only for start and stop
  questionText: string
  questionType?: (typeof QuestionTypeCodes)[keyof typeof QuestionTypeCodes]
  choices?: Array<QuestionnaireComponentChoice>
  categoryId?: number
}

export type QuestionComponent = {
  componentType: (typeof QuestionnaireComponentTypes)[keyof typeof QuestionnaireComponentTypes]
  questionText: string
  questionType: (typeof QuestionTypeCodes)[keyof typeof QuestionTypeCodes]
}

export type QuestionComponentChoice = {
  weight: string
  choiceText: string | null
  next: number | null
}

export type QuestionnaireComponentChoice = {
  choiceId: number | null
  weight: string
  choiceText: string | null
  next: number | null
  // FE field
  id: string
}

export type QuestionnaireComponentScore = {
  scoreType: (typeof QuestionnaireScoreTypes)[keyof typeof QuestionnaireScoreTypes]
  scoreRanges: Array<QuestionnaireComponentScoreRange>
}

export type QuestionnaireEdge = {
  source: number
  choiceText: string
  next: number
}

export const enum QuestionnaireScoreTypes {
  None = 'NONE',
  Total = 'TOTAL',
  Count = 'COUNT'
}

export const enum QuestionnaireComponentTypes {
  Start = 'START',
  Stop = 'STOP',
  Question = 'QUESTION',
}

export type QuestionnaireComponentScoreRange = {
  rangeId: number | null
  rangeName: string
  rangeFrom: number | null
  rangeTo: number | null
  rangeOrder: number
  countScoreRanges: number | null
  // FE field
  id: string
}

export type QuestionnaireExport = {
  description: string
  name: string
  manifest: Record<string, object>
}