import { injectable, inject } from "inversify";
import LogService from "@/common/services/Log/LogService";
import OpenThemeProvider from "@/common/services/OpenThemeProvider/OpenThemeProvider";
import { ColorsConstant } from "@/common/constant/ColorsConstant";
import FILTER_MSG from "@/common/messages/filter.messages";

@injectable()
class IntToPalletFilter {
  constructor(
    @inject(LogService) private logService: LogService,
    @inject(OpenThemeProvider) private openThemeProvider: OpenThemeProvider
  ) {}

  intToPalette(int: number) {
    if (!this.openThemeProvider) {
      return this.logService.warn(FILTER_MSG.INT_TO_PALLETE.WARN_FETCHING_RANDOM_COLOR_MSG);
    }
    const accessibleColors = ColorsConstant.ACCESSIBLE_COLORS;
    const palette = Object.keys(this.openThemeProvider._PALETTES)[
      int % Object.keys(this.openThemeProvider._PALETTES).length
    ];
    const colorKey = accessibleColors[int % accessibleColors.length];
    return this.openThemeProvider._PALETTES[palette][colorKey];
  }
}

export default IntToPalletFilter;
