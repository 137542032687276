import TimeoutService from "@/common/services/utils/TimeoutService";
import { inject, injectable } from "inversify";

@injectable()
class Dbounce {
  constructor(@inject(TimeoutService) private timeoutService: TimeoutService) { }
  create(timeout: number) {
    const values = {
      loading: true,
      visible: false
    };

    this.timeoutService.timeoutPromise(timeout).then(() => {
      if (values.loading) {
        values.visible = true;
      }
    });

    function get() {
      return values;
    }

    function visible() {
      return values.visible;
    }

    function loading() {
      return values.loading;
    }

    function finish() {
      values.loading = false;
      values.visible = false;
    }

    return {
      finish,
      get,
      visible,
      loading
    };
  }

  createDelay(timeout: number, callback: any) {
    const values = {
      loading: true,
      visible: false
    };

    this.timeoutService.timeoutPromiseX(timeout).then(() => {
      if (values.loading) {
        values.visible = true;
      }
      values.loading = false;
      callback();
    });

    function get() {
      return values;
    }

    function visible() {
      return values.visible;
    }

    function loading() {
      return values.loading;
    }

    function finish() {
      values.loading = false;
      values.visible = false;
    }

    return {
      finish,
      get,
      visible,
      loading
    };
  }
}

export default Dbounce;
