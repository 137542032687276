import NcrFactory from "@/common/services/Vendor/Ncr/NcrFactory";
import { injectable, inject } from "inversify";
import NcrDialogService from "@/common/services/Dialogs/NcrDialogFactory/ncrDialogService";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
@injectable()
class NcrDialogFactory {
  constructor(
    @inject(NcrFactory) private ncrFactory: NcrFactory,
    @inject(NcrDialogService) private ncrDialogService: NcrDialogService
  ) {}

  async display(workspaceUUID: string, applicant: { applicantId: number }) {
    const registration = await this.ncrFactory.get(workspaceUUID, applicant.applicantId);

    if (registration) {
      return Promise.resolve(registration);
    }

    const dialogOption = {
      fullscreen: false,
      escapeToClose: false,
      multiple: true,
      data: {
        registration,
        applicant,
        showNcrDialog: true,
        workspaceUUID
      }
    };

    return this.ncrDialogService.show(dialogOption);
  }
}
export default NcrDialogFactory;
