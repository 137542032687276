import {defineStore} from 'pinia'

export const BltSidenavStore = defineStore({
  id: 'BltSidenavStore',
  state: () => ({
    sidenavOpen: false
  }),
  actions: {
    toggleSidenav() {
    }
  }
})
