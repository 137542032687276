import { injectable, inject } from "inversify";
import LogService from "@/common/services/Log/LogService";
import ConstantFactory from "@/common/services/utils/ConstantFactory";
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";
import FILTER_MSG from "@/common/messages/filter.messages";

@injectable()
class BltColorsFilter {
  constructor(
    @inject(ConstantFactory) private constantFactory: ConstantFactory,
    @inject(LogService) private logService: LogService
  ) {}

  bltColors(color: string) {
    const bltColors = this.constantFactory.getProp(OpenLanguageKeysConstant.ALL_BLT_COLORS);
    const returnval = bltColors.filter((bltColor: { typeCode: string }) => {
      return color === bltColor.typeCode;
    });

    if (returnval.length) {
      return returnval[0].color;
    } else {
      const warnMsg = FILTER_MSG.BLT_COLORS.INVALID_TYPECODE.replace("${color}", color);
      this.logService.warn(warnMsg);
    }
  }
}

export default BltColorsFilter;
