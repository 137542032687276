//Deprecated
const actions = {
  toggleOn(el: any) {
    if (!el.childElementCount) {
      el.append(
        "<div class='blt-attention-animation'" +
          "</div>"
      );
      el.append(
        "<div class='blt-attention-animation'" +
          "></div>"
      );
    } 
  },

  toggleOff(el: any) {
    Array.from(el.children).forEach((element: any) => {
      const jqLiteElem = el(element);
      if (jqLiteElem.hasClass("blt-attention-animation")) {
        jqLiteElem.remove();
      }
    });
  }
};

const bltButtonAttention = {
  mounted: (el: any) => {
    setTimeout(() => {
      setInterval(() => {
        if (!el.disabled) {
          actions.toggleOn(el);
        } else {
          actions.toggleOff(el);
        }
      }, 100);
    }, 500);
  }
};

export default bltButtonAttention;
