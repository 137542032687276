import { injectable, inject } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import DEPENDENCYTYPES from "@/common/dependency.types";
import { HttpHandler } from "@/common/services/services.module";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import type AuthorizeResponse from "@/common/data/AuthorizeResponse";
@injectable()
class OpenAuthFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private store: IWorkspaceMetadataStore,
    private localStore = useLocalStorage()
  ) { }

  async authorize(workspaceUUID?: string): Promise<AuthorizeResponse> {
    const initialPromise = (workspaceUUID) ? this.httpHandler.get("{{none}}/ping", {}, ResponseTypes.Payload) : Promise.reject();
    return initialPromise
      .then(function () {
        return 'AUTHORIZED';
      }, () => {
        return this.httpHandler.get("/v1/authorize");
      })
      .then((response) => {
        if (response !== 'AUTHORIZED') {
          this.localStore.apitoken = response.data.payload.apitoken
        }
        return response;
      });
  }

}
export default OpenAuthFactory;
