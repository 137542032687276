import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from 'inversify';
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type ApplicantTestimony from "@/common/data/ApplicantTestimony";

@injectable()
class ApplicantTestimonyFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    get(workspaceUUID: string, applicantId: number) {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/testimony`, {}, ResponseTypes.Payload);
    }
    save(workspaceUUID: string, applicantId: number, dto: ApplicantTestimony) {
        /**
         * @TODO: needs cleanup so that this is deleted in the state component
         */
        if (!dto.backupWithholding && dto.backupWithholdingReason) {
            delete dto.backupWithholdingReason
        }

        return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/testimony`, dto, {}, ResponseTypes.Payload);
    }
}
export default ApplicantTestimonyFactory;