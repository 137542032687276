<template>
  <blt-modal-dialog
    :disclosureHeightHotfix="true"
    @confirm="acceptDisclosure"
    @cancel="closeDisclosure"
    showConfirm
    :show-footer="showFooter"
    :confirmLabel="acceptText"
    :title="disclosure.disclosureTitle"
    :showDialog="showDisclosureDialog"
    :aria-label="disclosure.disclosureTitle"
    :confirmDisabled="disclosureStatus"
    type="large-dialog"
  >
    <template v-slot:dialog-content>
      <iframe
        id="disclosureId"
        title="disclosure"
        class="disclosure-content-iframe"
        ref="disclosureContent"
        v-blt-iframe="{
          html: disclosure.disclosure,
          dictionary: disclosure.data,
          disclosureStatus: disclosure.status,
          modalStatus: modalStatus
        }"
      >
      </iframe>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DisclosureConstant } from "@/common/constant/DisclosureConstant";
import { DialogConstant } from "@/common/constant/DialogConstant";
import { LanguageFactory } from "@/common/services/services.module";
import { $inj } from "@/common/decorators/depinject";
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";

export default defineComponent({
  name: "disclosureDialog",
  emits: [DisclosureConstant.DISCLOSURE_MODAL_CLOSE, "acceptDisclosure"],
  props: {
    disclosure: {
      required: true,
      type: Object as PropType<Disclosure>
    },
    showFooter: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDisclosureDialog: {
      required: true,
      type: Boolean
    },
    isFinalDisclosure: {
      type: Boolean,
      default: false
    },
    isAccountApplicantDisclosure: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      DisclosureConstant,
      modalStatus: "",
      acceptText: ""
    };
  },
  setup() {
    const languageFactory = $inj(LanguageFactory);
    return {
      languageFactory
    };
  },
  async created() {
    if (this.isFinalDisclosure) {
      this.acceptText = this.languageFactory.getSynchronous(OpenLanguageKeysConstant.OPEN_FINAL_DISCLOSURE_BUTTON_ACCEPT);
    } else if (this.isAccountApplicantDisclosure) {
      this.acceptText = this.languageFactory.getSynchronous(OpenLanguageKeysConstant.OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_ACCEPT);
    } else {
      this.acceptText = this.languageFactory.getSynchronous(OpenLanguageKeysConstant.OPEN_INITIAL_DISCLOSURE_BUTTON_SUBMIT);
    }
  },
  computed: {
    disclosureStatus() {
      return this.disclosure.status === DisclosureConstant.COMPLETE ? true : false;
    }
  },
  methods: {
    acceptDisclosure() {
      this.$emit("acceptDisclosure");
      this.modalStatus = DialogConstant.OPEN;
    },
    closeDisclosure() {
      this.$emit(DisclosureConstant.DISCLOSURE_MODAL_CLOSE);
      this.modalStatus = DialogConstant.CLOSE;
    }
  }
});
</script>
<style scoped>
.disclosure-content-iframe {
  width: 100%;
  border: 0;
  min-height: calc(86vh - 150px);
  overflow: hidden;
}

.disclosure-content-iframe input {
  width: 100%;
}

@media (max-width: 599px) {
  .disclosure-content-iframe {
    min-height: calc(82vh - 90px);
  }
}
@media (max-width: 375px) {
  .disclosure-content-iframe {
    min-height: calc(77vh - 90px);
  }
}
@media (max-width: 360px) {
  .disclosure-content-iframe {
    min-height: calc(76vh - 90px);
  }
}
</style>
