import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import DEPENDENCYTYPES from "@/common/dependency.types";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type AccountClassificationCodes from "@/common/data/AccountClassificationCodes";

@injectable()
class BusinessTypesFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  getAll() {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/mapping/business/type`,
      {},
      ResponseTypes.Payload
    );
  }
  update(businessType: { typeCode: string }) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/mapping/business/type/${businessType.typeCode}`,
      businessType,
      {},
      ResponseTypes.Payload
    );
  }
  getAcctClsfCodes(): Promise<Array<AccountClassificationCodes>> {
    return this.httpHandler.get(
      `{{boltscore}}/lookup/AcctClsfCode/type`,
      { cache: true },
      ResponseTypes.Payload
    );
  }
  getTaxIdTypes(typeCode: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/type/business-tax-id/${typeCode}`,
      {},
      ResponseTypes.Payload
    );
  }
  updateTaxId(typeCode: string, dto: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/type/business-tax-id/` + typeCode,
      dto,
      {},
      ResponseTypes.Payload
    );
  }
}

export default BusinessTypesFactory;
