import { injectable, inject } from "inversify";
import LogService from "@/common/services/Log/LogService";
import HttpHandler from "@/common/services/connect/HttpHandler";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";

@injectable()
class LanguageFactory {
  //Deprecated: consumer code should maintain such implementation separately in store
  private editModeEnabled: boolean;




  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(BroadcastService) private broadcastService: BroadcastService
  ) {
    this.editModeEnabled = false;
  }

  //Deprecated: consumer code should maintain such implementation separately in store
  getEditMode() {
    this.logService.info("getEditMode: is deprecated during rewrite by architect. please use store by consumer code!");
    return this.editModeEnabled;
  }

  //Deprecated: consumer code should maintain such implementation separately in store
  setEditMode() {
    this.logService.info("getEditMode: is deprecated during rewrite by architect. please use store by consumer code!");
    this.editModeEnabled = !this.editModeEnabled;
    this.broadcastService.broadcast(BroadcastConstant.BLT_UI_TEXT_TOGGLE_EDIT_MODE);
  }

  async getAll() {
    return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/message`, {
      params: {
        /**
         * @Note: Excluding the `.join(',')` produces:
         * `categoryNotIn[]=62&categoryNotIn[]=63`,
         * which is correct, but not what spring framework is expecting if the request param is List<Int>.
         * It is expecting `categoryNotIn=62,63`
         */
        categoryNotIn: [
            OMITTED_MESSAGE_CATEGORIES["Workflow Decision"],
            OMITTED_MESSAGE_CATEGORIES["Workflow State"]
        ].join(',')
      },
      cache: true
    }, ResponseTypes.Payload)
      .then(response => {
        this.workspaceMetadataStore.messages = response;
        return response;
      })
  }

  async getAllIn(messageCodes: Array<string>): Promise<Array<string>> {
    return Promise.all(messageCodes.map(code => this.get(code)))
  }

  get(messageCode: string, ignoreCache?: boolean): Promise<string> {
    if (this.workspaceMetadataStore.messages[messageCode] && !ignoreCache) {
      return Promise.resolve(this.workspaceMetadataStore.messages[messageCode]);
    } else {
      return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/message/${messageCode}`, {}, ResponseTypes.Payload)
        .then(response => {
          this.workspaceMetadataStore.messages[messageCode] = response;
          return response;
        })
    }
  }

  getSynchronous(messageCode: string): string {
    if (this.workspaceMetadataStore.messages[messageCode]) {
      return this.workspaceMetadataStore.messages[messageCode];
    } else {
      this.logService.error("Could not find ui message value for [" + messageCode + "] in sync mode!");
      return "";
    }
  }


  async put(messageCode: string, value: any) {
    return await this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/language/eng/message/${messageCode}`, { value: value }, {}, ResponseTypes.Payload);
  }
}

export enum OMITTED_MESSAGE_CATEGORIES {
  "Workflow Decision" = 62,
  "Workflow State" = 63
}

export default LanguageFactory;
