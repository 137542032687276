<script lang="ts">
import "@jack-henry/jh-ui/components/card/card.js";
import { $inj } from "@/common/decorators/depinject";
import LanguageFactory from "@/common/services/Language/LanguageFactory";
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltCard",
  props: {
    headerText: {
      type: String
    },
    headerIcon: {
      type: String
    },
    bltUiText: {
      type: String
    }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory);
    return {
      languageFactory
    };
  },
  data() {
    return {
      headerTitle: "" as string
    };
  },
  created() {
    if (this.headerText) this.headerTitle = this.headerText;
    else if (this.bltUiText) {
      this.languageFactory.get(this.bltUiText).then((text) => {
        this.headerTitle = text;
      });
    }
  }
});
</script>
<template>
  <jh-card v-bind:title="headerTitle" show-header-divider>
    <div slot="jh-card-header" class="jh-card-header jh-card-header-position">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <slot name="body"></slot>
    </div>
    <div slot="jh-card-footer" class="card-footer">
      <slot name="footer"></slot>
    </div>
  </jh-card>
</template>
<style>
:root {
  --jh-card-footer-space-padding: 0px;
}
</style>
