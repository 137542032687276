import { clone } from "lodash";

class AddressUtil {
  splitZip(DTO: any) {
    //Using clone because one variable
    const message: any = clone(DTO);
    if (message.zipCode.length > 5) {
      message.zipCode = message.zipCode.replace("-", "");
      message.zipCodeSuffix = message.zipCode.substr(5, message.zipCode.length);
      message.zipCode = message.zipCode.substr(0, 5);
    } else {
      delete message.zipCodeSuffix;
    }

    return message;
  }

  getMergedZipCode(zipCode: any, zipCodeSuffix: any) {
    return (zipCode || "") + (zipCodeSuffix || "");
  }
}

export default AddressUtil
