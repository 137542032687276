import type { AxiosInstance } from "axios";
import type AxiosResponseInterceptor from "@/common/interfaces/axios/AxiosResponseInterceptor";
import type AxiosRequestInterceptor from "@/common/interfaces/axios/AxiosRequestInterceptor";

export function useAxiosResponseInterceptor(axiosInstance: AxiosInstance, responseInterceptor: AxiosResponseInterceptor) {
  axiosInstance
    .interceptors
    .response
    .use(
      responseInterceptor.onFulfilled,
      responseInterceptor.onRejected
    )
}

export function useAxiosRequestInterceptor(axiosInstance: AxiosInstance, requestInterceptor: AxiosRequestInterceptor) {
  axiosInstance
    .interceptors
    .request
    .use(
      requestInterceptor.onFulfilled,
      requestInterceptor.onRejected
    )
}