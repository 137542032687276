<template>
  <jha-collapsible-group class="header-collapsible">
    <jha-collapsible-section 
    :class="{'collapsible-section' : index !== currentIndex }"
      v-for="(item, index) in items"
      :key="item"
      :isOpen="toggleAccordion(index)"
      @click="accordionClicked(index)"
      :headless="isHeadless"
      :disabled="isDisabled(index)"
    >
      <slot :item="item" :index="index" :selectedIndex="currentIndex" :selectedItem="currentItem"></slot>
    </jha-collapsible-section>
  </jha-collapsible-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-group/jha-collapsible-group.js";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-section/jha-collapsible-section.js";

export default defineComponent({
  name: "bltAccordion",
  emits: ["accordion-opened"],
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    disabledArray: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: this.selectedIndex, //set to 0 to keep first accordion item open on component load
      currentItem: this.items[this.selectedIndex]
    };
  },
  computed: {
    isHeadless(): boolean {
      if (this.showHeader) return false;
      return this.items.length <= 1;
    }
  },
  methods: {
    toggleAccordion(index: number): boolean {
      return this.currentIndex === index;
    },
    accordionClicked(index: number): void {
      if (!this.isDisabled(index)) {
        this.currentIndex = index;
        this.currentItem = this.items[this.currentIndex];
        this.$emit("accordion-opened", this.currentIndex, this.currentItem);
      }
    },
    isDisabled(index: number) {
      if (this.disabledArray.length) {
        return this.disabledArray?.some((item) => item === index);
      }
      return false;
    }
  }
});
</script>

<style scoped>
jha-collapsible-section {
  --jha-background-color: var(--jha-background-color);
}
</style>
