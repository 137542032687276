import { injectable } from "inversify";
import dayjs from 'dayjs';


@injectable()
class RelativeDateFilter {
  relativeDate(filterDate: Date, showSeconds?: boolean) {
    const date = new Date(filterDate);

    //const delta = Math.round((new Date().getTime() - date.getTime()) / 1000),
    const delta = Math.round((date.getTime() - new Date().getTime()) / 1000),
      minute = 60,
      hour = 60 * minute,
      day = 24 * hour,
      week = 7 * day,
      isPos = delta >= 0 ? 1 : -1;

    let returnString = "";
    const rtf1 = new Intl.RelativeTimeFormat('en',)

    if (Math.abs(delta) < minute) {
      if (showSeconds) {
        returnString = rtf1.format(Math.floor(Math.abs(delta) ) * isPos, 'second')
      } else {
        returnString = "less than a minute";
      }
    } else if (Math.abs(delta) < hour) {
      returnString = rtf1.format(Math.floor(Math.abs(delta) / minute) * isPos, 'minute')
    } else if (Math.abs(delta) < day) {
      returnString = rtf1.format(Math.floor(Math.abs(delta) / hour) * isPos, 'hour')
    } else if (Math.abs(delta) < week) {
      returnString = rtf1.format(Math.floor(Math.abs(delta) / day) * isPos, 'day')
    } else {
      returnString = rtf1.format(Math.floor(Math.abs(delta) / week) * isPos, 'week')
    }

    return returnString;
  }
}
export default RelativeDateFilter;
