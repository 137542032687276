import { injectable } from "inversify";
import DateUtil from "@/common/services/utils/dateUtil";

@injectable()
class BltDateFilter {
  // D/MM/YYYY @ HH:SSa
  bltDate(filterDate: Date) {
    const localDate = new Date(filterDate);

    // date
    const month = localDate.getMonth() + 1;
    const day = localDate.getDate();
    const year = localDate.getFullYear().toString().slice(-2);
    // time
    const hrs24 = localDate.getHours();
    const hrs12 = hrs24 > 12 ? hrs24 - 12 : hrs24;
    const hoursWithLeading0 = hrs12 < 10 ? "0" + hrs12 : hrs12;
    const mins = localDate.getMinutes();
    const minWithLeading0 = mins < 10 ? "0" + mins : mins;
    const localDateStringEndingWithAMPM = localDate.toLocaleString();
    const meridiemAMPM = localDateStringEndingWithAMPM.slice(-2).toUpperCase();

    // format - M/d/yy @ hh:mma - 4/11/96 @ 1:30 PM
    return `${month}/${day}/${year} @ ${hoursWithLeading0}:${minWithLeading0} ${meridiemAMPM}`;
  }
}

export default BltDateFilter;
