<template>
  <div 
    layout="row" 
    class="signature-layout" 
    layout-align="start end"
     style="border-bottom: 1px solid #e1e1e1;
            font-size: 1.2em;
            min-width: 180px;
            height: 43px;
            display: flex;
            flex-grow: 1;"
  >
    <div class="x-button signature-button"  style="color: #a1a1a1;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: -15px;
                font-family: cursive;
                font-size: 20px;
                font-weight: bold;">
      X
    </div>
    <img 
      v-if="!loading && data" 
      class="signature-image" 
      :src="data" alt="User signature"
      style="width: auto;
                height: 100%;
                margin: auto;
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;"
    />
    <div v-if="loading" class="loading">
      <jh-progress 
        indeterminate="" 
        type="circular" 
        size="large">
      </jh-progress>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltSignatureDisplay",
  props: {
    data: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
});
</script>
<style scoped>
.signature-layout {
  border-bottom: 1px solid #e1e1e1;
  font-size: 1.2em;
  min-width: 180px;
  height: 43px;
  display: flex;
  flex-grow: 1;
  /*This came from link */
  padding-top: 7px;
  padding-bottom: 7px;
}
.signature-button {
  color: #a1a1a1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -15px;
  font-family: cursive;
  font-size: 20px;
  font-weight: bold;
}
.signature-image {
  width: auto;
  height: 100%;
  margin: auto;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
blt-signature-display {
  flex-grow: 1;
}
blt-signature-display .loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
