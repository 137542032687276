import EnrollmentAccountFactory from "@/common/services/Enrollment/enrollmentAccountFactory";
import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type RestResponse from "@/common/data/RestResponse";
import type IAccount from "@/common/models/IAccount";

@injectable()
class EnrollmentRolesFactory {
  constructor(
    @inject(EnrollmentAccountFactory) private enrollmentAccountFactory: EnrollmentAccountFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  async getAccountRoles(productId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/product/${productId}?include=roles`,
      {},
      ResponseTypes.Payload
    );
  }

  async getSuggestedAccountRoles(workspaceUUID: string, enrollmentId: number, accountId: number): Promise<RestResponse<IAccount>> {
    return await this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/suggest?type=roles`,
        {}
    );
  }

  setAccountSuggestedRoles(workspaceUUID: string, enrollmentId: number, account: { accountId: number }) {
    return this.getSuggestedAccountRoles(workspaceUUID, enrollmentId, account.accountId).then((response) => {
      return this.enrollmentAccountFactory.putEnrollmentAccount(workspaceUUID, enrollmentId, response.data.payload);
    });
  }

  setAccountListSuggestedRoles(workspaceUUID: string, enrollmentId: number, accountList: Array<IAccount>) {
    return Promise.all(
      accountList.map((account) => {
        return this.setAccountSuggestedRoles(workspaceUUID, enrollmentId, account);
      })
    );
  }

  setEnrollmentSuggestedRoles(workspaceUUID: string, enrollmentId: number) {
    this.enrollmentAccountFactory.getEnrollmentAccountList(workspaceUUID, enrollmentId)
      .then((accounts) => {
        return this.setAccountListSuggestedRoles(workspaceUUID, enrollmentId, accounts)
      })
  }

}
export default EnrollmentRolesFactory;
