<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/lists/jha-list-item/jha-list-item.js";
import "@banno/jha-wc/src/icons/jha-icon-chevron-forward.js";

export default defineComponent({
  name: "bltDecisionOption",
  emits: ["selected"],
  props: {
    //value prop and input emit are required for v-model exposed by vue
    value: {
      type: Object
    },
    bltDecision: {
      type: Object,
      default: () => null
    },
    bltDecisionText: {
      type: String,
      default: () => null
    },
    idKey: {
      type: String,
      required: true
    },
    groupName: {
      type: String,
      default: () => "decision"
    }
  },
  computed: {
    displayLabel() {
      return this.bltDecisionText || this.bltDecision.display;
    }
  },
  methods: {
    onOptionSelected() {
      this.$emit("selected", this.bltDecision || this.value);
    }
  }
});
</script>
<template>
  <jha-list-item class="list-align" @click="onOptionSelected">
    <button type="button" button-reset>
      <jha-flex-wrapper space-between>
        <span class="list-text" :aria-label="displayLabel">{{ displayLabel }}</span>
        <jha-icon-chevron-forward small class="chevron-forward"></jha-icon-chevron-forward>
      </jha-flex-wrapper>
    </button>
  </jha-list-item>
</template>
