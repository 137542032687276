import HttpHandler from "@/common/services/connect/HttpHandler";
import { cloneDeep } from "lodash";
import { injectable, inject } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import type OwnershipFields from "@/common/data/OwnershipFields";
import type IApplicant from "@/common/models/IApplicant";
import type {IApplicantBusinessApplicant, IBusinessOwnership} from "@/common/models/IOwnership";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
class ApplicantBusinessApplicantFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(workspaceUUID: string, applicantId: number): Promise<Array<IApplicantBusinessApplicant>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/applicant`,
      {},
      ResponseTypes.Payload
    );
  }

  get(workspaceUUID: string, applicantId: number, businessApplicantId: number): Promise<RestResponse<IApplicantBusinessApplicant>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}`,
    );
  }

  delete(workspaceUUID: string, applicantId: number, businessApplicantId: number): Promise<RestResponse<void>> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}`,
    );
  }

  put(workspaceUUID: string, applicantId: number, businessApplicant: OwnershipFields): Promise<OwnershipFields> {
    const message = cloneDeep(businessApplicant);
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/applicant/${message.applicantId}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  post(workspaceUUID: string, applicantId: number, businessApplicant: OwnershipFields): Promise<OwnershipFields> {
    const message = cloneDeep(businessApplicant);
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/applicant`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  save(workspaceUUID: string, applicantId: number, businessApplicant: OwnershipFields): Promise<OwnershipFields> {
    return businessApplicant.applicantBusinessApplicantId
        ? this.put(workspaceUUID, applicantId, businessApplicant)
        : this.post(workspaceUUID, applicantId, businessApplicant)
  }

  getApplicantsNotOnBusiness(personApplicants: any[], personApplicantsOnBusiness: any[]) {
    return personApplicants.filter((personApplicant: any) => {
      return !personApplicantsOnBusiness.some((personApplicantOnBusiness: any) => {
        return personApplicantOnBusiness.applicantId === personApplicant.applicantId;
      });
    });
  }

  async setOwnershipsToObject(
      personApplicants: Array<IApplicant>,
      ownershipList: Array<IApplicantBusinessApplicant>,
      businessApplicant: IApplicant,
      workspaceUUID: string,
  ): Promise<IBusinessOwnership> {
    const businessOwnershipObj = {} as IBusinessOwnership
    personApplicants.map((applicant: IApplicant) => {
      const result = ownershipList.find((ownership) => {
        return ownership.applicantId === applicant.applicantId;
      });

      if (result) {
        businessOwnershipObj[applicant.applicantId] = result
      } else {
        businessOwnershipObj[applicant.applicantId] = this.getDefaultObject(
            businessApplicant,
            applicant
        )
      }
    });

    return Promise.resolve(businessOwnershipObj)
  }

  getDefaultObject(businessApplicant: IApplicant, applicant: IApplicant) {
    return {
      title: "",
      percentageOwned: 0,
      businessApplicantId: businessApplicant.applicantId,
      applicantId: applicant.applicantId,
      businessController: false,
      beneficialPerson: false,
      certifyingPerson: false,
      authorizedSigner: false,
      beneficialOwner: false,
    }
  }
}
export default ApplicantBusinessApplicantFactory;
