import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentAccountTitleFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getEnrollmentAccountTitleAddress(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/title/address`,
      {},
      ResponseTypes.Payload
    );
  }

  async getEnrollmentAccountTitleSuggestion(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return await this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/suggest?type=titles`,
        {},
        ResponseTypes.Payload
      )
      .then((title: any) => {
        return title.titles || [];
      });
  }

  async setAccountTitleSuggestion(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return this.getEnrollmentAccountTitleSuggestion(workspaceUUID, enrollmentId, accountId).then((response) => {
      return this.putEnrollmentAccountTitle(workspaceUUID, enrollmentId, accountId, response);
    });
  }

  setAccountListTitleSuggestion(workspaceUUID: string, enrollmentId: number, accountList: any) {
    return Promise.all(
      accountList.map((account: { accountId: number }) => {
        return this.setAccountTitleSuggestion(workspaceUUID, enrollmentId, account.accountId);
      })
    );
  }

  async getEnrollmentAccountTitle(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/title`,
      {},
      ResponseTypes.Payload
    );
  }

  async postEnrollmentAccountTitle(workspaceUUID: string, enrollmentId: number, accountId: number, title: any) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/title`,
      title,
      {},
      ResponseTypes.Payload
    );
  }

  async putEnrollmentAccountTitle(workspaceUUID: string, enrollmentId: number, accountId: number, title: any) {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/title`,
      title,
      {},
      ResponseTypes.Payload
    );
  }

  async deleteEnrollmentAccountTitle(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return await this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/title`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentAccountTitleFactory;
