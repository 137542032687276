import { inject, injectable } from "inversify";
import LogService from "@/common/services/Log/LogService";

@injectable()
class ImageResizeFactory {
  constructor(@inject(LogService) private logService: LogService) {}

  createCanvas() {
    const document: any = window.document;
    const canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");

    return { canvas, ctx };
  }

  resize(imgElement: { src: string; naturalWidth: number; naturalHeight: number }, options?: Record<string, number>) {
    const parsedOptions = this.parseOptions(options);
    const newDimensions = this.calculateDimensions(imgElement, parsedOptions);

    const { canvas, ctx } = this.createCanvas();
    canvas.width = newDimensions.w;
    canvas.height = newDimensions.h;
    ctx.drawImage(imgElement, 0, 0, Math.ceil(newDimensions.w), Math.ceil(newDimensions.h));

    const result = canvas.toDataURL("image/jpeg", parsedOptions.quality);
    this.logService.info(
      "Reduced filesize by " +
        Math.ceil(100 - (result.length / imgElement.src.length) * 100) +
        "%. (" +
        imgElement.src.length +
        " -> " +
        result.length +
        ")"
    );
    return result;
  }

  parseOptions(optionsObj?: Record<string, number>) {
    const optionsObjValue = optionsObj ? optionsObj : { maxHeight: 0, maxWidth: 0, quality: 0 };
    optionsObjValue.maxHeight = optionsObjValue.maxHeight || 3000;
    optionsObjValue.maxWidth = optionsObjValue.maxWidth || 3000;
    optionsObjValue.quality = optionsObjValue.quality || 0.85;
    return optionsObjValue;
  }

  calculateDimensions(
    imgElement: { src?: string; naturalWidth: number; naturalHeight: number },
    options: Record<string, number>
  ) {
    let w, h;
    const imageRatio = imgElement.naturalWidth / imgElement.naturalHeight;

    if (imgElement.naturalWidth > options.maxWidth && imgElement.naturalHeight > options.maxHeight) {
      w = options.maxWidth;
      h = w / imageRatio;

      if (h > options.maxHeight) {
        h = options.maxHeight;
        w = h * imageRatio;
      }
    } else if (imgElement.naturalWidth <= options.maxWidth && imgElement.naturalHeight > options.maxHeight) {
      h = options.maxHeight;
      w = h * imageRatio;
    } else if (imgElement.naturalWidth > options.maxWidth && imgElement.naturalHeight <= options.maxHeight) {
      w = options.maxWidth;
      h = w / imageRatio;
    } else {
      w = imgElement.naturalWidth;
      h = imgElement.naturalHeight;
    }

    return {
      w: w,
      h: h,
      ratio: imageRatio
    };
  }
}

export default ImageResizeFactory;
