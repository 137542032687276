
export interface LegacyModule {
  name: string,
  path: string,
}
export const legacyManagementModules: LegacyModule[] = [
  {
    name: "Mapping",
    path: "/mapping"
  },
  {
    name: "Rules",
    path: "/rules"
  }
];