export class PaletteConstant {
  static readonly CUSTOM_COLOR: string = "customColor";
  static readonly CUSTOM_ACCENT: string = "customAccent";
  static readonly LIGHT: string = "light";
  static readonly DARK: string = "dark";
  static readonly ACCENT: string = "accent";
  static readonly CONTRAST_LIGHT_COLORS: string = "contrastLightColors";
  static readonly CONTRAST_DARK_COLORS: string = "contrastDarkColors";
  static readonly BLT_THEME: string = "bltTheme";
}
