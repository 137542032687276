const FILTER_MSG = Object.freeze({
  INT_TO_PALLETE: {
    WARN_FETCHING_RANDOM_COLOR_MSG: "Trying to fetch a random color before palettes have initalized",
    COLOR_NOT_FOUND_IN_PALETTE_MSG: "Unable to find color ${key} in the palette ${name}"
  },
  BLT_COLORS: {
    INVALID_TYPECODE: 'Type code ${color} is not a valid bltColor typeCode'
  },
  ENROLLMENT_SUMMARY: {
    NEW_ENROLLMENT: 'New Enrollment',
    ENROLLMENT: 'Enrollment',
    NEW_WORKSPACE: 'New Workspace',
    NA: 'N/A',
    ACCOUNTS: 'Accounts on ',
    CREATED: ' created',
  }
});

export default FILTER_MSG;