const FACTORY_MSG = Object.freeze({
  DISCLOSURE: {
    NO_DISCLOSURE_PACKAGE_PASSED: "Tried to display send modal, but no disclosure package was passed.",
    MUST_SEND_EITHER_APPLICANT_OR_ENROLLMENT: "Tried to send applicant and enrollment package, must send only one type."
  },
  ENROLLMENTDISCLOSURE: {
    DISCLOSURE_EMAILED: "Disclosures emailed",
    DISCLOSURE_PRINTED: "Disclosures printed",
    ATLEAST_ONE_DISCLOSURE_MUST_BE_SELECTED:
      "Tried to printEnrollmentDisclosures with an empty list of disclosureStoreIds, there must be at least one disclosureStoreId in the list..."
  },
  APPLICANT: {
    FAILED_SET_PERSON: "Failed to set person object"
  },
  WORKFLOW: {
    FAILED_NAVIGATE_TO_RULE_DECISION: "Could not navigate to RULE_DECISION: "
  },
  APPLICANTDISCLOSURE: {
    APPLICANT_DISCLOSURE_LOG_EROOR:
      "Tried to printApplicantDisclosures with an empty list of disclosureStoreIds, there must be at least one disclosureStoreId in the list..."
  },
  PRINT_TOKEN: {
    PRINT_TOKEN_LOG_ERROR: "Unable to display disclosures, please disable your Pop-Up Blocker..."
  },
  INFO_DIALOG: {
    HTML_LOAD_FAILED: "The HTML content failed to load..."
  },
  ANALTYICS: {
    LOAD_ERROR: "Unable to load google analytics script"
  },
  TAB: {
    NO_MATCHING_VALIDATION:
      "Tried to update validation for tab {tabState}, but no matching validation was returned from the server",
    MISSING_TAB_STATES_ERROR: "Missing tab states in employee channel workflowfactory"
  }
});

export default FACTORY_MSG;
