import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { inject, injectable } from "inversify";

//EnrollmentQuestionnaireQuestionFactory not used in Open but original implementation would be done in respective module
@injectable()
class EnrollmentQuestionnaireQuestionFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async get(workspaceUUID: string, resource: any, resourceId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/${resource}/${resourceId}/questionnaire`
    );
  }
}
export default EnrollmentQuestionnaireQuestionFactory;
