const SERVICE_CODE = {
  AONL: "AONL", //Online Access
  BANNO: "BANNO", //Online Access with Enrollment (BANNO)
  DRFT: "DRFT", //Overdraft
  DRFTP: "DRFTP", //Overdraft Protection
  ADBT: "ADBT", //Debit
  ESTMT: "ESTMT", //E-Statement

  //todo(mikol): move all non service codes out of this enum...
  ALLSERVICE: "allservice", //ALL SERVICE SCREEN KEY
  PRIMARY_ACCOUNTS: "primaryAccounts",
  SECONDARY_ACCOUNTS: "secondaryAccounts",
  APPLICANT: "APPLICANT",
  SERVICES: "SERVICES",
  CREATED: "CREATED"
} as const;

export default SERVICE_CODE;
