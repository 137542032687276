export class PlaidConstats {
  static readonly PENDING: string = "PENDING";
  static readonly MANUALLY_VERIFIED: string = "manually_verified";
  static readonly ACCOUNT_VERIFIED: string ="Account verified";
  static readonly AUTOMATICALLY_VERIFIED: string = "automatically_verified";
  static readonly DEPOSITORY: string = "depository";
  static readonly TOO_MANY_VERIFICATION_ATTEMPTS: string = "TOO_MANY_VERIFICATION_ATTEMPTS";
  static readonly OAUTH_STATE_ID: string = "oauth_state_id";
  static readonly PLAID_ERROR_MESSAGE: string = "Tried to launch Plaid API, but it was not loaded...";
  static readonly PLAID_INFORMATION_NOTIFICATION: string = "In a few days, you’ll see two small deposits in the range of $0.01-0.99 arrive in any accounts you linked. You can close this screen and return back here later to then verify the account.";
  static readonly PENDING_AUTOMATIC_VERIFICATION: string = "pending_automatic_verification";
  static readonly PENDING_MANUAL_VERIFICATION: string = "pending_manual_verification";
  static readonly VERIFICATION_EXPIRED: string = "verification_expired";
  static readonly VERIFICATION_FAILED: string = "verification_failed";
  static readonly PENDINGVERIFICATION: string = "pending verification";
  static readonly PENDING_VERIFICATION: string = "pending verification";
  static readonly NO_LINKED_ACCOUNTS_EXIST: string = "No linked accounts exist for this applicant";
  static readonly MANUAL: string = "Manual";
  static readonly PENDING_TRANSFER: string = "Pending transfer of {amount} to ";
  static readonly AUTOMETIC_VERIFICATION_PENDING: string = "Automatic verification in progress. Please check back later.";
}
