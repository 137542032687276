import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import type RestResponse from '@/common/data/RestResponse'

@injectable()
export default class ReportsFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(id: string): Promise<RestResponse<Report>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${id}`)
  }

  getAll(): Promise<RestResponse<Array<Report>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report`)
  }

  getAllSummary(params: {enabled: boolean; type: 'NUMBER' | 'SUMMARY'}): Promise<RestResponse<Array<Report>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report`, {params})
  }

  getReportSummary(
    reportId: number,
    params: {startDate: string; endDate: string}
  ): Promise<RestResponse<Array<ReportShortSummary>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${reportId}/sql`, {params})
  }

  getReportSchedules(reportId: string): Promise<RestResponse<Array<ReportSchedule>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${reportId}/schedule`)
  }

  getLinkedReportSummary(
    linkedReportId: number,
    params: {startDate: string; endDate: string; id?: string}
  ): Promise<RestResponse<Array<Record<string, string | null>>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${linkedReportId}/sql`, {params})
  }

  createReportSchedule(dto: ReportScheduleCreate): Promise<RestResponse<ReportSchedule>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${dto.reportId}/schedule`, dto)
  }

  update(dto: Report): Promise<RestResponse<Report>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${dto.reportId}`, dto)
  }

  updateReportSchedule(dto: ReportSchedule): Promise<RestResponse<ReportSchedule>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${dto.reportId}/schedule/${dto.reportEmailScheduleId}`, dto)
  }

  deleteReportSchedule(dto: ReportSchedule): Promise<RestResponse<void>> {
    return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${dto.reportId}/schedule/${dto.reportEmailScheduleId}`)
  }

  export(id: number, params: {startDate: string; endDate: string; id?: string}): Promise<{data: ReportExport}> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${id}/export`, {params})
  }

  exportAll(linkedReportId: number, params: {startDate: string; endDate: string}): Promise<{data: ReportExport}> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/report/${linkedReportId}/exportAll`, {params})
  }
}

export type Report = {
  category: string
  displayName: string
  enabled: boolean
  file: string | null
  linkedReportId: number
  login: string | null
  name: string
  reportId: number
  type: (typeof ReportTypes)[keyof typeof ReportTypes]
}

export type ReportShortSummary = {
  id: string
  name: string
  value: number | string
}

export const enum ReportTypes {
  NUMBER = 'NUMBER',
  SUMMARY = 'SUMMARY'
}

export const enum FrequencyTypes {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Daily = 'DAILY'
}

export type ReportExport = {
  filename: string
  content: string
}

export type ReportSchedule = {
  bcc?: string
  cc?: string
  enabled: boolean
  frequencyType: string
  pigeonCarrier: string
  recipient: string
  reportEmailScheduleId: number
  reportId: number
  createdDate: string
  updatedDate: string
}

export type ReportScheduleCreate = {
  bcc?: string
  cc?: string
  enabled: boolean
  frequencyType: string
  pigeonCarrier: string
  recipient: string
  reportId: string
}
