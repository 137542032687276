import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'

@injectable()
class WorkflowDecisionFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(workflowName: string, stateId: number) {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}/state/${stateId}/decision`)
  }

  create(workflowName: string, stateId: number, decision: WorkflowDecisionCreate) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}/state/${stateId}/decision`,
      decision
    )
  }

  update(workflowName: string, stateId: number, decision: WorkflowDecision) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}/state/${stateId}/decision/${decision.stateDecisionId}`,
      decision
    )
  }

  delete(workflowName: string, stateId: number, decisionId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}/state/${stateId}/decision/${decisionId}`
    )
  }
}

export default WorkflowDecisionFactory

export type WorkflowDecision = {
  createdTime: string
  display: string
  displayKey: string
  displayOrder: number
  locked: boolean
  nextState: string
  nextStateId: number | null
  nextWorkflow: string
  stateDecisionId: number
  updatedTime: string
}

export type WorkflowDecisionCreate = {
  displayOrder: number
}

export type WorkflowDecisionWithStateId = {
  stateId: number
} & WorkflowDecision
