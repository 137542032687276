import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import type { LaunchpadServiceResponse, LaunchpadService } from "@/common/services/Properties/LaunchpadServiceService";


@injectable()
class ServiceUrlFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) { }

  async getAll() {
    const response = await this.getAllDetails();
    return response.services.reduce((
      col: { [x: string]: string | undefined },
      service: LaunchpadService
    ) => {
      col[service.serviceCode] = service.url;
      return col;
    },
      {});
  }

  async getAllDetails(): Promise<LaunchpadServiceResponse> {
    return this.httpHandler.get("/v1/launchpadREST/active/service", {}, ResponseTypes.Data, true);
  }

  async getOne(serviceCode: string) {
    const response = await this.getAll();
    return response[serviceCode];
  }
}

export default ServiceUrlFactory;
