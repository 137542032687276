import { inject, injectable } from "inversify";
import STATE_MSG from "@/common/messages/state.messages";
import CustomerSearchFactory from "@/common/services/Customer/customerSearchFactory";
import ApplicantFactory from "@/common/services/Applicant/applicantFactory";
import type IApplicant from "@/common/models/IApplicant";

@injectable()
class TaxIdValidationService {
  constructor(
    @inject(CustomerSearchFactory) private customerSearchFactory: CustomerSearchFactory,
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory
  ) {}

  async coreTaxIdUnique(workspaceUuid: string, userInput: string, applicantType: string): Promise<any> {
    if (userInput) {
      return await this.customerSearchFactory
        .search(workspaceUuid, {
          taxid: userInput,
          applicantType: applicantType
        })
        .then((response) => {
          return response.customers.length > 0 ? Promise.reject(STATE_MSG.PERSON_INFO.CORE_SSN_DUPLICATE_ERROR) : true;
        })
        .catch(() => {
          throw { message: 'Unable to communicate with the core...' }
        });
    } else {
      return Promise.resolve();
    }
  }

  async workspaceTaxIdUnique(
      workspaceUuid: string,
      userInput: string,
      applicantId: number,
      applicantType: string
  ): Promise<boolean> {
    return this.applicantFactory
      .getApplicants(workspaceUuid)
      .then((applicants: Array<IApplicant>) => {
        const isExistingId = applicants.some( applicant => {

            // This allows it if 2 applicants have the same taxId, but they are different types of applicants
            return applicant.applicantType == applicantType
                && applicant.applicantId !== applicantId
                && applicant.taxId == userInput
            }
        );
        return isExistingId ? Promise.reject(STATE_MSG.PERSON_INFO.CURRENT_WORKSPACE_SSN_DUPLICATE_ERROR) : true;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default TaxIdValidationService;
