export class AddressConstant {
  static readonly OPEN_BRANCH_LOCATION: string = "OPEN_BRANCH_LOCATION";
  static readonly DEFAULT_COUNTRY_CODE: string = "US";
  static readonly ADDRESS: string = "ADDRESS";
  static readonly ADDRESS_TYPE_CODE: string = "addressTypeCode";
  static readonly PRIMARY: string = "PRIMARY";
  static readonly MAILING: string = "MAILING";
  static readonly TYPE_CODE: string = "typeCode";
  static readonly ADDRESS1: string = "address1";
  static readonly ADDRESS2: string = "address2";
  static readonly COUNTRY: string = "country";
  static readonly CITY: string = "city";
  static readonly STATE: string = "state";
  static readonly ZIPCODE: string = "zipCode";
}
