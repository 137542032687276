import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWfState from "@/common/models/WfState";
import { BroadcastService } from "@/common/services/services.module";
import type IRouterService from "@/common/services/utils/IRouterService";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import { inject, injectable } from "inversify";
import type IWorkflowStore from "@/branchmanager/stores/IWorkflowStore";
import type { Validation } from "@/common/models/IStateValidationResponse";
import { WorkspaceStatesConstant } from "@/branchmanager/app/constant/WorkspaceStatesConstant";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import { NotificationMessageCategory } from "@/branchmanager/app/constant/NotificationMessageCategory";
import FACTORY_MSG from "@/common/messages/factory.messages";

@injectable()
class EmployeeWorkflowFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IWorkspaceStore,
    @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService,
    @inject(DEPENDENCYTYPES.IWorkflowStore) private workflowStore: IWorkflowStore,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(BroadcastService) private broadcastService: BroadcastService
  ) {}

  setTabStates(states: IWfState[]) {
    this.workspaceStore.workflow.tabStates = states;
  }
  private getcurrentState(): IWfState {
    return this.workspaceStore.workflow.currentState;
  }

  nextV2(arg: { validationResponses: Validation[] }) {
    this.workspaceStore.workflow.tabStates.forEach((tab) => {
      const validatedTab = arg.validationResponses.find((state) => state.state === tab.state);

      if (validatedTab) {
        tab.infos = validatedTab.infos || [];
        tab.errors = validatedTab.errors || [];
        tab.warnings = validatedTab.warnings || [];
        if (tab.errors.length) {
          this.workspaceMetadataStore.notification.messages.push({
            message: tab.errors[0]?.message,
            type: NotificationConstants.ERROR,
            messageCategory: NotificationMessageCategory.WORKFLOW
          });
        }
        if (tab.warnings.length) {
          this.workspaceMetadataStore.notification.messages.push({
            message: tab.warnings[0].message,
            type: NotificationConstants.WARN,
            messageCategory: NotificationMessageCategory.WORKFLOW
          });
        }
        if (tab.infos.length) {
          this.workspaceMetadataStore.notification.messages.push({
            message: tab.infos[0].message,
            type: NotificationConstants.INFO,
            messageCategory: NotificationMessageCategory.WORKFLOW
          });
        }
      }
    });
    this.next();
  }
  next() {
    this.workflowStore.headerActions = [];
    if ((this.workspaceStore.workflow.tabStates || []).length === 0) {
      throw new Error(FACTORY_MSG.TAB.MISSING_TAB_STATES_ERROR);
    }
    const curState = this.getcurrentState();
    if (curState) {
      curState.isCompleted = !(curState.errors || []).concat(curState.warnings || []).length;
    }
    const curIndex = this.workspaceStore.workflow.tabStates.findIndex((s) => s.stateId === curState.stateId);
    const nextIndex = curIndex < this.workspaceStore.workflow.tabStates.length - 1 ? curIndex + 1 : curIndex;
    if (nextIndex === curIndex) {
      this.routerService.push({
        name: WorkspaceStatesConstant.WORKSPACE_DETAIL.STATE,
        params: {
          workspaceUUID: this.workspaceStore.workspaceUUID,
          applicantType: this.routerService.currentRoute.value.params.applicantType
        }
      });
      return;
    }
    const nextState = this.workspaceStore.workflow.tabStates[nextIndex];
    this.workspaceStore.workflow.transitioning = true;
    this.broadcastService.broadcast(BroadcastConstant.EC_APPLICANT_NAVIGATION, nextState);
    return this.routerService
      .push({
        name: nextState.state,
        params: { workspaceUUID: this.workspaceStore.workspaceUUID, trackerUUID: "" }
      })
      .then(() => {
        this.workspaceStore.workflow.currentState = nextState;
        this.workspaceStore.workflow.transitioning = false;
      });
  }
  previous() {
    this.workflowStore.headerActions = [];
    if ((this.workspaceStore.workflow.tabStates || []).length === 0) {
      throw new Error(FACTORY_MSG.TAB.MISSING_TAB_STATES_ERROR);
    }
    const curIndex = this.workspaceStore.workflow.tabStates.findIndex(
      (s) => s.stateId === this.getcurrentState().stateId
    );
    const prevIndex = curIndex > 0 ? curIndex - 1 : curIndex;
    const prevState = this.workspaceStore.workflow.tabStates[prevIndex];
    this.workspaceStore.workflow.transitioning = true;
    this.broadcastService.broadcast(BroadcastConstant.EC_APPLICANT_NAVIGATION, prevState);
    return this.routerService
      .push({
        name: prevState.state,
        params: { workspaceUUID: this.workspaceStore.workspaceUUID, trackerUUID: "" }
      })
      .then(() => {
        this.workspaceStore.workflow.currentState = prevState;
        this.workspaceStore.workflow.transitioning = false;
      });
  }

  goDirect(stateName: string) {
    this.workflowStore.headerActions = [];
    if ((this.workspaceStore.workflow.tabStates || []).length === 0) {
      throw new Error(FACTORY_MSG.TAB.MISSING_TAB_STATES_ERROR);
    }
    const curIndex = this.workspaceStore.workflow.tabStates.findIndex((s) => s.state === stateName);
    if (curIndex < 0) {
      throw new Error(FACTORY_MSG.TAB.MISSING_TAB_STATES_ERROR);
    }
    const prevState = this.workspaceStore.workflow.tabStates[curIndex];
    this.workspaceStore.workflow.transitioning = true;
    this.broadcastService.broadcast(BroadcastConstant.EC_APPLICANT_NAVIGATION, prevState);
    return this.routerService
      .push({
        name: prevState.state,
        params: { workspaceUUID: this.workspaceStore.workspaceUUID, trackerUUID: "" }
      })
      .then(() => {
        this.workspaceStore.workflow.currentState = prevState;
        this.workspaceStore.workflow.transitioning = false;
      });
  }

  setBeginPreloadingStatus() {
    this.workspaceStore.transitionInfo.preloading = true;
  }
  setEndPreloadingStatus() {
    this.workspaceStore.transitionInfo.preloading = false;
  }
  getPreloadingStatus() {
    return this.workspaceStore.transitionInfo.preloading;
  }

  getState() {
    return this.workspaceStore.workflow.currentState;
  }
  getTransitioning() {
    return this.workspaceStore.workflow.transitioning;
  }
  hasWorkflowError(): boolean {
    return (
      this.workspaceMetadataStore.notification.messages.findIndex(
        (m) => m.type === NotificationConstants.ERROR && m.messageCategory === NotificationMessageCategory.WORKFLOW
      ) >= 0
    );
  }
  hasSystemError(): boolean {
    return (
      this.workspaceMetadataStore.notification.messages.findIndex(
        (m) => m.type === NotificationConstants.ERROR && m.messageCategory === NotificationMessageCategory.SYSTEM
      ) >= 0
    );
  }
}
export default EmployeeWorkflowFactory;
