import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
@injectable()
class QuestionAnswerFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async get(workspaceUUID: string, resource: string, resourceId: number, questionnaireId: number) {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}/question`;
    return await this.httpHandler.get(url, {}, ResponseTypes.Payload);
  }

  async save(
    workspaceUUID: string,
    resource: string,
    resourceId: number,
    questionnaireId: number,
    questionId: number,
    QuestionnaireAnswer: {
      cddRequestId: number;
      questionId: number;
      answers: any;
    }
  ) {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}/question/${questionId}/answer`;
    return await this.httpHandler.post(url, QuestionnaireAnswer, {}, ResponseTypes.Payload);
  }
}
export default QuestionAnswerFactory;
