import { injectable } from "inversify";

@injectable()
class BltDOBDateFilter {
    bltDate(filterDate: Date) {
        const localDate = new Date(filterDate);
        // date
        const month = localDate.getMonth() + 1;
        const day = localDate.getDate();
        const year = localDate.getFullYear().toString();
        // format - YYY/D/MM
        return `${year}-${day}-${month}`;
      }
}

export default BltDOBDateFilter;
 