import { $inj, $injByInterface } from "@/common/decorators/depinject";
import APP_CODES from "@/common/enums/appCodeEnum";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import type CacheableAxiosRequestConfig from "../services/connect/CacheableAxiosRequestConfig";
import { isNull } from "lodash";
import CoreLoginFactory from "@/common/services/Dialogs/CoreLogin/CoreLoginFactory";
import type IHttpErrorHandler from "../services/connect/IHttpErrorHandler";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import type AxiosRequestInterceptor from "@/common/interfaces/axios/AxiosRequestInterceptor";
import type { AxiosRequestConfig } from "axios";
import UrlInterpolateInterceptor from "@/common/services/connect/UlrInterpolateInterceptor";

class CommonRequestInterceptor implements AxiosRequestInterceptor {
    onFulfilled<T>(config: AxiosRequestConfig<T>): CacheableAxiosRequestConfig | Promise<CacheableAxiosRequestConfig> {

        const coreLoginFactory = $inj(CoreLoginFactory);
        const store = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore")
        const localStore = useLocalStorage()

        config.url = UrlInterpolateInterceptor.constructUrl(config.url, store);

        //forceInterceptor and ignoreInterceptor both options are not avaliable in Axios so added in store.
        //This allows us to skip bootstrapping in test and allows us the ability to test it if we wish.
        if ((((<any>window).APP_CODE === APP_CODES.TEST) && !store.forceInterceptor) || store.ignoreInterceptor) {
            return config;
        }

        const coreLogin = coreLoginFactory.getCoreLogin();
        const corePassword = coreLoginFactory.getCorePassword();

        if (!isNull(coreLogin) && !isNull(corePassword)) {
            config.headers = config.headers ? config.headers : {};
            config.headers["Core-Authorization"] = `CORE login="${coreLogin}", password="${corePassword}"')`;
        }

        if (localStore.apitoken) {
            config.headers = config.headers ? config.headers : {};
            config.headers[
              "Authorization"
              ] = `BOLTS apitoken="${(localStore.apitoken)}", gps="0;0;1", deviceguid="${(localStore.guid)}", login= "${(localStore.login)}"`;
        }
        return config;

    }
    onRejected(error: unknown): unknown {
        const httpErrorHandler = $injByInterface<IHttpErrorHandler>("IHttpErrorHandler");
        if (httpErrorHandler) {
            httpErrorHandler.handle(error);
        }
        return Promise.reject(error)
    }

}
export default CommonRequestInterceptor;