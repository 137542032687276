import { injectable } from "inversify";

@injectable()
class PhoneNumberFilter {
  phonenumber(number: number | string | undefined) {
    if (!number) {
      return "";
    }

    number = String(number);

    // Will return formattedNumber.
    // If phonenumber isn't longer than an area code, just show number
    let formattedNumber = number;
    // if the first character is '1', strip it out and add it back
    const c = number[0] == "1" ? "1 " : "";
    number = number[0] == "1" ? number.slice(1) : number;

    // # (###) ###-#### as c (area) front-end
    const area = number.substring(0, 3),
      front = number.substring(3, 6),
      end = number.substring(6, 10);

    if (front) {
      formattedNumber = c + "(" + area + ") " + front;
    }
    if (end) {
      formattedNumber += "-" + end;
    }
    return formattedNumber;
  }
}

export default PhoneNumberFilter;
