import NotificationFactory from "@/common/services/notificationFactory";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type ApplicantValidationStates from "@/branchmanager/app/enums/applicantValidationStates";
import type { Validation } from "@/common/models/IStateValidationResponse";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
class ApplicantValidationFactory {
  constructor(
    @inject(NotificationFactory) private notificationFactory: NotificationFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  // todo(mikol): why is this common and enrollment factory is not?

  getValidation(workspaceUUID: string, applicantId: number, state: any): Promise<Validation> {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/validate?type=${state}`,
        {},
        ResponseTypes.Payload
      )
      .then((response: Array<Validation>) => {
        return response[0];
      });
  }

  getValidationWithRestResponse(workspaceUUID: string, applicantId: number, state: any): Promise<RestResponse<Array<Validation>>> {
    return this.httpHandler
      .get(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${applicantId}/validate?type=${state}`,
        {},
      )
  }

  async getForState(workspaceUUID: string, applicantId: number, state: keyof typeof ApplicantValidationStates) {
    const validations = await this.request(workspaceUUID, applicantId, state)
    if(validations.length === 1) {
      return validations[0]
    } else {
      throw new Error(`Unexpectedly received multiple validation responses for ${state}`)
    }
  }

  async getAll(workspaceUUID: string, applicantId: number, state: keyof typeof ApplicantValidationStates) {
    return this.request(workspaceUUID, applicantId, state)
  }

  checkValidation(workspaceUUID: string, applicantId: number, state: any): Promise<Validation> {
    return this.getValidation(workspaceUUID, applicantId, state).then((response: Validation) => {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        this.notificationFactory.showData(response);
        return Promise.reject(response.errors);
      }
      return response;
    });
  }

  private async request(workspaceUUID: string, applicantId: number, state: keyof typeof ApplicantValidationStates): Promise<Validation[]> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/validate?type=${state}`,
      {},
      ResponseTypes.Payload
    )
  }

}
export default ApplicantValidationFactory;
