<script lang="ts">
import {$injByInterface} from '@/common/decorators/depinject'
import {vsgShowToast} from '@jack-henry/jha-vue-components'
import {ToastConstants} from '@/common/constant/ToastConstants'
import type IWorkspaceMetadataStore from '@/common/services/Workspace/IWorkspaceMetadataStore'
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'bltToaster',
  setup() {
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>('IWorkspaceMetadataStore')
    return {
      workspaceMetadataStore
    }
  },
  async created() {
    const {message, icon, type} = this.workspaceMetadataStore.toast
    this.showToast(message, icon, type as '' | 'success' | 'danger')
  },
  methods: {
    showToast(message: string, icon: any, type: '' | 'success' | 'danger') {
      const el = document.createElement('div')
      if (icon) {
        icon.classList?.add('icon')
        el.appendChild(icon)
      }

      vsgShowToast({
        message,
        role: type,
        el
      })

      this.removeToast();
    },

    removeToast() {
      this.workspaceMetadataStore.toast.showToast = false
    }
  }
})
</script>
