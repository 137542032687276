import type { AxiosInstance } from "axios";
import type IWorkspaceMetadataStore from "../Workspace/IWorkspaceMetadataStore";
import type CacheableAxiosRequestConfig from "./CacheableAxiosRequestConfig";
import { CacheStore } from "./CacheStore";
import UrlInterpolateInterceptor from "./UlrInterpolateInterceptor";

export class HttpCacheAdapter {
    constructor(
        private axiosInstance: AxiosInstance,
        private store: IWorkspaceMetadataStore,
        private expiryMins: number) { }

    async execute_get(url: string, config?: CacheableAxiosRequestConfig, enableCaching?: boolean) {
        if (!enableCaching) {
            return await this.axiosInstance.get(url, config);
        }

        //1: generate uuid from url and config.params
        const interpolatedUrl = UrlInterpolateInterceptor.constructUrl(url, this.store);
        const uuid = HttpCacheAdapter.key(interpolatedUrl, config);
        //2: fetch apiCacheStore against the uuid
        const cacheStore = CacheStore();
        const data = cacheStore.get(uuid);
        if (data) {
            return { ...data, __request: { cached: true } };
        }
        //3. otherwise resp is original call, cached and return
        const resp = await this.axiosInstance.get(url, config);
        cacheStore.set(uuid, resp, this.expiryMins);
        return resp;
    }

    private static key(url: string, config?: CacheableAxiosRequestConfig): string {
        return url + HttpCacheAdapter.serializeParams(config);
    }

    private static serializeParams(config?: CacheableAxiosRequestConfig) {
        if (!config || !config.params) return ''

        // Probably server-side, just stringify the object
        if (typeof URLSearchParams === 'undefined') return JSON.stringify(config.params)

        let params = config.params

        const isUrlSearchParams = config.params instanceof URLSearchParams

        if (!isUrlSearchParams) {
            params = new URLSearchParams()
            Object.keys(config.params).forEach(key => params.append(key, config.params[key]))
        }

        return `?${params.toString()}`
    }
}