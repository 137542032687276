import {defineStore} from "pinia";
import type ApplicantBusiness from "@/common/data/ApplicantBusiness";
import type ApplicantBusinessValidity from "@/common/data/ApplicantBusinessValidity";

export interface IBusinessInfoStore {
    einErrorMessage?: string,
    business: ApplicantBusiness,
    businessValidity: ApplicantBusinessValidity
}
export const BusinessInfoStore = defineStore({
    id: "BusinessInfoStore",
    state: (): IBusinessInfoStore => ({
        einErrorMessage: "",
        businessValidity: { taxIdSSNValid: true, taxIdEINValid: true} ,
        business: {} as ApplicantBusiness
    })
});