import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class ApplicantSuggestionFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  get(workspaceUUID: string, applicantId: number, type: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/suggest?type=${type}`, {}, ResponseTypes.Payload
    );
  }

 mergeRequiredTypes(workspaceUUID: string, applicantId: number, type: string, objectList: any[], typeCodeName: string) {
    return this.get(workspaceUUID, applicantId, type).then((requiredList: any[]) => {
      requiredList = requiredList || [];
      objectList = this.markRequired(objectList, requiredList, typeCodeName);
      return this.addRequired(objectList, requiredList, typeCodeName);
    });
  }

  // Legacy function for Addresses, which are classes that cannot use mergeRequiredTypes
  applyRequiredTypes(objectList: any[], typeCodeName: string, requiredList: any[]) {
    objectList = objectList || [];
    requiredList = requiredList || [];
    objectList = this.markRequired(objectList, requiredList, typeCodeName);
    return this.addRequired(objectList, requiredList, typeCodeName);
  }

  //Add required:true if you have a pre-existing object that is required.
  markRequired(itemList: any[], requiredList: any[], typeCodeName: string) {
    return itemList.map((item: any) => {
      item.required = requiredList.some((requiredTypeCode) => {
        return requiredTypeCode === item[typeCodeName];
      });
      return item;
    });
  }

  //Add in required objects if you don't have them.
  addRequired(itemList: any[], requiredList: any[], typeCodeName: string) {
    requiredList.forEach((requiredTypeCode) => {
      if (
        !itemList.some((object) => {
          return object[typeCodeName] === requiredTypeCode;
        })
      ) {
        const objectToAdd: { [key: string]: any } = {};
        objectToAdd["required"] = true;
        objectToAdd[typeCodeName] = requiredTypeCode;
        itemList.push(objectToAdd);
      }
    });
    if (itemList.length === 0) {
      itemList.push({});
    }
  return itemList;
  }
}
export default ApplicantSuggestionFactory;
