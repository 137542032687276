import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import {AddressConstant} from "@/common/constant/AddressConstant";
import type {StartWorkflowFactory, WorkflowFactory} from "@/common/services/services.module";
import {WorkflowCodesConstant} from "@/common/constant/WorkflowCodesConstant";
import type IWorkspace from "@/common/models/IWorkspace";
import {WorkflowStatesConstant} from "@/common/constant/WorkflowStatesConstant";
import {inject, injectable} from "inversify";
import SsoRedirectFactory from "@/open/states/sso-redirect/ssoRedirect.factory";
import OpenAuthFactory from "@/open/app/factories/authorization/OpenAuthFactory";
import {
    ApplicantFactory,
    ConstantFactory,
    EnrollmentFactory,
    TrackerFactory,
    WorkspaceFactory
} from "@/common/services/services.module";
import CORE_STATUS from "@/common/enums/coreStatusEnum";
import type SsoValidate from "@/common/data/SsoValidate";
import type AuthorizeResponse from "@/common/data/AuthorizeResponse";
import type IEnrollment from "@/common/models/IEnrollment";
import type IApplicant from "@/common/models/IApplicant";
import type WorkspaceTracker from "@/common/data/WorkspaceTracker";
import type StartWorkflow from "@/common/data/StartWorkflow";
import type {NavigationFailure} from "vue-router";

@injectable()
export default class SsoActions {
    constructor(
        @inject(SsoRedirectFactory) private ssoRedirectFactory: SsoRedirectFactory,
        @inject(OpenAuthFactory) private openAuthFactory: OpenAuthFactory,
        @inject(WorkspaceFactory) private workspaceFactory: WorkspaceFactory,
        @inject(EnrollmentFactory) private enrollmentFactory: EnrollmentFactory,
        @inject(ConstantFactory) private constantFactory: ConstantFactory,
        @inject(ApplicantFactory) private applicantFactory: ApplicantFactory,
        @inject(TrackerFactory) private trackerFactory: TrackerFactory,
    ) { }


    /**
     * @Returns:
     * Applicant Exists and has workspace to continue to: { token: String, workspaceUUID: String, ...}
     * Applicant Exists, but needs to restart: { token: String }
     * Applicant Does not exist, needs new everything: ""
     */
    BannoValidation(
        locationUrl: string,
        SSO_PROVIDERS: { BANNO: string }
    ): Promise<SsoValidate> {
        return this.ssoRedirectFactory.ssoValidate(locationUrl, SSO_PROVIDERS.BANNO);
    };

    SsoAuthentication(
        bannoValidation: SsoValidate
    ): Promise<string | AuthorizeResponse> {
        if (bannoValidation && bannoValidation.workspaceUUID) {
            return this.ssoRedirectFactory.ssoAuthenticate(bannoValidation.workspaceUUID, bannoValidation.token);
        } else {
            return this.openAuthFactory.authorize();
        }
    };

    Workspace(
        bannoValidation: SsoValidate
    ): Promise<IWorkspace> {
        if (bannoValidation && bannoValidation.workspaceUUID) {
            return this.workspaceFactory.getWorkspace(bannoValidation.workspaceUUID);
        } else {
            return this.workspaceFactory.postWorkspace({ workspaceType: WORKFLOW_TYPES.PERSON });
        }
    };

    Enrollment(
        workspace: { workspaceUUID: string },
        bannoValidation: SsoValidate
    ): Promise<Array<IEnrollment>>  {
        if (bannoValidation && bannoValidation.enrollmentId) {
            return this.enrollmentFactory.from(workspace.workspaceUUID, bannoValidation.enrollmentId);
        } else {
            return this.enrollmentFactory.post(
                workspace.workspaceUUID,
                null,
                this.constantFactory.getProp(AddressConstant.OPEN_BRANCH_LOCATION)
            );
        }
    };

    Applicant(
        bannoValidation: SsoValidate,
        workspace: { workspaceUUID: string }
    ): Promise<IApplicant> {
        if (bannoValidation && bannoValidation.applicantId) {
            return this.applicantFactory.get(workspace.workspaceUUID, bannoValidation.applicantId);
        } else {
            return this.applicantFactory.create(workspace.workspaceUUID, {
                applicantState: CORE_STATUS.CREATED,
                applicantType: WORKFLOW_TYPES.PERSON
            });
        }
    };

    FillDataFromSso(
        bannoValidation: SsoValidate,
        workspace: { workspaceUUID: string },
        applicant: { applicantId: number }
    ): Promise<IApplicant> | Promise<void> {
        // This only happens when you SSO, have information, but NO applicant in OA yet
        if (bannoValidation && bannoValidation.token && !bannoValidation.applicantId) {
            return this.ssoRedirectFactory.ssoFill(workspace.workspaceUUID, applicant.applicantId, bannoValidation.token);
        } else {
            return Promise.resolve();
        }
    };

    Tracker(
        workspace: { workspaceUUID: string },
        enrollment: { enrollmentId: number }[],
        applicant: { applicantId: number | null },
        workflow: { workflowName: string; startStateId: number },
        bannoValidation: SsoValidate
    ): Promise<WorkspaceTracker> {
        if (bannoValidation && bannoValidation.trackerUUID) {
            return this.trackerFactory.get(workspace.workspaceUUID, bannoValidation.trackerUUID);
        } else {
            return this.trackerFactory.init(
                workspace.workspaceUUID,
                applicant.applicantId,
                enrollment[0].enrollmentId,
                workflow.workflowName,
                workflow.startStateId
            );
        }
    };

    Workflow(
        startWorkflowFactory: StartWorkflowFactory,
        bannoValidation: SsoValidate,
        workflowFactory: WorkflowFactory,
        startWorkflowCode: string | undefined
    ): Promise<StartWorkflow> {
        if (bannoValidation && bannoValidation.trackerUUID && bannoValidation.trackerWorkflow) {
            return workflowFactory.getWorkflow(bannoValidation.trackerWorkflow);
        } else {
            return startWorkflowFactory.get(startWorkflowCode ?? WorkflowCodesConstant.BANNO_SSO_ORIGIN);
        }
    };

}
