<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-information.js";
import "@banno/jha-wc/src/icons/jha-icon-warning.js";
import "@banno/jha-wc/src/jha-error-message/jha-error-message.js";
import "@banno/jha-wc/src/jha-message/jha-message.js";

import { defineComponent } from "vue";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import type { IErrorMessage } from "@/common/models/IErrorMessage";

export default defineComponent({
  name: "bltNotificationEmp",
  props: {
    messages: { type: Array<IErrorMessage> }
  },
  data() {
    return { NotificationConstants };
  },
  computed: {
    warnings() {
      return this.messages?.filter((m) => m.type === NotificationConstants.WARN);
    },
    errors() {
      return this.messages?.filter((m) => m.type === NotificationConstants.ERROR);
    },
    infos() {
      return this.messages?.filter((m) => m.type === NotificationConstants.INFO);
    }
  }
});
</script>
<template>
  <jha-error-message class="notification" v-if="errors?.length">
    <div v-for="(message, index) in errors" :key="index" class="error-div">{{ message.message }}</div>
  </jha-error-message>

  <jha-message class="information notification" v-if="infos?.length">
    <div class="error-div" v-for="(message, index) in infos" :key="index">
      <div class="d-flex center">
        <div>
          <jha-icon-information class="icon-align icon error-icon-top"></jha-icon-information>
        </div>
        <div class="message">{{ message.message }}</div>
      </div>
    </div>
  </jha-message>

  <jha-message class="information notification" v-if="warnings?.length">
    <div class="error-div" v-for="(message, index) in warnings" :key="index">
      <div class="d-flex center">
        <div>
          <jha-icon-warning class="icon-align icon error-icon-top"></jha-icon-warning>
        </div>
        <div class="message">{{ message.message }}</div>
      </div>
    </div>
  </jha-message>
</template>
<style scoped>
jha-error-message {
  --jha-color-warning: var(----jha-color-danger);
}
.message {
  inline-size: 99%;
  overflow-wrap: break-word;
}
.icon-align {
  padding-right: var(--jh-space-400);
}

/* @media only screen and (min-width: 1025px) and (max-width: 1240px) {
:deep(.notification-top) {
  margin: 24px 24px 0 24px;
}
} */

@media (max-width: 768px) {
  .message {
    inline-size: 99%;
  }
}
@media (max-width: 468px) {
  .message {
    inline-size: 99%;
  }
}
</style>
