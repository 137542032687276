import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { isUndefined } from "lodash";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class OAuthFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  async get(providerUUID: string) {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/oauthprovider/${providerUUID}`;
    return await this.httpHandler.get(url, {}, ResponseTypes.None);
  }

  async getAll(queryParams?: { enabled: boolean; simple: boolean }) {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/oauthprovider`;
    if (isUndefined(queryParams) || queryParams == null) {
      return await this.httpHandler.get(url, {}, ResponseTypes.None);
    } else {
      return await this.httpHandler.get(url, { params: queryParams }, ResponseTypes.None);
    }
  }
}

export default OAuthFactory;
