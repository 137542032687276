import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import {injectable, inject} from 'inversify'
import HttpHandler from '@/common/services/connect/HttpHandler'
import ResponseTypes from '@/common/enums/responseTypesEnum'
import type UIField from '@/common/data/UIFields'
import type RestResponse from '@/common/data/RestResponse'

@injectable()
export default class UIFieldsFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(messageCategoryId: number): Promise<Array<UIField>> {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSUI}/field/${messageCategoryId}`
    return this.httpHandler.get(url, {}, ResponseTypes.Payload)
  }

  getAll(): Promise<Array<UIField>> {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSUI}/field`
    return this.httpHandler.get(url, {}, ResponseTypes.Payload)
  }

  update(dto: UIField): Promise<RestResponse<UIField>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/field/${dto.fieldId}`, dto)
  }

  getAllCategories(): Promise<RestResponse<Array<FieldsCategory>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/fieldscategories`)
  }
}

export interface FieldsCategory {
  categoryId: number
  categoryName: string
  parentCategoryId?: number
  messageCount: number
}
