import DEPENDENCYTYPES from "@/common/dependency.types";
import { inject, injectable } from "inversify";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { NotificationConstants } from "@/common/constant/NotificationConstants";

@injectable()
class NotificationFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {}

  showData = (data: {
    infos: { message: string }[];
    warnings: { message: string }[];
    errors: { message: string }[];
  }) => {
    if (data !== undefined) {
      if (Array.isArray(data.infos)) {
        data.infos.forEach((info: { message: string }) => {
          this.info(info.message);
        });
      }
      if (Array.isArray(data.warnings)) {
        data.warnings.forEach((warn: { message: string }) => {
          this.warn(warn.message);
        });
      }
      if (Array.isArray(data.errors)) {
        data.errors.forEach((er: { message: string }) => {
          this.error(er.message);
        });
      }
    }
  };

  clearMessages() {
    this.workspaceMetadataStore.notification.messages = [];
  }

  removeErrorMessage(message: string) {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (messages.map((m) => m.message).includes(message))
      messages.splice(messages.findIndex((m) => m.message.includes(message)));
    this.workspaceMetadataStore.notification.messages = messages;
  }

  error(message: string) {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({ message: message, type: NotificationConstants.ERROR });
  }

  warn(message: string) {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({ message: message, type: NotificationConstants.WARN });
  }

  info(message: string) {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({ message: message, type: NotificationConstants.INFO });
  }
}

export default NotificationFactory;
