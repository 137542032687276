
 /**
   * @deprecated
   * PaletteFactory Service is not used
   */

import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import LogService from "@/common/services/Log/LogService";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import APP_NAMES from "@/common/constant/AppNamesConstant";
import BASE_COLORS from "@/common/constant/BaseColorsConstant";
import ColorsConstant from "@/common/constant/ColorsConstant";
import OpenThemeProvider from "@/common/services/OpenThemeProvider/OpenThemeProvider";
import { cloneDeep } from "lodash";
import { $inj } from "@/common/decorators/depinject";
import { PaletteConstant } from "@/common/constant/PaletteConstant";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWorkspaceMetadataStore from "../Workspace/IWorkspaceMetadataStore";
import REGEX from "@/common/enums/regexEnum";

@injectable()
class PaletteFactory {
  public store: any;

  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService,
    @inject(OpenThemeProvider) private openThemeProvider: OpenThemeProvider,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {
    this.store = this.workspaceMetadataStore;
  }

  async loadTheme(serviceCode: string, themingProvider?: string) {
    try {
      this.store.themes.themes = await this.fetch(serviceCode);
      this.apply(this.store.themes.themes, themingProvider, null);
    } catch (error) {
      this.logService.error(
        "There was an error parsing the angular material theme, please check the appearance module."
      );
    }
  }

  async get(appName: string, mdThemingProvider: string) {
    this.store.themes.themes = await this.fetch(appName);
    if (this.store.themes.themes) await this.apply(this.store.themes.themes, mdThemingProvider, null);
    else this.apply(null, mdThemingProvider, appName);
  }

  async getMainColor(appName: string) {
    return await this.httpHandler
      .get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/palette/${appName}`, {}, ResponseTypes.Payload, true)
      .then((response) => {
        return response.theme["500"];
      });
  }

  async fetch(appName: string) {
    return Promise.all([
      await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/palette/${appName}`, {}, ResponseTypes.Payload),
      await this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.BOLTSUI}/palette/${appName}_accent`,
        {},
        ResponseTypes.Payload
      )
    ]).then((response) => {
      return response;
    });
  }

  apps() {
    return APP_NAMES;
  }

  baseColors() {
    return BASE_COLORS;
  }

  validColors() {
    return ColorsConstant.VALID_COLORS;
  }

  applyPreloadingColors(palettes: any[]) {
    const document: any = window.document;
    const elem = document[0].getElementById("OPEN_PRELOAD");
    elem.querySelector(".top").style.background = palettes[0].theme[500];
    elem.querySelector(".progress").style.background = palettes[1].theme["100"];
    elem.querySelector(".main .loader").style.borderColor = palettes[1].theme["100"];
    elem.querySelector(".main .loader").style.borderTopColor = palettes[1].theme["A200"];
  }

  hexToRGB(hex: string) {
    const result: any = REGEX.HEXToRGB.exec(hex);
    let response: number[];

    try {
      response = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    } catch (e) {
      this.logService.error(`Error parsing Hex to RGB value: " ${hex} ", returning a default of black...`);
      response = [0, 0, 0];
    }

    return response;
  }

  getContrastColor(
    theme: { contrastLightColors: any[]; contrastDarkColors: any[]; contrastStrongLightColors: any[] },
    colorCode: string
  ) {
    const light = theme.contrastLightColors.some((lightColor) => {
      return lightColor === colorCode;
    });
    const dark = theme.contrastDarkColors.some((darkColor) => {
      return darkColor === colorCode;
    });
    const strongLight = theme.contrastStrongLightColors.some((sLightColor) => {
      return sLightColor === colorCode;
    });

    if (light) {
      return [255, 255, 255, 0.87];
    } else if (dark) {
      return [0, 0, 0, 0.87];
    } else if (strongLight) {
      return [255, 255, 255, 1];
    } else {
      return [255, 255, 255, 0.87];
    }
  }

  parsePalette(DTO: any) {
    const palette: any = {};
    const validColours = this.validColors();
    validColours.forEach((colorCode) => {
      palette[colorCode] = {
        hex: DTO[colorCode],
        contrast: this.getContrastColor(DTO, colorCode),
        value: this.hexToRGB(DTO[colorCode])
      };
    });
    return palette;
  }

  apply(theme: any[] | null, mdThemingProvider: any | null, themeName: string | null) {
    themeName = themeName || PaletteConstant.BLT_THEME;
    if (null === theme) {
      this.openThemeProvider.definePalette(PaletteConstant.CUSTOM_COLOR, this.openThemeProvider._PALETTES.blue);
      this.openThemeProvider.definePalette(PaletteConstant.CUSTOM_ACCENT, this.openThemeProvider._PALETTES.red);
    } else {
      const primary = theme[0] || {},
        accent = theme[1] || {};

      const primaryDTO = this.openThemeProvider.extendPalette(primary.baseColor, primary.theme),
        accentDTO = this.openThemeProvider.extendPalette(accent.baseColor, accent.theme);

      this.openThemeProvider.definePalette(
        PaletteConstant.CUSTOM_COLOR,
        this.openThemeProvider.parsePalette(primaryDTO)
      );
      this.openThemeProvider.definePalette(
        PaletteConstant.CUSTOM_ACCENT,
        this.openThemeProvider.parsePalette(accentDTO)
      );
    }

    this.openThemeProvider.defineTheme(themeName, {
      primary: PaletteConstant.CUSTOM_COLOR,
      accent: PaletteConstant.CUSTOM_ACCENT,
      dark: false
    });

    this.openThemeProvider.theme(themeName);
    this.openThemeProvider.primaryPalette(PaletteConstant.CUSTOM_COLOR);
    this.openThemeProvider.accentPalette(PaletteConstant.CUSTOM_ACCENT);
  }

  create(DTO: any, app: any, type: any) {
    return new Palette(DTO, app, type);
  }

  applyAll(app: any[]) {
    const promises: any[] = [];
    Object.keys(this.apps()).forEach((appName) => {
      const palette = cloneDeep(app[0]),
        accentPalette = cloneDeep(app[1]);
      palette.setApp(appName);
      accentPalette.setApp(appName);
      promises.push(palette.save());
      promises.push(accentPalette.save());
    });

    return Promise.all(promises);
  }
}

class Palette {
  DTO: any;
  app: any;
  type: any;
  colors: any;
  saving = false;
  private openThemeProvider = $inj(OpenThemeProvider);
  private httpHandler = $inj(HttpHandler);
  private paletteFactory = $inj(PaletteFactory);
  constructor(DTO: any, app: any, type: any) {
    this.DTO = DTO;
    this.app = app;
    this.type = type;
    this.saving = false;
    this.contrastLightColors();
    this.contrastDarkColors();
    this.validColors();
  }

  getContrastColor(colorKey: string) {
    let color = this.DTO.theme.contrastDefaultColor || PaletteConstant.LIGHT;
    color = this.DTO.theme.contrastLightColors.some((lightCodes: string) => {
      return lightCodes === colorKey;
    })
      ? PaletteConstant.LIGHT
      : color;
    color = this.DTO.theme.contrastDarkColors.some((darkCodes: string) => {
      return darkCodes === colorKey;
    })
      ? PaletteConstant.DARK
      : color;
    color = this.DTO.theme.contrastStrongLightColors.some((sLightCodes: string) => {
      return sLightCodes === colorKey;
    })
      ? PaletteConstant.LIGHT
      : color;

    return color;
  }

  setApp(app: any) {
    this.app = app;
  }

  setBase(baseColor: string | number) {
    const baseColorPalette = this.openThemeProvider._PALETTES[baseColor];
    this.paletteFactory.validColors().forEach((colorKey) => {
      this.colors[colorKey].code = baseColorPalette[colorKey].hex;
    });
  }

  async save() {
    this.saving = true;
    this.DTO.theme.contrastLightColors = this.contrastLightColors();
    this.DTO.theme.contrastDarkColors = this.contrastDarkColors();
    this.DTO.theme.contrastStrongLightColors = [];

    this.paletteFactory.validColors().forEach((colorKey) => {
      this.DTO.theme[colorKey] = this.colors[colorKey].code;
    });

    const promise =
      this.type === PaletteConstant.ACCENT
        ? await this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.BOLTSUI}/palette/${this.app}/_accent`,
            this.DTO,
            {},
            ResponseTypes.Payload
          )
        : await this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.BOLTSUI}/palette/${this.app}`,
            this.DTO,
            {},
            ResponseTypes.Payload
          );

    return promise.then((response: any) => {
      this.saving = false;
      return this.paletteFactory.create(response, this.app, this.type);
    });
  }

  validColors() {
    this.paletteFactory.validColors().forEach((colorKey) => {
      this.colors[colorKey] = {
        code: this.DTO.theme[colorKey],
        contrastColor: this.getContrastColor(colorKey)
      };
    });
  }

  contrastLightColors() {
    Object.defineProperty(this, PaletteConstant.CONTRAST_LIGHT_COLORS, {
      get: () => {
        return Object.keys(this.colors).map((colorKey) => {
          return this.colors[colorKey].contrastColor === PaletteConstant.LIGHT ? colorKey : null;
        });
      }
    });
  }

  contrastDarkColors() {
    Object.defineProperty(this, PaletteConstant.CONTRAST_DARK_COLORS, {
      get: () => {
        return Object.keys(this.colors).map((colorKey) => {
          return this.colors[colorKey].contrastColor === PaletteConstant.DARK ? colorKey : null;
        });
      }
    });
  }
}

export default PaletteFactory;
