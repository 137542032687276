import { injectable, inject } from "inversify";
import BltDateFilter from "@/common/services/filters/bltDateFilter";
import { isUndefined } from "lodash";

@injectable()
class SearchDateFilter {
  constructor(@inject(BltDateFilter) private bltDateFilter: BltDateFilter) { }

  searchDate(searchItem: { createdDate?: Date; dateOfBirthIso?: Date; createdDateIso?: Date }) {
    if (searchItem && (searchItem.createdDate || searchItem.dateOfBirthIso)) {
      const date = searchItem.createdDate || searchItem.dateOfBirthIso;
      return !isUndefined(date) ? this.bltDateFilter.bltDate(date) : "";
    } else if (searchItem && searchItem.createdDateIso) {
      return "Created: " + this.bltDateFilter.bltDate(searchItem.createdDateIso);
    } else {
      return "";
    }
  }
}

export default SearchDateFilter;