import { inject, injectable } from "inversify";
import AuthenticationFactory from "@/common/services/Authentication/AuthenticationFactory";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import type { Detail } from "@/common/data/RestResponse";
import type { AxiosResponse } from "axios";

export interface AuthenticatedResponse {
  login: string,
  apitoken: string
  firstName: string,
  lastName: string,
  warnings: Detail[],
  errors: Detail[],
  infos: Detail[],
}

export interface LoginRequest {
  login: string,
  password: string,
}

export interface LoginResponse extends AuthenticatedResponse {
  login: string,
  twoFactorRequired: boolean,
  apitoken: string // When twoFactorRequired is true, this is the tfaToken for an AnswerTfaChallengeRequest...
  firstName: string,
  lastName: string,
}

export interface AnswerTfaChallengeRequest {
  login: string,
  tfaToken: string,
  tfaCode: string
}

export interface AnswerTfaChallengeResponse extends AuthenticatedResponse {
  login: string,
  apitoken: string
  firstName: string,
  lastName: string,
}

// These are required by the API, but can always be passed as null because they are no longer used.
// Once these are removed as requirements from the API they can be removed from the auth contract.
const unusedAuthenticationFields = {
  latitude: null,
  longitude: null,
  locationAccuracyMeters: null,
  forceVerificationNewDevice: null
};

@injectable()
class LoginService {
  constructor(
    @inject(AuthenticationFactory) private authenticationFactory: AuthenticationFactory,
    private localStore = useLocalStorage()
  ) {
  }

  #persistAuthenticationDataToLocalStorage = <T extends AuthenticatedResponse>(response: AxiosResponse<T>): AxiosResponse<T> => {
    this.localStore.login = response.data.login;
    this.localStore.apitoken = response.data.apitoken;
    this.localStore.user = { firstName: response.data.firstName, lastName: response.data.lastName };
    return response;
  }

  login(request: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return this.authenticationFactory
      .authenticate({
        login: request.login,
        password: request.password,
        tfaToken: null,
        tfaCode: null,
        ...unusedAuthenticationFields,
        deviceGuid: this.localStore.guid // todo(mikol): make localStore guid non-nullable...
      })
      .then(this.#persistAuthenticationDataToLocalStorage);
  }

  answerTfaChallenge(request: AnswerTfaChallengeRequest): Promise<AxiosResponse<AnswerTfaChallengeResponse>> {
    return this.authenticationFactory
      .authenticate({
        login: request.login,
        tfaToken: request.tfaToken,
        tfaCode: request.tfaCode,
        ...unusedAuthenticationFields,
        deviceGuid: this.localStore.guid // todo(mikol): make localStore guid non-nullable...
      })
      .then(this.#persistAuthenticationDataToLocalStorage);
  }

}

export default LoginService;
