import { injectable } from "inversify";
import OPERATION_TYPES from "@/common/enums/operationTypeEnum";

@injectable()
class FullBusinessNameFilter {
    fullBusinessName(business: { businessName?: any; }) {
        return (business && business.businessName) ? business.businessName : OPERATION_TYPES.NEW_BUSINESS;
    }
}

export default FullBusinessNameFilter;
