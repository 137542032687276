<template>
  <jha-icon-chevron-up large :class="{'active': on}">
  </jha-icon-chevron-up>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltAnimatedChevron",
  props: {
    on: Boolean,
  },
});
</script>

<style scoped>
  jha-icon-chevron-up {
    fill: black;
    transition: transform .25s ease-in-out;
  }

  jha-icon-chevron-up.active {
    transform: scale(1, -1);
  }
</style>
