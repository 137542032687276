import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class SendContinueFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  sendEmail(uuid: string, aid: any, emailId: string) {
    return this.httpHandler.post(
      `${SERVICE_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${aid}/email/${emailId}/continue`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  async getApplicantsInWorkspace(uuid: string) {
    return this.httpHandler.get(
      `${SERVICE_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default SendContinueFactory;
