import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import WORKSPACE_STATUSES from "@/common/enums/workspaceStatusEnum";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import { $injByInterface } from "@/common/decorators/depinject";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type IWorkspace from "@/common/models/IWorkspace";
import {WorkspaceStore} from "@/open/stores/WorkspaceStore";

@injectable()
class WorkspaceFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  /*
    Create new workspace of workspaceType either PERSON or BUSINESS
    @param workspaceType - PERSON or BUSINESS
    */
  async postWorkspace(workspaceType: { workspaceType: string }): Promise<IWorkspace> {
    return await this.httpHandler.post(
      SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE,
      workspaceType,
      {},
      ResponseTypes.Payload
    );
  }
  // Get the workspace with UUID
  async getWorkspace(uuid: string): Promise<IWorkspace> {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}`,
      {},
      ResponseTypes.Payload
    );
  }

  // Set the workspace status
  async setStatus(workspace: any, status: any) {
    return await this.httpHandler
      .put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspace.workspaceUUID}/status/${status}`)
      .then(() => {
        workspace.statusCode = status;
      });
  }
  // Close the workspace
  isClosed(workspace: any) {
    return workspace.statusCode === WORKSPACE_STATUSES.CLOSED;
  }
  // History of the module is called with URL
  openStateHistoryModule() {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const baseUrl = window.location.origin;
    location.href = `${baseUrl}/bolts/managementconsole/app/index.html#!/tracker?workspaceUUID=${workspaceStore.workspaceUUID}`;
  }
}
export default WorkspaceFactory;
