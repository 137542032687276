import LookupFactory from "@/common/services/Lookup/LookupFactory";
import Base64Factory from "@/common/services/utils/Base64Factory";
import { injectable, inject } from "inversify";
import { DisplayConstant } from "@/common/constant/DisplayConstant";

@injectable()
class OCRDialogFactory {
  constructor(
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(Base64Factory) private base64Factory: Base64Factory,
  ) {}

  async display(event: string, parent: any, workspaceUUID: string, applicantId: number): Promise<any> {
    const dialogOptions = {
      theme: DisplayConstant.THEME_NAME,
      targetEvent: event,
      multiple: true,
      parent: parent,
      clickOutsideToClose: false,
      escapeToClose: false
    };
    const OCRTypes = this.lookupFactory.Ocr?.getAll();

    return { workspaceUUID, applicantId, OCRTypes, dialogOptions };
  }

  imageTypesToDTO(imageTypes: any) {
    return imageTypes.map((image: { selectedImage: { encodedBase64: string }; imageType: string }) => {
      const base64Image = this.base64Factory.getFromEncoded(image.selectedImage.encodedBase64);

      return {
        imageDataBase64: base64Image.imageDataBase64,
        imageFormat: base64Image.imageFormat,
        imageType: image.imageType
      };
    });
  }
}
export default OCRDialogFactory;
