import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type BusinessTaxId from "@/common/data/BusinessTaxId";

@injectable()
class BusinessTaxIdTypeService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

    get(businessType: string): Promise<BusinessTaxId> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/type/business-tax-id/${businessType}`,
            {},
            ResponseTypes.Payload
        )
    }
}

export default BusinessTaxIdTypeService