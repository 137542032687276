import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ENROLLMENT_DISCLOSURE_TYPES from "@/common/enums/enrollmentDisclosureTypesEnum";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentAccountDisclosureResourceFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getPackage(uuid: string, enrollmentId: number, accountId: number) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure`,
      {},
      ResponseTypes.Payload
    );
    return {
      type: ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_ACCOUNT,
      id: accountId,
      disclosures: response
    };
  }
}
export default EnrollmentAccountDisclosureResourceFactory;
