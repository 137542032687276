export class ColorsConstant {
  static readonly VALID_COLORS: string[] = [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "A100",
    "A200",
    "A400",
    "A700"
  ];
  static readonly ACCESSIBLE_COLORS: string[] = ["A100", "A200", "A400", "A700"];
}

export default ColorsConstant;
