export class DisplayConstant {
  static readonly THEMING_PROVIDER: string = "$mdThemingProvider";
  static readonly THEME_NAME: string = "bltTheme";
  static readonly Document: string = "document";
  static readonly REQUIRED: string = "required";
  static readonly FOUND: string = "FOUND";
  static readonly APPLICANT_CHANNEL_FAVICON: string = "APPLICANT_CHANNEL_FAVICON";
  static readonly PRINT: string = "Print";
  static readonly CHANGE: string = "Change";
  static readonly REMOVE: string = "Remove";
  static readonly CONFIRM_ACCOUNT_SELECTION: string = "Confirm account selection";
  static readonly BUSINESS_OWNERSHIP: string = "BUSINESS_OWNERSHIP";
  static readonly PERSON: string = "PERSON";
  static readonly COMPLETE: string = "COMPLETE";
  static readonly OWNERSHIP: string = "Ownership";
  static readonly INCOMPLETE: string = "Incomplete";
  static readonly COMPLETE_MSG: string = "Complete";
  static readonly OWNERSHIP_LESS_0: string = "Total ownership percentage is less than 0%";
  static readonly INVALID_PERCENTAGE: string = "Unable to calculate ownership percentage";
  static readonly REMAINING: string = "Remaining";
  static readonly BENEFICIAL_OWNERSHIP_INFORMATION: string = "BENEFICIAL_OWNERSHIP_INFORMATION";
  static readonly CERTIFYING_PERSON_CERTIFICATION: string = "Certifying person certification";
  static readonly CERTIFY_INFO: string =
    "certify to the best of my knowledge, that the information provided is complete and correct.";
  static readonly TRACKERUUID_REQUIRED_ERROR = "TrackerUUID is required, but is not present";
}
