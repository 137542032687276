import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import type CacheableAxiosRequestConfig from "./CacheableAxiosRequestConfig";

class UrlInterpolateInterceptor {
    public static interpolate(
        config: CacheableAxiosRequestConfig,
        store: IWorkspaceMetadataStore) {

    }

    public static constructUrl(
        url: string | undefined,
        store: IWorkspaceMetadataStore): string {
        let retUrl = url || '';
        if (url) {
            const startIndex = url?.indexOf("{{");
            const endIndex = url?.indexOf("}}");
            if (startIndex !== -1) {
                const name = url.slice(startIndex + 2, endIndex);
                if (store.serviceUrls[name]) {
                    // to replace all the interpolated url template in the factories
                    retUrl = url.replace("{{" + name + "}}", store.serviceUrls[name]);

                    // This is so we can proxy the requests to open.local with cors headers removed in dev environments.
                    // A little hacky, but ensures requests to the target are proxied through vite before reaching the target.
                    if (import.meta.env.VITE_PRODUCTION != "true" ||
                        import.meta.env.VITE_PRODUCTION_WITH_PROXY == "true") {
                        const proxyTargets = [
                            import.meta.env.VITE_APP_PROXY_APPLICANT_TARGET,
                            import.meta.env.VITE_APP_PROXY_EMPLOYEE_TARGET,
                        ]
                         proxyTargets.forEach((target) => {
                             retUrl = retUrl.replace(target, '');
                         })
                    }

                }
            }

        }
        return retUrl;
    }
}
export default UrlInterpolateInterceptor;