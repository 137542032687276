<template>
  <div>
    <label>
      <slot></slot>
    </label>
    <jha-form-select
      outline
      :label="(value || '').length > 1 ? lookupTitle : ''"
      :value="value"
      :labelKey="'typeDescription'"
      :valueKey="'typeCode'"
      :options="getLookupList"
      @change="onChange($event)"
      :emptyOptionLabel="getEmptyOptionLabel"
      :required="isRequired"
      :disabled="disabled"
    >
    </jha-form-select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import { $inj } from "@/common/decorators/depinject";
import { LanguageFactory, LookupFactory } from "@/common/services/services.module";
import { LOOKUPSUPPORTCONSTANTS } from "@/common/constant/LookupConstant";
import LogService from "@/common/services/Log/LogService";
import { find } from "lodash";
import type TypeCode from "@/common/data/TypeCode";
import {isEmpty} from "lodash";
export default defineComponent({
  name: "bltLookupSelector",
  emits: ["lookupChange"],
  props: {
    allowClear: {
      type: Boolean,
      required: false
    },
    bltLookup: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: false
    },
    lookupTitleDefault: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bltLookupOptions: {
      type: Object,
      default: null
    },
    bltSearchable: {
      type: Boolean,
      default: false
    },
    bltTallList: {
      type: Boolean,
      default: false
    },
    require: {
      type: Boolean,
      default: false
    },
    emptyOptionLabel: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    LOOKUPSUPPORTCONSTANTS,
    loaded: false,
    lookupList: [] as Array<TypeCode | {typeCode: undefined, typeDescription: string}>,
    bltFilter: "",
    lookupTitle: "",
  }),
  setup() {
    const lookupFactory = $inj(LookupFactory);
    const logService = $inj(LogService);
    const languageFactory = $inj(LanguageFactory);
    return {
      lookupFactory,
      logService,
      languageFactory
    };
  },
  async created() {
    // Load lookup list for given lookup type (bltLookup prop).
    await this.load();
  },
  computed: {
    isRequired() {
      return this.require;
    },
    getLookupList() {
      return this.lookupList;
    },
    getEmptyOptionLabel() {
      if (this.value) {
        const result = find(this.lookupList, { typeCode: this.value }) as any;
        return result ? result.typeDescription : this.lookupTitle;
      } else if (this.require) {
        return this.lookupTitle + " *";
      }
      return this.lookupTitle;
    }
  },
  methods: {
    onChange(event: any) {
      if (this.loaded && this.value !== event.detail) {
        this.$emit("lookupChange", event);
      }
    },
    addClear() {
      this.lookupList.unshift({
        typeCode: undefined,
        typeDescription: '',
      })
    },
    async load() {
      const params = this.bltLookupOptions ? this.bltLookupOptions : { enabled: true };
      this.lookupList = [];
      try {
        const lookup = this.lookupFactory[this.bltLookup as keyof LookupFactory];
        this.lookupList = await lookup?.getAll(params);
      } catch (error) {
        //case when this.bltLookup doesn't exist in LookupFactory
        this.logService.error(this.LOOKUPSUPPORTCONSTANTS.LOOKUP_ERROR.replace("bltLookup", this.bltLookup));
      }
      if (this.title && !this.lookupTitle) {
        await this.languageFactory.get(this.title).then((response) => {
          this.lookupTitle = response;
        });
      } else {
        this.lookupTitle = this.lookupTitleDefault ?? ""
      }

      if (this.lookupList) {
        this.loaded = Object.keys(this.lookupList).length > 0;

        if (this.allowClear) {
          this.addClear();
        }
      }
    }
  }
});
</script>
