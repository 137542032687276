import { defineStore } from "pinia";
import { LocalStorageKeyConstant } from "@/common/constant/LocalStorageKeyConstant";

/**infinite expiry concept is not implemented as no such requirement is seen */

interface CacheItem {
    expiry: number,
    data: object
}

export const CacheStore = defineStore({
    id: LocalStorageKeyConstant.CACHE_STORE,
    state: (): ICacheStore => ({
        cachedItems: []
    }),
    persist: {
        storage: localStorage
    },
    getters: {
    },
    actions: {
        get(uuid: string): object | undefined {
            const cachedObjStr: string = this.cachedItems[uuid];
            if (cachedObjStr) {
                const cachedObj: CacheItem = JSON.parse(cachedObjStr);
                const now = new Date();
                //remove if cache expired and return undefined
                if (now.getTime() > cachedObj.expiry) {
                    this.remove(uuid);
                    return undefined;
                }
                return cachedObj.data;
            }
            else
                return undefined;
        },
        set(uuid: string, data: any, expiryMins: number) {
            if (data && uuid) {
                const currentTime = new Date()
                const expiryInMilliseconds = currentTime.getTime() + (expiryMins * 60 * 1000)
                const cachedObj = { expiry: expiryInMilliseconds, data: data };
                this.cachedItems[uuid] = JSON.stringify(cachedObj);
            }
        },
        remove(uuid: string) {
            this.cachedItems[uuid] = undefined;
        },
        clear() {
            this.cachedItems = [];
        }
    }
});

interface ICacheStore {
    cachedItems: { [x: string]: any }
}