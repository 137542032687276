import { inject, injectable } from "inversify";
import { isObject } from "lodash";
import FullNameFilter from "@/common/services/filters/fullNameFilter";
import FullBusinessNameFilter from "@/common/services/filters/fullBusinessNameFilter";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import OPERATION_TYPES from "@/common/enums/operationTypeEnum";

@injectable()
class FullApplicantNameFilter {
  constructor(
    @inject(FullNameFilter) private fullNameFilter: FullNameFilter,
    @inject(FullBusinessNameFilter) private fullBusinessNameFilter: FullBusinessNameFilter
  ) {}

  fullApplicantName(applicant: { applicantType?: any; person?: any; business?: any }) {
    switch (isObject(applicant) && applicant.applicantType) {
      case WORKFLOW_TYPES.PERSON:
        return this.fullNameFilter.fullName(applicant.person);
      case WORKFLOW_TYPES.BUSINESS:
        return this.fullBusinessNameFilter.fullBusinessName(applicant.business);
      default:
        return OPERATION_TYPES.NEW_APPLICANT;
    }
  }
}

export default FullApplicantNameFilter;
