import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { WoltersKluwerConstant } from "@/common/constant/WKConstant";

@injectable()
class WoltersKluwerFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  getJurisdiction(workspaceUUID: string, accountId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/account/${accountId}/jurisdiction`,
      {},
      ResponseTypes.Payload
    );
  }

  getFieldsForJurisdiction(workspaceUUID: string, applicantId: number, jurisdiction: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/testimony-questions`,
      {
        params: {
          jurisdiction: jurisdiction
        }
      },
      ResponseTypes.Payload
    );
  }

  getPrimaryApplicantId(accountList: { applicants: { applicantId: number; primary: boolean }[] }[]) {
    const account = accountList[0] || {},
      applicantlist = account.applicants || [],
      primaryApplicant = applicantlist.filter((applicant) => {
        return applicant.primary;
      });

    return primaryApplicant.length ? primaryApplicant[0].applicantId : null;
  }

  /**
   * This now removes this question this from non-primaries, but FIs would like to
   * remove this from all applicants with a property in the future.
   */
  removeAllBackupWithholding(
    applicantList: { fieldSize?: number; wkFields: { questionKey: string }[] }[],
    primaryApplicantId: number
  ) {
    applicantList.forEach((applicant) => {
      this.removeBackupWithholding(applicant, primaryApplicantId);
      applicant.fieldSize = Object.keys(applicant.wkFields).length;
    });

    return applicantList;
  }

  removeBackupWithholding(
    applicant: { fieldSize?: number; wkFields: { questionKey: string }[]; applicantId?: number },
    primaryApplicantId: number
  ) {
    if (applicant.applicantId !== primaryApplicantId) {
      applicant.wkFields = applicant.wkFields.filter((field: any) => {
        return field.questionKey !== WoltersKluwerConstant.BACKUP_WITH_HOLDING;
      });
    }
  }
}
export default WoltersKluwerFactory;
