export class LOOKUPCONSTANT {
  static readonly DOCUMENT: string = "Document";
  static readonly PERSON: string = "Person";
  static readonly SALUTATION: string = "Salutation";
  static readonly ADDRESS: string = "Address";
  static readonly IDENTIFICATION: string = "Identification";
  static readonly EMAIL: string = "Email";
  static readonly PHONE: string = "Phone";
  static readonly COUNTRY: string = "Country";
  static readonly STATE: string = "State";
  static readonly BRANCHLOCATION: string = "BranchLocation";
  static readonly ENROLLMENTSTATE: string = "EnrollmentState";
  static readonly ENROLLMENT: string = "Enrollment";
  static readonly PRODUCTGROUP: string = "ProductGroup";
  static readonly PRODUCT: string = "Product";
  static readonly EXISTINGACCOUNT: string = "ExistingAccount";
  static readonly BUSINESS: string = "Business";
  static readonly NAICS: string = "Naics";
  static readonly OCR: string = "Ocr";
  static readonly GENDER: string = "Gender";
  static readonly EDUCATIONLEVEL: string = "EducationLevel";
  static readonly INCOMELEVEL: string = "IncomeLevel";
  static readonly MARITALSTATUS: string = "MaritalStatus";
  static readonly LANGUAGE: string = "Language";
  static readonly EMPLOYMENTSTATUS: string = "EmploymentStatus";
  static readonly CITIZENSHIP: string = "Citizenship";
  static readonly ETHNICITY: string = "Ethnicity";
  static readonly RACE: string = "Race";
  static readonly APPLICANTSTATE: string = "ApplicantState";
  static readonly FUNDING: string = "Funding";
  static readonly RELATIONSHIP: string = "Relationship";
  static readonly MEMBERGROUP: string = "MemberGroup";
  static readonly INTENTION: string = "Intention";
  static readonly RULE: string = "Rule";
  static readonly MAJORCODES: string = "MajorCodes";
  static readonly MINORCODES: string = "MinorCodes";
  static readonly SERVICES: string = "Services";
  static readonly ROLES: string = "Roles";
  static readonly WORKFLOWSTATECATEGORY: string = "WorkflowStateCategory";
  static readonly WORKFLOWSTATE: string = "WorkflowState";
  static readonly PERSON_TYPE: string = "Person";
}

export class LOOKUPSUPPORTCONSTANTS {
  static readonly PRODUCT_FEATURES: string = "productFeatures";
  static readonly LOOKUP_ERROR: string = "The lookup: [bltLookup] does not exist.";
  static readonly LOOKUP_TYPE: string = "/leafREST/type/";
}
