<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltSearch",
  emits: ["onEntersearchFilterValue", "onSearchFilterValue"],
  props: {
    labelText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: "" as string
    };
  },
  methods: {
    OnSearchFilterValue(event: { detail: string }): void {
      this.searchValue = event.detail;
      this.$emit("onSearchFilterValue", this.searchValue);
    },
    OnEnterSearchFilter(event: { target: { value: string; }; }): void {
      this.searchValue = event.target ? event.target.value: "";
      this.$emit("onEntersearchFilterValue", this.searchValue);
    }
  }
});
</script>
<template>
  <div class="searchFilter">
    <jha-form-search-input
      outline
      :label="labelText"
      @search="OnSearchFilterValue($event)"
      @keyup.enter="OnEnterSearchFilter($event)"
      :disabled="disabled"
    >
    </jha-form-search-input>
  </div>
</template>

<style scoped>
 jha-form-search-input {
   --jha-component-background-color: var(--jha-background-color);
 }
</style>
