<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-circle-plus.js";
import "@banno/jha-wc/src/icons/jha-icon-circle-minus.js";
import "@banno/jha-wc/src/lists/jha-list/jha-list.js";
import "@banno/jha-wc/src/lists/jha-list-item/jha-list-item.js";
import "@banno/jha-wc/src/lists/reorder/jha-reorder-list/jha-reorder-list.js";
import "@banno/jha-wc/src/lists/reorder/jha-reorder-list-item/jha-reorder-list-item.js";
import "@banno/jha-wc/src/jha-flex-wrapper/jha-flex-wrapper.js";
import { defineComponent } from "vue";
import type { bltModalDialog } from "@/common/components/appch.components.module";

export default defineComponent({
  name: "bltWebReorder",
  emits: ["accounts-selected"],
  props: {
    addedHeading: {
      required: true,
      type: String
    },
    availableHeading: {
      required: true,
      type: String
    },
    saveLabel: {
      required: true,
      type: String
    },
    accounts: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      availableAccounts: [...this.accounts.filter((ele: any) => !ele.added)] as any[],
      selectedAccounts: [...this.accounts.filter((ele: any) => ele.added)] as any[],
      selectedAccountOrder: [] as any[]
    };
  },
  methods: {
    cancelDialog() {
      (this.$refs.popup as typeof bltModalDialog).close();
    },
    show() {
      (this.$refs.popup as typeof bltModalDialog).open();
    },

    selectAccount(item: any) {
      this.selectedAccounts = [...this.selectedAccounts, ...[item]];
      this.availableAccounts = this.availableAccounts.filter((i: any) => i !== item);
      this.selectedAccountOrder.map((accountId: any) =>
        this.selectedAccounts.find((item: any) => item.accountId === accountId)
      );
      this.$emit("accounts-selected", {
        transferAccounts: this.selectedAccounts,
        avaliableAccounts: this.availableAccounts
      });
    },

    deselectAccount(item: any) {
      this.availableAccounts = [...this.availableAccounts, ...[item]];
      // Store the accounts in a the new order
      const newOrder = this.selectedAccountOrder.length
        ? this.selectedAccountOrder.map((accountId: number) =>
            this.selectedAccounts.find((item: any) => item.accountId === accountId)
          )
        : [...this.selectedAccounts];

      // clear accounts, then set them to stored value and remove the account
      this.selectedAccounts = [];
      window.requestAnimationFrame(() => {
        this.selectedAccounts = newOrder.filter((i: any) => i !== item);
      });
      this.$emit("accounts-selected", {
        transferAccounts: this.selectedAccounts,
        avaliableAccounts: this.availableAccounts
      });
    },

    handleListReorder(evt: any) {
      this.selectedAccountOrder = evt.detail || [];
      const selectedAccounts = this.selectedAccountOrder.map((accountId: any) =>
        this.selectedAccounts.find((item: any) => item.accountId === accountId)
      );
      this.$emit("accounts-selected", {
        transferAccounts: selectedAccounts,
        avaliableAccounts: this.availableAccounts
      });
    },

    getItemOrderIndex(itemId: any) {
      return this.selectedAccountOrder.indexOf(itemId) + 1;
    },

    onSave() {
      const selectedAccounts = this.selectedAccountOrder.map((accountId: any) =>
        this.selectedAccounts.find((item: any) => item.accountId === accountId)
      );
      this.$emit("accounts-selected", {
        detail: selectedAccounts
      });
      this.cancelDialog();
    },
    //Below method will be removed once ordersequence issue fix from JHA OCP-3782

    deSelectAccountWithoutReorder(item: any) {
      if (this.selectedAccounts.length) {
        this.selectedAccounts.forEach((ele, index) => {
          if (ele.accountId === item.accountId) {
            this.selectedAccounts.splice(index, 1);
            this.availableAccounts.push(item);
          }
        });
      } else {
        this.availableAccounts.push(item);
      }
      this.$emit("accounts-selected", {
        transferAccounts: this.selectedAccounts,
        avaliableAccounts: this.availableAccounts
      });
    },
    //Below method will be removed once ordersequence issue fix from JHA OCP-3782
    selectAccountWithoutReorder(item: any) {
      if (this.availableAccounts.length) {
        this.availableAccounts.forEach((ele, index) => {
          if (ele.accountId === item.accountId) {
            this.availableAccounts.splice(index, 1);
            this.selectedAccounts.push(item);
          }
        });
      } else {
        this.selectedAccounts.push(item);
      }
      this.$emit("accounts-selected", {
        transferAccounts: this.selectedAccounts,
        avaliableAccounts: this.availableAccounts
      });
    }
  }
});
</script>

<template>
  <section class="transfer-accounts-section" v-if="selectedAccounts && selectedAccounts.length">
    <div class="reorder-heading primary-text">{{ addedHeading }}</div>
    <!-- Below method will be removed once ordersequence issue fix from JHA OCP-3782 -->
    <jha-list class="mb-0">
      <div class="jha-list-item-wrapper" v-for="(item, index) in selectedAccounts" :key="item">
        <jha-list-item class="list-align mb-0">
          <jha-flex-wrapper space-between @click.stop="deSelectAccountWithoutReorder(item)">
            <div class="description">
              <span>{{ index + 1 + ". " + item.productDescription }}</span>
              <span class="secondary-text">{{ item.groupDescription }}</span>
            </div>
            <jha-button
              icon
              type="button"
              sync
              aria-label="select account"
            >
              <jha-icon-circle-minus class="icon-circle-minus"></jha-icon-circle-minus>
            </jha-button>
          </jha-flex-wrapper>
        </jha-list-item>
        <div v-if="index < selectedAccounts.length - 1" class="divider mb-0"></div>
      </div>
    </jha-list>
  </section>

  <section class="available-accounts-section" v-if="availableAccounts && availableAccounts.length">
    <div class="reorder-heading primary-text">{{ availableHeading }}</div>
    <jha-list class="mb-0">
      <div class="jha-list-item-wrapper" v-for="(item, index) in availableAccounts" :key="item">
        <jha-list-item class="list-align">
          <jha-flex-wrapper space-between  @click.stop="selectAccountWithoutReorder(item)">
            <jha-button
              icon
              type="button"
              sync
              aria-label="select account"
            >
              <jha-icon-circle-plus class="icon-circle-plus"></jha-icon-circle-plus>
            </jha-button>
            <div class="description">
              <span>{{ item.productDescription }}</span>
              <span class="secondary-text">{{ item.groupDescription }}</span>
            </div>
          </jha-flex-wrapper>
        </jha-list-item>
        <div v-if="index < availableAccounts.length - 1" class="divider mb-0"></div>
      </div>
    </jha-list>
  </section>
</template>
<style scoped>
footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.description {
  flex: 2;
}
.description span {
  display: block;
  /* margin: 8px 0; */
}

.secondary-text {
  margin-top: var(--jh-space-50);
}

.icon-circle-plus {
  fill: var(--jha-color-primary);
  padding: 4px;
  margin-right: var(--jh-space-200);

}
.icon-circle-minus {
  fill: var(--jha-color-primary);
  padding: 12px;
}


jha-icon-circle-minus.icon-circle-minus {
  fill: var(--jha-text-light);
}
jha-list-item {
  padding-right: var(--jh-space-600);
  --jha-border-color: transparent;
  --jha-list-item-horizontal-spacing: 0;
}
jha-reorder-list-item {
  --reorder-item-border-color: transparent;
}
h4 {
  margin: 0px;
}
section {
  width: 100%;
}

jha-list-item:hover {
  background: none;
  cursor: pointer;
}

.servicesState .banno-web-dialog-content-wrapper {
  overflow: auto;
  max-height: fit-content;
  padding: 0px;
}

@media only screen and (max-width: 280px) {
  jha-list-item {
    padding-right: 16px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 414px) {
  jha-list-item {
    padding-right: var(--jh-space-400);
  }
}
</style>
