import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class ApplicantQuestionnaireFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async complete(uuid: string, applicantId: number, questionnaire: { cddRequestId: any }) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/questionnaire/${questionnaire.cddRequestId}/complete`,
      questionnaire,
      {},
      ResponseTypes.Payload
    );
  }
}
export default ApplicantQuestionnaireFactory;
