import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type AddressConfirmation from "@/common/data/AddressConfirmation";
import {isEmpty} from "lodash";

@injectable()
export default class AddressConfirmationFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler,
    ) { }

    getOrPost(workspaceUUID: string, applicantId: number): Promise<AddressConfirmation> {
        return this.get(workspaceUUID, applicantId)
            .then(response => {
                if (isEmpty(response)) {
                    return this.post(workspaceUUID, applicantId);
                } else {
                    return Promise.resolve(response[0])
                }
            })
    }

    get(workspaceUUID: string, applicantId: number): Promise<Array<AddressConfirmation>>{
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/confirm-address`,
            {},
            ResponseTypes.Payload
        );
    }

    post(workspaceUUID: string, applicantId: number): Promise<AddressConfirmation> {
        return this.httpHandler.post(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/confirm-address`,
            null,
            {},
            ResponseTypes.Payload
        )
    }

    confirm(workspaceUUID: string, applicantId: number, addressConfirmation: AddressConfirmation): Promise<AddressConfirmation> {
        addressConfirmation.confirmationStatus = true;

        return this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/confirm-address`,
            addressConfirmation,
            {},
            ResponseTypes.Payload
        )
    }

    reject(workspaceUUID: string, applicantId: number, addressConfirmation: AddressConfirmation): Promise<AddressConfirmation> {
        addressConfirmation.confirmationStatus = false;

        return this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/confirm-address`,
            addressConfirmation,
            {},
            ResponseTypes.Payload
        )
    }
}