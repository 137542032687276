// This Factory will accept prefixed base64 strings and return specific data about them,
// such as the file type or the base64 data


import { injectable } from "inversify";
import { Base64Image, Base64Utils } from "@/common/services/utils/model/Base64Image";

@injectable()
class Base64Factory {
  utils: Base64Utils;
  constructor() {
    this.utils = new Base64Utils();
  }

  /**
   * @name getFromUnencoded
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method will new and return a Base64Image prototype from a data string and an extension.
   *
   * @param {String} data - base64 data.
   *
   * @param {String} extension - the image extension PNG JPG etc...
   */
  getFromUnencoded(data: string, extension: string) {
    return new Base64Image(data, extension);
  }

  /**
   * @name getFromEncoded
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method will new and return a Base64Image prototype from an encoded base64 string.
   *
   * @param {String} encodedBase64 - encoded base64 data.
   *
   */
  getFromEncoded(encodedBase64: string) {
    return new Base64Image(this.getBase64Data(encodedBase64), this.getBase64Extension(encodedBase64));
  }

  /**
   * @name prefixBase64
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method accepts base64 data and a file extension and parses them together into prefixed Base64 ready for
   *     use in browser
   *
   * @param {String} b64 - Raw Base64 data.
   *
   * @param {String} extension - the file extension of the base64 data.
   */
  prefixBase64(b64: string, extension: string) {
    return this.utils.prefixBase64(b64, extension);
  }

  /**
   * @name getBase64Extension
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method accepts a prefixed Base64 string and returns the capitalized file extension type of the Base64
   *     encoded image.
   *
   * @param {String} b64 - A prefixed Base64 string.
   */
  getBase64Extension(b64: string) {
    return this.utils.getBase64Extension(b64);
  }

  /**
   * @name getBase64Data
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method accepts a prefixed Base64 string and strips the prefix, leaving only the Base64 data.
   *
   * @param {String} b64 - A prefixed Base64 string.
   */
  getBase64Data(b64: string) {
    return this.utils.getBase64Data(b64);
  }
}

export default Base64Factory;
