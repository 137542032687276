import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class ApplicantMemberVerificationFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    verify(workspaceUUID: string, applicantId: number, message: { firstName: string, lastName: string, verificationNumber: number }) {
        return this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/verifyMember`,
            message,
            {},
            ResponseTypes.Payload);
    }

    setMemberGroup(workspaceUUID: string, applicantId: number, typeCode: string) {
        return this.httpHandler.post(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/member`,
            { typeCode: typeCode },
            {},
            ResponseTypes.Payload);
    }
}


export default ApplicantMemberVerificationFactory;