import DEPENDENCYTYPES from "@/common/dependency.types";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type WorkspaceTracker from "@/common/data/WorkspaceTracker";

@injectable()
class TrackerFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IWorkspaceStore
  ) {}

  getAll(workspaceUUID: string): Promise<WorkspaceTracker[]> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/tracker`,
      {},
      ResponseTypes.Payload
    );
  }

  get(workspaceUUID: string, trackerUUID: string): Promise<WorkspaceTracker> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/tracker/${trackerUUID}`,
      {},
      ResponseTypes.Payload
    );
  }

  post(workspaceUUID: string): Promise<WorkspaceTracker> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/tracker`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  getTracker(workspaceUUID: string, applicantId: number): Promise<WorkspaceTracker> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/tracker/applicant/${applicantId}`,
      {},
      ResponseTypes.Payload
    );
  }

  put(workspaceUUID: string, trackerUUID: string, message: any): Promise<WorkspaceTracker> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/tracker/${trackerUUID}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  async init(
    workspaceUUID: string,
    applicantId: number | null,
    enrollmentId: number,
    workflowName: string,
    state: number
  ): Promise<WorkspaceTracker> {
    const response: any = await this.post(workspaceUUID);
    if (applicantId) {
      await this.link(applicantId, workspaceUUID, response.trackerUUID);
    }
    const putResponse = await this.put(workspaceUUID, response.trackerUUID, {
      workspaceUUID,
      workflow: workflowName,
      enrollmentId: enrollmentId,
      activeApplicantId: applicantId,
      state: state,
    });
    return putResponse;
  }

  linkApplicantToTracker(workspaceUUID: string, trackerUUID: string, message: {workspaceUUID: string, trackerUUID: string, applicantId: number}): Promise<WorkspaceTracker> {
    return this.httpHandler.post(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${message.workspaceUUID}/tracker/${message.trackerUUID}/applicant`,
        message,
        {},
        ResponseTypes.Payload
    )
  }

  link(applicantId: number, workspaceUUID: string, trackerUUID?: string) {
    const message = {
      workspaceUUID: this.workspaceStore.workspaceUUID || workspaceUUID as string,
      trackerUUID: this.workspaceStore.trackerUUID || trackerUUID as string,
      applicantId: applicantId,
    };

    /**
     * @NOTE: This makes a non-RESTful post call to make an association between the applicant and a tracker in a table
     * called leaf_tracker_applicant. It will return a [[WorkspaceTracker]], but it won't have the activeApplicantId
     * even if we pass it in the message DTO. This association is used in dashboard
     */
    return this.linkApplicantToTracker(workspaceUUID, message.trackerUUID, message)
        .then(() => {
          /**
           * @NOTE: This updates the row in leaf_workspace_enduser_state_history to mark the applicant as the active one
           */
          return this.put(message.workspaceUUID, message.trackerUUID, {
            activeApplicantId: applicantId,
          })
        })
  }
}

export default TrackerFactory;
