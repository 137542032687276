import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ApplicantSuggestionFactory from "@/common/services/Applicant/applicantSuggestionFactory";
import { merge, clone, isUndefined, isEqual } from "lodash";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import AddressUtil from "@/common/services/utils/AddressUntil";
import { $inj } from "@/common/decorators/depinject";
import { AddressConstant } from "@/common/constant/AddressConstant";
import type LeafAddress from "@/common/data/LeafAddress";
import type ZipCode from "@/common/data/ZipCode";
import type IAddress from "@/common/data/IAddress";

@injectable()
class ApplicantAddressFactory {
  constructor(
    @inject(ApplicantSuggestionFactory) private applicantSuggestionFactory: ApplicantSuggestionFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LookupFactory) private lookupFactory: LookupFactory
  ) { }

  getAll(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/address`,
      {},
      ResponseTypes.Payload
    );
  }

  getApplicantAddressList(workspaceUUID: string, applicantId: number, addressTypes: any) {
    return Promise.all([
      this.applicantSuggestionFactory.get(workspaceUUID, applicantId, AddressConstant.ADDRESS),
      this.getAll(workspaceUUID, applicantId)
    ]).then(async (response: any) => {
      const suggestedAddressTypes = response[0];
      let addressList: any = Array.isArray(response[1]) ? response[1] : [];
      addressList = this.applicantSuggestionFactory.applyRequiredTypes(
        addressList,
        AddressConstant.ADDRESS_TYPE_CODE,
        suggestedAddressTypes
      );

      addressList = isEqual(addressList, [{}])
        ? [{ addressTypeCode: addressTypes[0].typeCode, countryCode: AddressConstant.DEFAULT_COUNTRY_CODE }]
        : addressList;

      return await addressList.map((address: any) => {
        return this.create(workspaceUUID, applicantId, address, suggestedAddressTypes);
      });
    });
  }

  saveApplicantAddress(workspaceUUID: string, applicantId: number, address: LeafAddress) {
    return address.addressId
      ? this.putApplicantAddress(workspaceUUID, applicantId, address)
      : this.postApplicantAddress(workspaceUUID, applicantId, address);
  }

  async postApplicantAddress(workspaceUUID: string, applicantId: number, address: any) {
    const message = clone(address);
    const response = await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/address`,
      message,
      {},
      ResponseTypes.Payload
    );
    return response[0];
  }

  async putApplicantAddress(workspaceUUID: string, applicantId: number, address: any) {
    const message = clone(address);
    const response = await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/address/${message.addressId}`,
      message,
      {},
      ResponseTypes.Payload
    );
    return response[0];
  }

  deleteApplicantAddress(workspaceUUID: string, applicantId: number, addressId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/address/${addressId}`,
      {},
      ResponseTypes.Data
    );
  }

  setAddressTypeDescription(address: any) {
    if (isUndefined(address.addressTypeCode)) {
      return;
    }

    return this.lookupFactory.Address?.getAll({ enabled: true }).then((addressTypes: any) => {
      address.addressTypeDescription = addressTypes.filter((addressType: { typeCode: any }) => {
        return addressType.typeCode === address.addressTypeCode;
      })[0].typeDescription;
    });
  }

  async setAddressListTypeDescription(addressList: any) {
    return Promise.all(
      addressList.map((address: any) => {
        return this.setAddressTypeDescription(address.DTO);
      })
    );
  }

  lookupAddressByZipCode(zipCode: string | undefined): Promise<Array<ZipCode>> {
    return zipCode
      ? this.httpHandler.get(
        SERVICE_PATH_CONSTANTS.BOLTSBRANCH + "/zipcode/" + zipCode.substr(0, 5),
        { isValidationRequest: true },
        ResponseTypes.Payload
      )
      : Promise.reject();
  }

  setAddressByZipCode(zipCode: any, address: any) {
    return this.lookupAddressByZipCode(zipCode)
      .then((response: any) => {
        address.DTO.city = response[0].city;
        address.DTO.state = response[0].stateCode;
      });
  }

  create(workspaceUUID: string, applicantId: number, defaultAddress: any, suggestedAddressTypes: any) {
    defaultAddress.countryCode = AddressConstant.DEFAULT_COUNTRY_CODE;
    return new Address(workspaceUUID, applicantId, defaultAddress, suggestedAddressTypes);
  }

  getAddressWithType(addressList: Array<any>, addressType: string) {
    return addressList.find((address) => address.DTO.addressTypeCode === addressType)
  }

  addressHasAnyContent(address: IAddress) {
    return (address.address1 || address.address2 || address.state || address.zipCode || address.city)
  }
  
  compareAddresses(address1: IAddress, address2: IAddress) {
    return this.addressHasAnyContent(address1) &&
        address1.address1 === address2?.address1 &&
        address1.address2 === address2?.address2 &&
        address1.state === address2?.state &&
        address1.zipCode === address2?.zipCode &&
        address1.zipCodeSuffix === address2?.zipCodeSuffix &&
        address1.city === address2?.city
  }
}

export interface IAddressBonusContent {
  addressTypeDescription?: string
}

export class Address {
  requiredTypes: any;
  workspaceUUID: string;
  applicantId: number;
  DTO: (IAddress & IAddressBonusContent);
  saving = false;
  private applicantAddressFactory = $inj(ApplicantAddressFactory);
  private addressUtil = new AddressUtil();
  constructor(workspaceUUID: string, applicantId: number, response: any, requiredTypes: any) {
    this.requiredTypes = requiredTypes || [];
    this.workspaceUUID = workspaceUUID;
    this.applicantId = applicantId;
    this.DTO = response;
    this.saving = false;
    this.DTO.zipCode = this.getMergedZipCode(this.DTO.zipCode, this.DTO.zipCodeSuffix);
    Object.defineProperty(this, "isRequired", {
      get: function () {
        return this.requiredTypes.some((requiredType: any) => {
          return requiredType === this.DTO.addressTypeCode;
        });
      }
    });
  }

  splitZip = this.addressUtil.splitZip;

  getMergedZipCode = this.addressUtil.getMergedZipCode;

  async save() {
    this.saving = true;
    const response = await this.applicantAddressFactory.saveApplicantAddress(
      this.workspaceUUID,
      this.applicantId,
      this.splitZip(this.DTO)
    );
    response.zipCode = this.getMergedZipCode(this.DTO.zipCode, this.DTO.zipCodeSuffix);
    if(!response.addressTypeDescription) {
      response.addressTypeDescription = this.DTO.addressTypeDescription;
    }
    this.DTO = response;
    this.saving = false;
  }

  async delete(addressList: any) {
    const promise = this.DTO.addressId
      ? this.applicantAddressFactory.deleteApplicantAddress(this.workspaceUUID, this.applicantId, this.DTO.addressId)
      : Promise.resolve();
    await promise;
    addressList.splice(addressList.indexOf(this), 1);
  }
}

export default ApplicantAddressFactory;
