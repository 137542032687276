import { inject, injectable } from "inversify";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import DEPENDENCYTYPES from "@/common/dependency.types";

@injectable()
class NetTellerSerivce {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {}

  show(dialogOption: Record<string, any>) {
    return new Promise((resolve, reject) => {
      dialogOption.data.promise = { resolve, reject };
      this.workspaceMetadataStore.dialogOptions.push(dialogOption);
    });
  }
}
export default NetTellerSerivce;
