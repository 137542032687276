import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentAccountMailingFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  getMailingTypes() {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/account/mail/type?enabled=true`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentAccountMailingFactory;
