import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '../../constant/servicePathConstants'
import type RestResponse from '@/common/data/RestResponse'
import type StartWorkflow from '@/common/data/StartWorkflow'
import ResponseTypes from '@/common/enums/responseTypesEnum'

@injectable()
class StartWorkflowFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(params: {visible: boolean}): Promise<RestResponse<Array<StartWorkflow>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/startworkflow`, {params})
  }

  get(startWorkflowCode: string): Promise<StartWorkflow> {
    //$resource was rewritten with HttpHandler
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/startworkflow/${startWorkflowCode}`,
      {},
      ResponseTypes.Payload
    )
  }

  create(dto: WorkflowCreate): Promise<RestResponse<StartWorkflow>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/startworkflow`, dto)
  }

  update(workflowCode: string, dto: StartWorkflow): Promise<RestResponse<StartWorkflow>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/startworkflow/${workflowCode}`, dto)
  }
}

export type WorkflowCreate = {
  name: string,
  startWorkflowCode: string,
  workspaceType: string
}

export default StartWorkflowFactory

