import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from 'inversify';
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type RestResponse from "@/common/data/RestResponse";
import type IApplicant from "@/common/models/IApplicant";

@injectable()
class ApplicantExistingFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler,
    ) { }

    post(workspaceUUID: string, coreId: string, applicantType: string): Promise<RestResponse<Array<IApplicant>>> {
        return  this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicantexisting`, {
            coreId: coreId,
            applicantType: applicantType,
        });
    }

}
export default ApplicantExistingFactory;