import isObject from "lodash.isobject";
import { injectable, inject } from "inversify";
import { TabConstants } from "@/common/constant/TabConstants";
import FACTORY_MSG from "@/common/messages/factory.messages";
import type IWfState from "@/common/models/WfState";
import StartWorkflowFactory from "@/common/services/Workflow/StartWorkflowFactory";
import WorkflowStateFactory from "@/common/services/Workflow/workflowStateFactory";
import LogService from "@/common/services/Log/LogService";

@injectable()
class TabFactory {
  constructor(
    @inject(StartWorkflowFactory) private startWorkflowFactory: StartWorkflowFactory,
    @inject(WorkflowStateFactory) private workflowStateFactory: WorkflowStateFactory,
    @inject(LogService) private logService: LogService
  ) {}

  getStateList(tabList: IWfState[]): string[] {
    return tabList.map((tab: { state: string }) => {
      return tab.state;
    });
  }

  enableAllTabs(tabList: IWfState[]): void {
    tabList.forEach((tab: { disabled: boolean }) => {
      tab.disabled = false;
    });
  }

  updateTabValidation(tabList: IWfState[], validationList: IWfState[]): void {
    tabList.forEach((tab) => {
      const matchingValidation = validationList.filter((validation) => {
        return validation.state === tab.state;
      })[0];

      if (isObject(matchingValidation)) {
        tab.warnings = matchingValidation.warnings || [];
        tab.errors = matchingValidation.errors || [];
        tab.infos = matchingValidation.infos || [];
      } else {
        this.logService.error(FACTORY_MSG.TAB.NO_MATCHING_VALIDATION.replace("{tabState}", tab.state));
      }
    });
  }

  disableAfterError(tabList: IWfState[]): void {
    tabList.forEach((tab, index) => {
      if (tab.errors?.length > 0 && isObject(tabList[index + 1])) {
        tabList[index + 1].disabled = true;
      }
      if (index !== 0 && tabList[index - 1].disabled === true) {
        tab.disabled = true;
      }
    });
  }

  getTabsFromWorkflow(stateType: string, workspaceType: string): Promise<IWfState[]> {
    let startWorkflowCode = "";

    switch (stateType) {
      case TabConstants.ENROLLMENT:
        startWorkflowCode =
          workspaceType === TabConstants.PERSON
            ? TabConstants.BRANCH_PERSON_ENROLLMENT
            : TabConstants.BRANCH_BUSINESS_ENROLLMENT;
        break;
      case TabConstants.APPLICANT:
        startWorkflowCode =
          workspaceType === TabConstants.PERSON ? TabConstants.BRANCH_PERSON_INFO : TabConstants.BRANCH_BUSINESS_INFO;
        break;
    }

    return this.startWorkflowFactory.get(startWorkflowCode).then((response) => {
      return this.workflowStateFactory
        .getByQuery(response.workflowName, { params: { orderby: "linear" } })
        .then((workflow: IWfState[]) => workflow);
    });
  }
}
export default TabFactory;
