import {AdminStatesConstant} from '@/branchmanager/app/constant/AdminStatesConstant'

export interface Module {
  name: string
  route: string
}
export const managementModules: Module[] = [
  {
    name: 'Disclosures',
    route: AdminStatesConstant.DISCLOSURES.STATE
  },
  {
    name: 'Relationships',
    route: AdminStatesConstant.RELATIONSHIPS.STATE
  },
  {
    name: 'OAuth providers',
    route: AdminStatesConstant.OAUTH.STATE
  },
  {
    name: 'OCR',
    route: AdminStatesConstant.OCR.STATE
  },
  {
    name: 'Business',
    route: AdminStatesConstant.BUSINESS.STATE
  },
  {
    name: 'CIP',
    route: AdminStatesConstant.CIP_ADMIN.STATE
  },
  {
    name: 'Synergy',
    route: AdminStatesConstant.SYNERGY.STATE
  },
  {
    name: 'Wolters Kluwer',
    route: AdminStatesConstant.WOLTERS_KLUWER.STATE
  },
  {
    name: 'Pigeon',
    route: AdminStatesConstant.PIGEON_ADMIN.STATE
  },
  {
    name: 'Fields',
    route: AdminStatesConstant.FIELDS_ADMIN.STATE
  },
  {
    name: 'ZIP Codes',
    route: AdminStatesConstant.ZIP_CODE.STATE
  },
  {
    name: 'Verbiage',
    route: AdminStatesConstant.VERBIAGE.STATE
  },
  {
    name: 'Promo codes',
    route: AdminStatesConstant.PROMO_CODES.STATE
  },
  {
    name: 'Scheduled tasks',
    route: AdminStatesConstant.SCHEDULED_TASKS.STATE
  },
  {
    name: 'Products',
    route: AdminStatesConstant.ADMIN_PRODUCTS.STATE
  },
  {
    name: 'Properties',
    route: AdminStatesConstant.PROPERTIES_ADMIN.STATE
  },
  {
    name: 'Questionnaire',
    route: AdminStatesConstant.QUESTIONNAIRE_ADMIN.STATE
  },
  {
    name: 'Export files',
    route: AdminStatesConstant.DOCUMENT_EXPORTS_ADMIN.STATE
  },
  {
    name: 'Funding',
    route: AdminStatesConstant.FUNDING.STATE,
  },
  {
    name: 'Services',
    route: AdminStatesConstant.ADMIN_SERVICE_LIST.STATE,
  },
  {
    name: 'Users & groups',
    route: AdminStatesConstant.USERS.STATE,
  },
  {
    name: 'Workflows',
    route: AdminStatesConstant.WORKFLOW_ADMIN.STATE,
  }
]
