import PrintFactory from "@/common/services/utils/printFactory";
import ToastService from "@/common/services/toastFactory/toastFactory";
import LogService from "@/common/services/Log/LogService";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { inject, injectable } from 'inversify';
import ResponseTypes from "@/common/enums/responseTypesEnum";
import ENROLLMENT_DISCLOSURE_TYPES from "@/common/enums/enrollmentDisclosureTypesEnum";
import FACTORY_MSG from "@/common/messages/factory.messages";
import type {Disclosure} from "@/common/data/Disclosure";

@injectable()
class EnrollmentDisclosureFactory {
    constructor(
        @inject(PrintFactory) private printFactory: PrintFactory,
        @inject(ToastService) private toastService: ToastService,
        @inject(HttpHandler) private httpHandler: HttpHandler,
        @inject(LogService) private logService: LogService,
    ) { }

    /**
     *@ngdoc method
     * @name getEnrollmentAccountDisclosureList
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @deprecated Use await EnrollmentAccountDisclosureResourceFactory.from().getPackage()
     * @description
     * This will return a list of all account disclosures on a given enrollment.
     *
     * @param {String} uuid - The workspace UUID the enrollmentAccount disclosures are related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentAccount disclosures are related to.
     *
     * @param {String} accountId - the accountId the enrollmentAccount disclosures are related to.
     *
     */
    async getEnrollmentAccountDisclosureList(uuid: string, enrollmentId: number, accountId: number) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure`, {}, ResponseTypes.Payload)
            .then((response) => {
                return {
                    type: ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_ACCOUNT,
                    id: accountId,
                    disclosures: response,
                }
            });
    }

    /**
     *@ngdoc method
     * @name getEnrollmentApplicantDisclosureList
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @deprecated Use await EnrollmentApplicantDisclosureResourceFactory.from().getPackage()
     * @description
     * This will return a list of all applicant disclosures on a given enrollment.
     *
     * @param {String} uuid - The workspace UUID the enrollmentApplicant disclosures are related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentApplicant disclosures are related to.
     *
     * @param {String} applicantId - the applicantId the enrollmentApplicant disclosures are related to.
     *
     */
    getEnrollmentApplicantDisclosureList(uuid: string, enrollmentId: number, applicantId: number) {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure`, {}, ResponseTypes.Payload)
            .then((response) => {
                return {
                    type: ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_APPLICANT,
                    id: applicantId,
                    disclosures: response,
                }
            });
    }

    /**
     *@ngdoc method
     * @name getEnrollmentApplicantDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will return an enrollment applicant disclosure.
     *
     * @param {String} uuid - The workspace UUID the enrollmentApplicant disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentApplicant disclosure is related to.
     *
     * @param {String} applicantId - the applicantId the enrollmentApplicant disclosure is related to.
     *
     * @param {String} disclosureCode - the disclosure code from the disclosurePackage list item.
     */
    async getEnrollmentApplicantDisclosure(uuid: string, enrollmentId: number, applicantId: number, disclosureCode: any) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/${disclosureCode}`, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name getEnrollmentAccountDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will return an enrollment account disclosure.
     *
     * @param {String} uuid - The workspace UUID the enrollmentAccount disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentAccount disclosure is related to.
     *
     * @param {String} accountId - the accountId the enrollmentAccount disclosure is related to.
     *
     * @param {String} disclosureCode - the disclosure code from the disclosurePackage list item.
     */
    async getEnrollmentAccountDisclosure(uuid: string, enrollmentId: number, accountId: number, disclosureCode: any) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure/${disclosureCode}`, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name putEnrollmentApplicantDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will put an enrollmentApplicantDisclosure to the server.
     *
     * @param {String} uuid - The workspace UUID the enrollmentApplicantDisclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentApplicantDisclosure is related to.
     *
     * @param {String} applicantId - the applicantId the enrollmentApplicantDisclosure is related to.
     *
     * @param {String} disclosureCode - the disclosure code of the enrollmentApplicantDisclosure.
     *
     * @param {Object} message - the disclosure message to send to the server.
     */
    async putEnrollmentApplicantDisclosure(uuid: string, enrollmentId: number, applicantId: number, disclosureCode: any, message: any) {
        return await this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/${disclosureCode}`, message, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name putEnrollmentAccountDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will put an enrollmentAccountDisclosure to the server.
     *
     * @param {String} uuid - The workspace UUID the enrollmentAccountDisclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the enrollmentAccountDisclosure is related to.
     *
     * @param {String} accountId - the accountId the enrollmentAccountDisclosure is related to.
     *
     * @param {String} disclosureCode - the disclosure code of the enrollmentAccountDisclosure.
     *
     * @param {Object} message - the disclosure message to send to the server.
     */
    async putEnrollmentAccountDisclosure(uuid: string, enrollmentId: number, accountId: number, disclosureCode: any, message: any) {
        return await this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure/${disclosureCode}`, message, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name getEmployeeSignature
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will get the employee signature that is tied to the workspace, enrollment, and employee login.
     *
     * @param {String} uuid - The workspace UUID of the enrollment.
     *
     * @param {String} enrollmentId - the enrollmentId the employee signature is on.
     *
     */
    async getEmployeeSignature(uuid: string, enrollmentId: number) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/employeesignature`, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name postEmployeeSignature
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will post an employee signature to the service and it will be tied to the workspace, enrollment, and
     *     employee login.
     *
     * @param {String} uuid - The workspace UUID of the enrollment.
     *
     * @param {String} enrollmentId - the enrollmentId you wish to post the signature to.
     *
     * @param {Object} message - the employee signature object to be sent to the backend.
     *
     */
    async postEmployeeSignature(uuid: string, enrollmentId: number, message: any) {
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/employeesignature`, message, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name getEnrollmentDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will either make a call to get an enrollmentApplicant or enrollmentAccount disclosure base on what type
     *     is passed
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} id - the id, either applicant or account the disclosure is related to.
     *
     * @param {String} disclosureCode - the disclosureCode of the disclosure.
     *
     * @param {String} type - either "ACCOUNT" or "APPLICANT" specifying whether the disclosure is an
     *     enrollmentAccount or enrollmentApplicant disclosure
     *
     */
    async getEnrollmentDisclosure(uuid: string, enrollmentId: number, id: number, disclosureCode: string, type: string) {
        switch (type) {
            case ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_ACCOUNT:
                return this.getEnrollmentAccountDisclosure(uuid, enrollmentId, id, disclosureCode);
            case ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_APPLICANT:
                return this.getEnrollmentApplicantDisclosure(uuid, enrollmentId, id, disclosureCode);
        }
    }

    /**
     *@ngdoc method
     * @name putEnrollmentDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will either make a call to put an enrollmentApplicant or enrollmentAccount disclosure base on what type
     *     is passed
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} id - the id, either applicant or account the disclosure is related to.
     *
     * @param {String} disclosureCode - the disclosureCode of the disclosure.
     *
     * @param {Object} message - the message to be sent to the server.
     *
     * @param {String} type - either "ACCOUNT" or "APPLICANT" specifying whether the disclosure is an
     *     enrollmentAccount or enrollmentApplicant disclosure
     *
     */
    putEnrollmentDisclosure(uuid: string, enrollmentId: number, id: number, disclosureCode: any, message: any, type: any) {
        delete message.disclosure;

        return type === ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_ACCOUNT
            ? this.putEnrollmentAccountDisclosure(uuid, enrollmentId, id, disclosureCode, message)
            : this.putEnrollmentApplicantDisclosure(uuid, enrollmentId, id, disclosureCode, message);
    }

    /**
     *@ngdoc method
     * @name unlockEnrollmentAccountDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will tell the backend to set the disclosure status to REVISION.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} accountId - the accountId the disclosure is related to.
     *
     * @param {String} disclosure - the disclosure to be unlocked.
     *
     */
    async unlockEnrollmentAccountDisclosure(uuid: string, enrollmentId: number, accountId: number, disclosure: any) {
        delete disclosure.disclosure;
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure/${disclosure.disclosureCode}/unlock`, disclosure, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name unlockEnrollmentApplicantDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will tell the backend to set the disclosure status to REVISION.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} applicantId - the enrollmentApplicantId the disclosure is related to.
     *
     * @param {String} disclosure - the disclosure to be unlocked.
     *
     */
    async unlockEnrollmentApplicantDisclosure(uuid: string, enrollmentId: number, applicantId: number, disclosure: any) {
        delete disclosure.disclosure;
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/${disclosure.disclosureCode}/unlock`, disclosure, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name resetEnrollmentAccountDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will tell the backend to set the disclosure status to REVISION and revert to the original dictionary
     *     data.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} accountId - the accountId the disclosure is related to.
     *
     * @param {String} disclosure - the disclosure to be unlocked.
     *
     */
    async resetEnrollmentAccountDisclosure(uuid: string, enrollmentId: number, accountId: number, disclosure: any) {
        delete disclosure.disclosure;
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure/${disclosure.disclosureCode}/unlock?reset=true`, disclosure, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name resetEnrollmentApplicantDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will tell the backend to set the disclosure status to REVISION and revert to the original dictionary
     *     data.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} applicantId - the applicantId the disclosure is related to.
     *
     * @param {String} disclosure - the disclosure to be unlocked.
     *
     */
    async resetEnrollmentApplicantDisclosure(uuid: string, enrollmentId: number, applicantId: number, disclosure: any) {
        delete disclosure.disclosure;
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/${disclosure.disclosureCode}/unlock?reset=true`, disclosure, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name getEnrollmentDisclosureApplicants
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will return an array of applicants that are on the account of the passed disclosure.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} accountId - the accountId the disclosure is related to.
     *
     * @param {String} disclosureCode - the enrollmentAccountDisclosure disclosureCode to retrieve applicants for.
     *
     */
    async getEnrollmentAccountDisclosureApplicants(uuid: string, enrollmentId: number, accountId: number, disclosureCode: any) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/disclosure/${disclosureCode}/applicant`, {}, ResponseTypes.Payload);
    }

    /**
     *@ngdoc method
     * @name addEnrollmentAccountDisclosureApplicants
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will take an enrollmentAccountDisclosure and add an applicants key to the disclosure object containing
     *     an array of applicants on the account.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {String} accountId - the accountId the disclosure is related to.
     *
     * @param {Object} disclosure - the disclosure to append applicantIds to.
     *
     */
    async addEnrollmentAccountDisclosureApplicants(uuid: string, enrollmentId: number, accountId: number, disclosure: any) {
        return this.getEnrollmentAccountDisclosureApplicants(uuid, enrollmentId, accountId, disclosure.disclosureCode)
            .then((response: any) => {
                disclosure.applicants = response.map((applicant: { applicantId: any; }) => {
                    return applicant.applicantId;
                });
                return disclosure;
            });
    }

    /**
     *@ngdoc method
     * @name addEnrollmentAccountDisclosureApplicantsToPackage
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will take an enrollmentAccountDisclosure package and add applicants ids to all of the account
     *     disclosures.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosure is related to.
     *
     * @param {Object} enrollmentAccountDisclosurePackage
     *
     */
    async addEnrollmentAccountDisclosureApplicantsToPackage(uuid: string, enrollmentId: number, enrollmentAccountDisclosurePackage: any) {
        return Promise.all(enrollmentAccountDisclosurePackage.disclosures
            .map((disclosure: any) => {
                return this.addEnrollmentAccountDisclosureApplicants(uuid, enrollmentId, enrollmentAccountDisclosurePackage.id, disclosure);
            }))
            .then((response) => {
                enrollmentAccountDisclosurePackage.disclosures = response;
                return enrollmentAccountDisclosurePackage;
            });
    }

    async emailEnrollmentDisclosure(uuid: string, applicantId: number, emailAddress: any, disclosures: any, enrollmentId: number) {
        const storeIds = disclosures.reduce((ids: string, disclosure: { disclosureStoreId: string; }) => {
            ids = (ids === '') ? disclosure.disclosureStoreId : ids + ',' + disclosure.disclosureStoreId;
            return ids;
        }, '');

        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/email`, {
            headers: {
                emailAddress: emailAddress,
            },
            params: {
                disclosureStoreIds: storeIds,
            }
        }, ResponseTypes.Payload).then(() => {
            this.toastService.success(FACTORY_MSG.ENROLLMENTDISCLOSURE.DISCLOSURE_EMAILED);
        });
    }

    /**
     *@ngdoc method
     * @name printEnrollmentDisclosure
     * @methodOf e2e.factory:EnrollmentDisclosureFactory
     * @description
     * This will open a tab and display all passed disclosures in PDF format.
     *
     * @param {String} uuid - The workspace UUID the disclosure is related to.
     *
     * @param {String} applicantId - the applicantId the disclosure is related to.
     *
     * @param {String} enrollmentId - the enrollmentId the disclosures are related to.
     *
     * @param {Array} disclosures - an array of disclosure objects to be printed.
     */
    async printEnrollmentDisclosure(uuid: string, applicantId: number, enrollmentId: number, disclosures: any) {
        const storeIds = disclosures.reduce((ids: string, disclosure: { disclosureStoreId: string; }) => {
            ids = (ids === '') ? disclosure.disclosureStoreId : ids + ',' + disclosure.disclosureStoreId;
            return ids;
        }, '');
        if (storeIds === '') {
            this.logService.error(FACTORY_MSG.ENROLLMENTDISCLOSURE.ATLEAST_ONE_DISCLOSURE_MUST_BE_SELECTED);
            return;
        }
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure/token?disclosureStoreIds=${storeIds}`, {}, ResponseTypes.Payload)
            .then((token) => {
                return this.printFactory.printByToken(token);
            })
            .then(() => {
                this.toastService.success(FACTORY_MSG.ENROLLMENTDISCLOSURE.DISCLOSURE_PRINTED);
            });
    }

    flattenDisclosureList(
        accountDisclosures: Array<{disclosures: Array<Disclosure>}>,
        applicantDisclosures: Array<{disclosures: Array<Disclosure>}>
    ): {disclosures: Array<Disclosure> } {
        const disclosurePackageList = accountDisclosures.concat(applicantDisclosures);

        return {
            disclosures: disclosurePackageList
                .reduce((acc: Array<Disclosure>, cur: {disclosures: Array<Disclosure>}) => {
                return acc.concat(cur.disclosures);
            }, []),
        }
    }

}
export default EnrollmentDisclosureFactory;