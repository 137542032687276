<script lang="ts">
import {defineComponent, type PropType} from "vue";
import bltSearchDetail from "@/common/components/bltSearchItem/bltSearchDetail.vue";
import {
  ApplicantCoreFactory,
  FullNameFilter,
  FullBusinessNameFilter,
  SearchDateFilter,
  SearchTaxIdFilter,
  SearchTitleFilter,
  SummaryFactory
} from "@/common/services/services.module";
import { $inj } from "@/common/decorators/depinject";
import OPERATION_TYPES from "@/common/enums/operationTypeEnum";
import WORKSPACE_STATUSES from "@/common/enums/workspaceStatusEnum";
import WorkspaceConstants from "@/common/constant/WorkspaceConstants";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { isEmpty } from "lodash";
import type {
  IApplicantSearch,
  IExistingCustomerSearch,
  ICustomerSearchPayload,
  IAPPLICANTCUSTOMERSEARCH
} from "@/common/models/ICustomerSearch";
import SEARCH_DETAIL_COMPONENT from "@/common/enums/searchDetailEnum";
import type {IWorkspaceApplicant} from "@/common/models/IExistingWorkspace";
export default defineComponent({
  name: "bltSearchItem",
  components: { bltSearchDetail },
  emits: ["redirectWorkspace", "addApplicant"],
  setup() {
    const summaryFactory = $inj(SummaryFactory);
    const applicantCoreFactory = $inj(ApplicantCoreFactory);
    const fullNameFilter = $inj(FullNameFilter);
    const fullBusinessNameFilter = $inj(FullBusinessNameFilter);
    const searchDateFilter = $inj(SearchDateFilter);
    const searchTaxIdFilter = $inj(SearchTaxIdFilter);
    const searchTitleFilter = $inj(SearchTitleFilter);
    return {
      summaryFactory,
      applicantCoreFactory,
      fullNameFilter,
      fullBusinessNameFilter,
      searchDateFilter,
      searchTaxIdFilter,
      searchTitleFilter
    };
  },
  props: {
    bltSearchApplicants: {
      type: Array as PropType<Array<IWorkspaceApplicant>>,
      required: true,
      default: () => []
    },
    bltSearchApplicantType: {
      type: String,
      required: true
    },
    componentType: {
      type: String
    }
  },
  data() {
    return {
      loader: false as boolean,
      applicantTypes: OPERATION_TYPES,
      WorkspaceConstants,
      WORKFLOW_TYPES,
      detailExistingCustomer: new Map() as Map<string, IExistingCustomerSearch>,
      detailApplicants: [] as Record<string, any>,
      selectedApplicantIndex: -1 as number,
      SEARCH_DETAIL_COMPONENT
    };
  },
  computed: {},
  methods: {
    isOpenWorkspace(value: string): boolean {
      return value !== WORKSPACE_STATUSES.CLOSED;
    },
    setPersonFullname(applicant: IApplicantSearch) {
      return this.fullNameFilter.fullName(applicant);
    },
    setBusinessFullname(business: { businessName?: string }) {
      return this.fullBusinessNameFilter.fullBusinessName(business);
    },
    setCreatedDate(date: Record<string, any>) {
      return this.searchDateFilter.searchDate(date);
    },
    redirectWorkspace(workspaceUUID: string) {
      this.$emit("redirectWorkspace", workspaceUUID);
    },
    setTaxId(taxData: IApplicantSearch): string | undefined {
      return this.searchTaxIdFilter.searchTaxId(taxData);
    },
    setCustomerSearchFullname(applicant: Record<string, unknown>): string {
      return this.searchTitleFilter.searchTitle(applicant);
    },
    async getSerchDetail(searchApplicant: ICustomerSearchPayload, applicantIndex: number): Promise<any> {
      if (searchApplicant.coreId) {
        const searchPersonBusinessDetail = await this.getApplicantDetail(searchApplicant, applicantIndex);
        this.detailExistingCustomer.set(searchApplicant.coreId, searchPersonBusinessDetail);
      } else {
        const response = await this.getApplicantDetail(searchApplicant, applicantIndex);
        this.detailApplicants.push(response);
      }
    },
    getWorkspaceAndApplicantDetail(searchApplicant: ICustomerSearchPayload): Promise<any> {
      return Promise.all([
        this.summaryFactory.getWorkspaceSummary(searchApplicant.workspaceUUID),
        this.summaryFactory.getApplicantSummary(searchApplicant.workspaceUUID, searchApplicant.applicantId)
      ]);
    },
    getApplicantDetail(searchApplicant: ICustomerSearchPayload, applicantIndex: number): Promise<any> {
      return searchApplicant.coreId
        ? this.applicantCoreFactory.get(searchApplicant.coreId, searchApplicant.applicantType).finally(() => {
            this.setApplicantDetailsLoader(applicantIndex, false);
          })
        : this.getWorkspaceAndApplicantDetail(searchApplicant).finally(() => {
            this.setApplicantDetailsLoader(applicantIndex, false);
          });
    },
    searchApplicantDetails(applicantIndex: number): void {
      if (this.bltSearchApplicantType === WorkspaceConstants.APPLICANTTYPE) {
        if (this.selectedApplicantIndex !== applicantIndex) {
          this.selectedApplicantIndex = applicantIndex;
          const detail: any = this.bltSearchApplicants.find((ele, index: number) => index === applicantIndex);
          this.setApplicantDetailsLoader(applicantIndex, true);
          this.getSerchDetail(detail, applicantIndex);
        }
      }
    },
    addApplicantToWorkspace(detail: IAPPLICANTCUSTOMERSEARCH) {
      this.$emit("addApplicant", detail);
    },
    setApplicantDetailsLoader(applicantIndex: number, isLoader: boolean) {
      this.bltSearchApplicants.forEach((ele: any, index: number) => {
        if (applicantIndex === index) {
          ele.loader = isLoader;
          return true;
        }
      });
    }
  }
});
</script>
<template>
  <template v-if="componentType === SEARCH_DETAIL_COMPONENT.EXISTING">
    <div v-if="bltSearchApplicants.length > 0" class="header-collapsible">
      <template v-for="(item, index) in bltSearchApplicants" :key="item">
        <jha-collapsible-section :isOpen="false" @click="searchApplicantDetails(index)">
          <jha-flex-wrapper slot="header" class="collapsiable-header">
            <template v-if="bltSearchApplicantType === WorkspaceConstants.WORKSPACETYPE">
              <div class="d-flex space-between"
                   style="width: 100%;">
                <div class="m-tb-auto">
                  <div class="user-icon icon-round" v-if="item?.workspaceType === WorkspaceConstants.PERSON">
                    <jha-icon-user></jha-icon-user>
                  </div>
                  <div class="business-icon icon-square" v-if="item?.workspaceType === WorkspaceConstants.BUSINESS">
                    <jha-icon-business></jha-icon-business>
                  </div>
                </div>
                <div class="details" v-if="item"
                     style="display: flex; flex-direction: column; width: 100%;">

                  <div class="d-flex" style="justify-content: space-between;">
                    <div v-if="item?.applicants?.length">
                      <div class="primary-text mb-2">
                      <span v-for="(applicant, index) in item.applicants" :key="applicant">
                            <span v-if="applicant?.applicantType === WorkspaceConstants.PERSON">
                              {{ setPersonFullname(applicant?.person) }}
                            </span>
                        <span v-if="applicant?.applicantType === WorkspaceConstants.BUSINESS">
                              {{ setBusinessFullname(applicant?.business) }}
                            </span>
                        <span v-if="index != item.applicants.length - 1">, </span>
                      </span>
                      </div>
                    </div>

                    <div class="status-badge">
                      <div class="uuid" v-if="isOpenWorkspace(item.statusCode)">
                        <div class="pending tag-wrapper-workspace-home tag-wrapper-open">{{
                            WorkspaceConstants.OPEN
                          }}
                        </div>
                      </div>
                      <div class="tag-wrapper-workspace-home tag-wrapper-closed" v-else>
                        {{ WorkspaceConstants.CLOSED }}
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-wrap-on-mobile" style="justify-content: space-between">
                    <div>
                      <div class="secondary-text caption-text ellipses" v-if="item?.createdDateIso">
                        {{ setCreatedDate(item) }} by {{ item.login == "foxee_proxy" ? "Applicant Channel" : item.login }}
                      </div>
                    </div>
                    <div class="ellipses w-30 secondary-text caption-text">{{ item.workspaceUUID }}</div>
                  </div>
                </div>
              </div>
            </template>
          </jha-flex-wrapper>
          <div
            slot="body"
            class="collapsiable-body searchDetailLoader" :class="{ 'searchDetailLastItem': index == bltSearchApplicants.length - 1 }"
            v-if="item && bltSearchApplicantType === WorkspaceConstants.WORKSPACETYPE"
          >
          <blt-contentloader :contentLoader="item.loader"></blt-contentloader>
          <template v-if="!item.loader">
            <bltSearchDetail
              :bltWorkspaceDetail="item"
              :bltDetailType="bltSearchApplicantType"
              @redirect-workspace="redirectWorkspace(item.workspaceUUID)"
            >
            </bltSearchDetail>
            </template>            
          </div>
        </jha-collapsible-section>
        </template>
    </div>
  </template>
  <template v-if="componentType === SEARCH_DETAIL_COMPONENT.CUSTOMSEARCH">
    <div v-if="bltSearchApplicants.length > 0" class="header-collapsible">
      <template v-for="(item, index) in bltSearchApplicants" :key="item">
        <jha-collapsible-section class="search-item-collapsible-section" :isOpen="false" @click="searchApplicantDetails(index)">
          <jha-flex-wrapper slot="header" class="collapsiable-header">
            <template v-if="bltSearchApplicantType === WorkspaceConstants.APPLICANTTYPE">
              <div class="d-flex" style="justify-content: space-between;">
                <div class="jha-list-left-content">
                  <div class="d-flex">
                    <div class="m-tb-auto">
                      <div class="user-icon icon-round" v-if="item?.applicantType === WorkspaceConstants.PERSON">
                        <jha-icon-user></jha-icon-user>
                      </div>
                      <div class="business-icon icon-square" v-if="item?.applicantType === WorkspaceConstants.BUSINESS">
                        <jha-icon-business></jha-icon-business>
                      </div>
                    </div>
                    <div>
                      <div class="primary-text">{{ setCustomerSearchFullname(item) }}</div>
                      <div class="secondary-text jha-text-base pt-2">{{ setTaxId(item) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </jha-flex-wrapper>
          <div
            slot="body"
            class="collapsiable-body searchDetailLoader" :class="{ 'searchDetailLastItem': index == bltSearchApplicants.length - 1 }"
            v-if="item && bltSearchApplicantType === WorkspaceConstants.APPLICANTTYPE"
          >
            <blt-contentloader :contentLoader="item.loader"></blt-contentloader>
            <template v-if="!item.loader">
              <bltSearchDetail
                :bltPersonBusinessDetail="detailExistingCustomer.get(item.coreId)"
                :bltDetailType="bltSearchApplicantType"
                @redirect-workspace="redirectWorkspace(item.workspaceUUID)"
                @addApplicant="addApplicantToWorkspace(item)"
              >
              </bltSearchDetail>
            </template>
            <template v-if="!item.loader">
              <template v-for="detail in detailApplicants" :key="detail">
                <bltSearchDetail
                  v-if="item.workspaceUUID === detail[0].workspaceUUID"
                  :bltPersonBusinessDetail="detail[1]"
                  :bltWorkspaceDetail="detail[0]"
                  :bltDetailType="bltSearchApplicantType"
                  @redirect-workspace="redirectWorkspace(item.workspaceUUID)"
                  @addApplicant="addApplicantToWorkspace(item)"
                >
                </bltSearchDetail>
              </template>
            </template>
          </div>
        </jha-collapsible-section>
      </template>
    </div>
  </template>
</template>
<style scoped>
.search-item-collapsible-section {
  --jha-collapsible-section-header-margin: var(--jh-space-200) var(--jh-space-400);
  --jha-background-color: transparent;
}

.left-list {
  max-width: 100%;
}

div.caption-text {
  color: var(--jha-text-base);
}

.user-icon jha-icon-user {
  fill: var(--jha-text-base);
}

jha-collapsable-section jha-icon-chevron-down {
  fill: red
}

.business-icon jha-icon-business {
  fill: var(--jha-text-base);
}
jha-flex-wrapper.collapsiable-header {
  width: 100%;
  justify-content: space-between;
}
.uuid {
  padding-right: var(--jh-space-200);
  color: var(--body-text-secondary-color);
  margin-left: auto;
}
@media (max-width: 700px) {
  .left-list {
    width: 70%;
    padding-right: 4px;
  }
  .uuid {
    padding-right: 0px;
  }
  .right-list {
    width: 20%;
    direction: rtl;
  }
  .ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .w-30 {
    width: 60%;
  }

  .flex-wrap-on-mobile {
    flex-wrap: wrap;
  }
 
}
@media (max-width: 390px) { 
    .ellipses {
      width: 160px;
    }
    .w-30 {
    width: 60%;
  }
}
</style>
