import { injectable } from "inversify";

@injectable()
class ConversionFactory {
  stringToDate(date: any) {
    //This RegEx will test that the date string is in a ####-##-## format
    const re = new RegExp("([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})");

    if (undefined !== date && typeof date === "string" && re.test(date)) {
      const dateArr = date.split("-"),
        year = parseInt(dateArr[0]),
        month = parseInt(dateArr[1]),
        day = parseInt(dateArr[2]);
      return new Date(year, month - 1, day, 0, 0, 0, 0);
    }
    return new Date(0, 0, 0, 0, 0, 0, 0);
  }

  dateToString(date: Date) {
      /*change to let if data changed*/
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      const year = date.getFullYear().toString();
      if (day.length === 1) day = "0" + day;
      if (month.length === 1) month = "0" + month;
      return `${year}-${month}-${day}`;
  }

  MMYYYYtoUTC(input: string) {
    if (input.length < 7) return null;
    const month = input.split("/")[0];
    const year = input.split("/")[1];
    return `${year}-${month}`;
  }
}

export default ConversionFactory;
