import { ToastConstants } from "@/common/constant/ToastConstants";
import DEPENDENCYTYPES from "@/common/dependency.types";
import { inject, injectable } from "inversify";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";

@injectable()
class ToastService {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {}
  success(message: string) {
    this.addToast(message, ToastConstants.SUCCESS);
  }

  addToast(message: string, messageType: string) {
    this.workspaceMetadataStore.toast.message = message;
    this.workspaceMetadataStore.toast.type = messageType;
    this.workspaceMetadataStore.toast.showToast = true;
  }

  error(message: string) {
    this.addToast(message, ToastConstants.ERROR);
  }
}

export default ToastService;
