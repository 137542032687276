import { injectable } from "inversify";

@injectable()
class TaxIdFilter {
  taxId(taxId: string) {
    taxId = taxId || "";
    const formatFullTaxId =
      taxId.length >= 9 ? taxId.substring(0, 3) + "-" + taxId.substring(3, 5) + "-" + taxId.substring(5) : undefined;
    return taxId.length === 4 ? "XXX-XX-" + taxId : formatFullTaxId;
  }
}

export default TaxIdFilter;
