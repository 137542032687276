import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class NcrFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(workspaceUUID: string, applicantId: number) {
     return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/ncrregistration`,
      {},
      ResponseTypes.Payload
    );
  }

  post(workspaceUUID: string, applicantId: number, message: { username: string; password: string }) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/ncrregistration`,
      message,
      {},
      ResponseTypes.Payload
    );
  }
}
export default NcrFactory;
