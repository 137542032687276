<script lang="ts">
import type IWfState from "@/common/models/WfState";
import { defineComponent } from "vue";
import { $injByInterface } from "@/common/decorators/depinject";
import "@banno/jha-wc/src/icons/jha-icon-warning.js";
import "@banno/jha-wc/src/icons/jha-icon-checkmark.js";
import "@banno/jha-wc/src/icons/jha-icon-circle-warning.js";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import { workspaceStatusConstant } from "@/branchmanager/app/constant/workspaceStatusConstant";
import IRouterService from "@/common/services/utils/IRouterService";

export default defineComponent({
  name: "bltTabMenu",
  props: {
    workflowTypeHeader: {
      type: String,
      default: "WORKFLOW TYPE"
    },
    tabs: {
      type: Array<IWfState>,
      required: true,
      default: [] as IWfState[]
    },
    selectedTab: {
      type: Object,
      default: {} as IWfState
    }
  },
  emits: ["changed"],
  setup() {
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const routerService = $injByInterface<IRouterService>("IRouterService");

    return {
      workspaceStore,
      routerService
    };
  },
  data() {
    return {
      tab: {}
    };
  },
  computed: {
    isActiveTab() {
      return (currentTab: string) => {
        if ((this.selectedTab || {}).state === currentTab) {
          return true;
        }
      };
    },
    applicantType(): string {
      return this.routerService.currentRoute.value.params.applicantType;
    },
    allTabs(): IWfState[] {
      return this.tabs;
    },
    isMobile() {
      return screen.width < 769;
    },
    isWorkspaceClosedOrLocked(): boolean {
      return this.workspaceStore.workspace?.statusCode === workspaceStatusConstant.CLOSED;
    }
  },
  methods: {
    handleTabClick(tab: IWfState) {
      this.$emit("changed", tab);
    },
    tabDisabled(tab: IWfState) {
      if (this.applicantType === "ENROLLMENT") {
        return tab.disabled
      } else {
        return this.workspaceStore.applicant.existing
            ? false : tab.disabled
      }
    }
  }
});
</script>
<template>
  <div class="workflow-navigation-type">
    <jha-card class="card-size">
      <jha-nav class="pb-16">
        <div class="workflow-type-header">{{ workflowTypeHeader }}</div>
        <a
          href="javascript:void(0)"
          v-for="tab in allTabs"
          :key="tab.state"
          :class="{ active: isActiveTab(tab.state), disabled: tabDisabled(tab) }"
          :active="isActiveTab(tab.state)"
          @click.prevent="handleTabClick(tab)"
          :tabIndex="tab.disabled ? -1 : 0"
        >
          {{ tab.title }}
          <template v-if="(tab.warnings?.length || tab.errors?.length)">
            <div class="icon" v-if="tab.errors?.length > 0">
              <jha-icon-circle-warning danger=""></jha-icon-circle-warning>
            </div>
            <div class="icon" v-else-if="tab.warnings?.length > 0">
              <jha-icon-warning base=""></jha-icon-warning>
            </div>
          </template>
        </a>
      </jha-nav>
    </jha-card>
  </div>
</template>
<style scoped>
jha-nav .workflow-type-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px var(--jh-space-400);
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

@media (max-width: 768px) {
  .workflow-navigation-type jha-nav a.active {
    font-weight: normal;
    background: none;
  }
  .workflow-navigation-type jha-nav a::before {
    width: 0;
  }
}
</style>
