import { injectable, inject } from "inversify";
import LanguageFactory from "@/common/services/Language/LanguageFactory";

@injectable()
class BltUiKeyFilter {
  constructor(@inject(LanguageFactory) private languageFactory: LanguageFactory) {}

  bltUiKey(text: string) {
    return this.languageFactory.getSynchronous(text);
  }
}

export default BltUiKeyFilter;
