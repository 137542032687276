import { merge, isUndefined } from "lodash";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import emailTypes from "@/common/enums/emailTypesEnum";
import type IExtendedEmail from '@/common/data/IExtendedEmail'
import type {IEmail} from '@/common/data/IExtendedEmail'

@injectable()
class ApplicantEmailsFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LookupFactory) private lookupFactory: LookupFactory
  ) { }

  getApplicantEmailList(workspaceUUID: string, applicantId: number): Promise<Array<IExtendedEmail>> {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/email`,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        return Array.isArray(response) ? response : [];
      });
  }

  getHomeEmail(workspaceUUID: string, applicantId: number): Promise<IEmail | undefined> {
    return this.getApplicantEmailList(workspaceUUID, applicantId).then((emailList) => {
      const result = emailList.filter((email: { emailType: string; }) => {
        return email.emailType === emailTypes.HOME;
      });
      return result[0]
    });
  }

  deleteApplicantEmail(workspaceUUID: string, applicantId: number, email: IExtendedEmail) {
    const response = this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/email/${email.emailId}`,
      {},
      ResponseTypes.Data
    );
    return response;
  }

  async saveApplicantEmail(workspaceUUID: string, applicantId: number, email: IExtendedEmail | IEmail) {
    return !isUndefined(email.emailId)
      ? await this.putApplicantEmail(workspaceUUID, applicantId, email)
      : await this.postApplicantEmail(workspaceUUID, applicantId, email);
  }

  async putApplicantEmail(workspaceUUID: string, applicantId: number, email: IExtendedEmail | IEmail) {
    const response = await this.httpHandler
      .put(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/email/${email.emailId}`,
        email,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        return response[0];
      });
    return response;
  }

  async postApplicantEmail(workspaceUUID: string, applicantId: number, email: IExtendedEmail | IEmail) {
    const response = await this.httpHandler
      .post(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/email`,
        email,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        return response[0];
      });
    return response;
  }

  async setEmailTypeDescription(email: IExtendedEmail) {
    if (isUndefined(email.emailType)) {
      return;
    }
    return await this.lookupFactory.Email?.getAll({ enabled: true }).then((emailTypes: IExtendedEmail[]) => {
      email.emailTypeDescription = emailTypes.filter((emailType: IExtendedEmail) => {
        return emailType.typeCode === email.emailType;
      })[0].typeDescription;
    });
  }

  setEmailListTypeDescription(emailList: IExtendedEmail[]) {
    return Promise.all(
      emailList.map((email: IExtendedEmail) => {
        return this.setEmailTypeDescription(email);
      })
    );
  }

  /**
   * @name saveApplicantEmailList
   * @methodOf e2e.factory:ApplicantEmailsFactory
   * @description
   * This method will accept a list of applicant emails and will either post them or put them
   * depending on whether or not an emailId exists.
   *
   * @param {String} workspaceUUID - The workspaceUUID the applicant belong to.
   *
   * @param {String} applicantId - The applicantId the emails belong to.
   *
   * @param {Array} emailList - A list of email objects either from the server or new.
   *
   */
  saveApplicantEmailList(workspaceUUID: string, applicantId: number, emailList: IExtendedEmail[]) {
    return Promise.all(
      emailList.map((email: IExtendedEmail) => {
        const promise = !isUndefined(email.emailId)
          ? this.putApplicantEmail(workspaceUUID, applicantId, email)
          : this.postApplicantEmail(workspaceUUID, applicantId, email);
        return promise.then((response) => {
          const emailResponse = merge(email, response);
          return emailResponse;
        });
      })
    );
  }
}
export default ApplicantEmailsFactory;
