import {inject, injectable} from "inversify";
import {Service} from "@/common/dependency.configs";
import {HttpHandler} from "@/common/services/services.module";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
@Service
export default class WorkspaceHistoryStateService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

    getState(workflowName: string, stateId: string): Promise<RestResponse<WorkspaceHistoryState>> {
        return this.httpHandler.get(`{{boltsui}}/workflow/${workflowName}/state/${stateId}`)
    }
}

export interface WorkspaceHistoryState {
    stateId: number
    state: string
    type: 'INFO' | 'DECISION' | 'RULE_DECISION'
    back: boolean
    title: string
    titleKey: string
    description: string
    descriptionString: string
    locked: boolean
    nextStateId: number
    decisions: Array<WorkspaceHistoryStateDecision>
    disclosureCode: string
    ruleUUID: string
}

export interface WorkspaceHistoryStateDecision {
    stateDecisionId: number
    nextStateId: number
    nextWorkflow: string
    nextState: string
    display: string
    displayKey: string
    displayOrder: number
}
