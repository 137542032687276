<template>
  <blt-modal-dialog ref="popup" aria-label="Information Dialog">
    <template v-slot:dialog-content>
      <div layout="row" class="modal-toolbar" layout-align="center space-between">
        <div class="md-toolbar-tools" layout="row" layout-align="space-between center">
          <div layout="row" layout-align="end center">
            <jha-icon-file></jha-icon-file>
            <h3>{{ title }}</h3>
          </div>
          <jha-button
            layout="row"
            layout-align="center center"
            style="position: relative"
            class="md-icon-button"
            aria-label="Close"
            @click="hide"
          >
            <jha-icon-close class="close-modal-button"></jha-icon-close>
          </jha-button>
        </div>
      </div>
      <div style="background-color: white">
        <iframe title="" class="md-dialog-iframe" v-blt-iframe:html="htmlResolve"> </iframe>
      </div>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-file.js";
import "@banno/jha-wc/src/icons/jha-icon-close.js";
import type { bltModalDialog } from "@/common/components/appch.components.module";
import { defineComponent } from "vue";

export default defineComponent({
  name: "infoDialog",
  props: {
    title: {
      required: true
    },
    htmlResolve: {
      required: true
    }
  },
  methods: {
    show() {
      (this.$refs.popup as typeof bltModalDialog).open();
    },
    hide() {
      (this.$refs.popup as typeof bltModalDialog).close();
    }
  }
});
</script>
