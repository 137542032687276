import { inject, injectable } from "inversify";
import LanguageFactory from "@/common/services/Language/LanguageFactory";
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";

@injectable()
class FullNameFilter {
  constructor(@inject(LanguageFactory) private languageFactory: LanguageFactory) {}

  fullName(personObj?: {
    nameSalutation?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    nameSuffix?: string;
    personId?: number;
  }) {
    const emptyName = this.languageFactory.getSynchronous(OpenLanguageKeysConstant.GLOBAL_FULL_NAME_PLACEHOLDER);

    if (!personObj) return emptyName;

    let salutation = "";

    // Convert "DR" to "Dr." if there is a salutation.
    if (personObj.nameSalutation) {
      salutation =
        personObj.nameSalutation.substr(0, 1) +
        personObj.nameSalutation.substr(1, personObj.nameSalutation.length).toLowerCase() +
        ". ";
    }

    return (
      [personObj.firstName, personObj.middleName, personObj.lastName, personObj.nameSuffix].reduce(function (
        col,
        name
      ) {
        return name ? col + name + " " : col;
      },
      salutation) || emptyName
    );
  }
}

export default FullNameFilter;
