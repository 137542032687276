import NetTellerService from "@/common/services/Dialogs/NetTeller/NetTellerService";
import { injectable, inject } from "inversify";

@injectable()
class NetTellerFactory {
  constructor(@inject(NetTellerService) private netTellerService: NetTellerService) {}

  displayModal(applicant: Record<string, unknown>): Promise<any> {
    const dialogOption = {
      fullscreen: false,
      escapeToClose: false,
      multiple: true,
      data: {
        applicant,
        showNettellerDialog: true
      }
    };

    return this.netTellerService.show(dialogOption);
  }
}
export default NetTellerFactory;
