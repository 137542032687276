//Deprecated
import { $inj } from "@/common/decorators/depinject";
import LogService from "@/common/services/Log/LogService";

const bltSubmitForm = {
  mounted: (el: any, binding: { value: string }, vnode: any) => {
    el.addEventListener("click", function () {
      const container = document.querySelector(binding.value);
      const logService: LogService = $inj(LogService);
      if (!container) {
        logService.error("blt-submit-form could not find the specified form container!");
        return;
      }
      const form = container ? container.querySelector("form") : null;
      if (!form) {
        logService.error('blt-submit-form could not find a form within - "' + binding.value + '"');
        return;
      }
      form.submit();
    });
  }
};

export default bltSubmitForm;
