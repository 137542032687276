import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from 'inversify';
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class CurrentQuestionFactory {

    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    async from(workspaceUUID: string, resource: any, resourceId: number, questionnaireId: number) {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}/question/current`)
    }
}
export default CurrentQuestionFactory;
