const REGEX = Object.freeze({
  LETTER: /[a-zA-Z]/,
  NUMBER: /[0-9]/,
  ALPHANNUMERIC: /^([a-zA-Z0-9]*)$/,
  ALPHANNUMERIC_WITH_ASTRISKS: /^([a-zA-Z0-9\*]*)$/,
  NCR_SPECIAL_CHAR: /[!@#$%^&*_+\-=()\[\]{}|:;`,.\/?]/,
  EIN: /^(0[1-6]|1[0-6]|2[0-7]|3\d|4[0-8]|5\d|[68][0-8]|7[1-7]|9[0-58-9])?-?\d{7}$/,
  EXPIRATION_DATE: /[0-1][0-9]\/2[0-9][0-9][0-9]/,
  // eslint-disable-next-line no-control-regex
  EMAIL:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
  CURRENCY: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
  DOUBLE_PRECISION: /^[0-9]+(\.[0-9]{1,2})?$/,
  POSITIVE_INTEGER: /^(?:[1-9]\d*|\d)$/,
  UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  TAX_ID: /\d{3}-?\d{2}-?\d{4}/,
  HEXToRGB: /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
  ANALYTICS_TRACKING_REGEX: /^(G|UA)-[a-zA-Z0-9-]+$/,
  ZIPCODE: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
  US_PHONE_REGEX: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/,
  PLAIN_EIN: /(\d{0,2})(\d{3,9})/,
  EIN_MATCH: /\d*/g
});

export default REGEX;
