import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import INVITE_APPLICANT_STATUS from "@/common/enums/inviteApplicantStatusEnum";

@injectable()
class ApplicantInviteFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  invite(workspaceUUID: string, applicantId: number, message: { inviteId: any }) {
    return message.inviteId
      ? this.httpHandler.put(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/invite/${message.inviteId}`,
          message,
          {},
          ResponseTypes.Payload
        )
      : this.httpHandler.post(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/invite`,
          message,
          {},
          ResponseTypes.Payload
        );
  }

  getAll(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/invite`,
      {},
      ResponseTypes.Payload
    );
  }

  get(workspaceUUID: string, applicantId: number, applicantInviteId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/invite/${applicantInviteId}`,
      {},
      ResponseTypes.Payload
    );
  }

  getOneInvite(workspaceUUID: string, applicantInviteId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/invite/${applicantInviteId}`,
      {},
      ResponseTypes.Payload
    );
  }

  send(workspaceUUID: string, applicantInviteId: number) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/invite/${applicantInviteId}/send`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  delete(workspaceUUID: string, applicantId: number, applicantInviteId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/invite/${applicantInviteId}`,
      {},
      ResponseTypes.Payload
    );
  }

  sendDisclosureEmails(workspaceUUID: string, applicantId: number, enrollmentId: number) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/send`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  setInvitedApplicantAsCompleted(workspaceUUID: string, invitedApplicantId: number) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/invite/${invitedApplicantId}`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  validate(workspaceUUID: string, token: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/invite`,
      { inviteToken: token },
      {},
      ResponseTypes.Payload
    );
  }

  continueDisabled(applicantInvites: any[]) {
    return applicantInvites.length
      ? applicantInvites.every((invitedApplicant) => {
          return invitedApplicant.status === INVITE_APPLICANT_STATUS.COMPLETE;
        })
      : false;
  }
}
export default ApplicantInviteFactory;
