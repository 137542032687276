import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import type RestResponse from '@/common/data/RestResponse'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import MEDIA_TYPE from '@/common/enums/mediaTypeEnum'

@injectable()
export default class CatalogFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(): Promise<RestResponse<Array<Category>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/lookup`)
  }

  create(dto: Category): Promise<RestResponse<Category>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/lookup/`, dto)
  }

  get(url: string): Promise<RestResponse<Array<CategoryDetails>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}${url}`)
  }

  update(url: string, dto: FormData): Promise<RestResponse<null>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}${url}`, dto, {
      headers: {'Content-Type': MEDIA_TYPE.MULTIPART_FORM_DATA}
    })
  }

  delete(url: string): Promise<RestResponse<Array<CategoryDetails>>> {
    return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}${url}`)
  }
}

export type Category = {
  categoryId?: number
  providerId?: number
  categoryName: string
  url?: string
}

export type CategoryDetails = {
  enabled: boolean
  typeCode: string
  typeDescription: string
}
