import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class ApplicantCoreFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  get(coreId: string, applicantType: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH_CORE_URL}/applicant/${applicantType}/${coreId}`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default ApplicantCoreFactory;
