import Base64Factory from "@/common/services/utils/Base64Factory";
import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class UiImageFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(Base64Factory) private base64Factory: Base64Factory
  ) { }

  async get(typeCode: string) {
    return await this.httpHandler
      .get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/image/${typeCode}`, {}, ResponseTypes.Payload, true) //caching is set to true
      .then((response: any) => {
        return this.base64Factory.prefixBase64(response.data, response.imageFormatTypeCode);
      });
  }

}

export default UiImageFactory;
