<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-user.js";
import "@banno/jha-wc/src/icons/jha-icon-briefcase.js";
import "@banno/jha-wc/src/icons/jha-icon-circle-filled.js";
import { defineComponent } from "vue";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import ApplicantFactory from "@/common/services/Applicant/applicantFactory";
import LogService from "@/common/services/Log/LogService";
import FullApplicantNameFilter from "@/common/services/filters/fullApplicantNameFilter";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type IRouterService from "@/common/services/utils/IRouterService";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import COMPONENT_MSG from "@/common/messages/component.messages";
import { isEmpty } from "lodash";
import { ApplicantEmailsFactory } from "@/common/services/services.module";
export default defineComponent({
  name: "bltCurrentApplicant",
  props: {
    workspaceUUID: {
      type: String,
      required: false,
      default: ""
    },
    applicantId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      applicant: {} as {
        applicantType: string;
        person?: boolean;
        business?: boolean;
        emails: { emailAddress: string }[];
      },
      PERSON: WORKFLOW_TYPES.PERSON,
      BUSINESS: WORKFLOW_TYPES.BUSINESS
    };
  },
  setup() {
    const routerService = $injByInterface<IRouterService>("IRouterService");
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const fullApplicantNameFilter = $inj(FullApplicantNameFilter);
    const applicantFactory = $inj(ApplicantFactory);
    const logService = $inj(LogService);
    const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
    return {
      routerService,
      workspaceStore,
      fullApplicantNameFilter,
      applicantFactory,
      logService,
      applicantEmailsFactory
    };
  },
  created() {
    this.onInit();
  },
  computed: {
    applicantEmail() {
      return (this.applicant?.person || this.applicant?.business) && this.applicant.emails?.length ? this.applicant.emails[0].emailAddress : "";
    },
    applicantName() {
      return this.applicant && this.applicant?.applicantType
        ? this.fullApplicantNameFilter.fullApplicantName(this.applicant)
        : "";
    }
  },
  methods: {
    async onInit() {
      const uuid = this.workspaceStore.workspaceUUID || this.workspaceUUID;
      if (!this.applicantId) {
        const routeName = this.routerService.currentRoute.value;
        return this.logService.error(COMPONENT_MSG.BLT_CURRENT_APPLICANT.NO_APPLICANT_ID_MSG + routeName);
      }
      const applicant = await this.applicantFactory.getApplicant(uuid, this.applicantId);
      const response = await this.applicantFactory.setApplicantInfo(uuid, applicant);
      this.applicant = response;
      this.getEmails();
    },
    getEmails() {
      if (!isEmpty(this.applicant) && isEmpty(this.applicant.emails)) {
        this.applicantEmailsFactory
          .getApplicantEmailList(this.workspaceStore.workspaceUUID, this.applicantId)
          .then((response) => {
            this.applicant.emails = response;
          });
      }
    }
  }
});
</script>

<template>
  <div v-if="applicantId && applicant.applicantType" class="current-applicant-wrapper">
    <div class="current-applicant-header" :class="{ 'current-applicant-header-email': applicantEmail }">
      <jha-flex-wrapper space-between>
        <div class="d-flex">
          <div class="applicant-icons m-tb-auto">
            <div v-if="applicant" class="pr-16">
              <jha-icon-user class="center-pos" v-if="applicant.applicantType === PERSON"></jha-icon-user>
              <jha-icon-briefcase
                class="center-pos"
                v-else-if="applicant.applicantType === BUSINESS"
              ></jha-icon-briefcase>
            </div>
            <div v-else>
              <jha-icon-circle-filled></jha-icon-circle-filled>
            </div>
          </div>

          <div class="applicant-details">
            <div class="primary-text">
              {{ applicantName }}
            </div>
            <div class="secondary-text">
              {{ applicantEmail }}
            </div>
          </div>
        </div>
      </jha-flex-wrapper>
    </div>
  </div>
</template>
<style scoped>
.current-applicant-wrapper {
  width: 100%;
}
.current-applicant-header {
  padding: var(--jh-space-300) var(--jh-space-400);
  margin: var(--jh-space-500) var(--jh-space-500) var(--jh-space-400) var(--jh-space-500);
  background-color: var(--secondary-content-background-color);
  border-radius: var(--jh-space-100);
}
.current-applicant-header-email {
  padding: var(--jh-space-200) var(--jh-space-400);
}
.center-pos {
  vertical-align: middle;
}
@media (max-width: 599px) {
  .current-applicant-header {
    margin-right: var(--jh-space-300);
    margin-left: var(--jh-space-300);
    padding: var(--jh-space-300) var(--jh-space-400);
  }
}
</style>
