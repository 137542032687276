import { injectable } from "inversify";

@injectable()
class PlaidLibraryService {
  async loadPlaidLink() {
    const plugin = window.document.createElement("script");
    plugin.setAttribute("src", "https://cdn.plaid.com/link/v2/stable/link-initialize.js");
    plugin.async = true;
    window.document.head.appendChild(plugin);
  }
}

export default PlaidLibraryService;
