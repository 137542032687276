<template>
  <jha-form-search-input
    ref="filterSearch"
    outline
    id="auto-search"
    type="text"
    @search="onChange($event)"
    :label="label"
    :debounceInterval="bltFilterDebounce"
  ></jha-form-search-input>
</template>

<script>
import "@banno/jha-wc/src/forms/jha-form-search-input/jha-form-search-input.js";
export default {
  name: "bltFilter",
  emits: ["filterValue"],
  props: {
    label: {
      type: String,
      required: false,
      default: "Search"
    },
    bltFilterDebounce: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    onChange(event) {
      this.$emit("filterValue", event.detail);
    }
  }
};
</script>
