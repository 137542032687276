<template>
  <div v-if="bltTaxIdTypes.ssn || bltTaxIdTypes.ein">
    <div class="radio-section-padding">
      <span class="tax-type" v-blt-ui-text="headerLabel"></span>
      <jha-form-radio-button
          class="business-info-radio"
          :disabled="!bltTaxIdTypes || disabled"
          v-show="!(bltTaxIdTypes && !bltTaxIdTypes.ein)"
          @checked="(evt: any) => taxIdTypeChecked(evt, BusinessInfoConstant.EIN)"
          :checked="businessInfoStore.business.taxIdType === BusinessInfoConstant.EIN"
          value="EIN">
        <span v-blt-ui-text="einLabel"> </span>
      </jha-form-radio-button>
      <jha-form-input
          v-if="!hideTaxId && businessInfoStore.business.taxIdType === BusinessInfoConstant.EIN && (bltTaxIdTypes && bltTaxIdTypes.ein) && !bltTaxIdTypes.einNotRequired"
          v-blt-ui-text="einLabel"
          :warning="businessInfoStore.einErrorMessage"
          class="radio-input mb-4"
          :showWarning="showWarning"
          v-model="businessInfoStore.business.taxId"
          :disabled="bltTaxIdTypes.einNotRequired || disabled"
          @blur="changeEINFormat()"
          @change="updateEINFormat"
          required
          inputmode="numeric"
          :maxlength="maxLength"
          :minlength="maxLength"
          name="taxId"
          outline
      >
      </jha-form-input>

      <jha-form-radio-button
          :disabled="!bltTaxIdTypes || disabled"
          v-show="!(bltTaxIdTypes && !bltTaxIdTypes.ssn)"
          @checked="(evt: any) => taxIdTypeChecked(evt, BusinessInfoConstant.SSN)"
          :checked="businessInfoStore.business.taxIdType === BusinessInfoConstant.SSN"
          class="business-info-radio1"
          value="SSN"
      >
        <span v-blt-ui-text="ssnLabel"> </span>
      </jha-form-radio-button>

      <jha-form-ssn
          v-if="!hideTaxId && businessInfoStore.business.taxIdType === BusinessInfoConstant.SSN && (bltTaxIdTypes && bltTaxIdTypes.ssn) && !bltTaxIdTypes.ssnNotRequired"
          v-blt-ui-text="ssnLabel"
          :warning="ssnErrorMessage"
          :showWarning="showWarning"
          class="radio-input mb-4"
          required
          v-model="businessInfoStore.business.taxId"
          :disabled="disabled"
          @blur="validateSSN()"
          inputmode="numeric"
          ref="TaxIdSSN"
          name="TaxIdSSN"
          outline
      >
      </jha-form-ssn>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, type PropType} from "vue";
import {BusinessInfoConstant} from "@/common/constant/BusinessInfoConstant";
import type BusinessTaxId from "@/common/data/BusinessTaxId";
import REGEX from "@/common/enums/regexEnum";
import {OpenLanguageKeysConstant} from "@/common/constant/OpenLanguageKeysConstant";
import {$inj} from "@/common/decorators/depinject";
import {LanguageFactory, NotificationFactory} from "@/common/services/services.module";
import {BusinessInfoStore} from "@/common/stores/BusinessInfoStore";
import CustomerSearchFactory from "@/common/services/Customer/customerSearchFactory";
import {isEmpty} from "lodash";

export default defineComponent({
      name: "bltBusinessTaxId",
      props: {
        disabled: {type: Boolean},
        bltTaxIdTypes: {type: Object as PropType<BusinessTaxId>, required: true},
        headerLabel: {type: String},
        ssnLabel: {type: String},
        einLabel: {type: String},
        hideTaxId: {type: Boolean, required: false, default: false},
        workspaceUuid: {type: String, required: true},
        checkTaxId: {type: Boolean, required: false, default: false}
      },
      setup() {
        const notificationFactory = $inj(NotificationFactory);
        const languageFactory = $inj(LanguageFactory);
        const businessInfoStore = BusinessInfoStore();
        const customerSearchFactory = $inj(CustomerSearchFactory);

        return {
          notificationFactory,
          languageFactory,
          businessInfoStore,
          customerSearchFactory
        }
      },
      async mounted() {
        this.changeEINFormat();
        this.requirementErrorMessage = await this.languageFactory.get(OpenLanguageKeysConstant.ERROR_MESSAGE_REQUIRED);
        this.einErrorMessageVerbiage = await this.languageFactory.get(OpenLanguageKeysConstant.OPEN_BUSINESS_INFO_EIN_ERROR);
        this.einErrorMessageVerbiage = await this.languageFactory.get(OpenLanguageKeysConstant.OPEN_BUSINESS_INFO_EIN_ERROR);
      },
      data() {
        return {
          BusinessInfoConstant,
          maxLength: 9,
          requirementErrorMessage: "",
          einErrorMessageVerbiage: "",
        }
      },
      computed: {
        showWarning() {
          return !this.disabled && !!this.businessInfoStore.einErrorMessage
        },
        ssnErrorMessage() {
          return isEmpty(this.businessInfoStore.einErrorMessage)
              ? this.requirementErrorMessage
              : this.businessInfoStore.einErrorMessage
        }
      },
      methods: {
        updateEINFormat() {
          if (this.businessInfoStore.business.taxId && !this.businessInfoStore.business.taxId.includes("-")) {
            this.notificationFactory.clearMessages();
            this.maxLength = 9;
          }
        },
        async validateSSN() {
          this.businessInfoStore.businessValidity.taxIdSSNValid = REGEX.TAX_ID.test(this.businessInfoStore.business.taxId ?? "")
          if (this.businessInfoStore.businessValidity.taxIdSSNValid) {
            this.notificationFactory.clearMessages();
            await this.checkTaxIdInCore()
          }
        },
        taxIdTypeChecked(event: CustomEvent, taxIdType: string) {
          this.businessInfoStore.einErrorMessage = undefined;
          this.businessInfoStore.business.taxId = undefined;
          this.businessInfoStore.business.taxIdType = taxIdType;
        },
        async checkTaxIdInCore() {
          if (!this.businessInfoStore.business.taxId || !this.checkTaxId || this.businessInfoStore.einErrorMessage) {
            return { customers: [], applicants: [] };
          }

          const results =
              await this.customerSearchFactory.search(
                  this.workspaceUuid,
                  {
                    applicantType: "BUSINESS",
                    taxid: this.businessInfoStore.business.taxId?.replaceAll('-','')
                  }
              )


          if (results.customers.length > 0) {
            this.businessInfoStore.einErrorMessage = await this.languageFactory.get('branch-validation-error--taxIdExistingInCoreBusiness')
          } else if (results.applicants.length > 0) {
            this.businessInfoStore.einErrorMessage = await this.languageFactory.get('branch-validation-error--taxIdExistingInWorkspace')
          } else {
            this.businessInfoStore.einErrorMessage = "";
          }

          return results;
        },


        /**
         * @TODO:
         *
         * Simplify this method!!!
         */
        changeEINFormat() {
          delete this.businessInfoStore.einErrorMessage

          this.checkTaxIdInCore().then(() => {
          if (!this.businessInfoStore.business.taxId) {
            this.businessInfoStore.einErrorMessage = this.requirementErrorMessage;
          } else if (this.businessInfoStore.business.taxId && this.businessInfoStore.business.taxIdType === BusinessInfoConstant.EIN) {
            const validEin = REGEX.EIN.test(this.businessInfoStore.business.taxId) && this.businessInfoStore.business.taxId.toString().length >= 9;
            if (!validEin) {
              this.businessInfoStore.einErrorMessage = this.einErrorMessageVerbiage
              this.businessInfoStore.businessValidity.taxIdEINValid = false
            }
          }

          if (this.businessInfoStore.business.taxIdType === BusinessInfoConstant.EIN) {
            this.businessInfoStore.businessValidity.taxIdEINValid = true;
            const ein: any = this.businessInfoStore.business.taxId || "";
            if (ein.length <= 2) {
              return;
            }
            const patt = new RegExp("d{2}[-]d{7}");
            const res = patt.test(ein);
            if (!res) {
              const plainEIN: any = ein.match(/\d*/g).join("");
              const fromatedEIN = plainEIN
                  .match(/(\d{0,2})(\d{3,9})/)
                  .slice(1)
                  .join("-")
                  .replace(/-*$/g, "");
              // user has  entered 9 digit in input or not
              if (fromatedEIN.length > 9) {
                this.businessInfoStore.business.taxId = fromatedEIN;
                this.maxLength = 10;
              } else {
                //user has not entered/remove - and reformat it
                this.businessInfoStore.business.taxId = plainEIN;
                this.maxLength = 9;
              }
            } else {
              //After formating EIN
              this.maxLength = 10;
            }
            this.checkTaxIdInCore()
          }
          })
        },
      }
    }
)
</script>

<style scoped>

</style>
