const COMPONENT_MSG = Object.freeze({
  BLT_CURRENT_APPLICANT: {
    NO_APPLICANT_ID_MSG: "ApplicantId not provided on state "
  },
  CIP: {
    VERIFY_APPLICANT: "Verifying applicant information...",
    STILL_VERIFY_APPLICANT: "Still verifying, please wait..."
  },
  SAVE: {
    SETTING_ACCOUNT: "Setting up your account ....",
    STILL_SETTING_ACCOUNT: "Still setting up, please wait..."
  },
  CAPTURE: {
    CAPTURE_IDENTIFICATION: "Capture an identification image"
  },
  PREQUALIFICATION: {
    INTERNAL_SERVER_ERROR_MESSAGE: "An internal server error has occurred."
  },
  DOCUMENT: {
    NO_DOCUMENTS_REQUIRED_MESSAGE: "There are no documents required for this applicant."
  },
  NETTELLER: {
    PASSWORDS_DO_NOT_MATCH: "The passwords provided do not match."
  }
});

export default COMPONENT_MSG;
