import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import isUndefined from "lodash.isundefined";


@injectable()
class ApplicantEmploymentFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  get(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment`,
      undefined,
      ResponseTypes.Payload      
    );
  }
  private post(workspaceUUID: string, applicantId: number, employment: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment`,
      employment,
      undefined,
      ResponseTypes.Payload      
    );
  }
  private put(workspaceUUID: string, applicantId: number, employment: {employmentId : number}) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment/${employment.employmentId}`,
      employment,
      undefined,
      ResponseTypes.Payload      
    );
  }
  save(workspaceUUID: string, applicantId: number, employment: {employmentId : number}) {
    if (isUndefined(employment.employmentId)) {
      return this.post(workspaceUUID, applicantId, employment);
    } else {
      return this.put(workspaceUUID, applicantId, employment);
    }
  }

}
export default ApplicantEmploymentFactory;
