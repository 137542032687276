import bltUiText from "./bltUiText";
/*Couple of the things have been removed from this directive 
as those were not applicable to applicant channel*/

/**extended to support updated behavior for EC requirements. Not to modify in already AC code */
const bltUiTextEx = {
    mounted: (el: any,
        binding: { value: string }) => {
        bltUiText.mounted(el, binding);
    },
    updated: (el: any,
        binding: { value: string }) => {
        bltUiText.mounted(el, binding);
    }
}

export default bltUiTextEx;
