import {
  ApplicantAddressFactory,
  ApplicantBusinessFactory,
  ApplicantEmailsFactory,
  ApplicantPersonFactory,
  ApplicantPhoneFactory,
  EnrollmentFactory,
  WorkspaceFactory,
  ApplicantFactory
} from "@/common/services/services.module";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { injectable, inject } from "inversify";

@injectable()
class SummaryFactory {
  constructor(
    @inject(ApplicantBusinessFactory) private applicantBusinessFactory: ApplicantBusinessFactory,
    @inject(ApplicantAddressFactory) private applicantAddressFactory: ApplicantAddressFactory,
    @inject(ApplicantEmailsFactory) private applicantEmailsFactory: ApplicantEmailsFactory,
    @inject(ApplicantPersonFactory) private applicantPersonFactory: ApplicantPersonFactory,
    @inject(ApplicantPhoneFactory) private applicantPhoneFactory: ApplicantPhoneFactory,
    @inject(EnrollmentFactory) private enrollmentFactory: EnrollmentFactory,
    @inject(WorkspaceFactory) private workspaceFactory: WorkspaceFactory,
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory
  ) { }

  getWorkspaceSummary(workspaceUUID: string) {
    return this.workspaceFactory
      .getWorkspace(workspaceUUID)
      .then((workspace) => {
        return Promise.all([
          Promise.resolve(workspace),
          this.applicantFactory.getApplicants(workspaceUUID),
          this.enrollmentFactory.getAll(workspaceUUID)
        ]);
      })
      .then((applicantsInformation) => {
        return Promise.all([
          Promise.resolve(applicantsInformation[0]),
          this.applicantFactory.setApplicantListInfo(workspaceUUID, applicantsInformation[1]),
          Promise.resolve(applicantsInformation[2])
        ]);
      })
      .then((workspaceSummary) => {
        const workspace = workspaceSummary[0];
        workspace.applicants = workspaceSummary[1];
        workspace.enrollments = workspaceSummary[2];
        return workspace;
      });
  }

  getApplicantSummary(workspaceUUID: string, applicantId: number) {
    return this.applicantFactory
      .get(workspaceUUID, applicantId)
      .then((applicant) => {
        return Promise.all([
          Promise.resolve(applicant),
          this.applicantAddressFactory.getAll(workspaceUUID, applicantId),
          this.applicantPhoneFactory.getApplicantPhoneList(workspaceUUID, applicantId),
          this.applicantEmailsFactory.getApplicantEmailList(workspaceUUID, applicantId),
          applicant.applicantType === WORKFLOW_TYPES.PERSON
            ? this.applicantPersonFactory.get(workspaceUUID, applicantId)
            : this.applicantBusinessFactory.get(workspaceUUID, applicantId)
        ]);
      })
      .then((applicantSummary) => {
        const applicant = applicantSummary[0];
        applicant.addresses = applicantSummary[1];
        applicant.phones = applicantSummary[2];
        applicant.emails = applicantSummary[3];
        if (applicant.applicantType === WORKFLOW_TYPES.BUSINESS) {
          applicant.business = applicantSummary[4];
        } else {
          applicant.person = applicantSummary[4];
        }
        return applicant;
      });
  }
}
export default SummaryFactory;