import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants';
import ResponseTypes from '@/common/enums/responseTypesEnum';

@injectable()
class ContinueFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    requestContinue(workspaceUUID: string, message: any) {
        return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/continue`, message, {}, ResponseTypes.Payload);
    }

    requestBusinessContinue(workspaceUUID: string, message: any) {
        return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/continuebusiness`, message, {}, ResponseTypes.Payload);
    }

}
export default ContinueFactory;