import { injectable } from "inversify";

@injectable()
class NumberFormatFilter {
  numberFormat(input: any) {
    if (isNaN(input)) {
      return input;
    }
    if (input >= 1000000000) {
      return (input / 1000000000).toFixed(2) + "b";
    } else if (input >= 1000000) {
      return (input / 1000000).toFixed(2) + "m";
    } else if (input >= 1000) {
      return (input / 1000).toFixed(2) + "k";
    } else {
      return input;
    }
  }
}

export default NumberFormatFilter;
