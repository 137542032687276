import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type { AuthenticatedResponse } from "@/common/services/Login/LoginService";
import type RestResponse from "@/common/data/RestResponse";
import type { AxiosResponse } from "axios";

export interface AuthenticationRequest {
    login: string,
    password: string,
    tfaToken: string | null,
    tfaCode: string | null,
    latitude: string | null,
    longitude: string | null,
    locationAccuracyMeters: string | null,
    forceVerificationNewDevice: boolean | null,
    deviceGuid: string
}

@injectable()
class AuthenticationFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler,
    ) { }

    authenticate(request: AuthenticationRequest): Promise<AxiosResponse<AuthenticatedResponse>> {

        request.latitude = '0';
        request.longitude = '0';
        request.locationAccuracyMeters = '0';

        return this.httpHandler.post(
            `/v1/launchpadREST/authenticate`,
            request,
            {},
            ResponseTypes.None
        );
    }

}
export default AuthenticationFactory;