import { injectable } from "inversify";
import { isEmpty } from "lodash";

@injectable()
class EnabledOrSelectedFilter {
  enabledOrSelected(allTypeList: any[], selectedTypeList: any[]) {
    const getSelectedTypeList = !isEmpty(selectedTypeList) ? selectedTypeList : [];
    if (isEmpty(allTypeList)) {
      return [];
    }
    return allTypeList.filter((allType: { enabled: boolean; typeCode: string; }) => {
      if (allType.enabled) {
        return true;
      }
      return getSelectedTypeList.some((selectedType: string) => {
        return selectedType === allType.typeCode;
      });
    });
  }
}

export default EnabledOrSelectedFilter;