import {WorkspaceStatesConstant} from '@/branchmanager/app/constant/WorkspaceStatesConstant'

export interface Site {
  route: string
  name: string
  icon: string
}

export const openAnywhereSites: Site[] = [
  {
    route: WorkspaceStatesConstant.DESKTOP.STATE,
    name: 'Workspace',
    icon: "<jha-icon-fi class='nav-icon'/>"
  },
  {
    route: WorkspaceStatesConstant.DASHBOARD.STATE,
    name: 'Dashboard',
    icon: "<jha-icon-dashboard class='nav-icon'/>"
  }
]
