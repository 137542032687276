import { $inj } from "@/common/decorators/depinject";
import { BltUiTextConstant } from '@/common/constant/BltUiTextConstant';
import { LanguageFactory } from "@/common/services/services.module";
/*Couple of the things have been removed from this directive 
as those were not applicable to applicant channel*/

const bltUiText = {
  mounted: (el: any,
    binding: { value: string }) => {
    const { value } = binding;
    const languageFactory: LanguageFactory = $inj(LanguageFactory);
    languageFactory.get(value).then((text: string) => {
      //if el tagName startswith "JHA-FORM"
      if (el.tagName.startsWith(BltUiTextConstant.BLT_UI_TEXT_JHA_FORM)) {
        el.label = text;
      }
      else {
        //case of span, div, p, h, etc.
        el.innerText = text;
      }
    });
  },

};

export default bltUiText;
