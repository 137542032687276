import { inject, injectable } from "inversify";
import { merge, isUndefined } from "lodash";
import CORE_STATUS from "@/common/enums/coreStatusEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import NetTellerFactory from "@/common/services/Dialogs/NetTeller/NetTellerFactory";
import NcrDialogFactory from "@/common/services/Dialogs/NcrDialogFactory/ncrDialogFactory";
import CoreLoginFactory from "@/common/services/Dialogs/CoreLogin/CoreLoginFactory";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import NotificationFactory from "@/common/services/notificationFactory";
import EnrollmentFactory from "@/common/services/Enrollment/enrollmentFactory";
import CORE_HEADER from "@/common/enums/coreHeaderEnum";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { EnrollmentConstant } from "@/common/constant/EnrollmentConstant";
import SERVICE_CODE from "@/common/enums/serviceCodeEnum";
import type Account from "@/common/data/Account";
import type { ApplicantService } from "@/open/states/workflow/enrollment/info/services/serviceTypes";

type Applicant = {
  applicantStatus?: string;
  existing?: boolean;
  applicantId?: number;
  accountServiceCode?: string;
  accountServiceId?: number;
  loading?: boolean;
  error?: string;
  errors?: any;
};

export interface AccountWithLoadingAndStatus extends Account {
  loading?: boolean;
  status?: string;
}

@injectable()
class EnrollmentCoreFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(NetTellerFactory) private netTellerFactory: NetTellerFactory,
    @inject(EnrollmentFactory) private enrollmentFactory: EnrollmentFactory,
    @inject(CoreLoginFactory) private coreLoginFactory: CoreLoginFactory,
    @inject(NcrDialogFactory) private ncrDialogFactory: NcrDialogFactory,
    @inject(NotificationFactory) private notificationFactory: NotificationFactory
  ) {}

  processCoreTransaction(
    coreItem: {
      accountServiceCode?: string;
      accountServiceId?: number;
      loading?: boolean;
      error?: string;
      errors?: any;
    },
    promise: any
  ) {
    coreItem.loading = true;
    delete coreItem.error;
    delete coreItem.errors;
    return promise
      .then((response: any) => {
        merge(coreItem, response);
        return Promise.resolve(coreItem);
      })
      .catch((response: { data: { message: string | undefined; errors: any } }) => {
        coreItem.error = response.data.message;
        coreItem.errors = response.data.errors;
        return Promise.reject();
      })
      .finally(() => {
        coreItem.loading = false;
      });
  }

  isServicePendingCreation(applicantService: { accountServiceCode?: string; status: string }) {
    return !(applicantService.status === CORE_STATUS.CREATED || applicantService.status === CORE_STATUS.STAGED);
  }

  createCoreService(
    workspaceUUID: string,
    enrollmentId: number,
    service: { accountServiceCode?: string; accountServiceId: number },
    pass?: string
  ) {
    const message = isUndefined(pass) || null === pass ? {} : { password: pass };
    const url = `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${service.accountServiceId}/core`;
    const response = this.httpHandler.post(url, message, {}, ResponseTypes.Payload);
    return this.processCoreTransaction(service, response);
  }

  isAccountPendingCreation(account: AccountWithLoadingAndStatus) {
    return !(account.status === CORE_STATUS.CREATED || account.status === CORE_STATUS.STAGED);
  }

  createCoreAccount(workspaceUUID: string, enrollmentId: number, account: AccountWithLoadingAndStatus) {
    const url = `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${account.accountId}/core`;
    const response = this.httpHandler.post(url, {}, {}, ResponseTypes.Payload);
    return this.processCoreTransaction(account, response);
  }

  linkCoreAccount(account: AccountWithLoadingAndStatus) {
    const url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH_CORE_URL}/account/${account.accountId}/applicant`;
    const response = this.httpHandler.put(url, {}, {}, ResponseTypes.Data);
    return this.processCoreTransaction(account, response);
  }

  isApplicantPendingCreation(applicant: Applicant) {
    return applicant.applicantStatus !== CORE_STATUS.CREATED && !applicant.existing;
  }

  createCoreApplicant(workspaceUUID: string, enrollmentId: number, applicant: Applicant) {
    const url = `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicant.applicantId}/core`;
    const response = this.httpHandler.post(url, {}, {}, ResponseTypes.Payload);
    return this.processCoreTransaction(applicant, response);
  }

  createAll(workspaceUUID: string, enrollmentId: number, applicantList: any[], accountList: any[]) {
    let isNetTellerRequired = false;
    let isCoreLoginRequired = false;
    let isNcrRequired = false;
    let applicantResponse: any[];
    let accountResponse: any[];
    let serviceResponse: any[];
    let isStaged = false;

    return Promise.all([
      this.isCoreHeaderKeyRequired(CORE_HEADER.NETTELLER_REQUIRED),
      this.isCoreHeaderKeyRequired(CORE_HEADER.CORE_LOGIN_REQUIRED),
      this.isCoreHeaderKeyRequired(CORE_HEADER.NCR_REQUIRED)
    ])
      .then((response) => {
        isNetTellerRequired = response[0];
        isCoreLoginRequired = response[1];
        isNcrRequired = response[2];

        return isCoreLoginRequired ? this.coreLoginFactory.collectCoreLogin() : Promise.resolve();
      })
      .then(() => {
        // The 5th param isNcrRequired was passed in Angular that was not defined in createAllApplicants hence removed here.
        return this.createAllApplicants(workspaceUUID, enrollmentId, applicantList, WORKFLOW_TYPES.PERSON);
      })
      .then(() => {
        // The 5th param was passed in Angular that was not defined in createAllApplicants hence removed here.
        return this.createAllApplicants(workspaceUUID, enrollmentId, applicantList, WORKFLOW_TYPES.BUSINESS);
      })
      .then((response) => {
        applicantResponse = response;
        return this.createAllAccounts(workspaceUUID, enrollmentId, accountList);
      })
      .then((response) => {
        accountResponse = response;
        return this.createAllServices(workspaceUUID, enrollmentId, applicantList, accountResponse, {
          isNetTellerRequired: isNetTellerRequired,
          isNcrRequired: isNcrRequired
        });
      })
      .then((response) => {
        serviceResponse = response;
        isStaged = this.isStaged(applicantResponse, accountResponse, serviceResponse);
        return isStaged ? this.commitStaged(workspaceUUID, enrollmentId) : Promise.resolve();
      })
      .then(() => {
        return {
          applicantResponse: applicantResponse,
          accountResponse: accountResponse,
          serviceResponse: serviceResponse,
          isStaged: isStaged
        };
      });
  }

  createAllApplicants(workspaceUUID: string, enrollmentId: number, applicantList: any[], applicantType: string) {
    return Promise.all(
      applicantList
        .filter((applicant) => {
          return applicant.applicantType === applicantType;
        })
        .map((applicant) => {
          return Promise.resolve(
            this.isApplicantPendingCreation(applicant)
              ? this.createCoreApplicant(workspaceUUID, enrollmentId, applicant)
              : applicant
          );
        })
    );
  }

  createAllAccounts(
    workspaceUUID: string,
    enrollmentId: number,
    accountList: AccountWithLoadingAndStatus[]
  ): Promise<Array<AccountWithLoadingAndStatus>> {
    return Promise.all(
      accountList.map((account) => {
        if (!this.isAccountPendingCreation(account)) {
          return Promise.resolve(account);
        }

        account.loading = true;

        return this.createCoreAccount(workspaceUUID, enrollmentId, account)
          .then(() => {
            this.linkCoreAccount(account);
          })
          .then(() => {
            account.loading = false;
            return account;
          });
      })
    );
  }

  createAllServices(
    workspaceUUID: string,
    enrollmentId: number,
    applicantList: any[],
    accountList: Array<AccountWithLoadingAndStatus>,
    options: { isNetTellerRequired?: boolean; isNcrRequired?: boolean }
  ) {
    options = options || {};
    return Promise.all(
      applicantList.reduce((col, applicant) => {
        return applicant.services
          .map((applicantService: ApplicantService) => {
            if (!this.isServicePendingCreation(applicantService)) {
              return Promise.resolve(applicantService);
            }

            if (options.isNetTellerRequired && options.isNcrRequired) {
              this.notificationFactory.error(EnrollmentConstant.ENROLLMENT_NCR_TELLER_NOT_SUPPORTED);
              return Promise.reject(EnrollmentConstant.ENROLLMENT_NCR_TELLER_NOT_SUPPORTED);
            }

            if (applicantService.accountServiceCode === SERVICE_CODE.AONL && !applicant.hasOnlineBanking) {
              if (options.isNetTellerRequired) {
                return this.netTellerFactory
                  .displayModal(applicant)
                  .then((password: { value: string; dirty: boolean }) =>
                    this.createCoreService(workspaceUUID, enrollmentId, applicantService, password.value)
                  );
              }
              if (
                options.isNcrRequired &&
                this.applicantIsPrimaryOnAnyAccount(applicant, applicantService, accountList)
              ) {
                return this.ncrDialogFactory
                  .display(workspaceUUID, applicant)
                  .then(() => this.createCoreService(workspaceUUID, enrollmentId, applicantService));
              }
            }
            return this.createCoreService(workspaceUUID, enrollmentId, applicantService, applicant.password);
          })
          .concat(col);
      }, [])
    );
  }

  applicantIsPrimaryOnAnyAccount(
    applicant: Applicant,
    applicantService: ApplicantService,
    accountList: Array<AccountWithLoadingAndStatus>
  ) {
    const primaryApplicantIds =
      // Filter out the accountList to show only accounts that are on the service
      accountList
        .filter(function (account) {
          return applicantService.accounts.some(function (serviceAccount) {
            return serviceAccount.accountId === account.accountId;
          });
        })

        // Get a list of the primary applicant from that account
        .map(function (account) {
          return account.applicants.reduce(function (acc: Array<number>, cur) {
            if (cur.primary) {
              acc.push(cur.applicantId);
            }
            return acc;
          }, []);
        })
        .flat();

    return primaryApplicantIds.some(function (ids) {
      return ids === applicant.applicantId;
    });
  }

  isStaged(applicantResponse: any[], accountResponse: any[], serviceResponse: any[]) {
    applicantResponse = Array.isArray(applicantResponse) ? applicantResponse : [];
    accountResponse = Array.isArray(accountResponse) ? accountResponse : [];
    serviceResponse = Array.isArray(serviceResponse) ? serviceResponse : [];

    const staged = (x: { status: string; applicantStatus: string }) => {
      return x.status === CORE_STATUS.STAGED || x.applicantStatus === CORE_STATUS.STAGED;
    };

    return applicantResponse.some(staged) || accountResponse.some(staged) || serviceResponse.some(staged);
  }

  commitStaged(workspaceUUID: string, enrollmentId: number) {
    const url = `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/commit`;
    return this.httpHandler.post(url, {}, {}, ResponseTypes.Data);
  }

  async isCoreHeaderKeyRequired(key: string) {
    const response = await this.enrollmentFactory.getCoreHeader();
    return response.some((header: { headerKey: string; required: any }) => {
      return header.headerKey === key && !!header.required;
    });
  }
}

export default EnrollmentCoreFactory;
