import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { ImageConstant } from "@/common/constant/ImageConstant";
import Base64Factory from "../utils/Base64Factory";

/**
 * @ngdoc service
 * @name e2e.factory:ApplicantImageFactory
 *
 * @description
 * This Factory handles sending and receiving applicant images from the Backend.
 *
 * @requires common:module
 */

@injectable()
class ApplicantImageFactory {
  constructor(
    @inject(Base64Factory) private base64Factory: Base64Factory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) { }

  /**
   *@ngdoc method
   * @name putApplicantSignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This will update an existing applicant siganture image, this method is best used in conjunction with the
   *     bltSignature directive to create the appropriate signature object.
   *
   * @param {String} uuid - The workspaceUUID the image is related to.
   *
   * @param {String} applicantId - The applicantId the image is related to.
   *
   * @param {Object} signature - The signature object returned from bltSignature.
   *
   * @param {String} imageId - The saved imageId returned from getApplicantSignature.
   *
   */
  putApplicantSignature(uuid: string, applicantId: number, signature: any, imageId: number) {
    const message = {
      imageDataBase64: signature.strippedBase64,
      imageType: ImageConstant.CUSTOMER_SIGNATURE,
      imageFormat: signature.extension,
    };

    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/image/${imageId}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  /**
   *@ngdoc method
   * @name postApplicantSignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This will post a new applicant siganture image, this method is best used in conjunction with the
   *     bltSignature directive to create the appropriate signature object.
   *
   * @param {String} uuid - The workspaceUUID the image is related to.
   *
   * @param {String} applicantId - The applicantId the image is related to.
   *
   * @param {Object} signature - The signature object returned from bltSignature
   *
   */
  postApplicantSignature(uuid: string, applicantId: number, signature: any) {
    const message = {
      imageDataBase64: signature.strippedBase64,
      imageType: ImageConstant.CUSTOMER_SIGNATURE,
      imageFormat: signature.extension,
    };

    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/image`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  /**
   *@ngdoc method
   * @name getApplicantSignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This will get an applicant siganture image.
   *
   * @param {String} uuid - The workspaceUUID the image is related to.
   *
   * @param {String} applicantId - The applicantId the image is related to.
   *
   * @param {Object} applicant - The applicant object you wish to get a signature for.
   *
   */
  getApplicantSignature(uuid: string, applicantId: string, applicant: any): Promise<any> {
    const image = applicant.images.filter((image: any) => image.imageType === ImageConstant.CUSTOMER_SIGNATURE)[0];

    if (image) {
      return this.httpHandler
        .get(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/image/${image.imageId}`,
          {},
          ResponseTypes.Payload
        )
        .then((response: any) => {
          return {
            image: this.base64Factory.prefixBase64(response.imageDataBase64, response.imageFormat),
            imageId: response.imageId,
          };
        });
    } else {
      return Promise.resolve(undefined);
    }
  }

  /**
   *@ngdoc method
   * @name containsApplicantSignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This will return true if the passed applicant object contains an image with an image type CUSTOMER_SIGNATURE
   *
   * @param {Object} applicant - the applicant object that is being checked for a customer signature reference.
   *
   */
  containsApplicantSignature(applicant: any) {
    let containsSignature = false;

    if (!applicant || !applicant.images) {
      return false;
    }

    applicant.images.forEach((image: any) => {
      if (image.imageType == ImageConstant.CUSTOMER_SIGNATURE) {
        containsSignature = true;
        return;
      }
    });

    return containsSignature;
  }

  /**
   *@ngdoc method
   * @name containsApplicantSignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This will add a containsApplicantSignature bool corresponding to the applicants signature's existence.
   *
   * @param {Object} applicant - the applicant object that is being checked for a customer signature reference.
   *
   */
  setApplicantContainsSignature(applicant: any) {
    applicant.containsApplicantSignature = this.containsApplicantSignature(applicant);

    return applicant;
  }

  /**
   *@ngdoc method
   * @name getEmptySignature
   * @methodOf e2e.factory:this.applicantImageFactory
   * @description
   * This method will return the image of an empty signature.
   *
   */
  getEmptySignature() {
    return ImageConstant.EMPTY_SIGNATURE;
  }

  getAll(workspaceUUID: string, applicant: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicant.applicantId}/image`,
      {},
      ResponseTypes.Payload
    );
  }

  getSignatureFromImageList(imageList: any) {
    const results = imageList.filter((image: any) => image.imageType === ImageConstant.CUSTOMER_SIGNATURE);

    return results.length
      ? {
        image: this.base64Factory.prefixBase64(results[0].imageDataBase64, results[0].imageFormat),
        imageId: results[0].imageId,
      }
      : {};
  }
}
export default ApplicantImageFactory;
