export class AdminStatesConstant {
  static readonly ADMIN = {STATE: 'ADMIN'}
  static readonly RELATIONSHIPS = {STATE: 'RELATIONSHIPS'}
  static readonly DISCLOSURES = {STATE: 'ADMIN_DISCLOSURES'}
  static readonly OAUTH = {STATE: 'OAUTH'}
  static readonly OAUTH_LIST = {STATE: 'OAUTH_LIST'}
  static readonly OAUTH_EDIT = {STATE: 'OAUTH_EDIT'}
  static readonly USERS = {STATE: 'USERS'}
  static readonly USERS_LIST = {STATE: 'USERS_LIST'}
  static readonly USER_DETAILS = {STATE: 'USER_DETAILS'}
  static readonly GROUP_DETAILS = {STATE: 'GROUP_DETAILS'}
  static readonly PASSWORD_SETTINGS = {STATE: 'PASSWORD_SETTINGS'}
  static readonly OCR = {STATE: 'OCR'}
  static readonly OCR_PROVIDER = {STATE: 'OCR_PROVIDER'}
  static readonly OCR_ID_TYPES = {STATE: 'OCR_ID_TYPES'}
  static readonly SYNERGY = {STATE: 'SYNERGY'}
  static readonly SYNERGY_QUEUED = {STATE: 'SYNERGY_QUEUED'}
  static readonly SYNERGY_COMPLETED = {STATE: 'SYNERGY_COMPLETED'}
  static readonly SYNERGY_FAILED = {STATE: 'SYNERGY_FAILED'}
  static readonly SYNERGY_INDEX_MAPPING = {STATE: 'SYNERGY_INDEX_MAPPING'}
  static readonly SYNERGY_PROPERTIES = {STATE: 'SYNERGY_PROPERTIES'}
  static readonly BUSINESS = {STATE: 'BUSINESS'}
  static readonly BUSINESS_TYPES = {STATE: 'BUSINESS_TYPES'}
  static readonly BUSINESS_DOCUMENTS = {STATE: 'BUSINESS_DOCUMENTS'}
  static readonly VERBIAGE = {STATE: 'VERBIAGE'}
  static readonly VERBIAGE_CATEGORY_LIST = {STATE: 'VERBIAGE_CATEGORY_LIST'}
  static readonly VERBIAGE_CATEGORY = {STATE: 'VERBIAGE_CATEGORY'}
  static readonly VERBIAGE_SEARCH = {STATE: 'VERBIAGE_SEARCH'}
  static readonly WOLTERS_KLUWER = {STATE: 'WOLTERS_KLUWER_ADMIN'}
  static readonly WOLTERS_KLUWER_PROPERTIES = {STATE: 'WOLTERS_KLUWER_PROPERTIES'}
  static readonly WOLTERS_KLUWER_ROLES = {STATE: 'WOLTERS_KLUWER_ROLES'}
  static readonly WOLTERS_KLUWER_ORGANIZATIONS = {STATE: 'WOLTERS_KLUWER_ORGANIZATIONS'}
  static readonly WOLTERS_KLUWER_PRODUCTS = {STATE: 'WOLTERS_KLUWER_PRODUCTS'}
  static readonly ZIP_CODE = {STATE: 'ZIP_CODE'}
  static readonly ZIP_CODE_REGIONS_LIST = {STATE: 'ZIP_CODE_REGIONS_LIST'}
  static readonly ZIP_CODE_REGION = {STATE: 'ZIP_CODE_REGION'}
  static readonly ZIP_CODE_WHITELIST = {STATE: 'ZIP_CODE_WHITELIST'}
  static readonly PIGEON_ADMIN = {STATE: 'PIGEON_ADMIN'}
  static readonly PIGEON_PROPERTIES = {STATE: 'PIGEON_PROPERTIES'}
  static readonly PIGEON_CARRIER_LIST = {STATE: 'PIGEON_CARRIER_LIST'}
  static readonly PIGEON_CARRIER = {STATE: 'PIGEON_CARRIER'}
  static readonly PIGEON_EMAIL = {STATE: 'PIGEON_EMAIL'}
  static readonly PIGEON_SMS = {STATE: 'PIGEON_SMS'}
  static readonly FUNDING = {STATE: 'ADMIN_FUNDING'}
  static readonly FUNDING_ACH = {STATE: 'ADMIN_FUNDING_ACH'}
  static readonly FUNDING_VERIFICATION = {STATE: 'ADMIN_FUNDING_VERIFICATION'}
  static readonly FUNDING_HOLIDAYS = {STATE: 'ADMIN_FUNDING_HOLIDAYS'}
  static readonly FUNDING_PROVIDERS = {STATE: 'ADMIN_FUNDING_PROVIDERS'}
  static readonly FUNDING_PROVIDERS_EDITOR = {STATE: 'ADMIN_FUNDING_PROVIDERS_EDITOR'}
  static readonly ADMIN_SERVICE_LIST = {STATE: 'ADMIN_SERVICE_LIST'}
  static readonly ADMIN_SERVICE_IMAGES = {STATE: 'ADMIN_SERVICE_IMAGES'}
  static readonly ADMIN_SERVICE = {STATE: 'ADMIN_SERVICE'}
  static readonly CIP_ADMIN = {STATE: 'CIP_ADMIN'}
  static readonly RULES_ADMIN = {STATE: 'RULES_ADMIN'}
  static readonly RULE_CATEGORY_LIST = {STATE: 'RULE_CATEGORY_LIST'}
  static readonly RULES_CATEGORY = {STATE: 'RULES_CATEGORY'}
  static readonly RULES_EDITOR = {STATE: 'RULES_EDITOR'}
  static readonly CIP_ADMIN_LIST = {STATE: 'CIP_ADMIN_LIST'}
  static readonly CIP_ADMIN_EDIT = {STATE: 'CIP_ADMIN_EDIT'}
  static readonly ADMIN_PRODUCTS = {STATE: 'ADMIN_PRODUCTS'}
  static readonly ADMIN_PRODUCT = {STATE: 'ADMIN_PRODUCT'}
  static readonly ADMIN_PRODUCT_OVERVIEW = {STATE: 'ADMIN_PRODUCT_OVERVIEW'}
  static readonly ADMIN_PRODUCT_FUNDING = {STATE: 'ADMIN_PRODUCT_FUNDING'}
  static readonly ADMIN_PRODUCT_OVERRIDES = {STATE: 'ADMIN_PRODUCT_OVERRIDES'}
  static readonly ADMIN_PRODUCTS_LIST = {STATE: 'ADMIN_PRODUCTS_LIST'}
  static readonly ADMIN_PRODUCTS_INTENTIONS = {STATE: 'ADMIN_PRODUCTS_INTENTIONS'}
  static readonly ADMIN_PRODUCTS_GROUPS = {STATE: 'ADMIN_PRODUCTS_GROUPS'}
  static readonly ADMIN_PRODUCTS_MAPPING = {STATE: 'ADMIN_PRODUCTS_MAPPING'}
  static readonly FIELDS_ADMIN = {STATE: 'FIELDS_ADMIN'}
  static readonly FIELDS_ADMIN_LIST = {STATE: 'FIELDS_ADMIN_LIST'}
  static readonly FIELDS_ADMIN_EDIT = {STATE: 'FIELDS_ADMIN_EDIT'}
  static readonly FIELDS_ADMIN_APPLICANT = {STATE: 'FIELDS_ADMIN_APPLICANT '}
  static readonly FIELDS_ADMIN_EMPLOYEE = {STATE: 'FIELDS_ADMIN_EMPLOYEE '}
  static readonly SCHEDULED_TASKS = {STATE: 'SCHEDULED_TASKS'}
  static readonly SCHEDULED_TASKS_LIST = {STATE: 'SCHEDULED_TASKS_LIST'}
  static readonly SCHEDULED_TASK = {STATE: 'SCHEDULED_TASK'}
  static readonly SCHEDULED_TASK_OVERVIEW = {STATE: 'SCHEDULED_TASK_OVERVIEW'}
  static readonly SCHEDULED_TASK_LOGS = {STATE: 'SCHEDULED_TASK_LOGS'}
  static readonly QUESTIONNAIRE_ADMIN = {STATE: 'QUESTIONNAIRE_ADMIN'}
  static readonly QUESTIONNAIRE_ADMIN_LIST = {STATE: 'QUESTIONNAIRE_ADMIN_LIST'}
  static readonly QUESTIONNAIRE_ADMIN_CHOICE_CATALOG = {STATE: 'QUESTIONNAIRE_ADMIN_CHOICE_CATALOG'}
  static readonly QUESTIONNAIRE_ADMIN_CHOICE_CATALOG_EDIT = {STATE: 'QUESTIONNAIRE_ADMIN_CHOICE_CATALOG_EDIT'}
  static readonly QUESTIONNAIRE_ADMIN_EDIT = {STATE: 'QUESTIONNAIRE_ADMIN_EDIT'}
  static readonly QUESTIONNAIRE_ADMIN_DIAGRAM_PREVIEW = {STATE: 'QUESTIONNAIRE_ADMIN_DIAGRAM_PREVIEW'}
  static readonly PROMO_CODES = {STATE: 'PROMO_CODES'}
  static readonly ADMIN_MAPPING = {STATE: 'MAPPING'}
  static readonly PROPERTIES_ADMIN = {STATE: 'PROPERTIES_ADMIN'}
  static readonly PROPERTIES_ADMIN_LIST = {STATE: 'PROPERTIES_ADMIN_LIST'}
  static readonly PROPERTIES_ADMIN_EDIT = {STATE: 'PROPERTIES_ADMIN_EDIT'}
  static readonly DOCUMENT_EXPORTS_ADMIN = {STATE: 'DOCUMENT_EXPORTS_ADMIN'}
  static readonly DOCUMENT_EXPORTS_ADMIN_LIST = {STATE: 'DOCUMENT_EXPORTS_ADMIN_LIST'}
  static readonly WORKFLOW_ADMIN = {STATE: 'WORKFLOW_ADMIN'}
  static readonly WORKFLOW_ADMIN_LIST = {STATE: 'WORKFLOW_ADMIN_LIST'}
  static readonly WORKFLOW_ADMIN_EDIT = {STATE: 'WORKFLOW_ADMIN_EDIT'}
}
