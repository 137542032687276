import { injectable } from "inversify";
import { PollerConstant } from "@/common/constant/PollerConstant";

@injectable()
export default class Poller<T> {
    private lastCalledMillis: number;
    private errorCallbacks: Map<symbol, Function>;
    private resultCallbacks: Map<symbol, Function>;
    private pollingFn: () => any;
    private intervalMs: number;
    private polling: boolean;

    constructor(
        intervalMs = 0,
        pollingFn: () => Promise<T>,
    ) {
        this.intervalMs = intervalMs;
        this.pollingFn = pollingFn;
        this.polling = false;
        this.errorCallbacks = new Map();
        this.resultCallbacks = new Map();
        this.lastCalledMillis = 0;
    }

    start() {
        if (this.polling) {
            console.warn(PollerConstant.POLLER_START_WARN);
            return;
        }
        this.polling = true;

        const loop = () => {
            this.lastCalledMillis = new Date().getTime();
            if (!this.polling) {
                return;
            }
            const callbackToAll = <T>(callbacks: Map<symbol, Function>, response: T) => {
                if (this.polling) {
                    callbacks.forEach((callback) => callback(response));
                }
            }

            this.pollingFn()
                .then((res: any) => callbackToAll(this.resultCallbacks, res))
                .catch((err: any) => callbackToAll(this.errorCallbacks, err));
            setTimeout(loop, this.intervalMs);
        }
        loop();
    }

    stop() {
        if (!this.polling) {
            console.warn(PollerConstant.POLLER_STOP_WARN);
        }
        this.polling = false;
    }

    subscribe(onResult: (res: any) => any, onError: (err: any) => any): () => void {
        const subscriptionId = Symbol(PollerConstant.POLLER_SUBSCRIBE);
        this.resultCallbacks.set(subscriptionId, onResult);
        this.errorCallbacks.set(subscriptionId, onError);
        return () => {
            this.resultCallbacks.delete(subscriptionId);
            this.errorCallbacks.delete(subscriptionId);
        };
    }

    getMillisecondsUntilNextPoll(): number {
        const dt = new Date().getTime() - this.lastCalledMillis;
        return this.intervalMs - dt;
    }

    getSecondsUntilNextPoll() {
        return Math.ceil(this.getMillisecondsUntilNextPoll() / 1000);
    }
}
