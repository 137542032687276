import {inject, injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import {HttpHandler} from '@/common/services/services.module'
import type RestResponse from '@/common/data/RestResponse'
import type IPerson from '@/common/models/IPerson'
import type IApplicant from '@/common/models/IApplicant'
import type IBusiness from '@/common/models/IBusiness'

@injectable()
@Service
export default class WorkspaceHistoryService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getHistory(workspaceUUID: string): Promise<RestResponse<Array<WorkspaceHistory>>> {
    return this.httpHandler.get(`{{boltsbranch}}/state-history/workspace/${workspaceUUID}`)
  }

  getApplicantInfo(workspaceUUID: string, applicantId: number): Promise<ApplicantInfo> {
    return this.getApplicant(workspaceUUID, applicantId).then((applicantRes) => {
      const applicantInfo = {
        applicant: applicantRes.data.payload[0]
      }

      return applicantInfo.applicant.applicantType == 'PERSON'
        ? this.getPerson(workspaceUUID, applicantId, applicantInfo)
        : this.getBusiness(workspaceUUID, applicantId, applicantInfo)
    })
  }

  getApplicant(workspaceUUID: string, applicantId: number): Promise<RestResponse<Array<IApplicant>>> {
    return this.httpHandler.get(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${applicantId}`)
  }

  getPerson(workspaceUUID: string, applicantId: number, applicantInfo: ApplicantInfo): Promise<ApplicantInfo> {
    return this.httpHandler
      .get(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${applicantId}/person`)
      .then((response) => {
        applicantInfo.person = response.data.payload
        return applicantInfo
      })
  }

  getBusiness(workspaceUUID: string, applicantId: number, applicantInfo: ApplicantInfo): Promise<ApplicantInfo> {
    return this.httpHandler
      .get(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${applicantId}/business`)
      .then((response) => {
        applicantInfo.business = response.data.payload
        return applicantInfo
      })
  }
}

export interface WorkspaceHistory {
  trackerUuid: string
  workspaceUuid: string
  state: null | WorkspaceHistoryState
  createdTimestamp: number // Epoch time, NOT ISO date.
}

export interface WorkspaceHistoryState {
  state: string
  workflow: string
  enrollmentId: number
  previousState: Array<string>
  activeApplicantId: number
}

export interface ApplicantInfo {
  applicant: IApplicant
  person?: IPerson
  business?: IBusiness
}
