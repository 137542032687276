<style scoped>
    .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
    }
    
    .ok-btn {
        padding: 0.5em 1em;
        background-color: #d5eae7;
        color: #35907f;
        border: 2px solid #0ec5a4;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .cancel-btn {
        padding: 0.5em 1em;
        background-color: #d5eae7;
        color: #35907f;
        border: 2px solid #0ec5a4;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
    }
</style>

<template>
  <blt-modal-dialog ref="popup" aria-label="Core Login Dialog">
    <h2 style="margin-top: 0">Core Login</h2>
    <form name="corePasswordForm">
      <label for="login">Login:</label>
      <input v-model="message.login" name="login" />

      <label for="password">Password:</label>
      <input v-model="message.password" name="password" type="password" />

      <div class="btns">
        <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
        <span class="ok-btn" @click="_confirm">{{ okButton }}</span>
      </div>
    </form>
  </blt-modal-dialog>
</template>

<script>
import bltModalDialog from "@/common/components/bltDialog/bltModalDialog.vue";

export default {
  name: "CoreLoginDialog",

  components: { bltModalDialog },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Exit", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),

  methods: {
    show(opts = {}) {
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
};
</script>
