<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-warning.js";
import "@banno/jha-wc/src/icons/jha-icon-view-more.js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltEllipses",
  props: {
    notificationsList: {
      type: Array<{ icon: string; title: string }>,
      required: true,
      default: () => []
    },
    clickOutSideToClose: {
      type: Boolean,
      default: false
    },
    ellipsesColor: {
      type: String,
      default: "black"
    }
  },
  emits: ["notificationItemClicked"],
  data() {
    return {
      showPopup: false
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    closeOnClickOutside() {
      if (this.clickOutSideToClose) {
        this.showPopup = false;
      }
    }
  }
});
</script>
<template>
  <div class="ellipses-popup">
    <div
      tabindex="0"
      role="button"
      @keydown.enter="togglePopup"
      @click="togglePopup"
      class="ellipses"
      :style="{ color: ellipsesColor }"
    >
      <jha-icon-view-more class="ellipses"></jha-icon-view-more>
    </div>
    <div v-if="showPopup" class="ellipse-popup" v-blt-click-outside="closeOnClickOutside">
      <template v-for="(notification, index) in notificationsList" :key="index">
        <div
          tabindex="0"
          role="button"
          class="ellipse-items"
          v-if="notification.title"
          @keydown.enter="$emit('notificationItemClicked', notification)"
          @click="$emit('notificationItemClicked', notification)"
        >
          <span v-html="notification.icon"></span> {{ notification.title }}
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped>
.ellipse-popup {
  width: 272px;
  position: absolute;
  right: 48px;
  min-height: 140px;
  border-radius: 10px;
  z-index: 99;
  background-color: var(--white-color);
  color: #000;
  box-shadow: 0px 2px 2px rgba(39, 40, 41, 0.1), 0px 4px 6px rgba(39, 40, 41, 0.2);
}
.ellipse-items {
  padding: var(--jh-space-300) var(--jh-space-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.ellipses {
  display: inline-block;
  fill: var(--white-color);
}
</style>
