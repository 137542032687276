import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type {Applicant} from "@/common/components/bltQuestionnaire/cipTypes";

@injectable()
class EnrollmentApplicantFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getEnrollmentApplicantList(uuid: string, enrollmentId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant`,
      {},
      ResponseTypes.Payload
    );
  }

  async delete(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return await this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}`,
      {},
      ResponseTypes.Data
    );
  }
}
export default EnrollmentApplicantFactory;
