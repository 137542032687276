<script setup lang="ts">

import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import { computed } from "vue";

const { user, login } = useLocalStorage()

const firstName = computed(() => user?.firstName ?? "")
const lastName = computed(() => user?.lastName ?? "")

</script>

<template>

  <div class="d-flex flex-100">
    <div class="user-icon icon-round m-tb-auto mr-16">
      <jha-icon-user class="secondary-fill-icon"></jha-icon-user>
    </div>
    <div class="details">
      <div class="jh-font-body-regular-2">{{ firstName }} {{ lastName }}
      </div>
      <div class="jh-font-helper-regular">{{ login }}</div>
    </div>
  </div>

</template>

<style scoped>
@import "../../../branchmanager/assets/typography.css";

.flex-100 {
  flex: 1 1 100%
}

.details {
  color: var(--jha-color-dark);
  padding-top: var(--jh-space-300);
  padding-bottom: var(--jh-space-300);
}

.user-icon {
  min-width: var(--jh-space-900);
  min-height: var(--jh-space-900);
}

</style>