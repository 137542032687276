import EnrollmentApplicantCipFactory from "@/common/services/Enrollment/enrollmentApplicantCipFactory";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import CORE_STATUS from "@/common/enums/coreStatusEnum";

@injectable()
class EnrollmentCipFactory {
  constructor(
    @inject(EnrollmentApplicantCipFactory) private enrollmentApplicantCipFactory: EnrollmentApplicantCipFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  run(workspaceUUID: string, enrollmentId: number, cipId: number, detail: any) {
    return detail.status === CORE_STATUS.COMPLETED
      ? this.enrollmentApplicantCipFactory.getApplicantCip(workspaceUUID, enrollmentId, cipId, detail.serviceCode)
      : this.postCipServiceRequest(workspaceUUID, enrollmentId, cipId, detail.serviceCode);
  }

  async rerun(workspaceUUID: string, enrollmentId: number, cipId: number, detail: any) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` +
        workspaceUUID +
        "/enrollment/" +
        enrollmentId +
        "/cip/" +
        cipId +
        "/service/" +
        detail.serviceCode +
        "/rerun",
      {},
      {},
      ResponseTypes.Payload
    );
  }

  async postCipServiceRequest(workspaceUUID: string, enrollmentId: number, cipId: number, serviceCode: any) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` +
        workspaceUUID +
        "/enrollment/" +
        enrollmentId +
        "/cip/" +
        cipId +
        "/service/" +
        serviceCode,
      {},
      {},
      ResponseTypes.Payload
    );
  }

 putCipServiceRequest(
    workspaceUUID: string,
    enrollmentId: number,
    cipId: number,
    serviceCode: any,
    message: any
  ) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` +
        workspaceUUID +
        "/enrollment/" +
        enrollmentId +
        "/cip/" +
        cipId +
        "/service/" +
        serviceCode,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  async putCipServiceRequestDecision(
    workspaceUUID: string,
    enrollmentId: number,
    cipId: number,
    serviceCode: any,
    message: any
  ) {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` +
        workspaceUUID +
        "/enrollment/" +
        enrollmentId +
        "/cip/" +
        cipId +
        "/service/" +
        serviceCode +
        "/decision",
      message,
      {},
      ResponseTypes.Payload
    );
  }

  async getCip(workspaceUUID: string, enrollmentId: number, cipId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` +
        workspaceUUID +
        "/enrollment/" +
        enrollmentId +
        "/cip/" +
        cipId,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentCipFactory;
