import DEPENDENCYTYPES from '@/common/dependency.types'
import HttpHandler from '@/common/services/connect/HttpHandler'
import {injectable, inject} from 'inversify'
import merge from 'lodash.merge'
import TrackerFactory from '@/common/services/Applicant/trackerFactory'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import LanguageFactory from '@/common/services/Language/LanguageFactory'
import ResponseTypes from '@/common/enums/responseTypesEnum'
import LogService from '@/common/services/Log/LogService'
import type IWorkspaceStore from '@/common/services/Workspace/IWorkspaceStore'
import {cloneDeep} from 'lodash'
import FACTORY_MSG from '@/common/messages/factory.messages'
import {WorkflowConstant} from '@/common/constant/WorkflowConstant'
import {OpenLanguageKeysConstant} from '@/common/constant/OpenLanguageKeysConstant'
import type IRouterService from '@/common/services/utils/IRouterService'
import {WorkflowStatesConstant} from '@/common/constant/WorkflowStatesConstant'
import type IWorkspaceMetadataStore from '../Workspace/IWorkspaceMetadataStore'
import type RestResponse from '@/common/data/RestResponse'
import MEDIA_TYPE from '@/common/enums/mediaTypeEnum'

@injectable()
class WorkflowFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LanguageFactory) private languageFactory: LanguageFactory,
    @inject(LogService) private logService: LogService,
    @inject(TrackerFactory) private trackerFactory: TrackerFactory,
    @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IWorkspaceStore,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService
  ) {}

  getWorkflows(params: {startWorkflowCode: string; visible: boolean}): Promise<RestResponse<Array<Workflow>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow`, {params})
  }

  createWorkflow(
    body: {displayName: string; startWorkflowCode: string},
    params?: {copyWorkflowName: string}
  ): Promise<RestResponse<Workflow>> {
    return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow`, body, {params})
  }

  updateWorkflow(workflowName: string, workflow: Workflow): Promise<RestResponse<Workflow>> {
    return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}`, workflow)
  }

  getWorkflowState(stateId: string) {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/state/${stateId}`, {}, ResponseTypes.Payload, true)
  }

  export(options: {workflowName: string}) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${options.workflowName}`,
      {params: {export: true}},
      ResponseTypes.Payload,
      true
    )
  }

  hide(options: {workflowName: string}, workFlowData: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${options.workflowName}`,
      workFlowData,
      {params: {hide: true}},
      ResponseTypes.Payload
    )
  }

  copy(options: {workflowName: string; copyId: number}, workFlowData: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${options.workflowName}`,
      options,
      {params: {copyWorkflowName: options.copyId}},
      ResponseTypes.Payload
    )
  }

  import(startWorkflowCode: string, workFlowData: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow`,
      workFlowData,
      {
        params: {import: true, startWorkflowCode: startWorkflowCode},
        headers: {'Content-Type': MEDIA_TYPE.JSON}
      },
      ResponseTypes.Payload
    )
  }
  //Implementation of from.get method, resource is replace with http handler
  getWorkflow(workflowName: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflowName}`,
      {},
      ResponseTypes.Payload,
      true
    )
  }

  setState(stateId: string) {
    return this.getWorkflowState(stateId).then((response) => {
      this.workspaceStore.workflow.currentState = response
    })
  }

  /**
   * Used to fetch all states in a workflow. Used in Employee Channel and start-single-person, and decision states
   * @deprecated
   * @param workflow
   * @returns {Promise}
   */
  get(workflow: string) {
    return this.httpHandler
      .get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/workflow/${workflow}/state`, {}, ResponseTypes.Payload, true)
      .then((response: any) => {
        return response.map(function (tab: any) {
          return merge({errors: [], warnings: [], infos: [], disabled: false}, tab)
        })
      })
  }

  getProgress(stateId: number) {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/state/${stateId}/endavg`, {}, ResponseTypes.Payload)
  }

  getState(): any {
    return cloneDeep(this.workspaceStore.workflow.currentState)
  }

  getDirection(): any {
    return cloneDeep(this.workspaceStore.workflow.direction)
  }

  getTitle() {
    if (this.getState().state !== WorkflowStatesConstant.ERROR.STATE) {
      const state = this.getState()
      return this.languageFactory.get(state.titleKey)
    } else {
      return this.languageFactory.get(OpenLanguageKeysConstant.OPEN_ERROR_HEADER)
    }
  }

  next() {
    this.workspaceStore.workflow.transitioning = true
    this.workspaceStore.workflow.direction = WorkflowConstant.NEXT
    return this.setState(this.getState().nextStateId)
      .then(() => {
        return this.move()
      })
      .finally(() => {
        this.workspaceStore.workflow.transitioning = false
      })
  }

  previous() {
    this.workspaceStore.workflow.transitioning = true
    this.workspaceStore.workflow.direction = WorkflowConstant.PREVIOUS
    return this.trackerFactory
      .get(this.workspaceStore.workspaceUUID, this.workspaceStore.trackerUUID)
      .then((response: any) => {
        response.previousState.splice(-1, 1)
        this.trackerFactory.put(this.workspaceStore.workspaceUUID, this.workspaceStore.trackerUUID, {
          activeApplicantId: response.activeApplicantId,
          previousState: response.previousState
        })

        const stateToGoTo = response.previousState[response.previousState.length - 1]
        return this.setState(stateToGoTo).then(() => {
          return this.move()
        })
      })
      .finally(() => {
        this.workspaceStore.workflow.transitioning = false
      })
  }

  getTransitioning() {
    return this.workspaceStore.workflow.transitioning
  }

  move() {
    return this.routerService
      .push({
        name: WorkflowStatesConstant.INTERIM.STATE,
        params: {workspaceUUID: this.workspaceStore.workspaceUUID},
        query: {trackerUUID: this.workspaceStore.trackerUUID}
      })
      .then(() => {
        return this.routerService.push({
          name: this.getState().state,
          params: {workspaceUUID: this.workspaceStore.workspaceUUID},
          query: {trackerUUID: this.workspaceStore.trackerUUID}
        })
      })
      .then(() => {
        this.updateTitle()
      })
      .finally(() => {
        this.workspaceStore.workflow.transitioning = false
      })
  }

  /**Not to be called from any of the code. This is created to support direct navigate from console. */
  goDirect(stateName: string) {
    this.workspaceStore.workflow.transitioning = true
    this.workspaceStore.workflow.direction = WorkflowConstant.NEXT
    return this.routerService
      .push({
        name: WorkflowStatesConstant.INTERIM.STATE,
        params: {workspaceUUID: this.workspaceStore.workspaceUUID},
        query: {trackerUUID: this.workspaceStore.trackerUUID}
      })
      .then(() => {
        return this.routerService.push({
          name: stateName,
          params: {workspaceUUID: this.workspaceStore.workspaceUUID},
          query: {trackerUUID: this.workspaceStore.trackerUUID}
        })
      })
      .then(() => {
        this.updateTitle()
      })
      .finally(() => {
        this.workspaceStore.workflow.transitioning = false
      })
  }

  init() {
    this.routerService.push({
      name: this.workspaceStore.workflow.currentState.state,
      replace: true,
      params: this.routerService.currentRoute.value.params,
      query: this.routerService.currentRoute.value.query
    })
    this.updateTitle()
    this.setEndPreloadingStatus()
    return this.workspaceStore.workflow.currentState
  }

  setBeginPreloadingStatus() {
    this.workspaceStore.transitionInfo.preloading = true
  }
  setEndPreloadingStatus() {
    this.workspaceStore.transitionInfo.preloading = false
  }
  getPreloadingStatus() {
    return this.workspaceStore.transitionInfo.preloading
  }

  moveToStateById(stateId: string, dir: string) {
    this.workspaceStore.workflow.direction = dir
    this.workspaceStore.workflow.transitioning = true
    return this.setState(stateId).then(() => {
      return this.move()
    })
  }

  updateTitle() {
    Promise.all([
      this.languageFactory.get(OpenLanguageKeysConstant.WEBPAGE_TITLE),
      this.languageFactory.get(this.workspaceStore.workflow.currentState.titleKey)
    ]).then((response) => {
      document.title = `${response[0]} ${response[1] ? ` | ${response[1]}` : ''}`
    })
  }

  navigateToRuleResponseDecision(ruleResponse: string) {
    const nextStates = this.getState()
    const decisions = nextStates.decisions
    const nextState = decisions.filter((decision: {display: string}) => {
      return decision.display === ruleResponse
    })[0]

    if (nextState) {
      this.moveToStateById(nextState.nextStateId, WorkflowConstant.FORWORD)
    } else {
      this.logService.error(FACTORY_MSG.WORKFLOW.FAILED_NAVIGATE_TO_RULE_DECISION + ruleResponse + '"')
      this.routerService.push({name: WorkflowStatesConstant.ERROR.STATE})
    }

    return ruleResponse
  }

  resetRedirectTo() {
    this.workspaceMetadataStore.onErrorRedirectTo = ''
  }

  setLoadingStatus(flag: boolean) {
    this.workspaceMetadataStore.wizardProps.loading = flag
  }
}
export default WorkflowFactory

export type Workflow = {
  createdTime: string
  displayName: string
  displayNameKey: string
  locked: false
  startWorkflowCode: string
  startWorkflowStateId: number
  updatedTime: string
  workflowName: string
  x: 0
  y: 0
}
