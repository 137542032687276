const APP_NAMES = Object.freeze({
  launchpad: {
    description: "Launchpad",
    id: "appearance_launchpad"
  },
  boltsdevice: {
    description: "Device Manager",
    id: "appearance_device"
  },
  boltsmanage: {
    description: "Management Console",
    id: "appearance_manage"
  },
  boltsopen: {
    description: "Applicant Channel",
    id: "appearance_applicant_channel"
  },
  boltsbranch: {
    description: "Employee Channel",
    id: "appearance_employee_channel"
  },
  dashboard: {
    description: "Dashboard",
    id: "appearance_dashboard"
  }
});

export default APP_NAMES;
