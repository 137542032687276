import { injectable, inject } from "inversify";
import ServiceUrlFactory from "@/common/services/Service/ServiceUrlFactory/ServiceUrlFactory";
import DEPENDENCYTYPES from "../dependency.types";
import type IWorkspaceMetadataStore from "./Workspace/IWorkspaceMetadataStore";

@injectable()
class ConfigService {
  constructor(@inject(ServiceUrlFactory) private serviceUrlFactory: ServiceUrlFactory,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private store: IWorkspaceMetadataStore) { }

  public async bootstrap() {
    this.store.serviceUrls = await this.serviceUrlFactory.getAll();
    this.store.serviceUrls["none"] = "/v1";
    this.store.serviceUrls["launchpad"] = "/v1/launchpadREST"
  }
}
export default ConfigService;
