import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ENROLLMENT_DISCLOSURE_TYPES from "@/common/enums/enrollmentDisclosureTypesEnum";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentApplicantDisclosureResourceFactory {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

   getPackage(uuid: string, enrollmentId: number, applicantId: number) {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant/${applicantId}/disclosure`, {}, ResponseTypes.Payload)
            .then((response) => {
                return {
                    type: ENROLLMENT_DISCLOSURE_TYPES.ENROLLMENT_APPLICANT,
                    id: applicantId,
                    disclosures: response
                }
            });
    }
}
export default EnrollmentApplicantDisclosureResourceFactory;
