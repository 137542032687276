import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class EnrollmentStatusFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  close(workspaceUUID: string, enrollmentId: number) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/status/COMPLETE`,
      {},
      {},
      ResponseTypes.Data
    );
  }
}
export default EnrollmentStatusFactory;
