<script lang="ts">
import { defineComponent } from "vue";
import WorkspaceConstants from "@/common/constant/WorkspaceConstants";

export default defineComponent({
  name: "bltEmptySearch",
  props: {
    bltText: {
      type: String,
      default: WorkspaceConstants.NO_DATA
    }
  },
  data: () => {
    return {
      WorkspaceConstants
    };
  },
});
</script>

<template>
  <div class="no-search-items-list">
    <div class="search-empty">
      <jha-icon-search class="secondary-fill-color"></jha-icon-search>
    </div>
    <div class="secondary-text font-14" v-html="bltText"></div>
  </div>
</template>

<style scoped>
.no-search-items-list {
  text-align: center;
  height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
}
.secondary-text {
  line-height: 21px;
}
.search-empty {
  line-height: 0;
  padding-bottom: 8px;
}
</style>
