import { injectable, inject } from "inversify";
import FullNameFilter from "@/common/services/filters/fullNameFilter";
import OPERATION_TYPES from "@/common/enums/operationTypeEnum";

@injectable()
class SearchTitleFilter {
  constructor(@inject(FullNameFilter) private fullNameFilter: FullNameFilter) { }

  searchTitle(searchItem: Record<string, unknown>) {
    if (searchItem) {
      if (searchItem.workspaceType) {
        return "Workspace: " + searchItem.workspaceUUID;
      } else if (searchItem.applicantType || searchItem.coreId || searchItem.businessName) {
        return searchItem.businessName || this.fullNameFilter.fullName(searchItem);
      }
    } else {
      return OPERATION_TYPES.NEW_APPLICANT;
    }
  }
}

export default SearchTitleFilter;