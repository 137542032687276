import ResponseTypes from "@/common/enums/responseTypesEnum";
import type HttpHandler from "@/common/services/connect/HttpHandler";
import { isUndefined } from "lodash";
import type IWorkspace from "@/common/models/IWorkspace";

export class Lookup {
  urlKey: string;
  url: string;
  interpolatedService: string;
  baseUrl: string;
  httpHandler: HttpHandler;

  constructor(url: string, urlKey: string, serviceKey: string, httpHandler: HttpHandler) {
    this.urlKey = urlKey;
    this.interpolatedService = "{{" + serviceKey + "}}";
    this.url = url;
    this.baseUrl = this.interpolatedService + this.url;
    this.httpHandler = httpHandler;
  }

  getEndpointKey(): string {
    return this.urlKey;
  }

  get = () => {
    return this.httpHandler.get(this.baseUrl, {}, ResponseTypes.Payload, true);
  };

  _suffixPathsToBaseUrl = (paths: any[]) => {
    if (!isUndefined(paths)) {
      let url = this.interpolatedService;
      //append each key and value to the url
      for (const key in paths) {
        url += "/" + key + "/" + paths[key];
      }
      return url + this.url;
    }
    return this.baseUrl;
  };

  getAll = (params?: any, options?: any, paths?: any): any => {
    const cacheOption = isUndefined(options) || isUndefined(options.cache) ? true : options.cache;
    const url = this._suffixPathsToBaseUrl(paths);
    return this.httpHandler.get(url, { params: params || {}, ...(options || {}) }, ResponseTypes.Payload, cacheOption);
  };

  getAllFlat = (flatKey: string, options: any) => {
    return this.getAll(options).then(function (response: any) {
      return response.reduce(function (col: { [x: string]: any }, value: { [x: string]: any }) {
        col[value[flatKey]] = value;
        delete value[flatKey];
        return col;
      }, {});
    });
  };

  getOne = (typeCode: string) => {
    return this.httpHandler.get(this.baseUrl + "/" + typeCode, {}, ResponseTypes.Payload, true);
  };

  search = (typeDescription: any, options: any) => {
    return this.httpHandler.get(
      this.baseUrl,
      {
        params: { typeDescription: typeDescription || "", limit: 30, ...(options || {}) }
      },
      ResponseTypes.Payload,
      true
    );
  };
}
