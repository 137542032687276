
class Base64Image {
    utils: Base64Utils;
    constructor(public imageDataBase64: string, public imageFormat: string) {
        this.utils = new Base64Utils();
    }
    get encodedBase64() {
        return this.imageDataBase64
            ? this.utils.prefixBase64(this.imageDataBase64, this.imageFormat)
            : null;
    }
    set encodedBase64(value) {
        this.imageFormat = this.utils.getBase64Extension(value);
        this.imageDataBase64 = this.utils.getBase64Data(value);
    }
}

class Base64Utils {
    /**
   * @name prefixBase64
   * @methodOf e2e.factory:Base64Factory
   * @description
   * This method accepts base64 data and a file extension and parses them together into prefixed Base64 ready for
   *     use in browser
   *
   * @param {String} b64 - Raw Base64 data.
   *
   * @param {String} extension - the file extension of the base64 data.
   */
    prefixBase64(b64: string, extension: string) {
        return "data:image/" + extension.toLowerCase() + ";base64," + b64;
    }

    /**
     * @name getBase64Extension
     * @methodOf e2e.factory:Base64Factory
     * @description
     * This method accepts a prefixed Base64 string and returns the capitalized file extension type of the Base64
     *     encoded image.
     *
     * @param {String} b64 - A prefixed Base64 string.
     */
    getBase64Extension(b64: string) {
        return b64.split("/")[1].split(";")[0].toUpperCase();
    }

    /**
     * @name getBase64Data
     * @methodOf e2e.factory:Base64Factory
     * @description
     * This method accepts a prefixed Base64 string and strips the prefix, leaving only the Base64 data.
     *
     * @param {String} b64 - A prefixed Base64 string.
     */
    getBase64Data(b64: string) {
        return b64.split(",")[1];
    }
}

export { Base64Image, Base64Utils };