import { injectable } from "inversify";

@injectable()
class TimeoutService {
  timeoutPromise(delay: number | undefined) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  timeoutPromiseX(delay: number | undefined) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  }
}

export default TimeoutService;
