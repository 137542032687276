const MEDIA_TYPE = Object.freeze({
  JSON: "application/json;charset=utf-8",
  CSV: "application/csv",
  ZIP: "application/zip",
  MULTIPART_FORM_DATA: 'multipart/form-data',
  OCTET_STREAM: 'octet/stream',
  APPLICATION_JSON: "application/json",
  TEXT_CSV: 'text/csv'
});

export default MEDIA_TYPE;
