import { cloneDeep, isUndefined, merge, isEmpty } from "lodash";
import Base64Factory from "@/common/services/utils/Base64Factory";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { EnrollmentConstant } from "@/common/constant/EnrollmentConstant";
import type {
  IAccountService,
  IAccount,
  ICompatibleAccount, IApplicantService,
} from "@/common/models/IService";
@injectable()
class EnrollmentServiceFactory {
  constructor(
    @inject(Base64Factory) private base64Factory: Base64Factory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  autoAddServices(workspaceUUID: string, enrollmentId: number) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/autoaccountservice`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  getExistingApplicantServices(workspaceUUID: string, enrollmentId: number, applicantId: number, serviceCode: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/existing/accountservice/${serviceCode}`,
      {},
      ResponseTypes.Payload
    );
  }

  getAvailableApplicantServices(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/accountservice?show=available`,
      {},
      ResponseTypes.Payload
    );
  }

  getApplicantServices(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/accountservice`,
      {},
      ResponseTypes.Payload
    );
  }

  postAccountServiceAccount(
    workspaceUUID: string,
    enrollmentId: number,
    serviceId: number | null,
    accountId: number,
    message: any
  ): Promise<IAccountService>  {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}/account/${accountId}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  putAccountServiceAccount(workspaceUUID: string, enrollmentId: number, serviceId: number, message: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  getAccountService(workspaceUUID: string, enrollmentId: number, applicantId: number, serviceCode: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/accountservice/${serviceCode}`,
      {},
      ResponseTypes.Payload
    );
  }

  postAccountService(workspaceUUID: string, enrollmentId: number, applicantId: number, message: any): Promise<IApplicantService> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/accountservice`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  putAccountService(workspaceUUID: string, enrollmentId: number, serviceId: number, message: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  getAccountServiceById(workspaceUUID: string, enrollmentId: number, serviceId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}`,
      {},
      ResponseTypes.Payload
    );
  }

  //todo(mikol): this is a quick fix for getting 1.14 out the door, we should follow this up with a cleanup...
  async clearADBTServiceImage(
    workspaceUUID: string,
    enrollmentId: number,
    serviceDTO: { accountServiceCode: string; accountServiceId: number }
  ) {
    if (serviceDTO.accountServiceCode !== EnrollmentConstant.ENROLLMENT_ACCOUNT_SERVICE_CODE) {
      return Promise.resolve();
    } else {
      const response = await this.getAccountServiceById(workspaceUUID, enrollmentId, serviceDTO.accountServiceId);
      response.imageId = null;
      return this.putAccountService(workspaceUUID, enrollmentId, serviceDTO.accountServiceId, response);
    }
  }

  deleteAccountServiceAccount(
    workspaceUUID: string,
    enrollmentId: number,
    serviceId: number | null,
    accountId: number
  ): Promise<IAccountService> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}/account/${accountId}`,
      {},
      ResponseTypes.Payload
    );
  }

  deleteAccountService(workspaceUUID: string, enrollmentId: number, serviceId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${serviceId}`,
      {},
      ResponseTypes.Data
    );
  }

  hasService(applicantServices: { accountServiceCode: string }[], serviceCode: string) {
    return applicantServices.some((service: { accountServiceCode: string }) => {
      return serviceCode === service.accountServiceCode;
    });
  }

  getApplicantServiceByServiceCode(applicantServices: { accountServiceCode: string }[], accountServiceCode: string) {
    return applicantServices.filter((service: { accountServiceCode: string }) => {
      return accountServiceCode === service.accountServiceCode;
    })[0];
  }

  getServiceIdFromServiceCode(
    accountServices: { accountServiceCode: string; accountServiceId: number }[] | undefined,
    parentService: { accountServiceCode: string } | undefined
  ) {
    if (!isUndefined(accountServices) && !isUndefined(parentService)) {
      return accountServices.filter((service: { accountServiceCode: string }) => {
        return service.accountServiceCode === parentService.accountServiceCode;
      })[0].accountServiceId;
    } else {
      return null;
    }
  }

  getServiceByAccountServiceCode(availableServices: any[], accountServiceCode: string) {
    return availableServices.filter((service: { accountServiceCode: string }) => {
      return service.accountServiceCode == accountServiceCode;
    })[0];
  }

  moveAccountsToCompatibleAccounts(availableServices: any[]) {
    return availableServices.map((availableService) => {
      if (!isUndefined(availableService.accounts)) {
        availableService.accounts.forEach((account: { added: boolean }) => {
          account.added = true;
          availableService.compatibleAccounts.push(account);
        });
      }
      return availableService;
    });
  }

  moveUniqueAccountsToCompatibleAccounts(availableServices: IAccountService[]) {
    return availableServices.map((availableService) => {
      if (!isEmpty(availableService.accounts)) {
        availableService.accounts.forEach((account: IAccount) => {
          const index = (availableService.compatibleAccounts || []).findIndex((compatibleAccount: ICompatibleAccount)=> compatibleAccount.accountId === account.accountId)
          account.added = true;
          if(index === -1) availableService.compatibleAccounts.push(account);
        });
      }
      return availableService;
    });
  }

  getProductList(
    workspaceUUID: string,
    enrollmentId: number,
    addedServices: { productCode: string | undefined; imageId: string; accountServiceId: number }[]
  ) {
    return Promise.all(
      addedServices.map((addedService) => {
        return addedService.productCode
          ? this.getProduct(workspaceUUID, enrollmentId, addedService).then((response) => {
              //If you already have an imageId, do not over-write it with the default one.
              if (addedService.imageId) {
                delete response[0].imageId;
              }
              const result = response.filter((product: { productCode: string }) => {
                return product.productCode === addedService.productCode;
              });
              const returnValue = cloneDeep(addedService);
              return merge(returnValue, result.length ? result[0] : {});
            })
          : Promise.resolve(addedService);
      })
    );
  }

  getProduct(workspaceUUID: string, enrollmentId: number, addedService: { accountServiceId: number }) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${addedService.accountServiceId}/product`,
      {},
      ResponseTypes.Payload
    );
  }

  getProductImageId(workspaceUUID: string, enrollmentID: number, accountServiceId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentID}/accountservice/${accountServiceId}/images`,
      {
        params: {
          hideThumbs: true
        }
      },
      ResponseTypes.Payload
    );
  }

  async getProductThumbnail(workspaceUUID: string, enrollmentId: number, accountServiceId: number, imageId: number) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${accountServiceId}/image/${imageId}`,
      {
        params: {
          thumbOnly: true
        }
      },
      ResponseTypes.Payload,
      true
    );
    return this.base64Factory.getFromUnencoded(response.thumbImageBase64, response.imageType);
  }

  async getProductImageHQ(
    workspaceUUID: string,
    enrollmentId: number,
    addedService: { accountServiceId: number },
    imageId: number
  ) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/accountservice/${addedService.accountServiceId}/image/${imageId}`,
      {},
      ResponseTypes.Payload,
      true
    );
    return this.base64Factory.getFromUnencoded(response.imageBase64, response.imageType);
  }
}
export default EnrollmentServiceFactory;
