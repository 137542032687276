import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import type ApplicantBusiness from "@/common/data/ApplicantBusiness";
import type BusinessTaxId from "@/common/data/BusinessTaxId";
import type ApplicantBusinessValidity from "@/common/data/ApplicantBusinessValidity";
import type IApplicant from "@/common/models/IApplicant";
import type IBusiness from "@/common/models/IBusiness";

@injectable()
class ApplicantBusinessFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  //Set the applicant Business with UUID and Applicant Id
  async set(workspaceUUID: string, applicant: IApplicant): Promise<IApplicant> {
    const response = await this.get(workspaceUUID, applicant.applicantId);
    applicant.business = response;
    return applicant;
  }

  //Get the applicant Business with UUID and Applicant Id
  get(workspaceUUID: string, applicantId: number): Promise<IBusiness> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/business`,
      {},
      ResponseTypes.Payload
    );
  }
  // Update the appplicant Business with UUID, Applicant id and Business details
  async put(workspaceUUID: string, applicantId: number, business: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/business`,
      business,
      {},
      ResponseTypes.Payload
    );
  }
  // Post the applicant Business with UUID, Applicant id and Business details
  async post(workspaceUUID: string, applicantId: number, business: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/business`,
      business,
      {},
      ResponseTypes.Payload
    );
  }

  // Save the applicant details with UUID, Applicant id and Business
  save(workspaceUUID: string, applicantId: number, business: any) {
    return business.applicantBusinessId
      ? this.put(workspaceUUID, applicantId, business)
      : this.post(workspaceUUID, applicantId, business);
  }

  isTheTaxIdValid(business: ApplicantBusiness, taxIdType: BusinessTaxId, businessValidity: ApplicantBusinessValidity): boolean {
    /* Nothing will show up on screen, always valid */
    if (!taxIdType.ssn && !taxIdType.ein) return true

    /* You need to at least select a type */
    if (!business.taxIdType) return false


    if (taxIdType.ssn && business.taxIdType == "SSN") {
      return (!!business.taxId || taxIdType.ssnNotRequired) && businessValidity.taxIdSSNValid
    }

    if (taxIdType.ein && business.taxIdType == "EIN") {
      return (!!business.taxId || taxIdType.einNotRequired) && businessValidity.taxIdEINValid
    }

    return false
  }
}
export default ApplicantBusinessFactory;
