import type { App } from "vue";
import * as directives from "./appch.directives.module";

export function useAllDirectives(app: App) {
    //example: app.directive('bltUiText', bltUiText);
    for (const directiveKey of Object.keys(directives)) {
        const directive = directives[directiveKey as keyof typeof directives];

        app.directive(directiveKey, directive);
    }
}