import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import type RestResponse from '@/common/data/RestResponse'

@injectable()
export default class QuestionTypeFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(): Promise<RestResponse<Array<QuestionType>>> {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/cdd/question/type`)
  }
}

export const enum QuestionTypeCodes {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTI_CHOICE = "MULTI_CHOICE",
  SINGLE_CHOICE_LOOKUP = "SINGLE_CHOICE_LOOKUP",
  MULTI_CHOICE_LOOKUP = "MULTI_CHOICE_LOOKUP",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  MONEY = "MONEY"
}

export type QuestionType = {
  typeCode: (typeof QuestionTypeCodes)[keyof typeof QuestionTypeCodes]
  typeDescription: string
}