import { inject, injectable } from "inversify";
import Base64Factory from "@/common/services/utils/Base64Factory";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import DEPENDENCYTYPES from "@/common/dependency.types";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";

@injectable()
class BltSignatureFactory {
  constructor(
    @inject(Base64Factory) private base64Factory: Base64Factory,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(BroadcastService) private broadcastService: BroadcastService
  ) {}

  resizeCanvas(elem: { css: (arg0: string, arg1: string) => void; attr: (arg0: string, arg1: number) => void; }) {
    let width, height;

    if (window.innerWidth > window.innerHeight) {
      // Landscape
      height = window.innerHeight * 0.8 - 116;
      width = height * 2.8;
    } else {
      // Portrait
      width = window.innerWidth;
      height = width / 2.8;
    }

    height = Math.min(height, 250);
    width = Math.min(width, 700);

    elem.css("width", width + "px");
    elem.css("height", height + "px");
    elem.attr("width", width);
    elem.attr("height", height);
  }

  collectSignature(ev: any, title: string) {
    window.scrollTo(0, 0);
    this.workspaceMetadataStore.signatureFormTitle = title;
    this.workspaceMetadataStore.isExistingSignature = false;
  }

  getTitle() {
    return this.workspaceMetadataStore.signatureFormTitle;
  }

  getIsExistingSignature() {
    return this.workspaceMetadataStore.isExistingSignature;
  }

  setIsExistingSignature(isExisting: boolean) {
    this.workspaceMetadataStore.isExistingSignature = isExisting;
  }

  setBase64(base: string) {
    this.workspaceMetadataStore.signatureBase64 = base;
  }

  getBase64() {
    return this.workspaceMetadataStore.signatureBase64;
  }

  BltSignatureCtrl() {
    const title = this.getTitle();

    const answer = () => {
      const response = {
        originalBase64: this.getBase64(),
        strippedBase64: this.base64Factory.getBase64Data(this.getBase64()),
        containsSignature: this.getIsExistingSignature(),
        extension: this.base64Factory.getBase64Extension(this.getBase64())
      };

    };

    const cancel = () => {
      console.log();
    };

    const reset = () => {
      this.broadcastService.broadcast(BroadcastConstant.BLT_SIGNATURE_RESET);
    };
  }
}

export default BltSignatureFactory;
