<script lang="ts">
import "@banno/jha-wc/src/icons/jha-icon-information.js";
import "@banno/jha-wc/src/icons/jha-icon-warning.js";
import "@banno/jha-wc/src/jha-error-message/jha-error-message.js";
import "@banno/jha-wc/src/icons/jha-icon-information.js";

import { defineComponent } from "vue";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import type { message } from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import { $injByInterface } from "@/common/decorators/depinject";

export default defineComponent({
  name: "bltNotification",
  props: {
    messages: { type: Array<message> }
  },
  data() {
    return { NotificationConstants };
  },
  setup() {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return {
      workspaceStore
    };
  },
  computed: {
    warnings() {
      return this.messages?.filter((m) => m.type === NotificationConstants.WARN);
    },
    errors() {
      return this.messages?.filter((m) => m.type === NotificationConstants.ERROR);
    },
    infos() {
      return this.messages?.filter((m) => m.type === NotificationConstants.INFO);
    },
    isValidatePlaidState() {
      return this.workspaceStore.workflow.currentState.state === WorkflowStatesConstant.VALIDATE_PLAID.STATE;
    }
  }
});
</script>
<template>
  <jha-error-message class="notification" v-if="errors?.length">
    <div v-for="(message, index) in errors" :key="index" class="error-div">{{ message.message }}</div>
  </jha-error-message>

  <jha-error-message class="information notification" v-if="infos?.length">
    <div class="error-div" v-for="(message, index) in infos" :key="index">
      <div class="d-flex center" :class="{ 'error-icon-top': isValidatePlaidState }">
        <div>
          <jha-icon-information class="icon-align icon"></jha-icon-information>
        </div>
        <div>{{ message.message }}</div>
      </div>
    </div>
  </jha-error-message>

  <jha-error-message class="information notification" v-if="warnings?.length">
    <div class="error-div" v-for="(message, index) in warnings" :key="index">
      <div class="d-flex center" :class="{ 'error-icon-top': isValidatePlaidState }">
        <div>
          <jha-icon-warning class="icon-align icon"></jha-icon-warning>
        </div>
        <div>{{ message.message }}</div>
      </div>
    </div>
  </jha-error-message>
</template>
<style scoped>
.icon-align {
  padding-right: var(--jh-space-400);
}
</style>
