
 /**
   * @deprecated
   * RU Service is not used
   */

import type { AxiosResponse } from "axios";

import { inject, injectable } from 'inversify';
import HttpHandler from "@/common/services/connect/HttpHandler";

@injectable()
class RU {
  constructor(public httpHandler: HttpHandler) { }

  public checkResponse(response: AxiosResponse): any {
    if (undefined !== response && undefined !== response.data && undefined !== response.data.payload) {
      return response.data.payload;
    } else {
      if (undefined !== response && undefined !== response.data) {
        return response.data;
      } else {
        if (undefined !== response) {
          throw new Error(JSON.stringify(response));  
        } else {
          console.error("Attempted to reject response, but response was undefined.");
          throw new Error("Attempted to reject response, but response was undefined.");
        }
      }
    }
  }

  public throwError(error: any) {
    if (error) {
      throw error;
    } else {
      throw new Error("Promise rejected with an undefined response.");
    }
  }

  async request(
    restType: string,
    serviceTypeCode: string,
    url: string,
    expectLevel?: any,
    postBody?: any,
    headerKey?: any,
    headerValue?: any
  ) {
    const header: any = {};
    if (headerKey && headerValue) {
      header[headerKey] = headerValue;
    }
    const finalUrl: string = "{{" + serviceTypeCode + "}}" + url;
    switch (restType) {
      case "getWithHeader":
        try {
          return this.checkResponse(await this.httpHandler.get(finalUrl, { headers: header }));
        } catch (error) {
          this.throwError(error);
        }
        break;
      case "get":
        try {
          return this.checkResponse(await this.httpHandler.get(finalUrl));
        } catch (error) {
          this.throwError(error);
        }
        break;
      case "postWithHeader":
        try {
          return this.checkResponse(await this.httpHandler.post(finalUrl, postBody, { headers: header }));
        } catch (error) {
          this.throwError(error);
        }
        break;
      case "post":
        try {
          return this.checkResponse(await this.httpHandler.post(finalUrl, postBody));
        } catch (error) {
          this.throwError(error);
        }
        break;
      case "put":
        try {
          return this.checkResponse(await this.httpHandler.put(finalUrl, postBody));
        } catch (error) {
          this.throwError(error);
        }
        break;
      case "delete":
        try {
          return this.checkResponse(await this.httpHandler.delete(finalUrl));
        } catch (error) {
          this.throwError(error);
        }
        break;
      default:
        throw new Error("Unhandled type: method=" + restType);
    }
  }
}
export default RU;
