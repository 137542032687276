import ApplicantSuggestionFactory from "@/common/services/Applicant/applicantSuggestionFactory";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import APPLICANT_DOCUMENT_TYPES from "@/common/constant/applicantDocumentTypesConstant";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import MEDIA_TYPE from "@/common/enums/mediaTypeEnum";

@injectable()
class ApplicantDocumentFactory {
  constructor(
    @inject(ApplicantSuggestionFactory) private applicantSuggestionFactory: ApplicantSuggestionFactory,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  /**
   * @deprecated
   * Use this.applicantSuggestionFactory.mergeRequiredTypes
   */
  getRequired(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/suggestdocuments`,
      {},
      ResponseTypes.Payload
    );
  }

  get(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/${APPLICANT_DOCUMENT_TYPES.DOCUMENT}`,
      {},
      ResponseTypes.Payload
    );
  }

  delete(workspaceUUID: string, applicantId: number, document: any) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/${APPLICANT_DOCUMENT_TYPES.DOCUMENT}/${document.documentId}`,
      {
        params: { documentId: document.documentId }
      },
      ResponseTypes.Payload
    );
  }

  getDocumentList(workspaceUUID: string, applicantId: number) {
    return Promise.all([
      this.applicantSuggestionFactory.get(workspaceUUID, applicantId, APPLICANT_DOCUMENT_TYPES.DOCUMENT),
      this.get(workspaceUUID, applicantId),
      this.lookupFactory.Document?.getAll({enabled: true})
    ]).then((response) => {
      let suggestedDocuments = response[0] || [];
      const existingDocuments = response[1] || [],
        documentTypes = response[2] || [];
      suggestedDocuments = this.parseSuggestedDocuments(suggestedDocuments, documentTypes);
      suggestedDocuments = this.removeDocumentsThatAreExistingAndSuggested(existingDocuments, suggestedDocuments);
      return suggestedDocuments.concat(existingDocuments);
    });
  }

  // /suggest?type=document returns typeCodes, so we need to map them with /type/document to get descriptions.
  parseSuggestedDocuments(suggestedDocuments: any, documentTypes: any) {
    return suggestedDocuments.reduce((acc: any, typeCode: any) => {
      const searchResult = documentTypes.filter((document: any) => {
        return document.typeCode === typeCode;
      });

      if (searchResult.length) {
        acc.push({
          typeCode: searchResult[0].typeCode,
          description: searchResult[0].typeDescription
        });
      }
      return acc;
    }, []);
  }

  removeDocumentsThatAreExistingAndSuggested(existingDocuments: any, suggestedDocuments: any) {
    return suggestedDocuments.filter((suggestedDocument: any) => {
      return !existingDocuments.some((document: any) => {
        return document.description === suggestedDocument.description;
      });
    });
  }

  post(workspaceUUID: string, applicantId: number, file: any, document: any) {
    document.sending = true;
    const formdata = new FormData();
    formdata.append(APPLICANT_DOCUMENT_TYPES.DOCUMENT, file);

    return this.httpHandler
      .post(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/${APPLICANT_DOCUMENT_TYPES.DOCUMENT}`,
        formdata,
        {
          params: {
            documentType: document.typeCode,
            description: document.description || document.typeDescription
          },
          headers: { "Content-Type": MEDIA_TYPE.MULTIPART_FORM_DATA },
          onUploadProgress: async (e: any) => {
            document.progress = Math.round((e.loaded * 100) / e.total);
          }
        },
        ResponseTypes.Payload
      )
      .finally(() => {
        document.sending = false;
      });
  }

  async download(workspaceUUID: string, applicantId: number, doc: any) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/
          ${workspaceUUID}/applicant/
          ${applicantId}/${APPLICANT_DOCUMENT_TYPES.DOCUMENT}/${doc.documentId}`,
      { responseType: "arraybuffer" }
    );

    const a = document.createElement("a");
    const type = response.headers.getContentType()?.toString()

    //Below one is replacement for a.style = "display: none";
    a.setAttribute("style", "display: none");
    document.body.appendChild(a);
    const blob = new Blob([response.data], { type: type ?? MEDIA_TYPE.OCTET_STREAM }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = doc.filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}

export default ApplicantDocumentFactory;
