import { injectable, inject } from "inversify";
import Poller from "@/common/services/utils/Poller"
import { PollerConstant } from "@/common/constant/PollerConstant";

@injectable()
class PollingService {
    constructor(
        @inject(Poller) private poller: Poller<unknown>,
    ) { }

    async awaitPredicate(intervalMs: number, timeoutMs: number, pollingFn: () => Promise<any>): Promise<any> {
        return await new Promise((resolve, reject) => {
            const poller = new Poller(intervalMs, pollingFn);
            setTimeout(() => {
                poller.stop();
                reject({ msg: PollerConstant.POLLER_SETTIMEOUT_REJECT_MSG });
            }, timeoutMs);

            function onReject(err: any) {
                poller.stop();
                reject({ msg: PollerConstant.POLLER_ONREJECT_REJECT_MSG, err });
            }

            function onResult(res: boolean) {
                if (res === true) {
                    poller.stop();
                    resolve(res);
                }
            }
            poller.subscribe(onResult, onReject);
            poller.start();

        });

    }

}
export default PollingService;