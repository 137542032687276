export class CipConstant {
  static readonly APP_PENDING_REVIEW_HEADER: string = "Applicant pending review";
  static readonly UNABLE_To_VERIFY_APPLICANT_HEADER: string = "Unable to verify Applicant ";
  static readonly CONTINUE_BUTTON: string = "Continue without Applicant";
  static readonly APP_VERIFICATION: string = "Verifying applicant information...";
  static readonly STILL_APP_VERIFICATION: string = "Still verifying applicant information...";

  static readonly OPEN_CIP_PENDING_CONTENT: string = "open_cip_modal_pending_content";
  static readonly OPEN_CIP_PENDING_ACCEPT_BUTTON: string = "open_cip_modal_pending_accept_button";
  static readonly OPEN_CIP_PENDING_TITLE: string = "open_cip_modal_pending_title";

  static readonly OPEN_CIP_FAILED_TITLE: string = "open_cip_modal_failed_title";
  static readonly OPEN_CIP_FAILED_CONTENT: string = "open_cip_modal_failed_content";
  static readonly OPEN_CIP_FAILED_ACCEPT_BUTTON: string = "open_cip_modal_pending_accept_button";

  static readonly OPEN_CIP_REMOVE__APP_TITLE: string = "open_cip_remove_applicant_title";
  static readonly OPEN_CIP_REMOVE_APP_CONTENT: string = "open_cip_remove_applicant_content";
  static readonly OPEN_CIP_REMOVE_APP_CONTINUE: string = "open_cip_remove_applicant_continue";
  static readonly OPEN_CIP_REMOVE_APP_STOP: string = "open_cip_remove_applicant_stop";

  static readonly PROMPT_REMOVE_APPLICANT: string = "PROMPT_REMOVE_APPLICANT";
  static readonly PROMPT_PENDING_REVIEW: string = "PROMPT_PENDING_REVIEW";

  static readonly AUTH: string = "AUTH";
  static readonly RISK: string = "RISK";
  static readonly DELIVER_NOA: string = "DELIVER_NOA";
  static readonly SERVER_ERROR: string = "SERVER_ERROR";
  static readonly FAILED_CIP: string = "FAILED_CIP";
  static readonly FAILED_CIP_REMOVE_MSG: string = "(Failed CIP, must be removed)";
  static readonly FAILED_CIP_MSG: string = "(Failed CIP)";
  static readonly REDFLAG: string = "REDFLAG";

  static readonly REJECT: string = "REJECT";
  static readonly ACCEPT: string = "ACCEPT";
  static readonly FAILED: string = "FAILED";
  static readonly INPROGRESS: string = "INPROGRESS";
  static readonly INCOMPLETE: string = "INCOMPLETE";
  static readonly CHALLENGE: string = "CHALLENGE";
  static readonly COMPLETED: string = "COMPLETED";
  static readonly PROCESSED: string = "PROCESSED";
  static readonly ENDUSER_NOA_ENABLED_YN: string = "ENDUSER_NOA_ENABLED_YN";
}
