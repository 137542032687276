import { injectable } from "inversify";
import { CipConstant } from "@/common/constant/cipConstant";
import { isString } from "lodash";
import CIP from "@/common/components/cip/cipEnum";
type Details = {
  allowOverride: string;
  userDecision: string;
  decision: string;
  status: string;
};
@injectable()
class CIPFactory {
  getDetailUiStatus(detail: Details) {
    if (detail.allowOverride && isString(detail.userDecision)) {
      return CIP.UI_STATUS.PENDING_REVIEW;
    }
    if (isString(detail.userDecision)) {
      switch (detail.userDecision) {
        case CipConstant.ACCEPT:
          return CIP.UI_STATUS.USER_ACCEPTED;
        case CipConstant.REJECT:
          return CIP.UI_STATUS.USER_REJECTED;
      }
    }
    if (isString(detail.decision)) {
      switch (detail.decision) {
        case CipConstant.ACCEPT:
          return CIP.UI_STATUS.API_ACCEPTED;
        case CipConstant.REJECT:
          return CIP.UI_STATUS.API_REJECTED;
      }
    }
    if (isString(detail.status)) {
      switch (detail.status) {
        case CipConstant.FAILED:
          return CIP.UI_STATUS.FAILED;
        case CipConstant.PROCESSED:
        case CipConstant.INPROGRESS:
        case CipConstant.INCOMPLETE:
          return CIP.UI_STATUS.INPROGRESS;
      }
    }
    return CIP.UI_STATUS.PRISTINE;
  }
}

export default CIPFactory;
