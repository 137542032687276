import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { AlliedFundingFactory } from "@/common/services/services.module";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";

const bltAlliedIframeHook = {
  mounted: (elem: any, binding: { value: string }) => {
    const alliedFundingFactory = $inj(AlliedFundingFactory);
    const broadcastService = $inj(BroadcastService);
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");

    elem.addEventListener("load", function () {
      elem.contentWindow.postMessage("message", binding.value);
    });

    window.addEventListener("message", (event: { data: Record<string, any>; originalEvent?: any }) => {
      const eventObj = event.data ? event : event.originalEvent;

      switch (eventObj.data.type) {
        case "success":
          alliedFundingFactory
            .postAlliedFunding(
              workspaceStore.workspaceUUID,
              workspaceStore.enrollment.enrollmentId,
              workspaceStore.account.accountId,
              eventObj.data.payment
            )
            .then(function () {
              broadcastService.broadcast(BroadcastConstant.ALLIED_SUCCESS);
            });
          break;
        case "cancel":
          broadcastService.broadcast(BroadcastConstant.ALLIED_CANCELLED);

          break;
        case "error":
          broadcastService.broadcast(BroadcastConstant.ALLIED_ERROR);
          break;
      }
    });
  },
  destroyed() {
    window.removeEventListener("message", () => {
      return;
    });
  }
};

export default bltAlliedIframeHook;
