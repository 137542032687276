import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class DocumentFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(documentCode: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/document/${documentCode}`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default DocumentFactory;
