import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

// NOTE: generateForm method and submit method have been REMOVED from this factory
// and implemented as /open/components/BannoEnrollmentForm.vue, submit method of this component to be used everywhere
@injectable()
class BannoEnrollmentFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async setEnrolled(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/enroll`,
      {},
      {},
      ResponseTypes.Payload
    );
  }
}
export default BannoEnrollmentFactory;
