import HttpHandler from '@/common/services/connect/HttpHandler';
import { inject, injectable } from 'inversify';
import merge from 'lodash.merge';
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants';
import ResponseTypes from '@/common/enums/responseTypesEnum';

@injectable()
class AlliedFundingFactory {

    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    async getAlliedFunding(uuid: string, enrollmentId: number, accountId: number) {
        return await this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/funding/ALLIED`, {}, ResponseTypes.Payload);
    }

    async getAccountListAlliedFunding(uuid: string, enrollmentId: number, accountList: Array<any>) {
        return Promise.all(accountList.map(async (account) => {
            const alliedFunding = await this.getAlliedFunding(uuid, enrollmentId, account.accountId)
            const mergeAccountAlliedFunding = merge(account, alliedFunding);
            return mergeAccountAlliedFunding;
        }))

    }

    async postAlliedFunding(uuid: string, enrollmentId: number, accountId: number, payment: any) {
        return await this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/account/${accountId}/funding/ALLIED/${payment.id}`, payment, {}, ResponseTypes.Payload);
    }

}
export default AlliedFundingFactory;