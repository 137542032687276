<script lang="ts">
import { defineComponent } from "vue";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { CommonService, ToastService } from "@/common/services/services.module";
import type IColourSchemeUtils from "@/common/services/IColorSchemeUtil";
import type NavItem from "@/common/models/INavItem";
import { BltSideNavConstant } from "@/common/constant/bltSideNavConstant";
import BltChangePassword from "@/common/components/bltChangePassword/bltChangePassword.vue";
import BltAnimatedChevron from "@/common/components/bltAnimatedChevron/bltAnimatedChevron.vue";
import "@banno/jha-wc/src/jha-accordion/jha-accordion.js";
import LoggedInUserDetails from "@/common/components/bltSideNav/LoggedInUserDetails.vue";
import { legacyManagementModules, type LegacyModule } from "@/common/components/bltSideNav/legacyManagementAppsEnum";
import { openAnywhereSites, type Site } from "@/common/components/bltSideNav/openAnywhereSitesEnum";
import { mapState } from "pinia";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import {managementModules, type Module} from "@/common/components/bltSideNav/managementAppsEnum";
import { useRoute, useRouter} from "vue-router";
import {BltSidenavStore} from "@/branchmanager/stores/bltSidenavStore";

export default defineComponent({
  name: "bltSideNav",
  components: {
    LoggedInUserDetails,
    BltChangePassword,
    BltAnimatedChevron
  },
  setup() {

    const commonService = $inj(CommonService);
    const colorSchemeUtil = $injByInterface<IColourSchemeUtils>("IColourSchemeUtils");
    const toastService = $inj(ToastService);
    const bltSidenavStore = BltSidenavStore()
    const router = useRouter()
    const route = useRoute()

    return {
      commonService,
      colorSchemeUtil,
      router,
      toastService,
      route,
      bltSidenavStore
    }
  },

  data() {
    return {
      loading: false,
      selectedNavItem: {} as NavItem,
      navItems: [] as NavItem[],
      navOptions: openAnywhereSites as Site[],
      logoBase64: null as string | null,
      fiName: null as string | null,
      institutionConfig: {} as any,
      openDropDown: false,
      showChangePasswordDialog: false,
      BltSideNavConstant,
      touchstartX: 0,
      touchendX: 0,
      legacyManagementModules: legacyManagementModules,
      managementModules: managementModules
    }
  },
  async created() {
    this.institutionConfig = (window as any).institutionConfig;
    this.loading = true;
    this.navItems = await this.commonService.getNavItems()
    this.fiName = this.institutionConfig.name;
    this.logoBase64 = this.colorSchemeUtil.onColorSchemeChange();
    this.loading = false;
  },
  emits: ['hideSidebar', 'showSidebar'],
  methods: {
    routeIsActive(route: string) {
      return this.route.matched.map((route) => route.name).includes(route)
    },
    isLegacyModule(module: LegacyModule | Module) {
      return 'path' in module
    },
    isModule(module: LegacyModule | Module) {
      return 'route' in module
    },
    showChangePasswordModal(): void {
      this.showChangePasswordDialog = true;
    },
    handleLegacyManagementModuleClick(module: LegacyModule): void {
      window.location = `${this.managementUrl}/bolts/managementconsole/app/index.html#!${module.path}`
    },
    handleManagementModuleClick(route: string): void {
      this.router.push({
        name: route
      })
      this.$emit('hideSidebar')
    },
    async logout(): Promise<void> {
      await this.commonService.logout();
      this.$emit('hideSidebar')
    },
    isActiveTab(currentTab: string) {
     return this.selectedNavItem.appCode === currentTab
    }
  },
  computed: {
    openMgmtSection() {
      // If you're in an admin/* state...
      return this.route.matched
          .map(route => route.name)
          .includes("ADMIN")
    },
    Module() {
      return Module
    },
    LegacyModule() {
      return LegacyModule
    },
    moduleList() {
      let moduleList = legacyManagementModules as Array<Module | LegacyModule>
      moduleList = moduleList.concat(managementModules)
      moduleList.sort((a, b) => a.name > b.name ? 1 : -1)
      return moduleList
    },
    ...mapState(useLocalStorage, ["login"]),
    managementUrl() {
      const manageApp = this.navItems.filter(item => item.appCode === "boltsmanage")[0];
      const fullPAth = manageApp.url
      if(!fullPAth) {
        return ""
      }
      const url = new URL(fullPAth)
      return  url.origin
    }
  }
});
</script>
<template>
  <div class="sidebar-overlay" @click="$emit('hideSidebar')"></div>
  <jha-card class="left-container-card left-sidebar" ref="sideNav">

    <div class="logo"><img :src="logoBase64" :alt="fiName" class="logo-img"/></div>

    <div class="divider"></div>

    <div class="nav-items">
      <jha-nav class="side-nav-jha-nav">
        <a
          v-for="{name, route, icon} in navOptions"
          :key="name"
          :class="routeIsActive(route) ? 'active' : 'nonActive'"
          :active="routeIsActive(route) ? 'true' : null"
          @click="handleManagementModuleClick(route)"
          tabindex="0"
          role="button"
          @keydown.enter="handleManagementModuleClick(route)">
          <span v-html="icon"></span>
          {{ name }}
        </a>
      </jha-nav>

      <jha-collapsible-section :isOpen="openMgmtSection"
        class="management-collapsible-section">
        <div slot="header">
          <jha-icon-settings style="margin-left: 11px;" class="nav-icon" />
          <span style="font-weight: 400; font-size: 16px; line-height: 24px;">Management Console</span>
          </div>
        <div slot="body">
          <div
            v-for="module in moduleList"
            :key="module.name">
            <jha-nav
              class="side-nav-jha-nav"
              v-if="isLegacyModule(module)">
              <a
                style="padding-left: 58px"
                class="nonActive"
                :key="module.name"
                @click="handleLegacyManagementModuleClick(module as LegacyModule)"
                tabindex="0"
                role="button"
                @keydown.enter="handleLegacyManagementModuleClick(module as LegacyModule)">
                {{ module.name }}
              </a>
            </jha-nav>

            <jha-nav
              class="side-nav-jha-nav new"
              v-if="isModule(module)">
              <a
                  style="padding-left: 58px"
                  :key="module.name"
                  :class="routeIsActive((module as Module).route) ? 'active' : 'nonActive'"
                  :active="routeIsActive((module as Module).route) ? 'true' : null"
                  @click="handleManagementModuleClick((module as Module).route)"
                  tabindex="0"
                  role="button"
                  @keydown.enter="handleManagementModuleClick((module as Module).route)"
              >
                {{ module.name }}
              </a>
            </jha-nav>
          </div>
        </div>
      </jha-collapsible-section>
    </div>

    <div class="action-items">
      <jha-dropdown class="side-nav-profile-dropdown-menu">
        <jha-dropdown-toggle
          slot="toggle"
          class="toggle-divider"
          @click.stop="openDropDown = !openDropDown"
          @keydown.enter="openDropDown = !openDropDown"
        >
          <div class="side-nav-dropdown-left">
            <LoggedInUserDetails />
            <blt-animated-chevron :on="openDropDown"></blt-animated-chevron>
          </div>
        </jha-dropdown-toggle>
        <jha-dropdown-menu open-up="" class="side-nav-profile-dropdown-menu-width">
          <jha-dropdown-menu-item
            @click.stop="showChangePasswordModal()"
            @keydown.enter="showChangePasswordModal()"
          >
            <a>
              <jha-icon-lock class="icon-pos"></jha-icon-lock>
              {{ BltSideNavConstant.CHANGE_PASSWORD }}</a>
          </jha-dropdown-menu-item>
          <jha-dropdown-menu-item @click.stop="logout" @keydown.enter="logout">
            <a>
              <jha-icon-logout class="icon-pos"></jha-icon-logout>
              {{ BltSideNavConstant.SIGN_OUT }}</a>
          </jha-dropdown-menu-item>
        </jha-dropdown-menu>
      </jha-dropdown>
    </div>

  </jha-card>
  <BltChangePassword
    v-if="showChangePasswordDialog"
    :login="login ?? ''"
    @close-dialog="showChangePasswordDialog = false"
  />
</template>

<style scoped>
.management-collapsible-section {
  --jha-accordion-open-body-padding-top: 0;
  --jha-background-color: transparent;
}

.management-collapsible-section:not([is-open]):hover {
  --jha-background-color: rgb(230, 230, 230);
}

jha-nav.new {
  position: relative;
}

.left-sidebar {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  border-radius: 0;
  z-index: 99;
  width: 272px;
  box-shadow: var(--jha-card-shadow, 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 1px 4px rgba(0, 0, 0, 0.12));
  background-color: var(--jha-card-background-color, var(--jha-component-background-color, #ffffff));
  margin: 0;
  padding-left: 0;
}

.nav-items {
  margin-top: var(--jh-space-200);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.action-items {
  display: flex;
  flex-direction: column;
}

:deep(.nav-icon) {
  margin-right: 16px;
  padding: 0px;
  vertical-align: middle;
}

.nonActive :deep(.nav-icon) {
  fill: var(--jha-text-base);
}

.active :deep(.nav-icon) {
  fill: var(--primary-button-color);
}

.divider {
  margin-left: 0;
}

.toggle-divider {
  border-top: 1px solid var(--jha-border-color, #e4e7ea);
}

.side-nav-profile-dropdown-menu {
  margin-left: 0px;
}

.side-nav-dropdown-left {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--jh-space-400);
}

.side-nav-jha-nav a {
  cursor: pointer;
  padding: var(--jh-space-300) var(--jh-space-500);
  font-size: 16px;
  line-height: 24px;
}

:deep(.active) {
  color: var(--jha--menuTextSelectedColor);
  font-weight: 500;
}

:deep(.nonActive) {
  font-weight: 400;
}

.icon-pos,
.secondary-fill-icon {
  fill: var(--jha-text-base);
}

.icon-pos {
  padding-right: var(--jh-space-400);
}

.logo {
  margin: var(--jh-space-500) auto;
  text-align: center;
}

jha-dropdown {
  width: 272px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.side-nav-profile-dropdown-menu-width {
  width: 272px;
}

.side-nav-profile-dropdown-menu-width a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: var(--jh-space-400) var(--jh-space-500);
  color: var(--jha-text-dark);
}
</style>
