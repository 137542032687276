import HttpHandler from "@/common/services/connect/HttpHandler";

import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class EnrollmentApplicantCipFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getApplicantCipServices(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/cip/`
    );
  }

  async getApplicantCip(workspaceUUID: string, enrollmentId: number, cipId: number, serviceCode: any) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/service/${serviceCode}`
    );
  }
}
export default EnrollmentApplicantCipFactory;
