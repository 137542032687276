<template>
  <jha-icon-information
    v-if="bltDocumentCode"
    small
    class="icon-align icon"
    @click.stop="viewDocument"
    :aria-label="`Open a document for more information on ${bltDocumentCode}`"
    :disabled="isViewing"
  ></jha-icon-information>
  <jha-tooltip text="More Information"></jha-tooltip>
  <info-dialog ref="infoPopup" :html-resolve="htmlResolve" :title="title" />
</template>
<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import { DocumentFactory } from "@/common/services/services.module";
import { defineComponent } from "vue";
import "@banno/jha-wc/src/jha-tooltip/jha-tooltip.js";
import "@banno/jha-wc/src/icons/jha-icon-information.js";
import infoDialog from "@/common/services/Dialogs/infoDialogFactory/infoDialog.vue";
import type { bltModalDialog } from "@/common/components/appch.components.module";

export default defineComponent({
  name: "bltDocumentButton",
  components: {
    infoDialog
  },
  props: {
    bltDocumentCode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  setup() {
    const documentFactory = $inj(DocumentFactory);
    return {
      documentFactory
    };
  },
  data: () => ({
    isViewing: false as boolean,
    htmlResolve: "" as string
  }),
  methods: {
    viewDocument() {
      this.isViewing = true;
      this.documentFactory
        .get(this.bltDocumentCode)
        .then((response) => {
          (this.$refs.infoPopup as typeof bltModalDialog).show();
          this.htmlResolve = response.html;
        })
        .finally(() => {
          this.isViewing = false;
        });
    }
  }
});
</script>
