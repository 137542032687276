import container from '@/common/dependency.configs';
import DEPENDENCYTYPES from '../dependency.types';
import * as services from '@/common/services/services.module';

/**To get instance of a type by giving the type object */
export function $inj<T>(type: { new(...args: any[]): T }): T {
    //get the container from the vue instance
    if (!container) {
        throw new Error(
            'CRITICAL: Inversify Container needs to be existing.'
        );
    }
    const instance = container.get(type);
    if (!instance) {
        throw new Error('CRITICAL: $inj could not resolve the instance. ' + type.name);
    }
    return instance;
}

/**To get instance of a type mapped with an interface by giving the interface name */
export function $injByInterface<T>(typename: string): T {
    //get the container from the vue instance
    if (!container) {
        throw new Error(
            'CRITICAL: Inversify Container needs to be existing.'
        );
    }
    type objectKey = keyof typeof DEPENDENCYTYPES;
    const name = typename as objectKey;
    //get the symbol of the type from DEPENDENCYTYPES
    const symbol = DEPENDENCYTYPES[name]
    const obj = container.get<T>(symbol);
    return obj;

}

/**To get instance of the type name provided as string */
export function $injStr(typeName: string) {
    let instance = undefined;
    Object.keys(services).forEach(serviceKey => {
        if (serviceKey.toString() === typeName) {
            const service: any = services[serviceKey as keyof typeof services];
            instance = $inj(service);
        }
    });
    return instance;
}