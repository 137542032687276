<template>
  <jha-form-chip-input
    outline
    :value="selectedLookup"
    labelKey="typeDescription"
    valueKey="typeCode"
    :chipLimit="chipLimit"
    :options="options"
    :label="bltPlaceholder"
    @change="onChange($event)"
  ></jha-form-chip-input>
</template>
<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import { defineComponent } from "vue";
import "@banno/jha-wc/src/forms/jha-form-chip-input/jha-form-chip-input.js";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import BltLookupFactory from "@/common/components/bltLookup/bltLookupFactory";
import { find } from "lodash";

export default defineComponent({
  name: "bltLookup",
  emits: ["onLookupChange"],
  props: {
    lookupUrl: {
      type: String,
      required: true
    },
    isMultiChoice: {
      type: Boolean,
      default: true
    },
    selectedLookup: {
      required: true,
      type: Object
    }
  },
  setup() {
    const bltLookupFactory = $inj(BltLookupFactory);
    return {
      bltLookupFactory
    };
  },
  created() {
    this.load();
    this.bltSelectedLookup = this.selectedLookup;
  },
  data: () => ({
    selectedItems: [],
    bltSelectedLookup: {},
    options: [],
    bltPlaceholder: "Enter Search Text...",
    searchText: "",
    lookupData: []
  }),
  computed: {
    chipLimit() {
      return this.isMultiChoice ? false : 1;
    }
  },
  methods: {
    onChange(event: any) {
      const response = event.detail?.map((item: string) => {
        return find(this.lookupData, { typeDescription: item });
      });
      this.$emit("onLookupChange", response);
    },
    querySearch(query: string | string[][] | Record<string, string>) {
      this.bltLookupFactory.getLookup(this.lookupUrl, query).then((response) => {
        this.lookupData = response;
        this.options = response.map((item: { typeDescription: string }) => item.typeDescription);
      });
    },

    updateMultiLookup() {
      this.bltSelectedLookup = this.selectedItems;
    },

    selectLookup(lookup: any) {
      this.bltSelectedLookup = lookup;
    },

    load() {
      if (this.isMultiChoice) {
        this.updateMultiLookup();
      }
      this.querySearch(this.searchText);
    }
  }
});
</script>
