import DisclosureFactory from "@/common/services/Disclosure/DisclosureFactory";
import LanguageFactory from "@/common/services/Language/LanguageFactory";
import ApplicantEmailsFactory from "@/common/services/Applicant/applicantEmailsFactory";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
@injectable()
class DisclosureSendingFactory {
  constructor(
    @inject(DisclosureFactory) private disclosureFactory: DisclosureFactory,
    @inject(LanguageFactory) private languageFactory: LanguageFactory,
    @inject(ApplicantEmailsFactory) private applicantEmailsFactory: ApplicantEmailsFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler,
  ) { }

  getPrintableDisclosures(disclosurePackage: any) {
    return disclosurePackage.disclosures.filter(this.disclosureFactory.hasStoreId);
  }

  getEmailableDisclosures(disclosurePackage: any) {
    return disclosurePackage.disclosures
      .filter(this.disclosureFactory.hasStoreId)
      .filter(this.disclosureFactory.isAllowEmail);
  }

  async getAllEmails(workspaceUUID: string, applicantList: any[]) {
    return Promise.all(
      applicantList.map((applicant) => {
        return this.applicantEmailsFactory.getApplicantEmailList(workspaceUUID, applicant.applicantId);
      })
    ).then((response: any) => {
      // Flatten
      return response.reduce((acc: string, cur: string) => {
        return acc.concat(cur);
      }, []);
    });
  }

}
export default DisclosureSendingFactory;
