<template>
  <div v-if="loading" class="loading">
    <jh-progress type="circular" size="large" indeterminate></jh-progress>
  </div>
</template>
<script lang="ts">
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { WorkflowFactory } from "@/common/services/services.module";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import "@jack-henry/jh-ui/components/progress/progress.js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltPreloader",
  setup() {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const workflowFactory = $inj(WorkflowFactory)
    return {
      workspaceStore,
      workflowFactory
    };
  },
  computed: {
    loading() {
      return this.workflowFactory.getPreloadingStatus();
    }
  }
});
</script>
