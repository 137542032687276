import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class AccountApplicantDisclosureFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

   getAll(workspaceUUID: string, enrollmentId: number, accountId: number, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/applicant/${applicantId}/disclosure`,
      {},
      ResponseTypes.Payload
    );
  }

  get(workspaceUUID: string, enrollmentId: number, accountId: number, applicantId: number, disclosureCode: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/applicant/${applicantId}/disclosure/${disclosureCode}`,
      {},
      ResponseTypes.Payload
    );
  }

  put(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    applicantId: number,
    disclosureCode: any,
    message: any
  ) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/applicant/${applicantId}/disclosure/${disclosureCode}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }
}
export default AccountApplicantDisclosureFactory;
