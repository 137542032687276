<template>
  <div layout="row" layout-align="center center" flex="100">
    <jh-progress v-if="document.sending" type="circular" indeterminate="true" size="medium" />
    <jha-form-file-input
      :accept="accept"
      :disabled="bltDisabled"
      @change="onChange"
      v-if="(document?.status !== DocumentConstant.SUCCESS || bltHideDelete) && !document?.sending"
      >Select file</jha-form-file-input
    >
    <jha-icon-delete
      class="disk-list-icon secondary-fill-color mr-0"
      v-if="!document?.sending && document?.status === DocumentConstant.SUCCESS && !bltHideDelete"
      :disabled="bltDisabled"
      @click="deleteClicked"
    ></jha-icon-delete>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/icons/jha-icon-delete";
import "@jack-henry/jh-ui/components/progress/progress.js";
import { DocumentConstant } from "@/common/constant/DocumentConstant";
import { $inj } from "@/common/decorators/depinject";
import { ModalUtil } from "@/common/utils/ModalUtil";

type document = {
  typeCode: string;
  applicantId?: number;
  documentId?: number;
  documentType: string;
  description: string;
  filename?: string;
  status?: string;
  sending?: boolean;
};

export default defineComponent({
  name: "bltMultipartUpload",
  emits: ["bltDeleteFunction"],
  props: {
    accept: {
      type: String,
      required: false,
      default: ".bmp, .dcx, .dicom, .gif, .img, .jpg,.jpeg, .jpeg2000, .mac, .pdf, .pcx, .png, .gfa, .giff, .jpe, .jif, .ppm, .pbm, .pgm, .tif, .tiff, .webp, .cell, .sxs, .sx, .ods, .xls, .xlsx, .xlsb, .sxc, .htm, .html, .rtf, .dwg, .dxf, .dgn, .vsd, .svm, .emf, .wmf, .pdd, .odt, .docx, .doc, .sxw, .sdw, .wpd, .wp5"
    },
    bltDocument: {
      type: Object,
      required: true
    },
    bltFunction: {
      type: Function,
      required: true
    },
    bltHideDelete: { type: Boolean },
    bltDisabled: { type: Boolean }
  },
  data() {
    return {
      document: this.bltDocument as document,
      DocumentConstant
    };
  },
  setup() {
    const modalUtil = $inj(ModalUtil);
    return {
      modalUtil
    }
  },
  methods: {
    deleteClicked() {
      this.modalUtil
        .prompt('Delete Document', 'Are you sure you want to delete this document?')
        .then(() => {
          this.$emit("bltDeleteFunction", this.document);
        })
    },
    onChange(event: { detail: File[] }) {
      const file = event.detail[0];
      this.document.sending = true;
      this.bltFunction(file, this.document)
        .then(() => {
          this.document["status"] = DocumentConstant.SUCCESS;
        })
        .catch(() => {
          this.document["status"] = DocumentConstant.FAILED;
        })
        .finally(() => {
          this.document.sending = false;
        });
    }
  }
});
</script>
<style scoped>
jha-icon-delete {
  height: 24px;
  width: 24px;
  padding: 4px;
}
</style>
