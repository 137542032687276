export class BroadcastConstant {
  static readonly DISPLAY_TOAST: string = "DISPLAY_TOAST";
  static readonly UPDATE_ENROLLMENT_STATE: string = "update-enrollment-state";
  static readonly BLT_UI_TEXT_TOGGLE_EDIT_MODE: string = "BLT_UI_TEXT_TOGGLE_EDIT_MODE";
  static readonly BLT_SIGNATURE_RESET: string = "blt-signature-reset";
  static readonly BLT_REPLACE_TOOLBAR_TITLE: string = "BLT_REPLACE_TOOLBAR_TITLE";
  static readonly ERROR_INCOMMING: string = "ERROR_INCOMMING";
  static readonly WORKFLOW_PREVIOUS_STATE_UPDATED: string = "WORKFLOW_PREVIOUS_STATE_UPDATED";
  static readonly OPEN_FORM_LOADING: string = "OPEN_FORM_LOADING";
  static readonly ALLIED_SUCCESS: string = "allied-success";
  static readonly ALLIED_CANCELLED: string = "allied-canceled";
  static readonly ALLIED_ERROR: string = "allied-error";
  static readonly WIZARD_TOGGLE_SEARCH: string = "WIZARD_TOGGLE_SEARCH";
  static readonly SHOW_HIDE_SEARCH: string = "SHOW_HIDE_SEARCH";
  static readonly WIZARD_NAVIGATED: string = "WIZARD_NAVIGATED";
  static readonly IFRAME_ACCEPT: string = "iframe-accept";
  static readonly CIP_SUBMIT: string = "cip-submit";
  static readonly CIP_COMPLETED: string = "cip-completed";
  static readonly BLTFORM_CREATED_UPDATED: string = "BLTFORM_CREATED_UPDATED";
  static readonly WORKSPACE_LOADED: string = "WORKSPACE_LOADED";

  //emp channel services
  static readonly EC_REQUEST_WORKFLOW_HEADER_UPDATION: string = "EC_REQUEST_WORKFLOW_HEADER_UPDATION";
  static readonly EC_APPLICANT_NAVIGATION: string = "EC_APPLICANT_NAVIGATION";
}
