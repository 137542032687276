import type { ImageDataReady, UploadedImageDetails } from "@/common/components/bltIdUploaderDialog/OCRTypes";
import { injectable, inject } from "inversify";
import ApplicantOCRFactory from "@/common/services/Applicant/applicantOCRFactory";
import OCRDialogFactory from "@/common/services/Dialogs/OCRDialogFactory/OCRDialogFactory";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";


@injectable()
class CaptureFactory {
    constructor(@inject(LookupFactory) private lookupFactory: LookupFactory,
        @inject(ApplicantOCRFactory) private applicantOCRFactory: ApplicantOCRFactory,
        @inject(OCRDialogFactory) private ocrDialogFactory: OCRDialogFactory,
        @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IWorkspaceStore) {

    }
    async idTypes(): Promise<any> {
        return await this.lookupFactory.Ocr?.getAll();
    }
    async findIdTypesBy(identificationType: string) {
        const idtypes = await this.idTypes() as { identificationType: string, imageTypes: { imageType: string }[] }[];
        return idtypes.find((item) => identificationType === item.identificationType) || { imageTypes: [] };
    }
    async toBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    async saveDocuments(uploadedImageDetails: UploadedImageDetails): Promise<void> {
        const sendImages = [] as ImageDataReady[];
        for (let i = 0; i < uploadedImageDetails.uploadedImages.length; i++) {
            const matchedImageType = await this.findIdTypesBy(uploadedImageDetails.idName);
            const base64Image = (await this.toBase64(uploadedImageDetails.uploadedImages[i])) as string;
            sendImages.push({
                imageType: matchedImageType?.imageTypes[i].imageType,
                selectedImage: {
                    encodedBase64: base64Image
                }
            });
        }
        return this.applicantOCRFactory.post(
            this.workspaceStore.workspaceUUID,
            this.workspaceStore.applicant.applicantId,
            {
                images: this.ocrDialogFactory.imageTypesToDTO(sendImages),
                identificationType: uploadedImageDetails.idName
            }
        );
    }
}
export default CaptureFactory;