import {  injectable } from "inversify";
import { QuestionType } from "@/common/constant/BltQuestionnaireConstant";
import type {MultiAnswerQuestion, Question, SingleAnswerQuestion} from "@/common/components/bltQuestionnaire/cipTypes";

@injectable()
class BltQuestionnaireFactory {
  getAnswer(question: Question) {
    switch (question.questionType) {
      case QuestionType.TEXT:
      case QuestionType.NUMBER:
      case QuestionType.SINGLE_CHOICE:
      case QuestionType.MONEY:
        return this.getTextAnswer(question as SingleAnswerQuestion);
      case QuestionType.MULTI_CHOICE_LOOKUP:
        return this.getMultiChoiceLookupAnswer(question as MultiAnswerQuestion);
      case QuestionType.SINGLE_CHOICE_LOOKUP:
        return this.getSingleChoiceLookupAnswer(question as SingleAnswerQuestion);
      case QuestionType.MULTI_CHOICE:
        return this.getMultiChoiceAnswer(question);
      case QuestionType.DATE:
        return this.getDateAnswer(question as SingleAnswerQuestion);
    }
  }

  private getSingleChoiceLookupAnswer(question: SingleAnswerQuestion) {
    return [question.selectedAnswer.typeCode];
  }

  private getMultiChoiceLookupAnswer(question: MultiAnswerQuestion) {
    return question.selectedAnswer.map(function (item: { typeCode: string }) {
      return item.typeCode;
    });
  }

  private getMultiChoiceAnswer(question: Question) {
    return question.choices
      .filter(function (choice) {
        return choice.selected;
      })
      .map(function (choice) {
        return choice.choiceId;
      });
  }

  private getTextAnswer(question: SingleAnswerQuestion) {
    return [question.selectedAnswer];
  }

  private getDateAnswer(question: SingleAnswerQuestion) {
    return [question.selectedAnswer];
  }
}

export default BltQuestionnaireFactory;
