import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import LookupFactory from "@/common/services/Lookup/LookupFactory";
import ApplicantFactory from "@/common/services/Applicant/applicantFactory";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { LOOKUPSUPPORTCONSTANTS } from "@/common/constant/LookupConstant";
import type IProductLookupFactory from "@/common/services/Lookup/IProductLookupFactory";

@injectable()
class EmpProductLookupFactory implements IProductLookupFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory
  ) {}

  getProductInfo(productId: string): Promise<any> {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/product/${productId}?include=${LOOKUPSUPPORTCONSTANTS.PRODUCT_FEATURES}`,
        {},
        ResponseTypes.Payload
      )
      .then(function (response) {
        return response.productFeatures;
      });
  }

  getRegionalProducts(workspaceUUID: string, enrollmentId: number): Promise<any> {
    return Promise.all([
      this.lookupFactory.ProductGroup?.getAll({ enabled: true }),
      this.applicantFactory.getAll(workspaceUUID, { params: { isOriginator: true } })
    ]).then((response) => {
      return Promise.all(
        response[0].map((group: { typeCode: string; products: any[] }) => {
          return this.lookupFactory.Product?.getAll(
            {
              group: group.typeCode,
              enabled: true,
              applicantId: response[1][0] ? response[1][0].applicantId : undefined
            },
            { cache: false },
            {
              workspace: workspaceUUID,
              enrollment: enrollmentId
            }
          ).then((response: any[]) => {
            group.products = response;
            return group;
          });
        })
      );
    });
  }
}

export default EmpProductLookupFactory;
