import isUndefined from "lodash.isundefined";
import ConstantFactory from "@/common/services/utils/ConstantFactory";
import LanguageFactory from "@/common/services/Language/LanguageFactory";
import { injectable, inject } from "inversify";
import { GAnalyticsConstant } from "@/common/constant/GAnalyticsConstant";
import FACTORY_MSG from "@/common/messages/factory.messages";
import REGEX from "@/common/enums/regexEnum";
@injectable()
class GAnalyticsFactory {
  private trackingId: string | null;
  private googleBlocked: boolean

  constructor(
    @inject(ConstantFactory) private constantFactory: ConstantFactory,
    @inject(LanguageFactory) private languageFactory: LanguageFactory
  ) {
    this.trackingId = null;
    this.googleBlocked = false;
  }

  gtag(...args: any[]) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer?.push(args);
  }

  init(workspaceUUID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.trackingId = this.constantFactory.getProp(GAnalyticsConstant.GOOGLE_ANALYTICS_TRACKING_ID);

      if (isUndefined(this.trackingId)) {
        return reject(GAnalyticsConstant.ANALTYICS_NOT_CONFIG);
      } else if (String(this.trackingId).match(REGEX.ANALYTICS_TRACKING_REGEX) == null) {
        return reject(GAnalyticsConstant.ANALTYICS_NOT_FORMAT);
      }

      const document: any = window.document;
      const script = document.createElement("SCRIPT");
      script.async = true
      script.src = GAnalyticsConstant.GOOGLE_MANAGER_URL + this.trackingId

      script.addEventListener("load", () => {

        this.gtag('js', new Date());
        this.gtag('config', this.trackingId, {
          'user_id': workspaceUUID,
        });
        resolve();
      });

      script.addEventListener("error", async () => {
        this.googleBlocked = true
        console.warn(`Analytics unable to be loaded`);
        return reject(FACTORY_MSG.ANALTYICS.LOAD_ERROR);
      });

      const head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(script);
    });
  }

  sendWorkflowEvent(state: { state: string; titleKey?: string }) {
    if (this.trackingId && !this.googleBlocked) {
      this.gtag("set", "title", state.state);
      this.gtag('event', 'page_view', {
        page_title: state.state,
        page_location: location.href
      });
    }
  }
}
export default GAnalyticsFactory;
