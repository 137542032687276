import HttpHandler from '@/common/services/connect/HttpHandler';
import { Lookup } from './Lookup';
import { inject, injectable } from 'inversify';

@injectable()
class LookupFactory {
    public Document?: Lookup;
    public Person?: Lookup;
    public Salutation?: Lookup;
    public Address?: Lookup;
    public Identification?: Lookup;
    public Email?: Lookup;
    public Phone?: Lookup;
    public Country?: Lookup;
    public State?: Lookup;
    public BranchLocation?: Lookup;
    public EnrollmentState?: Lookup;
    public Enrollment?: Lookup;
    public ProductGroup?: Lookup;
    public Product?: Lookup;
    public ExistingAccount?: Lookup;
    public Business?: Lookup;
    public Naics?: Lookup;
    public Ocr?: Lookup;
    public Gender?: Lookup;
    public EducationLevel?: Lookup;
    public IncomeLevel?: Lookup;
    public MaritalStatus?: Lookup;
    public Language?: Lookup;
    public EmploymentStatus?: Lookup;
    public Citizenship?: Lookup;
    public Ethnicity?: Lookup;
    public Race?: Lookup;
    public ApplicantState?: Lookup;
    public Funding?: Lookup;
    public Relationship?: Lookup;
    public MemberGroup?: Lookup;
    public Intention?: Lookup;
    public Rule?: Lookup;
    public MajorCodes?: Lookup;
    public MinorCodes?: Lookup;
    public Services?: Lookup;
    public Roles?: Lookup;
    public WorkflowStateCategory?: Lookup;
    public WorkflowState?: Lookup;

    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
        const serviceCodes = {
            boltsbranch: {
                Document: '/type/document',
                Person: '/type/person',
                Salutation: '/type/salutation',
                Address: '/type/address',
                Identification: '/type/identification',
                Email: '/type/email',
                Phone: '/type/phone',
                Country: '/type/country',
                State: '/type/state',
                BranchLocation: '/branchlocation',
                EnrollmentState: '/enrollment/state/type',
                Enrollment: '/workspace/enrollment/type',
                ProductGroup: '/productgroup',
                Product: '/product',
                ExistingAccount: '/type/existingaccountgroup',
                Business: '/type/business',
                Naics: '/type/naics',
                Ocr: '/type/ocr',
                Gender: '/type/gender',
                EducationLevel: '/type/educationlevel',
                IncomeLevel: '/type/incomelevel',
                MaritalStatus: '/type/maritalstatus',
                Language: '/type/language',
                EmploymentStatus: '/type/employmentstatus',
                Citizenship: '/type/citizenship',
                Ethnicity: '/type/ethnicity',
                Race: '/type/race',
                ApplicantState: '/type/applicantstate',
                Funding: '/type/fund',
                Relationship: '/type/relationship',
                MemberGroup: '/type/member',
                Intention: '/account/intention',
                Rule: '/rule',
                MajorCodes: '/accounttype/majorcode',
                MinorCodes: '/accounttype/minorcode',
                Services: '/type/service',
                Roles: '/type/accountrole',
            },
            boltsui: {
                WorkflowStateCategory: '/type/statetype',
                WorkflowState: '/type/leafstate',
            },
        };

        //Dynamic assignment of lookup handlers for each entities which are defined as static properties of the class.
        const serviceKeys = Object.keys(serviceCodes) as Array<keyof typeof serviceCodes>;
        serviceKeys.forEach(serviceKey => {
            const serviceCode = serviceCodes[serviceKey];
            const endpointKeys = Object.keys(serviceCode) as Array<keyof typeof serviceCode>;
            endpointKeys.forEach(endpointKey => {
                const endpointUrl = serviceCode[endpointKey];
                this[endpointKey as keyof LookupFactory] = new Lookup(endpointUrl, endpointKey, serviceKey, this.httpHandler);
            });
        });
    }


}

export default LookupFactory;