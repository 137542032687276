import { injectable } from "inversify";
import { isEmpty } from "lodash";
@injectable()
class RemainingAddressTypesFilter {
  remainingAddressTypes(
    typeList: { typeCode: string }[],
    addresses: { DTO: { addressTypeCode: string } }[],
    currentAddress: { DTO: { addressTypeCode: string } }
  ) {
    const filteredList = typeList.filter((type: { typeCode: string }) => {
      return (
        !addresses.some((address: { DTO: { addressTypeCode: string } }) => {
          return address.DTO.addressTypeCode === type.typeCode;
        }) || type.typeCode === currentAddress.DTO.addressTypeCode
      );
    });
    if (!isEmpty(currentAddress)) {
      const foundIdx = filteredList.findIndex((el) => el.typeCode === currentAddress.DTO.addressTypeCode);
      filteredList.push(...filteredList.splice(0, foundIdx));
    }
    return filteredList;
  }
}

export default RemainingAddressTypesFilter;
