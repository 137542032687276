import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IFeature from "@/common/models/IFeature";

@injectable()
class FeatureFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  queryCached(params: any): Promise<Array<IFeature>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.LAUNCHPAD}/active/feature`,
      { params },
      ResponseTypes.Payload,
      true
    );
  }
}
export default FeatureFactory;
