import {injectable} from "inversify";

@injectable()
class FormatFactory {
  currency(input: number | string) {
    const num = +input;
    if (!isNaN(num)) {
      return num.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }
    return "NaN";
  }
}

export default FormatFactory;
