import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { extend } from "lodash";

type RelationshipDTO = {
  relationshipId: number;
  targetApplicant?: number;
};
@injectable()
class PersonRelationshipsFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  async get(workspaceUUID: string, applicantId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/relationship`,
      {},
      ResponseTypes.Payload
    );
  }

  async delete(workspaceUUID: string, applicantId: number, dto: RelationshipDTO) {
    return await this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/relationship/${dto.relationshipId}`
    );
  }

  private async put(workspaceUUID: string, applicantId: number, dto: RelationshipDTO) {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/relationship/${dto.relationshipId}`,
      dto,
      {},
      ResponseTypes.Payload
    );
  }

  private async post(workspaceUUID: string, applicantId: number, dto: RelationshipDTO) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/relationship`,
      dto,
      {},
      ResponseTypes.Payload
    );
  }

  update(workspaceUUID: string, applicantId: number, dto: RelationshipDTO) {
    delete dto.targetApplicant;
    return dto.relationshipId ? this.put(workspaceUUID, applicantId, dto) : this.post(workspaceUUID, applicantId, dto);
  }

  async getAndSet(workspaceUUID: string, applicants: { applicantId: number; }[], activeApplicant: { applicantId: number }): Promise<any> {
    const response = await this.get(workspaceUUID, activeApplicant.applicantId);
    return this.setRelationships(response, applicants, activeApplicant);
  }

  findApplicant(applicantId: number, applicants: { applicantId: number }[]) {
    return applicants.find((applicant) => {
      return applicant.applicantId === applicantId;
    });
  }

  setRelationships(
    relationships: { targetApplicantId: number }[],
    applicants: { applicantId: number }[],
    activeApplicant: { applicantId: number }
  ) {
    return applicants
      .filter((applicant) => applicant.applicantId !== activeApplicant.applicantId)
      .map((applicant) => {
        const relationship = relationships.find(
          (relationship) => relationship.targetApplicantId === applicant.applicantId
        );

        return relationship
          ? extend(relationship, {
            targetApplicant: this.findApplicant(applicant.applicantId, applicants)
          })
          : {
            targetApplicantId: applicant.applicantId,
            sourceApplicantId: activeApplicant.applicantId,
            relationshipType: undefined,
            targetApplicant: this.findApplicant(applicant.applicantId, applicants)
          };
      });
  }
}

export default PersonRelationshipsFactory;
