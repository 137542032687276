import { injectable, inject } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { extend } from "lodash";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IRouterService from "@/common/services/utils/IRouterService";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { ApplicantFactory, HttpHandler, TrackerFactory } from "@/common/services/services.module";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type SsoValidate from "@/common/data/SsoValidate";
import type IApplicant from "@/common/models/IApplicant";

@injectable()
class SsoRedirectFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(TrackerFactory) private trackerFactory: TrackerFactory,
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory,
    @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService
  ) {}

  parseState(state: string) {
    /*change to let if data changed*/
    const parts = state.split(":");
    return {
      workspaceUUID: parts[0],
      trackerUUID: parts[1],
      providerUUID: parts[2]
    };
  }

  stateMapToString(stateMap: { workspaceUUID: string; trackerUUID: string; providerUUID: string }) {
    return stateMap.workspaceUUID + ":" + stateMap.trackerUUID + ":" + stateMap.providerUUID;
  }

  async submitToken(applicant: any, providerUUID: string, redirectUrl: string) {
    const tempApplicant = extend(applicant, { redirectUrl: redirectUrl, providerUUID: providerUUID });
    return this.applicantFactory.respondSso(tempApplicant.workspaceUUID, tempApplicant.applicantId, applicant);
  }

  async submitTokenByState(state: string, redirectUrl: string) {
    const stateMap = this.parseState(state);
    const response = await this.trackerFactory.get(stateMap.workspaceUUID, stateMap.trackerUUID);
    const applicant = await this.applicantFactory.get(stateMap.workspaceUUID, response.activeApplicantId);
    return this.submitToken(applicant, stateMap.providerUUID, redirectUrl);
  }

  async reidrectToWorkflowBySsoState(state: string) {
    const stateMap = this.parseState(state);
    this.routerService.push({
      name: WorkflowStatesConstant.WORKFLOW.STATE,
      params: { workspaceUUID: stateMap.workspaceUUID },
      query: { trackerUUID: stateMap.trackerUUID },
      replace: true
    });
  }

  async ssoValidate(redirectUrl: string, providerUUID: string): Promise<SsoValidate> {
    return await this.httpHandler.post(
      SERVICE_PATH_CONSTANTS.BOLTSBRANCH + "/sso-validate",
      { redirectUrl: redirectUrl, providerUUID: providerUUID },
      {},
      ResponseTypes.Payload
    );
  }

  async ssoAuthenticate(workspaceUUID: string, token: string): Promise<string> {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` + workspaceUUID,
      {
        workspaceUUID: workspaceUUID,
        token: token
      },

      {
        params: {
          ssoChallenge: true
        }
      },
      ResponseTypes.Payload
    );
  }

  async ssoFill(workspaceUUID: string, applicantId: number, token: string): Promise<IApplicant> {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/` + workspaceUUID + "/applicant/" + applicantId,
      {
        workspaceUUID: workspaceUUID,
        token: token
      },
      {
        params: {
          "sso-fill": true
        }
      },
      ResponseTypes.Payload
    );
  }
}
export default SsoRedirectFactory;
