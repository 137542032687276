export function getRgbaFromAnyValidCssColor(color: string): { r: number, g: number, b: number, a: number } {

  const canvas = document.createElement('canvas')

  canvas.height = 1;
  canvas.width = 1;

  const ctx = canvas.getContext('2d')
  if(!ctx) {
    throw new Error(`Unexpectedly failed to retrieve canvas context while trying to resolve rgba for color: ${color}`)
  }

  ctx.fillStyle = color;
  ctx.fillRect(0,0,1,1)

  const pixel = ctx.getImageData(0,0,1,1)

  const [r,g,b,a] = pixel.data

  return {r,g,b,a}

}