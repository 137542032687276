import { injectable, inject } from "inversify";
import FormatFactory from "@/common/services/utils/FormatFactory";

@injectable()
class BltTotalFunding {
  constructor(@inject(FormatFactory) private formatFactory: FormatFactory) {}

  getTotalFunding(fundingConfirmations: { amount: any }[]) {
    const total = fundingConfirmations.reduce((col, confirmation) => {
      return col + parseFloat(confirmation.amount);
    }, 0);

    return this.formatFactory.currency(total);
  }
}

export default BltTotalFunding;
