export class WoltersKluwerConstant {
  static readonly INCOMPLETE_STATE: string = "Incomplete";
  static readonly COMPLETE_STATE: string = "Complete";
  static readonly PERSON: string = "PERSON";
  static readonly LANG_OPEN: string = "open_";
  static readonly LANG_TRUE: string = "_true";
  static readonly LANG_FALSE: string = "_false";
  static readonly NO_QUESTIONS_MESSAGE: string = "No questions are required for this applicant";
  static readonly BACKUP_WITH_HOLDING: string = "backupWithholding";
  static readonly BOOLEAN: string = "BOOLEAN";
}
