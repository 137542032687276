import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentCddFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async beginCdd(workspaceUUID: string, enrollmentId: number, cipId: number) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/cddrequest`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  async getCurrentQuestion(workspaceUUID: string, enrollmentId: number, cipId: number, cddRequestId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/cddrequest/${cddRequestId}/question/current`,
      {},
      ResponseTypes.Payload
    );
  }

  async answerQuestion(
    workspaceUUID: string,
    enrollmentId: number,
    cipId: number,
    cddRequestId: number,
    questionId: number,
    answers: any[]
  ) {
    const message = {
      cddRequestId: cddRequestId,
      questionId: questionId,
      answers: answers
    };
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/cddrequest/${cddRequestId}/question/${questionId}/answer`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  async scoreCdd(workspaceUUID: string, enrollmentId: number, cipId: number, cddRequestId: number) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/cddrequest/${cddRequestId}/score`,
      {},
      {},
      ResponseTypes.Data
    );
  }

  async getSummary(workspaceUUID: string, enrollmentId: number, cipId: number, cddRequestId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/cddrequest/${cddRequestId}/summary`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentCddFactory;
