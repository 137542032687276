export class DialogConstant {
  static readonly EMAIL_DIALOG_TITLE: string = "Email disclosures";
  static readonly EMAIL_DIALOG_CONFIRM_BUTTON_TEXT: string = "Send";
  static readonly NCR_DIALOG_CONFIRM_BUTTON_TEXT: string = "ACCEPT";
  static readonly OPEN: string = "OPEN";
  static readonly CLOSE: string = "CLOSE";
  static readonly EDIT_QUESTION_DIALOG_TITLE: string = "Are you sure you want to edit this answer?";
  static readonly EDIT_QUESTION_DIALOG_BODY: string =
    "Changing answer to this question will result in you having to re-answer any following questions.";
  static readonly EDIT_QUESTION_CONFIRM_BUTTON_TEXT: string = "Yes, proceed";
  static readonly EDIT_QUESTION_CANCEL_BUTTON_TEXT: string = "Never mind";
  static readonly PRINT_DIALOG_TITLE: string = "Print disclosures";
  static readonly PRINT_DIALOG_CONFIRM_BUTTON_TEXT: string = "Print";
}
