import { injectable, inject } from "inversify";
import LogService from "@/common/services/Log/LogService";
import OpenThemeProvider from "@/common/services/OpenThemeProvider/OpenThemeProvider";
import FILTER_MSG from "@/common/messages/filter.messages";

@injectable()
class BltThemeContrastFilter {
  constructor(
    @inject(LogService) private logService: LogService,
    @inject(OpenThemeProvider) private openThemeProvider: OpenThemeProvider
  ) {}

  bltThemeContrast(themeName: string, themeKey: string) {
    const throwError = (key: string, name: string) => {
      const filterMsg = FILTER_MSG.INT_TO_PALLETE.COLOR_NOT_FOUND_IN_PALETTE_MSG;
      const errorMsg = filterMsg.replace("${key}", key).replace("${name}", name);
      this.logService.error(errorMsg);
    };

    try {
      const palettes = this.openThemeProvider._PALETTES;
      const rgb = palettes[themeName][themeKey];
      if (rgb && rgb.contrast) {
        return "rgb(" + rgb.contrast.join(",") + ")";
      } else {
        throwError(themeKey, themeName);
      }
    } catch (e) {
      throwError(themeKey, themeName);
    }
  }
}

export default BltThemeContrastFilter;
