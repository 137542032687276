//Deprecated
import { SignatureConstant } from "@/common/constant/SignatureConstant";
import { $inj } from "@/common/decorators/depinject";
import { BltSignatureFactory, ConstantFactory } from "@/common/services/services.module";

let bltSignatureFactory: BltSignatureFactory;
let constantFactory: ConstantFactory;
let signatureColor: string;

// const interactedWith = false;

// variable that decides if something should be drawn on mousemove
let drawing = false,
  // the last coordinates before the current move
  lastX: number | undefined,
  lastY: number | undefined,
  offsetX = 0,
  offsetY = 0;

//actions
const actions = {
  resize(el: { css: (arg0: string, arg1: string) => void; attr: (arg0: string, arg1: number) => void }) {
    bltSignatureFactory.resizeCanvas(el);
  },

  setExistingSignature(setValue: boolean) {
    bltSignatureFactory.setIsExistingSignature(setValue);
  },

  setBase64Data(base64String: string) {
    bltSignatureFactory.setBase64(base64String);
  },

  initSignature(
    mdContainer: {
      setAttribute: (arg0: string, arg1: string) => void;
    },
    el: {
      setAttribute: (arg0: string, arg1: string) => void;
    }
  ) {
    mdContainer.setAttribute("class", "blt-signature-dialog-container");
    el.setAttribute("style", "background-color: rgb(237, 237, 237);");
    el.setAttribute("id", "blt-signature");
  },

  init(
    mdContainer: {
      setAttribute: (arg0: string, arg1: string) => void;
    },
    el: any
  ) {
    actions.initSignature(mdContainer, el);
    actions.resize(el);
  },

  resetLastCoords(event: {
    offsetX: number | undefined;
    offsetY: number;
    layerX: number;
    currentTarget: { offsetLeft: number };
    layerY: number;
  }) {
    if (event.offsetX !== undefined) {
      lastX = event.offsetX;
      lastY = event.offsetY;
    } else {
      lastX = event.layerX - event.currentTarget.offsetLeft;
      lastY = event.layerY;
    }
  },

  // calculate the offset of the nodes.
  setOffsets(node: any) {
    if (null == node) {
      return;
    }
    if (undefined == node.offsetLeft || undefined == node.offsetTop) {
      return;
    }
    if (node.offsetLeft >= 0 && node.offsetTop >= 0) {
      offsetX += node.offsetLeft;
      offsetY += node.offsetTop;
      actions.setOffsets(window);
    }
  },

  draw(
    lX: number | undefined,
    lY: number | undefined,
    cX: number | undefined,
    cY: number | undefined,
    ctx: {
      lineCap: string;
      lineJoin: string;
      strokeStyle: string;
      lineWidth: number;
      moveTo: (arg0: any, arg1: any) => void;
      lineTo: (arg0: any, arg1: any) => void;
      stroke: () => void;
    }
  ) {
    actions.setExistingSignature(true);

    //Set context properties
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = signatureColor;
    ctx.lineWidth = 4;

    // line from
    ctx.moveTo(lX, lY);

    // line to
    ctx.lineTo(cX, cY);

    // draw it
    ctx.stroke();

    if (lX !== cX || lY !== cY) {
      // Deprecated implementation of below line pending
      // scope.$parent.$apply("bltSignatureCtrl.interactedWith = true");
    }
  },

  // canvas reset
  reset(
    ctx: { beginPath: () => void },
    el: {
      getContext: (arg0: string) => {
        (): any;
        new (): any;
        clearRect: { (arg0: number, arg1: number, arg2: any, arg3: any): void; new (): any };
      };
      clientWidth: any;
      clientHeight: any;
    }
  ) {
    ctx.beginPath();
    el.getContext("2d").clearRect(0, 0, el.clientWidth, el.clientHeight);
    actions.setExistingSignature(false);
  }
};

const bltSignature = {
  created: (el: { css: (arg0: string, arg1: string) => void; attr: (arg0: string, arg1: number) => void }) => {
    bltSignatureFactory = $inj(BltSignatureFactory);
    constantFactory = $inj(ConstantFactory);
    signatureColor = constantFactory.getProp(SignatureConstant.SIGNATURE_COLOR);

    window.addEventListener("resize", () => {
      actions.resize(el);
 
    });

  },
  mounted: (el: any) => {
    let browserMouseDown = false;
    const parent = el.parentNode,
      mdContainer = parent.parentNode;

    //call initSignature and resize methods
    actions.init(mdContainer, el);

    const ctx = el.getContext("2d");

    ctx.imageSmoothingEnabled = true;

    //We use this to tell if the mouse is in a mousedown or mouseup position outside the element.
    el.addEventListener("onmouseup", function () {
      browserMouseDown = false;
    });
    el.addEventListener("onmousedown", function () {
      browserMouseDown = true;
    });

    //Begin draw events
    el.bind(
      "touchstart",
      function (event: {
        target: { parentNode: any };
        touches: { clientX: number; clientY: number }[] | undefined;
        originalEvent: { touches: { clientX: number; clientY: number }[] } | undefined;
      }) {
        // We don't want to calculate the offset if we already figured that out.
        if (offsetX == 0 && offsetY == 0) {
          actions.setOffsets(event.target.parentNode);
        }

        const rect = el.getBoundingClientRect();

        if (undefined !== event.touches) {
          lastX = event.touches[0].clientX - rect.left;
          lastY = event.touches[0].clientY - rect.top;
        } else if (undefined !== event.originalEvent) {
          lastX = event.originalEvent.touches[0].clientX - rect.left;
          lastY = event.originalEvent.touches[0].clientY - rect.top;
        }

        // begins new line
        ctx.beginPath();

        drawing = true;
      }
    );

    el.bind(
      "mousedown",
      function (event: {
        offsetX: number | undefined;
        offsetY: number;
        layerX: number;
        currentTarget: { offsetLeft: number };
        layerY: number;
      }) {
        if (event.offsetX !== undefined) {
          lastX = event.offsetX;
          lastY = event.offsetY;
        } else {
          lastX = event.layerX - event.currentTarget.offsetLeft;
          lastY = event.layerY;
        }

        // begins new line
        ctx.beginPath();

        drawing = true;
      }
    );

    //Move events
    el.bind("touchmove", function (event: any) {
      event.preventDefault();

      let currentX, currentY;
      if (drawing) {
        const rect = el.getBoundingClientRect();

        if (undefined !== event.touches) {
          currentX = event.touches[0].clientX - rect.left;
          currentY = event.touches[0].clientY - rect.top;
        } else if (undefined !== event.originalEvent) {
          currentX = event.originalEvent.touches[0].clientX - rect.left;
          currentY = event.originalEvent.touches[0].clientY - rect.top;
        }

        actions.draw(lastX, lastY, currentX, currentY, ctx);

        // set current coordinates to last one
        lastX = currentX;
        lastY = currentY;
      }
    });

    el.bind(
      "mousemove",
      function (event: {
        offsetX: number | undefined;
        offsetY: number | undefined;
        layerX: number;
        currentTarget: { offsetLeft: number; offsetTop: number };
        layerY: number;
      }) {
        let currentX, currentY;
        if (drawing || browserMouseDown) {
          // get current mouse position
          if (event.offsetX !== undefined) {
            currentX = event.offsetX;
            currentY = event.offsetY;
          } else {
            currentX = event.layerX - event.currentTarget.offsetLeft;
            currentY = event.layerY - event.currentTarget.offsetTop;
          }

          actions.draw(lastX, lastY, currentX, currentY, ctx);

          // set current coordinates to last one
          lastX = currentX;
          lastY = currentY;
        }
      }
    );

    el.bind(
      "mouseenter",
      function (event: {
        offsetX: number | undefined;
        offsetY: number;
        layerX: number;
        currentTarget: { offsetLeft: number };
        layerY: number;
      }) {
        if (browserMouseDown) {
          actions.resetLastCoords(event);
        }
      }
    );

    //End draw events
    el.bind("mouseup mouseout mouseleave mouseenter touchend", function () {
      drawing = false;
      actions.setBase64Data(el.toDataURL());
    });
  }
};

export default bltSignature;
