import TrackerFactory from "@/common/services/Applicant/trackerFactory";
import WorkflowFactory from "@/common/services/Workflow/WorkFlowFactory";
import { injectable, inject } from "inversify";
import DEPENDENCYTYPES from "@/common/dependency.types";
import BACK_IGNORED_WORKFLOW_STATE_TYPES from "@/common/services/Workspace/backIgnoredWorkflowStateTypes.enum"
import type IWorkspaceStore from "../Workspace/IWorkspaceStore";

@injectable()
class WorkspaceStateFactory {

    constructor(
        @inject(TrackerFactory) private trackerFactory: TrackerFactory,
        @inject(WorkflowFactory) private workflowFactory: WorkflowFactory,
        @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IWorkspaceStore
    ) { }

    async putWorkspaceState(workspaceUUID: string, trackerUUID: string, state: any) {
        return Promise.all([
            this.workflowFactory.getWorkflowState(state.state),
            this.trackerFactory.get(workspaceUUID, trackerUUID),
        ])
            .then((response: any) => {
                const workflowResponse = response[0];
                const trackerResponse = response[1];
                const type = workflowResponse.type;
                state.previousState = trackerResponse.previousState || [];
                // Implementation of State
                if (trackerResponse.activeApplicantId) {
                    state.activeApplicantId = trackerResponse.activeApplicantId;
                }
                if (
                    state.previousState[state.previousState.length - 1] !== state.state &&
                    !BACK_IGNORED_WORKFLOW_STATE_TYPES[type]
                ) {
                    state.previousState.push(state.state);
                }
                this.workspaceStore.workflow.previousState = state.previousState;
                return this.trackerFactory.put(workspaceUUID, trackerUUID, state);
            });
    };


    updateWorkspaceState(stateId: number) {
        return this.putWorkspaceState(this.workspaceStore.workspaceUUID, this.workspaceStore.trackerUUID, {
            state: stateId,
        });
    };


}
export default WorkspaceStateFactory;