import type UIField from "@/common/data/UIFields";
import type {DirectiveBinding, VNode} from "vue";

const updateField = (
    el: HTMLElement,
    binding: Array<UIField>,
    vnode: VNode<any>,
) => {
  const getOptions = (fieldName: string, fields: Array<UIField>) =>
     fields?.find((field: UIField) => field.field === fieldName);

  const currentField: UIField | undefined = getOptions(vnode.props.name, binding);

  if (currentField?.enabled) {
    el.classList.add("showField");
  } else if (currentField && !currentField.enabled) {
    el.classList.add("removeField");
    return;
  }

  if (currentField?.required) {
    el.setAttribute("required", "true");
  }
};
const bltInputField = {
  mounted(el: HTMLElement, binding: DirectiveBinding<Array<UIField>>, vnode: VNode<any>) {
    if (binding.value.length) {
      updateField(el, binding.value, vnode);
    }
  },
  updated(el: HTMLElement, binding: DirectiveBinding<Array<UIField>>, vnode: VNode<any>) {
    if (binding.value.length && Object.is(binding.value, binding.oldValue)) {
      updateField(el, binding.value, vnode);
    }
  }
};
export default bltInputField;
