<script lang="ts">
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { BroadcastService, Dbounce, TrackerFactory, WorkflowFactory } from "@/common/services/services.module";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import "@banno/jha-wc/src/progress/jha-progress-linear/jha-progress-linear.js";
import { defineComponent } from "vue";
import type { RouteLocationNormalizedLoaded } from "vue-router";

export default defineComponent({
  name: "bltProgressBar",
  props: {
    showDeterminate: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const workflowFactory = $inj(WorkflowFactory);
    const trackerFactory = $inj(TrackerFactory);
    const broadcastService = $inj(BroadcastService);
    const dbounce = $inj(Dbounce);
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return {
      workflowFactory,
      trackerFactory,
      workspaceStore,
      broadcastService,
      dbounce
    };
  },
  data() {
    return {
      indeterminate: false,
      loading: false
    };
  },
  created: function () {
    this.broadcastService.remove(BroadcastConstant.WORKFLOW_PREVIOUS_STATE_UPDATED);
    this.broadcastService.remove(BroadcastConstant.OPEN_FORM_LOADING);

    this.broadcastService.on(BroadcastConstant.WORKFLOW_PREVIOUS_STATE_UPDATED, this.updateProgressBar);
    this.broadcastService.on(BroadcastConstant.OPEN_FORM_LOADING, this.handleDbounce);
  },
  computed: {
    percentageCompleted() {
      if (this.showDeterminate) {
        return 0;
      }
      return this.workspaceStore.transitionInfo.percentage;
    },
    currentScreenNumber() {
      if (!this.showDeterminate) {
        return this.workspaceStore.transitionInfo.currentNumber;
      }
      return 0;
    }
  },
  methods: {
    hasWorkflowAncestor(state: RouteLocationNormalizedLoaded) {
      if (state.fullPath) {
        return (
          state.fullPath.includes(WorkflowStatesConstant.WORKFLOW.STATE) &&
          state.name !== WorkflowStatesConstant.INTERIM.STATE
        );
      }
    },
    handleDbounce(){
      if (this.loading) {
        this.loading = this.dbounce.create(1000).loading();
      } else {
        this.loading = false;
      }
    },
    updateProgressBar() {
      if (this.showDeterminate) {
        this.indeterminate = false;
        return;
      }
      this.indeterminate = true;

      Promise.all([
        this.workflowFactory.getProgress(this.workflowFactory.getState().stateId),
        this.trackerFactory.get(this.workspaceStore.workspaceUUID, this.workspaceStore.trackerUUID)
      ])
        .then((response) => {
          const remaining = response[0].remainingStateAvg;
          const previousState = response[1].previousState;
          let haveBeenOn = 0;
          if (previousState) {
            haveBeenOn = response[1].previousState.length;
          }
          const pcCompleted = Math.ceil((haveBeenOn / (remaining + haveBeenOn)) * 100);
          this.workspaceStore.transitionInfo.currentNumber = haveBeenOn;
          this.workspaceStore.transitionInfo.remaining = remaining;
          this.workspaceStore.transitionInfo.percentage = pcCompleted;
        })
        .finally(() => {
          this.indeterminate = false;
          this.loading = false;
        });
    }
  },
  expose: ["updateProgressBar"]
});
</script>
<template>
  <div class="progressbar-container" :class="{ 'progressbar-easeout': currentScreenNumber > 1 }">
    <jha-progress-linear
      v-if="currentScreenNumber > 1"
      class="progressBar"
      :percentage="percentageCompleted"
      :accessible-value-max="100"
      :accessible-value-min="0"
      :indeterminate="indeterminate"
    >
    </jha-progress-linear>
  </div>
</template>
