import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { isUndefined } from "lodash";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class QuestionnaireFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getAll(
    workspaceUUID: string,
    resource: string,
    resourceId: number,
    questionnaireId?: number,
    queryParams?: Record<string, string>
  ) {
    let url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire`;
    if (questionnaireId) {
      url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}`;
    }
    const requestConfig = !isUndefined(queryParams) ? { params: queryParams } : {};
    return await this.httpHandler.get(url, requestConfig, ResponseTypes.Payload);
  }

  save(
    workspaceUUID: string,
    resource: string,
    resourceId: number,
    questionnaireId: number,
    question: Record<string, unknown>,
    queryParams?: Record<string, string>
  ) {
    let url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire`;
    if (questionnaireId) {
      url = `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/${resource}/${resourceId}/questionnaire/${questionnaireId}`;
    }
    const requestConfig = !isUndefined(queryParams) ? { params: queryParams } : {};
    return this.httpHandler.post(url, question, requestConfig, ResponseTypes.Payload);
  }
}
export default QuestionnaireFactory;
