import {nextTick, type VNode, type DirectiveBinding} from 'vue'
import {debounce} from 'lodash'

const bltInfiniteScroll = {
  updated(
    element: HTMLElement,
    binding: DirectiveBinding<{child: string | undefined; parent: string | undefined} | undefined>,
    vNode: VNode
  ) {
    const parentClass = binding.value?.parent ?? 'admin-layout'
    const scrollParent = getScrollParent(element, parentClass)
    const vNodeBoundingBox = vNode.el?.getBoundingClientRect()
    const scrollParentBoundingBox = scrollParent?.getBoundingClientRect()

    /**
     * @NOTE: If the element is updated, and the parent's height is TOO TALL so that
     * the bounding client won't intersect, keep loading new things, (as long as you
     * haven't reached the end).
     *
     * If you don't pass :infiniteScrollStatus from the parent, this will not try to do this.
     */
    if (
      scrollParentBoundingBox &&
      vNode.props?.infiniteScrollStatus &&
      scrollParentBoundingBox.height > vNodeBoundingBox.height + vNodeBoundingBox.top + scrollParent?.scrollTop &&
      !vNode.props?.infiniteScrollStatus.reachedTheEnd
    ) {
      console.info(
        'The parent element of a scroll is too tall, so we are loading more items to compensate',
        scrollParent
      )
      handleOnIntersect(vNode)
    }
  },
  mounted: (
    element: HTMLElement,
    binding: DirectiveBinding<{child: string | undefined; parent: string | undefined} | undefined>,
    vNode: VNode
  ) => {
    nextTick().then(() => {
      const parentClass = binding.value?.parent ?? 'admin-layout'
      const childClass = binding.value?.child ?? 'infinite-scrolling-status-block'

      const scrollParent = getScrollParent(element, parentClass)
      const loadingBlock = element.querySelector(`.${childClass}`)

      intersectionMapping.set(
        vNode,
        new IntersectionObserver(
          (entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
              if (entry.isIntersecting) {
                onIntersectDebounce(vNode)
              }
            })
          },
          {
            root: scrollParent,
            rootMargin: '250px'
          }
        )
      )

      if (!loadingBlock || !scrollParent) {
        console.error(`Unable to find parent ${binding.arg} or an infinite-scrolling-status-block element`)
      } else {
        intersectionMapping.get(vNode)?.observe(loadingBlock)
      }
    })
  },
  unmounted: (element: HTMLElement, binding: DirectiveBinding<string>, vNode: VNode) => {
    intersectionMapping.get(vNode)?.disconnect()
    intersectionMapping.delete(vNode)
  }
}

const intersectionMapping = new Map<VNode, IntersectionObserver>()

const handleOnIntersect = (vNode: VNode) => {
  vNode.el?.dispatchEvent(
    new CustomEvent('infinite-scroll', {
      bubbles: true,
      composed: true
    })
  )
}

const onIntersectDebounce = debounce(
  (vNode: VNode) => {
    handleOnIntersect(vNode)
  },
  250,
  {
    leading: false,
    trailing: true
  }
)

const getScrollParent = (node: Element | null, className: string): HTMLElement | null => {
  if (node == null) {
    return null
  }

  if (node.classList?.contains(className)) {
    return node as HTMLElement
  } else {
    return getScrollParent(node.parentNode as Element, className)
  }
}

export default bltInfiniteScroll
