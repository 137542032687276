import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";

@injectable()
class BltLookupFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  async getLookup(url: string, query: string | string[][] | Record<string, string>) {
    const requestUrl = url.split("/leafREST")[1];

    const params: {
      enabled: boolean,
      typeDescription?: string,
      limit?: number
    } = { enabled: true };

    if (query !== undefined && query != "") {
      const querySerialized = new URLSearchParams(query).toString();
      params.typeDescription = querySerialized;
    }

    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}${requestUrl}`,
      { params: params },
      ResponseTypes.Payload
    );
  }

  async getLookupByTypeCode(url: string, typeCode: string) {
    const requestUrl = url.split("/leafREST")[1];
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}${requestUrl}?${typeCode}`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default BltLookupFactory;
