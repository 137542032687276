<template>
  <blt-modal-dialog
    ref="popup"
    type="small-dialog"
    :aria-label="dialogTitle"
    :title="dialogTitle"
    :message-body="dialogContent"
    :show-confirm="true"
    :showDialog="showDialog"
    :show-cancel="showCancelButton"
    :cancel-label="cancelButtonText"
    :confirm-label="okButtonText"
    @confirm="confirm"
    @cancel="confirm"
  >
  </blt-modal-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertDialog",
  emits: ["onConfirm"],
  props: {
    showDialog: {
      type: Boolean
    },
    dialogTitle: {
      type: String
    },
    dialogContent: {
      type: String
    }
  },
  data: () => ({
    okButtonText: "Ok",
    cancelButtonText: "Cancel",
    showCancelButton: false
  }),
  methods: {
    confirm() {
      this.$emit("onConfirm");
    }
  }
});
</script>
