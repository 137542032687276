import { defineStore } from "pinia";

/**
 * Keep track of what you searched for so that other states can pre-fill that information
 */
export const ApplicantSearchStore = defineStore({
    id: "ApplicantSearchStore",
    state: () => ({
        searchTerm: "",
        searchCategory: "",
    })
});