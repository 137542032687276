<template>
  <div style="width: 300px; min-width: 300px; height: 40px; min-height: 40px">
    <button
      ref="signBtn"
      v-if="!isSigned"
      :disabled="!isEditable"
      style="height: 100%; width: 100%; cursor: pointer; font-size: 1rem; border: 0"
      :style="[
        isEditable
          ? { background: signBtnBgColor, color: signBtnTxtColor }
          : { cursor: 'not-allowed', opacity: 0.7, background: signBtnBgColor, color: signBtnTxtColor }
      ]"
    >
      {{ sign }}
    </button>
    <bltSignatureDisplay :data="data" v-if="isSigned"></bltSignatureDisplay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import bltSignatureDisplay from "@/common/components/bltSignatureDisplay/bltSignatureDisplay.vue";
import { $injByInterface } from "@/common/decorators/depinject";
import type {IDisclosureStore} from "@/common/services/Disclosure/IDisclosureStore";
export default defineComponent({
  name: "bltSignButton",
  components: {
    bltSignatureDisplay
  },
  props: {
    signed: {
      type: Boolean,
      default: false
    },
    data: {
      type: String,
      default: ""
    },
    bltEditable: {
      type: Boolean
    },
    tagName: {
      type: String
    }
  },
  data() {
    return {
      isSigned: this.signed,
      isEditable: this.bltEditable,
      sign: "SIGN" as string,
      tagNameKey: this.tagName as string,
      signBtnBgColor: "" as string,
      signBtnTxtColor: "" as string
    };
  },
  setup() {
    const disclosureStore = $injByInterface<IDisclosureStore>("IDisclosureStore");
    return {
      disclosureStore
    };
  },
  mounted() {
    if (this.isEditable && this.$refs.signBtn) {
      (this.$refs.signBtn as any).addEventListener(
        "click",
        () => {
          this.isSigned = true;
          this.disclosureStore.disclosureDictionary.data[this.tagNameKey].value.signed = this.isSigned;
          this.disclosureStore.clickSignButton(
              this.tagNameKey,
              this.disclosureStore.disclosureDictionary.data[this.tagNameKey].value
          );

        },
        false
      );
    }
    const styles = getComputedStyle(document.documentElement);
    this.signBtnBgColor = styles.getPropertyValue("--jha-button-background");
    this.signBtnTxtColor = styles.getPropertyValue("--jha-button-text");
  }
});
</script>
