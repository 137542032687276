import { inject, injectable } from "inversify";
import { isString, isUndefined, isObject } from "lodash";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class BltIframeFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  /**
   * @name getTagsFromHtml
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will scan the passed html and return an array of tags that are within a ${} enclosure.
   *
   * @example
   * Passed: This ${is} a ${test} string!
   *
   * Returned: ['is','test']
   *
   * @param {String} html - A string of html data.
   */
  getTagsFromHtml(html: string) {
    const re = /\$\{(.*?)\}/g;
    return !isUndefined(html) && isString(html) && re.test(html) ? html.match(re) : [];
  }

  /**
   * @name sortDictionaryTags
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will sort dictionary values into arrays corresponding to their type, as well as remove null tags
   *     as they will not be used. This method requires that tag types already be injected into the dictionary
   *     preferably by the injectTagType method.
   *
   * @param {Object} dictionary - The dictionary object containing all tag types.
   *
   */
  sortDictionaryTags(dictionary?: { [x: string]: any }) {
    const sortedDictionary = {
      textbox: [] as any[],
      signature: [] as any[],
      checkbox: [] as any[],
      select: [] as any[]
    };

    if (!isUndefined(dictionary) && isObject(dictionary)) {
      Object.getOwnPropertyNames(dictionary).forEach((key) => {
        const tag = <{ tagType: string; tagName: string; originalTagName: string }>(
          dictionary[key as keyof typeof dictionary]
        );

        this.injectOriginalTag(tag);

        if (!isUndefined(tag) && isObject(tag)) {
          if (
            !isUndefined(tag.tagType) &&
            !isUndefined(sortedDictionary[tag.tagType as keyof typeof sortedDictionary])
          ) {
            sortedDictionary[tag.tagType as keyof typeof sortedDictionary].push(tag);
          }
        }
      });
    }

    return sortedDictionary;
  }

  /**
   * @name injectOriginalTag
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will add the orignal tag found in the html of this tag object to the tag object
   *
   * @param {Object} tag - The tag to add an originalTag property to.
   *
   * @example
   *
   * input - {tagName: "checkbox:mockTag"}
   *
   * output - {tagName: "checkbox:mockTag", originalTagName: "${checkbox:mockTag}"}
   *
   */
  injectOriginalTag(tag: { tagName: string; originalTagName: string }) {
    isObject(tag) && isString(tag.tagName) ? (tag.originalTagName = "${" + tag.tagName + "}") : {};
  }

  /**
   * @name injectApplicantImages
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will return a $q.all of promises that will add the correct signature base64 data to the
   *     signature tags.
   *
   * @param {Object} dictionary - The dictionary object containing all tag types.
   *
   * @param {String} uuid - The workspaceUUID to get applicantImages for.
   */
  injectApplicantImages(
    dictionary: {
      signature: { existing: boolean; value: { employee: any; applicantId: number; imageId: number }; base64: any }[];
    },
    uuid: string,
    enrollmentId: number
  ) {
    const signaturePromises: any[] = [];

    dictionary.signature.forEach((signature) => {
      signature.existing = false;

      signature.value =
        !isUndefined(signature.value) && isString(signature.value) ? JSON.parse(signature.value) : signature.value;

      if (!isUndefined(signature.value) && isObject(signature.value)) {
        if (signature.value.employee) {
          signaturePromises.push(
            this.httpHandler
              .get(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/employeesignature`,
                {},
                ResponseTypes.Payload
              )
              .then(function (employeeImage: any) {
                signature.base64 = employeeImage;
                signature.existing = true;
              })
          );
        } else {
          signaturePromises.push(
            this.httpHandler
              .get(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${signature.value.applicantId}/image/${signature.value.imageId}`,
                {},
                ResponseTypes.Payload
              )
              .then(function (signatureImage: any) {
                signature.base64 = signatureImage;
                signature.existing = true;
              })
          );
        }
      }
    });

    return Promise.all(signaturePromises);
  }

  /**
   * @name getCheckboxReplacements
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will add a 'tagReplacement' property to every checkbox object in the dictionary.checkbox array.
   *     This property will contain an HTML string that can be used to replace tags embedded in an html form.
   *
   * @param {Object} dictionary - The dictionary object containing the checkbox array.
   *
   */
  getCheckboxReplacements(dictionary: {
    checkbox: {
      editable?: boolean;
      tagName?: string;
      value?: string | boolean;
      tagReplacement?: string;
    }[];
  }) {
    dictionary.checkbox.forEach((checkbox) => {
      const disabled = checkbox.editable ? "" : " disabled ",
        id = isString(checkbox.tagName) ? 'id="' + checkbox.tagName + '"' : "",
        value = checkbox.value === "true" || checkbox.value === true ? " checked " : "";

      checkbox.tagReplacement = id + value + disabled;
    });
  }

  /**
   * @name getTextboxReplacements
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will add a 'tagReplacement' property to every textbox object in the dictionary.textbox array.
   *     This property will contain an HTML string that can be used to replace tags embedded in an html form.
   *
   * @param {Object} dictionary - The dictionary object containing all tag types.
   *
   */
  getTextboxReplacements(dictionary: { textbox: { tagReplacement: string; tagName: string }[] }) {
    dictionary.textbox.forEach((textbox) => {
      textbox.tagReplacement = textbox.tagName ? 'blt-tag="' + textbox.tagName + '"' : "";
    });
  }

  /**
   * @name injectApplicantImages
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will make a copy of the constant signature html, replace the appropriate data, and then set the
   *     corresponding signature.tagReplacement to the result.
   *
   * @param {Object} dictionary - The dictionary object containing all tag types.
   */
  getSignatureReplacements(dictionary: { signature: { tagReplacement: string; tagName: string }[] }) {
    dictionary.signature.forEach((signature) => {
      signature.tagReplacement = '<div id="' + signature.tagName + '"> </div>';
    });
  }

  /**
   * @name injectApplicantImages
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will return a $q.all of promises that will add the correct signature base64 data to the
   *     signature tags.
   *
   * @param {Object} dictionary - The dictionary object containing all tag types.
   */
  getSelectReplacements(dictionary: { select: { tagReplacement: string; tagName: string }[] }) {
    dictionary.select.forEach((select) => {
      select.tagReplacement = 'id="' + select.tagName + '"';
    });
  }

  getElementTagMap(HtmlDocument: any) {
    const nodeList = HtmlDocument.contentDocument.querySelectorAll("[blt-tag]"),
      array = [];

    for (let i = 0; i < nodeList.length; i++) {
      array.push(nodeList[i]);
    }

    return array.reduce(function (acc, cur) {
      const tagName = cur.getAttribute("blt-tag");

      if (!Array.isArray(acc[tagName])) {
        acc[tagName] = [];
      }

      acc[tagName].push(cur);

      return acc;
    }, {});
  }

  setTextboxValues(tagMap: { [x: string]: any }, dictionary: { textbox: any[] }) {
    Object.keys(tagMap).forEach((tagName) => {
      const tag = dictionary.textbox.filter((tag: { tagName: string }) => {
        return tag.tagName === tagName;
      })[0];

      if (tag) {
        const tagElements = tagMap[tagName];

        tagElements.forEach((el: { setAttribute: (arg0: string, arg1: string) => void; value: any }) => {
          const editable = tag.editable;
          const disabled = !editable;
          const value = tag.value !== null && tag.value !== undefined ? tag.value : null;

          el.setAttribute("id", tagName);
          el.value = value;
          if (disabled) {
            el.setAttribute("disabled", "true");
          }
        });
      }
    });
  }

  /**
   * @name replaceHtmlTags
   * @methodOf common.factory:BltIframeFactory
   * @description
   * This method will replace all instances of dictionary tags inside of the passed html with each tag object's
   *     "tagReplacement" string.
   *
   * @param {String} html - The html string to parse and replace tags.
   *
   * @param {Object} dictionary - The dictionary object that contains info about what to replace HTML tags with.
   *
   */
  replaceHtmlTags(html: string, dictionary: { [x: string]: any[] }) {
    ["select", "checkbox", "textbox"].forEach((list) => {
      dictionary[list].forEach((tag: { originalTagName: string; tagReplacement: any }) => {
        html = html.replace(new RegExp("\\" + tag.originalTagName, "g"), tag.tagReplacement);
      });
    });
    return html;
  }
}

export default BltIframeFactory;
