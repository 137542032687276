import { injectable } from "inversify";
@injectable()
class BroadcastService {
  private record: any;
  private backlog: any;
  constructor(
  ) {
    this.record = {};
    this.backlog = {};
  }

  remove(key: string) {
    if (this.record[key] && this.record[key].length > 0) {
      this.record[key] = undefined;
    }
  }

  on(key: string, value: Function) {
    if (this.record[key] && this.record[key].length > 0) {
      if (this.record[key].indexOf(value) == -1) {
        this.record[key].push(value);
      }
    } else {
      this.record[key] = [value];
    }
    /**if any broadcast was happened prior to subscrible, pickup and emit the due broadcast, then destroy the backlog */
    this.triggerBacklog(key);
  }

  triggerBacklog(key: string) {
    if (this.backlog[key] !== undefined) {
      this.broadcast(key, this.backlog[key]);
      this.backlog[key] = undefined;
    }
  }

  broadcast(key: string, value?: any) {
    if (this.record[key] && this.record[key].length > 0) {
      for (let i = 0; i < this.record[key].length; i++) {
        value ? (this.record[key][i] as Function)(value) : (this.record[key][i] as Function)();
      }
    }
    else {
      this.backlog[key] = value;
    }
  }
}

export default BroadcastService;
