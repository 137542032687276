<template>
  <div v-for="tile in tiles" :key="tile.id" class="bltTile">
    <div>
      <div class="workspace-title">
        {{ tile.description }}
      <jha-button
        type="button"
        icon
        primary
        outline="none"
        class="plusBtn"
        @click="handleClick(tile.workspaceType)"
        >
        <jha-icon-plus :key="tile.id" class="plusIcon" ></jha-icon-plus>
      </jha-button>
      </div>
      <div class="divider divider-margin"></div>
    </div>
    <div class="center center-icon">
      <div v-html="tile.icon" class="tile-center-icon-pos"></div>
    </div>
    <div class="tile-subheader">{{ tile.subHeading }}</div>
    <div class="blt-tile-footer-button">
      <jha-button outline-primary @click="handleClick(tile.workspaceType)" class="bltTileButton">{{ tile.description }}</jha-button>
    </div>
  </div>

</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltTile",
  emits: ["triggered"],
  props: {
    tiles: {
      type: Array<{ description: string; icon: string; subHeading: string; id: string; workspaceType: string }>,
      required: true,
      default: () => []
    }
  },
  methods: {
    handleClick(workspaceType: string) {
      this.$emit("triggered", workspaceType);
    }
  }
});
</script>

<style scoped>
.center {
  text-align: center;
}

.center-icon {
  height: 85px;
}

.tile-subheader {
  text-align: center;
  min-height: 40px;
}

.tile-center-icon-pos {
  margin: 28px auto 12px;
}

.plusIcon {
  float: right;
  cursor: pointer;
  fill: var(--body-text-secondary-color);
}
.plusBtn{
  --jha-button-outline-border: transparent;
}
.blt-tile-footer-button {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .tile-subheader {
    min-height: initial;
  }
}
</style>
