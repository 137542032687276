export class ToastConstants {
  static readonly ERROR: string = "error";
  static readonly INFO: string = "info";
  static readonly WARNING: string = "warning";
  static readonly DANGER: string = "danger";
  static readonly SUCCESS: string = "success";
  static readonly IS_VISIBLE: string = "is-visible";
  static readonly ARIA_HIDDEN: string = "aria-hidden";
  static readonly JHA_TOAST_CONTAINER: string = "jha-toast-container";
  static readonly TIME_OUT: number = 5000;
  static readonly ANIMATION_TIME_OUT: number = 2000;
  static readonly LINKED_ACCOUNTS_ADDED: string = "Linked account(s) added";
  static readonly APPLICANT_REMOVED: string = "Applicant removed";
}
