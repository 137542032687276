<script lang="ts">
import { defineComponent } from "vue";
interface TabList {
  tabType: string;
}
export default defineComponent({
  name: "bltTab",
  emits: ["activeTab"],
  props: {
    tabList: {
      type: Array<TabList>,
      required: true,
      default: () => []
    },
    activeTabIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      activeTab: this.activeTabIndex
    };
  },
  methods: {
    sendTabActive(type: string, index: number): void {
      this.$emit("activeTab", type);
      this.activeTab = index;
      this.setActiveTab();
    },
    setActiveTab() {
      setTimeout(() => {
        const tabs = document.querySelectorAll("jha-tab-item");
        if (tabs) {
          tabs.forEach((item, index) => {
            if (index === this.activeTab) {
              item.setAttribute("active", "");
            } else {
              item.removeAttribute("active");
            }
          });
        }
      }, 100);
    }
  }
});
</script>

<template>
  <jha-tab bordered="">
    <template v-for="(tab, index) in tabList" :key="tab">
      <jha-tab-item  class="jha-tab-item" :active="setActiveTab()" @click="sendTabActive(tab.tabType, index)">
        <slot :index="index" :tab="tab"></slot>
      </jha-tab-item>
    </template>
  </jha-tab>
</template>
<style scoped>
jha-tab {
  border-bottom: 0px solid;
}
.jha-tab-item {
  padding: 18px 16px 14px;
  font-size: 14px;
  line-break: 20px;
  font-weight: 400;
  color: var(--jha-text-dark);
  margin-right: 8px;
  line-height: normal;
}
@media (max-width: 393px) {
  .jha-tab-item {
  padding: 18px 6px 14px;
}
}
@media (max-width: 375px) {
.jha-tab-item {
  padding: 18px 6px 14px;
}
}
</style>
