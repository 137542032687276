<template>
  <div class="blt-signature-field-wrapper">
    <label v-if="!hideLabel"> {{ signatureLabel }}</label>
    <div class="signature" v-if="isEditMode">
      <img :src="previewBase64" alt="signature" />
      <button class="edit reset" type="button" @click="buttonClick" :disabled="disableClick">
        {{ BltSignatureField.EDIT }}
      </button>
    </div>
    <div v-else>
      <button @click="buttonClick" type="button" class="reset main" :disabled="disableClick">
        <jha-icon-edit></jha-icon-edit>
        <span class="sign-link"> {{ BltSignatureField.ADD_YOUR_SIGNATURE }}</span>
      </button>
    </div>
    <blt-modal-dialog
      type="small-dialog"
      ref="modalDialog"
      :title="dialogTitle || BltSignatureField.SIGNATURE"
      :showFooter="false"
      :dialogBorder="false"
    >
      <template v-slot:dialog-content>
        <bltSignatureFormDialog :dialogTitle="dialogTitle" @signatureChanged="save($event)"> </bltSignatureFormDialog>
      </template>
    </blt-modal-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/icons/jha-icon-edit.js";
import type { bltModalDialog } from "@/common/components/appch.components.module";
import { BltSignatureField } from "@/common/constant/bltSignatureFieldConstant";
import bltSignatureFormDialog from "@/common/components/bltSignatureFormDialog/bltSignatureFormDialog.vue";
import { LanguageFactory } from "@/common/services/services.module";
import { $inj } from "@/common/decorators/depinject";
import { getRgbaFromAnyValidCssColor } from "@/common/services/utils/canvasUtil";

export default defineComponent({
  name: "bltSignatureField",
  emits: ["signatureChanged"],
  components: { bltSignatureFormDialog },
  props: {
    signatureLabel: {
      type: String,
      default: ""
    },
    signatureImage: {
      type: String,
      default: ""
    },
    dialogBorder: {
      type: Boolean,
      default: true
    },
    dialogTitle: {
      type: String,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: () => false
    },
    disableClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      BltSignatureField,
      base64Image: "" as string,
      showDialog: false as boolean,
      previewBase64: "" as string,
    };
  },
  setup() {

    const languageFactory: LanguageFactory = $inj(LanguageFactory);
    return { languageFactory };

  },
  computed: {
    isEditMode() {
      return this.getImage !== "";
    },
    getImage() {
      return this.base64Image ? this.base64Image : this.signatureImage;
    }
  },
  watch: {
    getImage: function() {
      this.renderPreview()
    }
  },
  methods: {
    renderPreview() {

      const TRANSPARENT_ALPHA = 0;
      const RED_OFFSET = 0;
      const GREEN_OFFSET = 1;
      const BLUE_OFFSET = 2;
      const ALPHA_OFFSET = 3;

      const bodyComputedStyles = getComputedStyle(window.document.body)

      const jhaTextBaseColor = bodyComputedStyles.getPropertyValue("--jha-text-base")
      const signatureStokeOverride = bodyComputedStyles.getPropertyValue("--jha-signature-fill-color")

      const lineColor = signatureStokeOverride || jhaTextBaseColor

      const canvas = document.createElement('canvas')
      canvas.height = 300;
      canvas.width = 700;

      const ctx = canvas.getContext('2d');

      if(!ctx) {
        console.error("Unable to load context when trying to render signature canvas preview.")
        return;
      }

      const image = new Image();
      image.src = this.getImage;
      image.onload = () => {
        ctx.drawImage(image, 0, 0)

        const { width, height } = canvas;

        const imageData = ctx.getImageData(0, 0, width, height);
        const pixels = imageData.data;

        const { r, g, b, a } = getRgbaFromAnyValidCssColor(lineColor)

        for (let i = 0; i < pixels.length; i += 4) {

          const pixelAlpha = pixels[i + ALPHA_OFFSET];

          if (pixelAlpha !== TRANSPARENT_ALPHA) {
            pixels[i + RED_OFFSET] = r;
            pixels[i + BLUE_OFFSET] = g;
            pixels[i + GREEN_OFFSET] = b;
            pixels[i + ALPHA_OFFSET] = a;
          }

        }


        ctx.putImageData(imageData, 0, 0);

        this.previewBase64 = canvas.toDataURL();

      }

    },
    buttonClick() {
      if (!this.disableClick) {
        (this.$refs.modalDialog as typeof bltModalDialog).open();
      }
    },
    save(event: any) {
      this.base64Image = event.detail;
      this.$emit("signatureChanged", event);
      (this.$refs.modalDialog as typeof bltModalDialog).close();
    },
    cancel() {
      (this.$refs.modalDialog as typeof bltModalDialog).close();
    }
  }
});
</script>
<style scoped>
jha-icon-edit {
  fill: var(--jha-color-primary);
}

.blt-signature-field-wrapper {
  display: block;
  margin-top: var(--jh-space-400);
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  color: var(--body-text-primary-color);
  font-size: 14px;
}

label {
  font-size: 14px;
  color: var(--jha-text-light, #8c989f);
  font-weight: 400;
  margin: 0 8px;
  margin-left: var(--jh-space-50);
}

button.reset {
  box-sizing: border-box;
  height: auto;
  color: var(--jh-color-content-brand-enabled);
  touch-action: manipulation;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
}

button.edit {
  position: absolute;
  top: 4px;
  right: 0;
  padding: 8px 16px;
  z-index: 1;
  width: auto;
  color: var(--jha-color-primary);
}

button.main {
  border: 1px solid var(--jha-form-floating-group-outline-border-color, var(--jha-border-color, #8c989f));
  border-radius: 4px;
  padding: 48px 16px;
  margin: 0px 0 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature {
  border: 1px solid var(--jha-form-floating-group-outline-border-color, var(--jha-border-color, #8c989f));
  height: 122px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

img {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

jha-icon-edit {
  margin-right: 8px;
  fill: var(--jha-color-primary);
}
.sign-link {
  color: var(--link-button-text-color);
}
</style>
