<template>
  <jha-icon-checkmark
    class="jha-icon-right icon-sucess"
    v-if="bltDisclosure.status === DisclosureConstant.COMPLETE"
  ></jha-icon-checkmark>
  <div class="jha-icon-right open" v-if="bltDisclosure.status === DisclosureConstant.REVISION">
    {{ DisclosureConstant.OPEN }}
  </div>
  <div
    class="jha-icon-right open"
    v-if="bltDisclosure.status !== DisclosureConstant.COMPLETE && bltDisclosure.status !== DisclosureConstant.REVISION"
  >
    {{ DisclosureConstant.OPEN }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/icons/jha-icon-checkmark.js";
import { DisclosureConstant } from "@/common/constant/DisclosureConstant";
export default defineComponent({
  name: "bltDisclosureIcon",
  props: {
    bltDisclosure: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    DisclosureConstant
  })
});
</script>

<style scoped>
.jha-icon-right {
  color: var(--jha-color-primary);
}
</style>

