export class OpenLanguageKeysConstant {
  static readonly OPEN_SSO_CONTINUE_WORKFLOW_HEADER: string = "open_sso_continue_workflow_header";
  static readonly OPEN_SSO_CONTINUE_WORKFLOW_BODY: string = "open_sso_continue_workflow_body";
  static readonly MESSAGE_CODE_OPEN_EOD_ERROR_SCREEN: string = "open_eod_error_screen";
  static readonly OPEN_ERROR_HEADER: string = "open_error_header";
  static readonly WEBPAGE_TITLE: string = "webpage_title";
  static readonly GLOBAL_FULL_NAME_PLACEHOLDER: string = "global_full_name_placeholder";
  static readonly OPEN_TOOLBAR_TITLE: string = "open_toolbar_title";
  static readonly APPLICANT_CHANNEL_LOGO: string = "APPLICANT_CHANNEL_LOGO";
  static readonly ALL_BLT_COLORS: string = "bltColors_val";

  // SAVE state dialogs
  static readonly NCR_DIALOG_TITLE: string = "ncr_modal_title";
  static readonly NCR_BUTTON_ACCEPT: string = "ncr_button_accept";
  static readonly ERROR_MESSAGE_REQUIRED: string = "error_message_required";
  static readonly ERROR_MESSAGE_MISMATCH: string = "error_message_mismatch";
  static readonly ERROR_MESSAGE_VALID_DATA: string = "Enter valid data";
  static readonly NCR_ERROR_PASS_UNDER_6: string = "ncr_error_pass_under_6";
  static readonly NCR_ERROR_USER_UNDER_6: string = "ncr_error_user_under_6";
  static readonly NCR_ERROR_PASS_OVER_32: string = "ncr_error_pass_over_32";
  static readonly NCR_ERROR_USER_OVER_256: string = "ncr_error_user_over_256";
  static readonly ERROR_MESSAGE_NCR_VALIDATION: string = "error_message_ncr_validation";

  // CONTINUE state
  static readonly OPEN_CONTINUE_WELCOME: string = "open_continue_welcome";
  static readonly OPEN_CONTINUE_TITLE: string = "open_continue_title";
  static readonly OPEN_CONTINUE_INSTRUCTIONS: string = "open_continue_instructions";
  static readonly OPEN_CONTINUE_LABEL_EMAIL: string = "open_continue_label_email";
  static readonly OPEN_CONTINUE_LABEL_DOB: string = "open_continue_label_dob";
  static readonly OPEN_CONTINUE_LABEL_TAX_ID: string = "open_continue_label_tax_id";
  static readonly OPEN_CONTINUE_LABEL_LAST_NAME: string = "open_continue_label_last_name";

  // INITIAL DISCLOSURE state
  static readonly DISCLOSURE_ACCEPT_TEXT: string = "disclosure_accept_text";

  // PLAID/FINICITY
  static readonly OPEN_FAILED_PLAID_VERIFICATION: string = "open_failed_plaid_verification";
  static readonly PENDING_MANUAL_VERIFICATION: string = "pending_manual_verification";
  static readonly PENDING_AUTOMATIC_VERIFICATION: string = "pending_automatic_verification";
  static readonly OPEN_VALIDATE_PLAID_DEPOSIT_MESSAGE: string = "open_validate_plaid_deposit_message";
  static readonly OPEN_VALIDATE_PLAID_VALIDATE_BUTTON: string = "open_validate_plaid_validate_button";
  static readonly OPEN_VALIDATE_PLAID_VALIDATE_RETURN_TEXT: string = "open_validate_plaid_validate_return_text";

  // PRODUCT SELECTION state
  static readonly OPEN_PRODUCT_SELECTION_MODAL_TITLE: string = "open_product_selection_modal_title";
  static readonly OPEN_PRODUCT_SELECTION_NO_ACCOUNT_HEADER: string = "open_product_selection_no_account_header";
  static readonly OPEN_PRODUCT_SELECTION_NO_ACCOUNT_BODY: string = "open_product_selection_no_account_body";
  static readonly OPEN_PRODUCT_SELECTION_ADD_BUTTON: string = "open_product_selection_add_button";

  //identification state
  static readonly OPEN_IDENTIFICATION_ERROR_REQUIRED: string = "open_identification_error_required";
  static readonly OPEN_IDENTIFICATION_ERROR_PATTERN: string = "open_identification_error_pattern";
  static readonly OPEN_IDENTIFICATION_LABEL_TYPE: string = "open_identification_label_type";
  static readonly OPEN_IDENTIFICATION_LABEL_NUMBER: string = "open_identification_label_number";
  static readonly OPEN_IDENTIFICATION_LABEL_ISSUEDATE: string = "open_identification_label_issueDate";
  static readonly OPEN_IDENTIFICATION_LABEL_EXPIRYDATE: string = "open_identification_label_expiryDate";
  static readonly OPEN_IDENTIFICATION_LABEL_COUNRTY: string = "open_identification_label_country";
  static readonly OPEN_IDENTIFICATION_LABEL_STATE: string = "open_identification_label_state";
  static readonly OPEN_IDENTIFICATION_ADD_BUTTON: string = "open_identification_add_button";

  //phone state
  static readonly OPEN_PHONE_LABEL_TYPE: string = "open_phone_label_type";
  static readonly OPEN_PHONE_LABEL_PHONE_NUMBER: string = "open_phone_label_phone_number";
  static readonly OPEN_PHONE_ADD_BUTTON: string = "open_phone_add_button";

  //Address state
  static readonly OPEN_ADDRESS_LABEL_TYPE_CODE: string = "open_address_label_type_code";
  static readonly OPEN_ADDRESS_MIRROR_PRIMARY: string = "open_address_mirror_primary";
  static readonly OPEN_ADDRESS_LABEL_ADDRESS1: string = "open_address_label_address1";
  static readonly OPEN_ADDRESS_LABEL_ADDRESS2: string = "open_address_label_address2";
  static readonly OPEN_ADDRESS_LABEL_COUNTRY: string = "open_address_label_country";
  static readonly OPEN_ADDRESS_LABEL_ZIP_CODE: string = "open_address_label_zip_code";
  static readonly OPEN_ADDRESS_LABEL_CITY: string = "open_address_label_city";
  static readonly OPEN_ADDRESS_LABEL_STATE: string = "open_address_label_state";
  static readonly OPEN_ADDRESS_ADD_BUTTON: string = "open_address_add_button";

  // Person Info state
  static readonly OPEN_PERSONINFO_LABEL_GENDER: string = "open_personinfo_label_gender";
  static readonly OPEN_PERSONINFO_LABEL_SALUTATION: string = "open_personinfo_label_salutation";
  static readonly OPEN_PERSONINFO_SIGNATURE_HEADER: string = "open_personinfo_signature_header";
  static readonly BRANCH_PERSONINFO_PERSON_TYPE: string = "branch_person_input_person_type";
  static readonly BRANHCH_PERSON_BUTTON_SCAN: string = "branch_person_button_scan";
  static readonly OPEN_PERSONINFO_FIRSTNAME: string = "open_personinfo_label_first_name";
  static readonly OPEN_PERSONINFO_MIDDLENAME: string = "open_personinfo_label_middle_name";
  static readonly OPEN_PERSONINFO_LASTNAME: string = "open_personinfo_label_last_name";
  static readonly OPEN_PERSONINFO_SUFFIX: string = "open_personinfo_label_suffix";
  static readonly OPEN_PERSONINFO_TAXID: string = "open_personinfo_label_tax_id";
  static readonly OPEN_PERSONINFO_DOB: string = "open_personinfo_label_dob";

  // Wolters Kluwer state
  static readonly NO_QUESTIONS: string = "open_wk_NoQuestions";

  // Right Of Survivorship state
  static readonly RIGHT_OF_SURVIVORSHIP_TITLE: string = "open_right_of_survivorship_title";
  static readonly RIGHT_OF_SURVIVORSHIP_TRUE: string = "open_right_of_survivorship_true";
  static readonly RIGHT_OF_SURVIVORSHIP_FALSE: string = "open_right_of_survivorship_false";

  // Existing Applicant state
  static readonly EXISTING_LABEL_LAST_NAME: string = "open_existing_label_last_name";
  static readonly EXISTING_LABEL_DOB: string = "open_existing_label_dob";
  static readonly EXISTING_LABEL_LAST_4_SSN: string = "open_existing_label_last_4_tax_id";
  static readonly EXISTING_LABEL_ACCOUNT_TYPE: string = "open_existing_label_account_type";
  static readonly EXISTING_LABEL_ACCOUNT_NUMBER: string = "open_existing_label_account_number";

  // Questionaaire state
  static readonly NUMBER_VALIDATION_MESSAGE: string = "Must be an integer.";

  // Wall state
  static readonly WALL_MESSAGE_ORIGINATOR: string = "wall_message_originator";
  static readonly WALL_MESSAGE_INVITED: string = "wall_message_invited";

  // Employment state
  static readonly OPEN_EMPLOYMENT_LABEL_EMPLOYER: string = "open_employment_label_employer";
  static readonly OPEN_EMPLOYMENT_LABEL_OCCUPATION: string = "open_employment_label_occupation";
  static readonly OPEN_EMPLOYMENT_LABEL_TITLE: string = "open_employment_label_title";
  static readonly OPEN_EMPLOYMENT_LABEL_ADDRESS1: string = "open_employment_label_address1";
  static readonly OPEN_EMPLOYMENT_LABEL_ADDRESS2: string = "open_employment_label_address2";
  static readonly OPEN_EMPLOYMENT_LABEL_COUNTRY: string = "open_employment_label_country";
  static readonly OPEN_EMPLOYMENT_LABEL_ZIP_CODE: string = "open_employment_label_zip_code";
  static readonly OPEN_EMPLOYMENT_LABEL_STATE: string = "open_employment_label_state";
  static readonly OPEN_EMPLOYMENT_LABEL_CITY: string = "open_employment_label_city";
  static readonly OPEN_EMPLOYMENT_LABEL_PHONE_NUMBER: string = "open_employment_label_phone_number";
  static readonly OPEN_EMPLOYMENT_LABEL_YEARS: string = "open_employment_label_years";
  static readonly OPEN_EMPLOYMENT_LABEL_MONTHS: string = "open_employment_label_months";

  // BusinessInfo
  static readonly OPEN_BUSINESS_INFO_LABEL_BUSINESSNAME: string = "open_business_info_label_businessName";
  static readonly OPEN_BUSINESS_INFO_LABEL_DBA: string = "open_business_info_label_dba";
  static readonly OPEN_BUSINESSINFO_LABEL_BUSINESS_TYPE: string = "open_business_info_label_businessType";
  static readonly OPEN_BUSINESS_INFO_LABEL_TAX_ID_TYPE: string = "open_business_info_label_taxIdType";
  static readonly OPEN_BUSINESS_INFO_OPTION_EIN: string = "open_business_info_option_EIN";
  static readonly OPEN_BUSINESS_INFO_OPTION_SSN: string = "open_business_info_option_SSN";
  static readonly OPEN_BUSINESS_INFO_LABEL_COUNTRY: string = "open_business_info_label_country";
  static readonly OPEN_BUSINESS_INFO_LABEL_DOE: string = "open_business_info_label_doe";
  static readonly OPEN_BUSINESS_INFO_LABEL_NAICS: string = "open_business_info_label_NAICS";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT: string = "open_business_info_forprofit_label";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT_TRUE: string = "open_business_info_forprofit_true_label";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT_FALSE: string = "open_business_info_forprofit_false_label";
  static readonly OPEN_BUSINESS_INFO_EIN_ERROR: string = "open_business_info_ein_error";

  //Demographics
  static readonly OPEN_DEMOGRAPHICS_EDUCATIONLEVEL: string = "open_demographics_educationLevel";
  static readonly OPEN_DEMOGRAPHICS_INCOMELEVEL: string = "open_demographics_incomeLevel";
  static readonly OPEN_DEMOGRAPHICS_MARITALSTATUS: string = "open_demographics_maritalStatus";
  static readonly OPEN_DEMOGRAPHICS_LANGUAGE: string = "open_demographics_language";
  static readonly OPEN_DEMOGRAPHICS_EMPLOYMENTSTATUS: string = "open_demographics_employmentStatus";
  static readonly OPEN_DEMOGRAPHICS_CITIZENSHIP: string = "open_demographics_citizenship";
  static readonly OPEN_DEMOGRAPHICS_ETHNICITY: string = "open_demographics_ethnicity";
  static readonly OPEN_DEMOGRAPHICS_RACE: string = "open_demographics_race";

  // Funding state
  static readonly OPEN_FUNDING_PLAID_ADD_ACCOUNT: string = "open_funding_plaid_add_account";
  static readonly OPEN_FUNDING_PLAID_BUTTON: string = "open_funding_plaid_button";
  static readonly OPEN_FUNDING_LABEL_PLAID_AMOUNT: string = "open_funding_label_plaid_amount";
  static readonly OPEN_FUNDING_PLAID_DISCLAIMER: string = "open_funding_plaid_disclaimer";
  static readonly OPEN_FUNDING_LABEL_ROUTING_NUMBER: string = "open_funding_label_routing_number";
  static readonly OPEN_FUNDING_LABEL_CC_SUBMIT_BUTTON: string = "open_funding_label_cc_submit_button";
  static readonly OPEN_FUNDING_LABEL_CC_SUBMIT_DISCLAIMER: string = "open_funding_label_cc_submit_disclaimer";
  static readonly OPEN_FUNDING_LABEL_PLAID_LINKED: string = "open_funding_label_plaid_linked";
  static readonly OPEN_FUNDING_LABEL_ACCOUNT_NUMBER: string = "open_funding_label_account_number";
  static readonly OPEN_FUNDING_LABEL_AMOUNT: string = "open_funding_label_amount";
  static readonly OPEN_FUNDING_LABEL_CC_NAME: string = "open_funding_label_cc_name";
  static readonly OPEN_FUNDING_LABEL_CC_MONTH: string = "open_funding_label_cc_month";
  static readonly OPEN_FUNDING_LABEL_CC_YEAR: string = "open_funding_label_cc_year";
  static readonly OPEN_FUNDING_LABEL_CC_AMOUNT: string = "open_funding_label_cc_amount";
  static readonly OPEN_FUNDING_LABEL_CC_NUMBER: string = "open_funding_label_cc_number";
  static readonly OPEN_FUNDING_LABEL_CC_EXPIRATION_HEADER: string = "open_funding_label_cc_expiration_header";
  static readonly OPEN_FUNDING_LABEL_CC_SECURITY_CODE: string = "open_funding_label_cc_security_code";
  static readonly OPEN_FUNDING_LABEL_ACCOUNT_TYPE: string = "open_funding_label_account_type";
  static readonly OPEN_FUNDING_LABEL_TYPE: string = "open_funding_label_type";
  static readonly OPEN_FUNDING_ERROR_REQUIRED: string = "open_funding_error_required";

  // Capture state
  static readonly OPEN_CAPTURE_OCR_POST_FAILED: string = "open_capture_ocr_post_failed";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_FOCUS: string = "open_capture_instructions_focus";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_HORIZONTAL: string = "open_capture_instructions_horizontal";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_BORDER: string = "open_capture_instructions_border";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_SKEW: string = "open_capture_instructions_skew";
  static readonly OPEN_CAPTURE_SCAN_BUTTON: string = "open_capture_scan_button";

  // Business Continue state
  static readonly OPEN_BUSINESS_CONTINUE_WELCOME: string = "open_business_continue_welcome";
  static readonly OPEN_BUSINESS_CONTINUE_INSTRUCTIONS: string = "open_business_continue_instructions";
  static readonly OPEN_BUSINESS_CONTINUE_BUSINESS_NAME_LABEL: string = "open_business_continue_business_name_label";
  static readonly OPEN_BUSINESS_CONTINUE_TAX_ID_LABEL: string = "open_business_continue_tax_id_label";
  static readonly OPEN_BUSINESS_CONTINUE_TAX_ID_HINT: string = "open_business_continue_tax_id_hint";
  static readonly OPEN_BUSINESS_CONTINUE_DOE_LABEL: string = "open_business_continue_date_of_establishment";

  // Business ownership state
  static readonly OWNERSHIP_OVER_100: string = "open_business_ownership_over100_warning";
  static readonly OWNERSHIP_ERROR_MAX: string = "open_business_ownership_error_max";
  static readonly OWNERSHIP_LABEL_PERCENT: string = "open_business_ownership_label_percentage";
  static readonly OWNERSHIP_LABEL_TITLE: string = "open_business_ownership_label_title";

  // Invite Applicants state
  static readonly OPEN_INVITEAPPLICANTS_FIRST_NAME: string = "open_inviteapplicants_label_firstName";
  static readonly OPEN_INVITEAPPLICANTS_LAST_NAME: string = "open_inviteapplicants_label_lastName";
  static readonly OPEN_INVITEAPPLICANTS_EMAIL: string = "open_inviteapplicants_label_email";
  static readonly OPEN_INVITEAPPLICANTS_BUTTON_ADD: string = "open_inviteapplicants_button_add";
  static readonly OPEN_INVITEAPPLICANTS_INVALID_EMAIL: string = "open_welcome_error_invalid_email";
  static readonly INVITEAPPLICANT_STATE_CIP: string = "inviteApplicant_state_CIP";

  // Email state
  static readonly OPEN_EMAIL_ADD_BUTTON: string = "open_email_add_button";
  static readonly OPEN_EMAIL_LABEL_TYPE: string = "open_email_label_type";
  static readonly OPEN_EMAIL_LABEL_ADDRESS: string = "open_email_label_address";
  static readonly OPEN_EMAIL_ERROR_REQUIRED: string = "open_email_error_required";
  static readonly OPEN_EMAIL_ERROR_INVALID: string = "open_email_error_invalid";

  //Prequalification
  static readonly OPEN_PREQUALIFICATION_REJECT_MESSAGE: string = "open_prequalification_reject_message";

  //Emp Channel Applicant State
  static readonly WORKSPACE_CLOSED_BANNER: string = "branch_workspace_closed_banner";

  //Account Applicant Disclosure
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_ACCEPT: string = "open_account_applicant_disclosure_button_accept";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_CANCEL: string = "open_account_applicant_disclosure_button_cancel";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_CLOSE: string = "open_account_applicant_disclosure_button_close";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_EDIT: string = "open_account_applicant_disclosure_button_edit";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_EMAIL: string = "open_account_applicant_disclosure_button_email";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_PRINT: string = "open_account_applicant_disclosure_button_print";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_RESET: string = "open_account_applicant_disclosure_button_reset";
  static readonly OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_SIGNATURE: string = "open_account_applicant_disclosure_button_signature";

  //Initial Disclosure
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_CANCEL: string = "open_initial_disclosure_button_cancel";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_CLOSE: string = "open_initial_disclosure_button_close";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_DECLINE: string = "open_initial_disclosure_button_decline";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_EDIT: string = "open_initial_disclosure_button_edit";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_EMAIL: string = "open_initial_disclosure_button_email";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_PRINT: string = "open_initial_disclosure_button_print";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_RESET: string = "open_initial_disclosure_button_reset";
  static readonly OPEN_INITIAL_DISCLOSURE_BUTTON_SUBMIT: string = "open_initial_disclosure_button_submit";

  //Final Disclosure
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_ACCEPT: string = "open_final_disclosure_button_accept";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_CANCEL: string = "open_final_disclosure_button_cancel";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_CLOSE: string = "open_final_disclosure_button_close";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_EDIT: string = "open_final_disclosure_button_edit";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_EMAIL: string = "open_final_disclosure_button_email";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_PRINT: string = "open_final_disclosure_button_print";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_RESET: string = "open_final_disclosure_button_reset";
  static readonly OPEN_FINAL_DISCLOSURE_BUTTON_SIGNATURE: string = "open_final_disclosure_button_signature";
}
