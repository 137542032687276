import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { ApplicantCloneFactoryConstant } from "@/common/constant/applicantCloneFactoryConstant";
import ApplicantFactory from "@/common/services/Applicant/applicantFactory";
import ApplicantBusinessFactory from "@/common/services/Applicant/ApplicantBusinessFactory";
import ApplicantPersonFactory from "@/common/services/Applicant/ApplicantPersonFactory";
import type { ICustomerSearchPayload } from "@/common/models/ICustomerSearch";
@injectable()
class ApplicantCloneFactory {
  constructor(
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory,
    @inject(ApplicantBusinessFactory) private applicantBusinessFactory: ApplicantBusinessFactory,
    @inject(ApplicantPersonFactory) private applicantPersonFactory: ApplicantPersonFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  clone(originalApplicant: ICustomerSearchPayload, newWorkspaceUUID: string) {
    return Promise.all([
      this.applicantFactory.post(newWorkspaceUUID, originalApplicant.applicantType),
      this.applicantFactory.setApplicantInfo(originalApplicant.workspaceUUID, originalApplicant)
    ]).then(async (response) => {
      const newApplicant = response[0][0];
      originalApplicant = response[1];
      newApplicant.workspaceUUID = newWorkspaceUUID;
      await Promise.all([
        this.cloneInfoDTO(originalApplicant, newApplicant),
        Promise.all(
          [
            ApplicantCloneFactoryConstant.ADDRESS,
            ApplicantCloneFactoryConstant.PHONE,
            ApplicantCloneFactoryConstant.EMAIL,
            ApplicantCloneFactoryConstant.IDENTIFICATION,
            ApplicantCloneFactoryConstant.EMPLOYMENT,
            ApplicantCloneFactoryConstant.DEMOGRAPHIC
          ].map((urlResource) => {
            return this.cloneSimpleDTO(originalApplicant, newApplicant, urlResource);
          })
        )
      ]);
      return newApplicant;
    });
  }

  cloneSimpleDTO(originalApplicant: Applicant, newApplicant: Applicant, urlResource: string) {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${originalApplicant.workspaceUUID}/applicant/${originalApplicant.applicantId}/${urlResource}`,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        response = response === null ? [] : response;
        response = Array.isArray(response) ? response : [response];
        return Promise.all(
          response.map((DTO: { [x: string]: string }) => {
            delete DTO[urlResource + "Id"];
            return this.httpHandler.post(
              `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${newApplicant.workspaceUUID}/applicant/${newApplicant.applicantId}/${urlResource}`,
              DTO,
              {},
              ResponseTypes.Payload
            );
          })
        );
      });
  }

  cloneInfoDTO(originalApplicant: Applicant, newApplicant: Applicant) {
    return originalApplicant.applicantType === ApplicantCloneFactoryConstant.BUSINESS
      ? this.applicantBusinessFactory.post(
          newApplicant.workspaceUUID,
          newApplicant.applicantId,
          originalApplicant.business
        )
      : this.applicantPersonFactory.post(
          newApplicant.workspaceUUID,
          newApplicant.applicantId,
          originalApplicant.person
        );
  }
}
export default ApplicantCloneFactory;
