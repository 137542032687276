import FACTORY_MSG from "@/common/messages/factory.messages";
import { injectable } from "inversify";

@injectable()
class InfoDialogFactory {
  displayInfo(event: Record<string, unknown>, html: string | Promise<string>, title: string, error: string) {
    const errorMessage = error || FACTORY_MSG.INFO_DIALOG.HTML_LOAD_FAILED;

    const dialogResolveOptions = {
      multiple: true,
      targetEvent: event,
      clickOutsideToClose: true
    };

    const dialogRejectOptions = {
      ok: "Close",
      targetEvent: event,
      clickOutsideToClose: true
    };

    Promise.resolve(html).then(
      (htmlResolve) => {
        return { htmlResolve, title, dialogResolveOptions };
      },
      () => {
        const title = "Error";
        return { errorMessage, title, dialogRejectOptions };
      }
    );
  }
}

export default InfoDialogFactory;
