import { inject, injectable } from "inversify";
import { isString } from "lodash";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
@injectable()
class CoreLoginFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {}

  getCoreLogin() {
    return this.workspaceMetadataStore.coreCredentials.coreLogin;
  }

  getCorePassword() {
    return this.workspaceMetadataStore.coreCredentials.corePassword;
  }

  async collectCoreLogin() {
    if (
      isString(this.workspaceMetadataStore.coreCredentials.coreLogin) &&
      isString(this.workspaceMetadataStore.coreCredentials.corePassword)
    ) {
      return Promise.resolve();
    }
  }

  async resetCoreCredential() {
    this.workspaceMetadataStore.coreCredentials.coreLogin = null;
    this.workspaceMetadataStore.coreCredentials.corePassword = null;
  }

}
export default CoreLoginFactory;
