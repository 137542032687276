export class WorkflowConstant {
  static readonly PREVIOUS: string = "previous";
  static readonly NEXT: string = "next";
  static readonly FORWORD: string = "forward";
  static readonly NONE: string = "NONE";
  static readonly CLOSED: string = "CLOSED";
  static readonly CONTINUE: string = "Continue";
  static readonly CANCEL: string = "Cancel";
  static readonly ENROLLMENT_FOR: string = "Enrollment for ";
  static readonly CREATED: string = "CREATED";
}
