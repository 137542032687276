<template>
  <div v-if="contentLoader" class="contentLoaderWrap">
    <div :class="message ? 'content-loading' : 'circle-loading'">
      <jh-progress type="circular" size="large" indeterminate> </jh-progress>
    </div>
    <div v-if="message" class="center" :key="message">
      <jha-slider>
        <jha-slider-content :focusedPane="'messageTransition'">
          <jha-slider-pane messageTransition focus>
            <label class="content-message"> {{ message }} </label>
          </jha-slider-pane>
        </jha-slider-content>
      </jha-slider>
    </div>
  </div>
</template>
<script lang="ts">
import "@jack-henry/jh-ui/components/progress/progress.js";
import "@banno/jha-wc/src/sliders/jha-slider/jha-slider.js";
import "@banno/jha-wc/src/sliders/jha-slider-content/jha-slider-content.js";
import "@banno/jha-wc/src/sliders/jha-slider-pane/jha-slider-pane.js";
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltContentloader",
  data: () => ({
    timer: null as any,
    message: ""
  }),
  props: {
    contentLoader: { type: Boolean },
    contents: {
      default: () => []
    }
  },
  mounted: function () {
    // This functionality will work when contents array is paased to this component.
    // Below changes are made to support the CIP Preloader with messages.
    if (this.contents && this.contents.length > 0) {
      setTimeout(() => {
        this.message = this.contents[0];
      }, 1000);
    }
    if (this.contents && this.contents.length > 1) {
      let index = 1;
      this.timer = setInterval(() => {
        this.message = this.contents[index++];
        if (index === this.contents.length) clearInterval(this.timer);
      }, 5000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
});
</script>
<style scoped>
.content-message {
  font-size: 14px;
  color: var(--body-text-secondary-color);
}
.center {
  align-items: center;
  text-align: center;
  padding-top: 16px;
}
.contentLoaderWrap{
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  display: block;
  right: 0;
  bottom: 0;
  height: 25px;
  min-width: 35px;
  max-width: 100%;
  z-index: 100;
}
</style>
