import { defineStore } from "pinia";
import { LocalStorageKeyConstant } from "@/common/constant/LocalStorageKeyConstant";
import { UUIDFactory } from "@/common/services/services.module";
import WorkspaceConstants from "@/common/constant/WorkspaceConstants";
import { ref, watch } from "vue";

export interface LoggedInUser {
  firstName: string,
  lastName: string,
}

const setLegacyLocalStorageValue = (key: string, value: string | null) => value
  ? localStorage.setItem(key, `"${value}"`)
  : localStorage.removeItem(key);

const setLocalStorageValue = (key: string, value: string | null) => value
  ? localStorage.setItem(key, value)
  : localStorage.removeItem(key);

const isInLegacyAngularJsStorageFormat = (value: string) => value.startsWith("\"") && value.endsWith("\"")

const parseLegacyStoreFormat = (value: string | null): string | null => value ? value.substring(1, value.length - 1) : null

const toLegacyStorageFormat = (value: string): string => `"${value}"`

const ensureGuidExists = () => {
  const guid = localStorage.getItem(LocalStorageKeyConstant.GUID)
  if (!guid) {
    const newGuid = new UUIDFactory().createUUID()
    localStorage.setItem(LocalStorageKeyConstant.GUID, toLegacyStorageFormat(newGuid))
  }
}

const getLegacyLocalStorageItem = (key: string): string | null => {
  const value = localStorage.getItem(key)
  return value ? parseLegacyStoreFormat(value) : null
}

const getLocalUser = (): null | LoggedInUser => {

  const userManifest = localStorage.getItem(LocalStorageKeyConstant.USER)

  if(!userManifest) return null

  try {
    return JSON.parse(userManifest) as LoggedInUser
  } catch (e) {
    console.error("Failed to deserialize user from localstorage")
    return null
  }

}

const applyBackwardsCompatibility = (key: string) => {
  const value = localStorage.getItem(key)
  if(value != null && !isInLegacyAngularJsStorageFormat(value)) {
    localStorage.setItem(key, toLegacyStorageFormat(value))
  }
}

function getLocalStorageNumberOrNull(key: string): number | null {
  const value = localStorage.getItem(key)
  if(value == null) {
    return null
  }
  try {
    return parseInt(value)
  } catch (e) {
    console.error(`Error attempting to pull key: ${key} from local storage, expected type Number, but received: ${value}`)
    return null
  }
}

export const useLocalStorage = defineStore('LOCAL_STORAGE', () => {

  ensureGuidExists()

  applyBackwardsCompatibility(LocalStorageKeyConstant.GUID)
  applyBackwardsCompatibility(LocalStorageKeyConstant.LOGIN)
  applyBackwardsCompatibility(LocalStorageKeyConstant.APITOKEN)

  const apitoken = ref(getLegacyLocalStorageItem(LocalStorageKeyConstant.APITOKEN))
  const guid = ref(getLegacyLocalStorageItem(LocalStorageKeyConstant.GUID))
  const login = ref(getLegacyLocalStorageItem(LocalStorageKeyConstant.LOGIN))

  const user = ref(getLocalUser())

  const createdById = ref(localStorage.getItem(LocalStorageKeyConstant.CREATED_BY_ID) || WorkspaceConstants.CREATED_BY_ME);
  const createdByLabel = ref(localStorage.getItem(LocalStorageKeyConstant.CREATED_BY_LABEL) || WorkspaceConstants.CREATED_BY_ME);

  const ssoUuid = ref(localStorage.getItem(LocalStorageKeyConstant.SSO_UUID));
  const ssoRedirectApplicantId = ref(getLocalStorageNumberOrNull(LocalStorageKeyConstant.SSO_REDIRECT_APPLICANT_ID))
  const ssoRedirectWorkspaceUuid = ref(localStorage.getItem(LocalStorageKeyConstant.SSO_REDIRECT_WORKSPACE_UUID))
  const ssoRedirectTrackerUuid = ref(localStorage.getItem(LocalStorageKeyConstant.SSO_REDIRECT_TRACKER_UUID))
  const ssoRedirectProviderUuid = ref(localStorage.getItem(LocalStorageKeyConstant.SSO_REDIRECT_PROVIDER_UUID))
  const isSsoRedirect = ref(localStorage.getItem(LocalStorageKeyConstant.IS_SSO_REDIRECT))

  const clearAuthenticationData = () => {
    apitoken.value = null;
    login.value = null;
    user.value = null;
  }

  const clearSsoRedirectData = () => {
    isSsoRedirect.value = null
    ssoRedirectWorkspaceUuid.value = null
    ssoRedirectTrackerUuid.value = null
    ssoRedirectProviderUuid.value = null
    ssoRedirectApplicantId.value = null
  }

  // write into legacy format
  watch(apitoken, () => setLegacyLocalStorageValue(LocalStorageKeyConstant.APITOKEN, apitoken.value))
  watch(guid, () => setLegacyLocalStorageValue(LocalStorageKeyConstant.GUID, guid.value))
  watch(login, () => setLegacyLocalStorageValue(LocalStorageKeyConstant.LOGIN, login.value))

  watch(user, () => setLocalStorageValue(LocalStorageKeyConstant.USER, JSON.stringify(user.value)))

  watch(createdById, () => setLocalStorageValue(LocalStorageKeyConstant.CREATED_BY_ID, createdById.value))
  watch(createdByLabel, () => setLocalStorageValue(LocalStorageKeyConstant.CREATED_BY_LABEL, createdByLabel.value))

  watch(ssoUuid, () => setLocalStorageValue(LocalStorageKeyConstant.SSO_UUID, ssoUuid.value))

  watch(isSsoRedirect, () => setLocalStorageValue(LocalStorageKeyConstant.IS_SSO_REDIRECT, isSsoRedirect.value))
  watch(ssoRedirectApplicantId, () => setLocalStorageValue(LocalStorageKeyConstant.SSO_REDIRECT_APPLICANT_ID, ssoRedirectApplicantId.value?.toString() ?? null))
  watch(ssoRedirectProviderUuid, () => setLocalStorageValue(LocalStorageKeyConstant.SSO_REDIRECT_PROVIDER_UUID, ssoRedirectProviderUuid.value))
  watch(ssoRedirectWorkspaceUuid, () => setLocalStorageValue(LocalStorageKeyConstant.SSO_REDIRECT_WORKSPACE_UUID, ssoRedirectWorkspaceUuid.value))
  watch(ssoRedirectTrackerUuid, () => setLocalStorageValue(LocalStorageKeyConstant.SSO_REDIRECT_TRACKER_UUID, ssoRedirectTrackerUuid.value))

  return {
    apitoken,
    guid,
    login,
    user,
    createdById,
    createdByLabel,
    ssoUuid,
    ssoRedirectApplicantId,
    ssoRedirectProviderUuid,
    ssoRedirectWorkspaceUuid,
    ssoRedirectTrackerUuid,
    isSsoRedirect,
    clearAuthenticationData,
    clearSsoRedirectData,
  }

})

