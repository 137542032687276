import { injectable } from "inversify";

@injectable()
class EinFilter {
    ein(ein: any) {
        ein = ein || '';
        return (ein.length === 4) ? 'XX-XXX' + ein :
            (ein.length >= 9) ? ein.substr(0, 2) + '-' + ein.substr(2) : undefined;
    }
}

export default EinFilter;