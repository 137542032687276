import { Container, injectable } from "inversify";
import { autobindIoC } from "./decorators/ioc.autobind";
import * as services from './services/services.module';

const container: Container = new Container();

// todo(mikol): Replace with @Service annotations
autobindIoC(container, services);

//todo(mikol): We should eventually make the container a private singleton
export default container;

/**
 * Automatically binds the decorated class to a singleton IOC {@link container}.
 * the name `Service` was chosen to mimic our current API Kotlin/Spring stack IOC decorators.
 *
 *  @Service
 *  class MyService { ... }
 *
 * @param target -- the target class constructor
 */
export function Service<T extends abstract new (...args: never) => unknown>(target: T) {
  injectable()(target.prototype)
  container.bind(target).toSelf()
}