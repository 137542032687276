<template>
  <div class="blt-signature-field-wrapper blt-signature-field-wrapper-custom">
    <div v-if="!hideLabel">{{ signatureLabel }}</div>
    <div>
      <jha-button
        primary
        default
        sync
        v-if="showButtonBorder"
        class="reset main"
        :class="{ disabled: disableClick && !hasSignature }"
        type="button"
        @keydown.enter="openSignatureDialog"
        @click="openSignatureDialog"
        :disabled="disableClick && !hasSignature"
      >
        <div class="d-flex">
          <jha-icon-edit></jha-icon-edit> <span class="sign-link"> {{ buttonTitle }} </span>
        </div>
      </jha-button>
      <button
        v-if="!showButtonBorder"
        class="reset main"
        :class="{ disabled: disableClick && !hasSignature }"
        type="button"
        @keydown.enter="openSignatureDialog"
        @click="openSignatureDialog"
        :disabled="disableClick && !hasSignature"
      >
        <jha-icon-edit></jha-icon-edit>
        <span class="sign-link">
          {{ buttonTitle }}
        </span>
      </button>
    </div>
    <blt-modal-dialog
      type="small-dialog"
      :showDialog="showDialog"
      :title="dialogTitle || BltSignatureField.SIGNATURE"
      :showFooter="false"
      :dialogBorder="false"
      @cancel="cancel"
    >
      <template #dialog-content>
        <div class="signature-wrapper" v-if="!isEditMode">
          <div class="signature">
            <img :src="getImage" alt="signature" />
            <button
              class="edit reset"
              type="button"
              @keydown.enter="isEditMode = true"
              @click="isEditMode = true"
              :disabled="disableClick"
            >
              {{ BltSignatureField.EDIT }}
            </button>
            <div class="signature-line">
              <span class="sign-instruction">{{ BltSignatureField.DRAW_SIGN_MSG }}</span>
            </div>
          </div>
          <div class="btn-save">
            <jha-button type="button" disabled>{{ BltSignatureField.SAVE }}</jha-button>
          </div>
        </div>
        <div class="signature-width" v-else>
          <bltSignatureFormDialog @signatureChanged="save($event)"> </bltSignatureFormDialog>
        </div>
      </template>
    </blt-modal-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { $inj } from "@/common/decorators/depinject";
import { LanguageFactory } from "@/common/services/services.module";
import { BltSignatureField } from "@/common/constant/bltSignatureFieldConstant";
import bltSignatureFormDialog from "@/common/components/bltSignatureFormDialog/bltSignatureFormDialog.vue";

export default defineComponent({
  name: "bltSignatureField",
  emits: ["signatureChanged"],
  components: { bltSignatureFormDialog },
  props: {
    signatureLabel: {
      type: String,
      default: ""
    },
    signatureImage: {
      type: String,
      default: ""
    },
    dialogBorder: {
      type: Boolean,
      default: true
    },
    dialogTitle: {
      type: String,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: () => false
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    showButtonBorder: {
      type: Boolean,
      default: false
    },
    addSignatureButtonLabel: {
      type: String,
      default: ""
    },
    viewSignatureButtonLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      BltSignatureField,
      base64Image: "" as string,
      showDialog: false as boolean,
      isEditMode: false as boolean
    };
  },
  setup() {
    const languageFactory: LanguageFactory = $inj(LanguageFactory);
    return { languageFactory };
  },
  computed: {
    buttonTitle() {
      return this.hasSignature
        ? this.viewSignatureButtonLabel || BltSignatureField.VIEW_SIGNATURE
        : this.addSignatureButtonLabel || BltSignatureField.ADD_SIGNATURE;
    },
    hasSignature() {
      return !!this.getImage;
    },
    getImage() {
      return this.base64Image ? this.base64Image : this.signatureImage;
    }
  },
  methods: {
    openSignatureDialog() {
      this.showDialog = true;
    },
    save(event: any) {
      this.base64Image = event.detail;
      this.$emit("signatureChanged", event);
      this.showDialog = false;
      this.isEditMode = false;
    },
    cancel() {
      this.showDialog = false;
      if (!this.hasSignature) {
        this.isEditMode = true;
      } else {
        this.isEditMode = false;
      }
    }
  },
  created() {
    if (!this.hasSignature) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
  }
});
</script>
<style scoped>
jha-icon-edit {
  fill: var(--jha-color-primary);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
.blt-signature-field-wrapper {
  display: block;
  margin-top: var(--jh-space-400);
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  color: var(--body-text-primary-color);
  font-size: 14px;
}

label {
  font-size: 14px;
  color: var(--jha-text-light, #8c989f);
  font-weight: 400;
  margin: 0 8px;
  margin-left: var(--jh-space-50);
}

button.reset {
  box-sizing: border-box;
  height: auto;
  color: var(--jh-color-content-brand-enabled);
  touch-action: manipulation;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  margin: 0;
  text-align: left;
  width: 100%;
  padding: 6px 8px;
  -webkit-appearance: none;
}

button.edit {
  position: absolute;
  top: 12px;
  right: 16px;
  padding: 8px;
  z-index: 1;
  width: auto;
  color: var(--jha-color-primary);
}
.signature-width {
  width: 590px;
  padding: 0 24px;
}
.signature-width :deep(.signature-form-dialog) {
  width: 100%;
}
button.main {
  border-radius: 4px;
  margin: 0px 0 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature-wrapper {
  padding: 0 24px;
  max-width: 600px;
  width: 100%;

}
.signature {
  overflow: hidden;
  position: relative;
  border: 1px solid var(--jha-form-floating-group-outline-border-color, var(--jha-border-color, #8c989f));
  height: 200px;
  border-radius: 4px;
}
@media (prefers-color-scheme: dark) {
  .signature img {
    filter: invert(0);
  }
}
.signature-line {
  position: absolute;
  bottom: 0px;
  right: 24px;
  left: 24px;
  z-index: 0;
  color: var(--jha-signature-line, var(--jha-text-light, #8c989f));
  padding: 8px 0px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-size: var(--jha-form-signature-input-font-size, 12px);
  border-top: 1px solid var(--jha-color-gray-light, #e6e6e6);
}
.sign-instruction {
  font-weight: 400;
}
.btn-save {
  padding: 16px 24px;
  margin: 0 auto;
  text-align: center;
}
.btn-save jha-button {
  width: 120px;
}

img {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

jha-icon-edit {
  margin-right: 8px;
  fill: var(--jha-color-primary);
}
.sign-link {
  color: var(--link-button-text-color);
}
@media (max-width: 767px) {
  .signature-width {
    width: 100%;
    padding: 0px 16px;
  }
  :deep(.footer) {
    padding-right: 32px;
  }
  .signature-wrapper {
     padding: 0 16px;
  }
}
</style>
