<script setup lang="ts">
import BltModalDialog from '@/common/components/bltDialog/bltModalDialog.vue'
import type IWorkspace from '@/common/models/IWorkspace'
import dayjs from 'dayjs'
import {$inj} from '@/common/decorators/depinject'
import WorkspaceHistoryService, {
  type ApplicantInfo,
  type WorkspaceHistory
} from '@/common/services/WorkspaceHistory/workspaceHistoryService'
import {computed, onMounted, ref} from 'vue'
import HistoryDialogListItem from '@/branchmanager/app/components/workspaceHistoryDialog/historyDialogListItem.vue'
import type IPerson from '@/common/models/IPerson'
import BltEmptyData from "@/common/components/bltEmptyData/bltEmptyData.vue";

const props = defineProps<{
  currentWorkspaceUUID: string
  currentWorkspace: IWorkspace
  showDialog: boolean
}>()

const emits = defineEmits<{
  (e: 'closeModal'): void
}>()

const workspaceHistoryService = $inj(WorkspaceHistoryService)
const history = ref<Array<WorkspaceHistory>>([])
const applicantInfo = ref<Map<number, ApplicantInfo>>(new Map())
const loading = ref(true)

onMounted(() => {
  workspaceHistoryService
    .getHistory(props.currentWorkspaceUUID)
    .then((response) => {
      history.value = response.data.payload
      return getApplicantInfo()
    })
    .then((response) => {
      applicantInfo.value = response
    })
    .finally(() => (loading.value = false))
})

const getApplicantInfo = () => {
  const map = new Map<number, ApplicantInfo>()
  return Promise.all(
    applicantIds.value.map((applicantId) => {
      return workspaceHistoryService.getApplicantInfo(props.currentWorkspaceUUID, applicantId).then((response) => {
        map.set(applicantId, response)
      })
    })
  ).then(() => {
    return map
  })
}

const applicantIds = computed(() => {
  const result = new Set<number>()

  history.value.forEach((item) => {
    if (item.state?.workflow && item.state?.activeApplicantId) {
      result.add(item.state?.activeApplicantId)
    }
  })

  return Array.from(result)
})

const workflows = computed(() => {
  const result = new Set<string>()

  history.value.forEach((item) => {
    if (item.state?.workflow) {
      result.add(item.state.workflow)
    }
  })

  return Array.from(result).join(', ')
})

const trackers = computed(() => {
  const result = new Set<string>()

  history.value.forEach((item) => {
    result.add(item.trackerUuid)
  })

  return Array.from(result).join(', ')
})
</script>

<template>
  <blt-modal-dialog
    :showDialog="showDialog"
    title="Applicant state history"
    :showFooter="false"
    @cancel="emits('closeModal')"
    type="large-dialog-centered">
    <template #dialog-content>
      <jha-progress
        card
        v-if="loading" />
      <div
        v-else
        style="width: 100%">
        <dl>
          <dt>Workspace Type</dt>
          <dd>{{ currentWorkspace.workspaceType }}</dd>

          <dt v-if="workflows">Workflow(s)</dt>
          <dd>{{ workflows }}</dd>

          <dt v-if="trackers">Tracker</dt>
          <dd>{{ trackers }}</dd>

          <dt>Started</dt>
          <dd>{{ dayjs(currentWorkspace.createdDateIso).format('M/D/YY @ hh:mma') }}</dd>
        </dl>

        <blt-empty-data v-if="!history.length"
                        :blt-text="`No Applicant Channel history for this workspace`">
          <template #jhaIcon>
            <jha-icon-receipt class="secondary-fill-color"></jha-icon-receipt>
          </template>
        </blt-empty-data>

        <jha-list>
          <jha-list-item
            v-for="(historyItem, index) in history"
            :key="historyItem.createdTimestamp">
            <history-dialog-list-item
              :historyItem
              :nextHistory="index < history.length ? history[index + 1] : undefined"
              :applicantInfo />
          </jha-list-item>
        </jha-list>
      </div>
    </template>
  </blt-modal-dialog>
</template>

<style scoped>
jha-list-item {
  --jha-list-item-margin-left: 0;
  --jha-list-item-vertical-spacing: 0;
  --jha-list-item-horizontal-spacing: 0;
}

dt {
  font: var(--jh-font-weight-500) var(--jh-font-size-300) / var(--jh-font-line-height-400) var(--jh-font-family-primary);
}

dd {
  margin: 0 0 var(--jh-space-200) 0;
  color: var(--jha-text-dark);
  font: var(--jh-font-weight-400) var(--jh-font-size-350) / var(--jh-font-line-height-500) var(--jh-font-family-primary);
}
</style>
