import type IWorkspace from "@/common/models/IWorkspace";
import {injectable} from "inversify";
@injectable()
class LookupWorkspaceFactory {
    getFilter = (workspace: IWorkspace | undefined) => {
            switch (workspace?.workspaceType) {
                case "PERSON":
                    return {personEnabled: true}
                case "BUSINESS" :
                    return {businessEnabled: true}
                default:
                    return {}
            }
    }
}

export default LookupWorkspaceFactory;