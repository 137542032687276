<!--
@Deprecated: Please use bltAccordion2.
It will make it easier to manage the variables that are inside of the header and body,
since you will be able to keep them typed.
-->

<template>
  <jha-collapsible-group class="header-collapsible">
    <jha-collapsible-section
      v-for="(item, index) in items"
      :key="item"
      :isOpen="toggleAccordion(index)"
      @click="accordionClicked(index)"
      :headless="isHeadless"
    >
      <slot :item="item" :index="index" :selectedItem="selectedItem"></slot>
    </jha-collapsible-section>
  </jha-collapsible-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-group/jha-collapsible-group.js";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-section/jha-collapsible-section.js";

export default defineComponent({
  name: "bltAccordion",
  emits: ["accordion-opened"],
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItem: this.selectedIndex //set to 0 to keep first accordion item open on component load
    };
  },
  computed: {
    isHeadless(): boolean {
      if (this.showHeader) return false;
      return this.items.length <= 1;
    }
  },
  methods: {
    toggleAccordion(index: number): boolean {
      return this.selectedItem === index;
    },
    accordionClicked(index: number): void {
      this.selectedItem = index;
      this.$emit("accordion-opened", this.selectedItem);
    }
  }
});
</script>
