import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type {IApplicantSearch, IExistingCustomerSearch} from "@/common/models/ICustomerSearch";

@injectable()
class CustomerSearchFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  search(
      workspaceUUID: string,
      params: { [index: string]: string | undefined }
  ): Promise<{
      customers: Array<IExistingCustomerSearch>,
      applicants: Array<IApplicantSearch>}
  > {
    const taxId = params.taxid;
    delete params.taxid;
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/customerSearch`,
      {
        params: params || {},
        headers: taxId ? { taxId: taxId } : {}
      },
      ResponseTypes.Payload
    );
  }
}
export default CustomerSearchFactory;
