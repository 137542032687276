export class QuestionType {
  static readonly TEXT: string = "TEXT";
  static readonly NUMBER: string = "NUMBER";
  static readonly SINGLE_CHOICE: string = "SINGLE_CHOICE";
  static readonly MONEY: string = "MONEY";
  static readonly MULTI_CHOICE_LOOKUP: string = "MULTI_CHOICE_LOOKUP";
  static readonly SINGLE_CHOICE_LOOKUP: string = "SINGLE_CHOICE_LOOKUP";
  static readonly MULTI_CHOICE: string = "MULTI_CHOICE";
  static readonly DATE: string = "DATE";
}

export class StatusType {
  static readonly INPROGRESS: string = "INPROGRESS";
  static readonly ANSWERED: string = "ANSWERED";
  static readonly COMPLETED: string = "COMPLETED";
  static readonly REJECT: string = "REJECT";
}
