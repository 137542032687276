import { $inj } from "@/common/decorators/depinject";
import FeatureFactory from "@/common/services/Feature/featureFactory";

const getFeature = (el: any, binding: { value: string }) => {
  el.css("display", "none");
  el.attr("hidden", true);
  const { value } = binding;
  const feature = {
    parent: value.split(":")[0],
    child: value.split(":")[1]
  };

  const featureFactory: FeatureFactory = $inj(FeatureFactory);

  return featureFactory
    .queryCached({
      featureCode: feature.parent === "launchpad" ? undefined : feature.parent,
      appCode: feature.parent === "launchpad" ? "launchpad" : undefined,
      depth: 1
    })
    .then((response) => {
      if (
        response.some((feature: { featureCode: any; child: any }) => {
          return feature.featureCode === feature.child;
        })
      ) {
        el.css("display", "");
        el.attr("hidden", false);
      }
    });
};

const bltAppFeature = {
  mounted: (el: any, binding: { value: string }) => getFeature(el, binding),
  updated: (el: any, binding: { value: string }) => getFeature(el, binding)
};

export default bltAppFeature;
