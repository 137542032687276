import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import LogService from "@/common/services/Log/LogService";
import DEPENDENCYTYPES from "@/common/dependency.types";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";

@injectable()
class ConstantFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService,
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore
  ) {
    this.workspaceMetadataStore.languageConstants = this.getDefault();
  }

  private getDefault() {
    const defaultConstants: { [key: string]: any } = {};
    defaultConstants["urlWhitelist"] = [
      "self",
      "https://*.alliedpayment.com/portalpay/app/**",
      "https://*.demo.alliedpayment.com/portalpay/app/**",
    ];
    defaultConstants["bltColors_val"] = [
      { typeCode: "ERROR", color: "#F44336" },
      { typeCode: "WARNING", color: "#FFA726" },
      { typeCode: "INFO", color: "#3F51B5" },
      { typeCode: "SUCCESS", color: "#4CAF50" },
      { typeCode: "DEFAULT", color: "#333333" },
    ];
    return defaultConstants;
  }

  get(typeCode: string) {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/property/${typeCode}`, {}, ResponseTypes.Payload);
  }
  getAll() {
    return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.BOLTSUI}/property`, {}, ResponseTypes.Payload);
  }
  async init() {
    this.getAll().then((response: any) => {
      const defaultConstants = this.getDefault();
      this.workspaceMetadataStore.languageConstants = { ...response, ...defaultConstants };
    });
  }
  getProp(typeCode: string) {
    return this.workspaceMetadataStore.languageConstants[typeCode];
  }
  getBool(typeCode: string) {
    if (this.workspaceMetadataStore.languageConstants[typeCode] !== "Y"
      && this.workspaceMetadataStore.languageConstants[typeCode] !== "N") {
      this.logService.error(
        "Invalid service property set for [" +
        typeCode +
        "], expected Y/N value but got [" +
        this.workspaceMetadataStore.languageConstants[typeCode] +
        "]"
      );
    } else {
      return this.workspaceMetadataStore.languageConstants[typeCode] === "Y";
    }
  }
}
export default ConstantFactory;
