import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { ApplicantConstant } from "@/common/constant/ApplicantConstant";

@injectable()
class ApplicantDisclosureResourceFactory {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }
    async getPackage(UUID: string, applicantId: number) {
        return await this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${UUID}/applicant/${applicantId}/initialdisclosure`).
            then((data: string) => {
                return {
                    type: ApplicantConstant.APPLICANT,
                    id: applicantId,
                    disclosures: JSON.parse(data).payload || []
                }
            })
    }
}
export default ApplicantDisclosureResourceFactory;
